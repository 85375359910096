import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClickOutside from "react-click-outside";
import MenuHeader from "./components/MenuHeader";
import LeftMenu from "./components/LeftMenu";
import Body from "./components/Body";
import {
  clickOutside,
  toggleMenu,
  switchTooltipsMode
} from "../../actions/common/layout";
import { updateUserAccount } from "../../actions/clinic/account";
import { isMobile } from "react-device-detect";

export default () => {
  const {
    user: {
      id,
      role,
      first_name,
      current_policy_and_terms_version,
      settings: {
        tooltipsModeOn,
        leftMenuOpened,
        policy_and_terms_accepted,
        policy_and_terms_version,
        initialAccount: { setup_completed }
      }
    }
  } = useSelector(state => state.auth);

  const { opened, hidden } = useSelector(state => ({
    opened: state.layout.opened,
    hidden: state.layout.hidden
  }));
  const dispatch = useDispatch();
  const clickOutsideAction = () => dispatch(clickOutside);

  const update_profile = updateUserAccount({ id: id });

  useEffect(() => {
    if (leftMenuOpened && !isMobile) {
      dispatch(toggleMenu);
    }
    if (_.isNull(tooltipsModeOn)) {
      dispatch(switchTooltipsMode);
      dispatch(
        update_profile({ settings: { leftMenuOpened, tooltipsModeOn: false } })
      );
    } else {
      if (tooltipsModeOn) {
        dispatch(switchTooltipsMode);
      }
    }
  }, []);

  return (
    <div>
      <ClickOutside onClickOutside={() => clickOutsideAction}>
        <MenuHeader
          role={role}
          opened={opened}
          /* setup_completed={setup_completed} */
        />
        <DisplayLeftMenu
          userId={id}
          role={role}
          /* setup_completed={setup_completed} */
          setup_completed={true}
          policy_and_terms_accepted={policy_and_terms_accepted}
          opened={opened}
        />
      </ClickOutside>

      <Body
        role={role}
        first_name={first_name}
        policy_and_terms_accepted={policy_and_terms_accepted}
        policy_and_terms_version={policy_and_terms_version}
        current_policy_and_terms_version={current_policy_and_terms_version}
        setup_completed={setup_completed}
        menu_expanded={opened}
      />
    </div>
  );
};

const DisplayLeftMenu = ({
  userId,
  role,
  setup_completed,
  policy_and_terms_accepted,
  opened,
  hidden
}) => {
  if (role === "customer" && (!setup_completed || !policy_and_terms_accepted)) {
    return null;
  } else {
    return <LeftMenu role={role} expanded={opened} userId={userId} />;
  }
};
