import {
  buildAsyncGet,
  buildAsyncPatch,
  buildAsyncPost,
  requested,
  error,
  failed,
  succeeded
} from "../actionsFactory";
import createProxy from "../../api/apiProxy";

export const FETCH_TODAY_INVOICES = "fetch-today-invoices";
export const requestTodayInvoices = () =>
  buildAsyncGet(FETCH_TODAY_INVOICES, `admin/v1/invoicing/today`);

export const GENERATE_INVOICE = "generate-invoice";
export const generateInvoice = () =>
  buildAsyncPost(GENERATE_INVOICE, `admin/v1/invoicing/generate-invoices`);

export const GENERATE_SELECTED_INVOICES = "generate-selected-invoices";
export const generateSelectedInvoices = items => async dispatch => {
  dispatch({
    type: requested(GENERATE_SELECTED_INVOICES),
    payload: { totalInvoices: items.length, status: "Processing", processed: 0 }
  });

  for (let i = 0; i < items.length; i++) {
    try {
      const item = items[i];
      const {
        data: { success, payload }
      } = await createProxy().post(
        `admin/v1/invoicing/generate-invoices`,
        item
      );
      if (success) {
        dispatch({ type: succeeded(GENERATE_INVOICE), payload });
      } else {
        dispatch({
          type: failed(GENERATE_INVOICE),
          payload: { ...item, error: payload }
        });
      }
      if (items.length - 1 === i) {
        dispatch({
          type: succeeded(GENERATE_SELECTED_INVOICES),
          payload: {
            totalInvoices: items.length,
            status: "Finished",
            processed: items.length
          }
        });
      }
    } catch (err) {
      console.log("err", err);
      dispatch({ type: error(GENERATE_INVOICE), payload: { error: err } });
    }
  }
};

export const resetInvoiceProcess = () => ({
  type: requested(GENERATE_SELECTED_INVOICES),
  payload: { totalInvoices: null, status: null, processed: null }
});
