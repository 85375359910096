import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tab, Nav, Image } from "react-bootstrap";
import ClinicHeader from "./components/ClinicHeader";
import styles from "./index.module.css";
import ClinicInfo from "./components/ClinicInfo";
import ClinicTeam from "./components/ClinicTeam";
import ClinicServices from "./components/ClinicServices";
import ClinicCustomizations from "./components/ClinicCustomizations";
import ClinicPayments from "./components/ClinicPayments";
import ClinicSettings from "./components/ClinicSettings";
import history from "../../../history";
import rightArrowImg from "../../../static/img/right-arrow.svg";
import NavLinkCleaner from "../../../components/NavLinkCleaner/NavLinkCleaner";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";
import { withRouter } from "react-router-dom";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TooltipTexts from "../../../common/constants/tooltipsTexts";

const Clinic = () => {
  const dispatch = useDispatch();

  const { clinics, user_id, active_clinic } = useSelector(state => ({
    clinics: state.clinic.account.clinics.content,
    user_id: state.auth.user.id,
    active_clinic: state.clinic.account.activeClinic
  }));

  const { tooltipsMode } = useSelector(state => state.layout);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  const pathname = history.location.pathname;
  const tab_key =
    pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length) ===
    "clinic"
      ? "basic-info"
      : pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);

  let tab;
  const valid_tabs = [
    "basic-info",
    "team",
    "services",
    "customizations",
    "payments",
    "settings"
  ];
  if (valid_tabs.includes(tab_key)) {
    tab = tab_key;
  } else {
    if (pathname.lastIndexOf("team") != -1) {
      tab = "team";
    }
    if (pathname.lastIndexOf("services") != -1) {
      tab = "services";
    }
  }

  if (!clinic) {
    return <p>Loading...</p>;
  } else
    return (
      <div className="body-area">
        {/* <ClinicHeader clinic={clinic} /> */}

        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="clinic" view="clinic" />
          </Col>
        </Row>

        <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
          <Row className="clearfix">
            <Col sm={3}>
              <Nav className={styles.menu} bsStyle="pills" stacked>
                <NavLinkCleaner
                  to={`/clinic/basic-info`}
                  className={
                    tab === "basic-info" ? "adminNavActive" : "adminNav"
                  }
                >
                  Información básica
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/clinic/team`}
                  className={tab === "team" ? "adminNavActive" : "adminNav"}
                >
                  Personal
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/clinic/services`}
                  className={tab === "services" ? "adminNavActive" : "adminNav"}
                >
                  Servicios
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/clinic/customizations`}
                  className={
                    tab === "customizations" ? "adminNavActive" : "adminNav"
                  }
                >
                  Campos Personalizados
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/clinic/payments`}
                  className={tab === "payments" ? "adminNavActive" : "adminNav"}
                >
                  Pagos
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/clinic/settings`}
                  className={tab === "settings" ? "adminNavActive" : "adminNav"}
                >
                  Configuraciones
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
              </Nav>

              {/* Tooltip */}
              {tooltipsMode && (
                <Tooltip title={TooltipTexts[tab].title}>
                  {TooltipTexts[tab].content}
                </Tooltip>
              )}
            </Col>

            <Col sm={9} id="cTab">
              <Tab.Content animation className={styles.contentSolarGardenAdmin}>
                <Tab.Pane eventKey="basic-info">
                  <ClinicInfo clinic={clinic} />
                </Tab.Pane>
                <Tab.Pane eventKey="team">
                  {tab === "team" && (
                    <ClinicTeam
                      clinic_id={clinic.id}
                      clinic_type={clinic.type}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="services">
                  {tab === "services" && (
                    <ClinicServices
                      clinic_id={clinic.id}
                      clinic_type={clinic.type}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="customizations">
                  {tab === "customizations" && (
                    <ClinicCustomizations clinic={clinic} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="payments">
                  {tab === "payments" && <ClinicPayments clinic={clinic} />}
                </Tab.Pane>
                <Tab.Pane eventKey="settings">
                  {tab === "settings" && <ClinicSettings clinic={clinic} />}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
};

export default withRouter(Clinic);
