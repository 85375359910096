import React from "react";
import styles from "./Tooltip.module.css";
import { Image } from "react-bootstrap";
import infoImg from "../../../src/static/img/icons/info.svg";

const Tooltip = ({ title, children }) => {
  return (
    <div className={["h3-section", styles.tooltipWrapper].join(" ")}>
      <div className={styles.tooltipIconWrapper}>
        <div className={styles.tooltipInfoIcon}>
          <Image src={infoImg} responsive className={styles.infoIcon} />
        </div>
      </div>
      <div className="body ppx">
        <div className={styles.title}>{title}</div>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

export default Tooltip;
