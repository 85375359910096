import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { isRelatedAction } from "../utils";
import { pristine, reduce } from "../utils";
import {
  FETCH_MY_CLINICS,
  CHANGE_ACTIVE_CLINIC
} from "../../actions/clinic/account";
import { EDIT_MODE, TOGGLE_MODAL } from "../../actions/clinic/common";
import { UPDATE_CLINIC } from "../../actions/clinic/clinic";

var defaultState = {
  clinics: pristine([]),
  activeClinic: 0,
  editMode: false,
  toggleModal: false
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_MY_CLINICS)) {
    return reduce(state, "clinics", action, FETCH_MY_CLINICS);
  }

  if (action.type === CHANGE_ACTIVE_CLINIC) {
    return {
      ...state,
      activeClinic: action.payload
    };
  }

  if (action.type === requested(EDIT_MODE)) {
    return {
      ...state,
      editMode: !state.editMode,
      clinics: {
        ...state.clinics,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal,
      clinics: {
        ...state.clinics,
        errors: null
      }
    };
  }

  if (action.type === succeeded(UPDATE_CLINIC)) {
    return {
      ...state,
      clinics: {
        ...state.clinics,
        content: [
          ...state.clinics.content.filter(e => e.id !== action.payload.id),
          action.payload
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_CLINIC)) {
    return {
      ...state,
      clinics: {
        ...state.clinics,
        errors: action.payload
      }
    };
  }

  return state;
}
