import React, { useState, useEffect } from "react";
import { Row, Col, Button, Glyphicon } from "react-bootstrap";
import ReactTable from "react-table";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import moment from "moment";
import styles from "../index.module.css";
import ProcessStatus from "./ProcessStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  requestTodayInvoicesToCharge,
  chargeSelectedInvoices,
  resetBillingProcess
} from "../../../../actions/admin/billing";
import { WarnToast } from "../../../../common/functions/toasts";

const Billing = ({ todayInvoicesToCharge }) => {
  const dispatch = useDispatch();

  const [checkedState, setCheckedState] = useState({});

  const [msgState, setMsgState] = useState("");

  const { billingProcess } = useSelector(state => ({
    billingProcess: state.admin.billing.billingProcess.content
  }));

  const chargeInvoicesHandler = () => {
    const invoices_ids = invoiceSelection(checkedState).map(
      invoice => invoice.id
    );

    if (invoices_ids.length && invoices_ids.length > 0) {
      dispatch(chargeSelectedInvoices(invoices_ids));
      setCheckedState({});
      setMsgState("");
    } else {
      setMsgState("Debe seleccionar al menos una factura valida.");
    }
  };

  const invoiceSelection = selection => {
    //should return list of records to invoice from
    if (selection) {
      const clinics = Object.keys(selection);
      const selected_records = todayInvoicesToCharge
        .filter(
          record =>
            clinics.includes(record.clinic_id.toString()) &&
            selection[record.clinic_id]
        )
        .map(record => ({
          id: record.id,
          date: new Date()
        }));
      return selected_records;
    } else return [];
  };

  const onUnselectAll = () => {
    const newCheckedState = todayInvoicesToCharge.reduce((acc, curr) => {
      acc[curr.clinic_id] = false;
      return acc;
    }, {});
    setCheckedState(newCheckedState);
  };

  const onSelectValid = () => {
    const _validRecords = todayInvoicesToCharge.filter(
      record => record.clinic_id && record.primary_payment_method_id
    );
    const newCheckedState = _validRecords.reduce((acc, curr) => {
      acc[curr.clinic_id] = true;
      return acc;
    }, {});
    setCheckedState(newCheckedState);
  };

  const onSelect = e => {
    dispatch(resetBillingProcess());
    setCheckedState({
      ...checkedState,
      [e.target.id]: e.target.checked
    });
  };

  const onRefresh = () => {
    dispatch(requestTodayInvoicesToCharge());
  };

  const toBeinvoicedRecordsColumns = [
    {
      Header: "",
      maxWidth: 60,
      Cell: props => (
        <div style={{ marginTop: "-11px" }}>
          <FieldGroup
            onChange={onSelect}
            id={
              props.original.clinic_id
                ? props.original.clinic_id.toString()
                : ""
            }
            value={checkedState[props.original.clinic_id] || false}
            type="checkbox"
            disabled={
              !props.original.clinic_id ||
              !props.original.primary_payment_method_id ||
              props.original.status === "succeeded"
            }
          />
        </div>
      )
    },
    {
      accessor: "name",
      Header: "Clinica",
      maxWidth: 350
    },
    {
      Header: "Facturado",
      accessor: "due_date",
      show: true,
      Cell: props => (
        <div>
          {`${moment(props.original.due_date.toString()).format("ll")}`}
        </div>
      ),
      maxWidth: 200
    },
    {
      Header: "Balance",
      accessor: "balance_due",
      Cell: props => <div>${props.original.balance_due}</div>
    },
    {
      Header: "Metodo de pago",
      accessor: "primary_payment_method_id",
      Cell: props => (
        <div>{props.original.primary_payment_method_id ? "Si" : "No"}</div>
      )
    },
    {
      Header: "Estado",
      accessor: "status",
      Cell: props => <div>{props.original.status}</div>
    }
  ];

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}>
          <div className={styles.actionBut}>
            <ProcessStatus
              text="Cobrar facturas"
              total={billingProcess.totalInvoices}
              processed={billingProcess.processed}
              status={billingProcess.status}
              onClickHandle={chargeInvoicesHandler}
            />
          </div>
          <p style={{ color: "red" }}>{msgState}</p>
        </Col>
      </Row>
      {/* Ends section for buttons */}

      <Row>
        <Col sm={12}>
          <Row>
            <Col xs={12} md={12}>
              <div className={styles.submitButtonWrapper}>
                <Button bsSize="small" onClick={onRefresh}>
                  <Glyphicon glyph="refresh" /> Actualizar
                </Button>
              </div>
              <div className={styles.submitButtonWrapper}>
                <Button onClick={onUnselectAll} className={styles.submitButton}>
                  Deseleccionar todo
                </Button>
              </div>
              <div className={styles.submitButtonWrapper}>
                <Button onClick={onSelectValid} className={styles.submitButton}>
                  Seleccionar validos
                </Button>
              </div>
            </Col>
          </Row>
          <ReactTable
            data={todayInvoicesToCharge}
            columns={toBeinvoicedRecordsColumns}
            className="-highlight"
            defaultPageSize={10}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Billing;
