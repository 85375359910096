import React from "react";
import { Route, Switch } from "react-router-dom";

// import ActivateAccount from '../common/ActivateAccount';
// import DocusignSplash from '../common/DocusignSplash';
// import EmailVerification from '../common/EmailVerification';
import Index from "../common/Index";
import Login from "../common/Login";
import VirtualAppointment from "../common/VirtualAppointment";
import RequestDemo from "../common/RequestDemo";
// import Register from '../common/Register';
// import RequestResetPassword from '../common/RequestResetPassword';
// import ResetPassword from '../common/ResetPassword';

import styles from "../../App.module.css";

export default () => {
  return (
    <div className={styles.heightDiv}>
      <Switch>
        {/* <Route exact path='/register' component={Register} />
        <Route exact path='/docusign' component={DocusignSplash} />
        <Route exact path='/request-reset-password' component={RequestResetPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route exact path='/activate-account' component={ActivateAccount} />
        <Route exact path='/email-verification' component={EmailVerification} />  */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/request-demo" component={RequestDemo} />
        <Route
          exact
          path="/virtual-appointment"
          component={VirtualAppointment}
        />
        <Route exact path="*" component={Index} />
      </Switch>
    </div>
  );
};
