var defaultState = {
  current: null,
  history: []
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case "show-notification":
      return {
        ...state,
        current: action.payload,
        history: [...state.history, action.payload]
      };
    case "hide-notification":
      return { ...state, current: null };
    default:
      break;
  }

  return state;
}
