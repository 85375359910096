import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";

let browser = null;
let popup = null;
let timer = null;

function watcher() {
  if (popup === null) {
    clearInterval(timer);
    timer = null;
  } else if (popup !== null && !popup.closed) {
    popup.focus();
  } else if (popup !== null && popup.closed) {
    clearInterval(timer);
    browser.focus();
    browser.onClose("Child was closed");
    timer = null;
    popup = null;
  }
}

export class VideoAppointmentOpener extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
    browser = window.self;
    browser.onSuccess = res => {
      props.bridge(null, res);
    };

    browser.onError = error => {
      props.bridge(error);
    };

    browser.onOpen = message => {
      props.toggleVideoAppt();
      props.handleSubmit();
      props.bridge(null, message);
    };

    browser.onClose = message => {
      props.bridge(null, message);
    };
  }

  onClickHandler(evt) {
    const { url, name, opts } = this.props;
    if (popup && !popup.closed) {
      popup.focus();

      return;
    }
    popup = browser.open(url, name, opts);

    setTimeout(() => {
      popup.opener.onOpen("Child was opened");
    }, 0);

    if (timer === null) {
      timer = setInterval(watcher, 2000);
    }

    return;
  }

  render() {
    const { children } = this.props;
    return <Button onClick={this.onClickHandler}>{children}</Button>;
  }
}

VideoAppointmentOpener.propTypes = {
  url: PropTypes.string.isRequired,
  bridge: PropTypes.func.isRequired,
  name: PropTypes.string,
  opts: PropTypes.string
};
VideoAppointmentOpener.defaultProps = {
  name: "Cool popup",
  opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${900}, height=${600}`
};
