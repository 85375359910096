import React from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { TOKEN_STORAGE_KEY } from "../../constants";
import { restoreLogin } from "../../actions/common/auth";
import PublicLayout from "./publicLayout";
import PrivateLayout from "./privateLayout";

const tryAuthenticateWithQueryToken = () => {
  const query = queryString.parse(window.location.search);
  if (query.authtoken) {
    window.localStorage.setItem(TOKEN_STORAGE_KEY, query.authtoken);
    window.location.href = "/";
  }
};

const tryRefreshExistingSession = (user, dispatch) => {
  const authToken = window.localStorage.getItem(TOKEN_STORAGE_KEY);
  const isResetPasswordPath = window.location.pathname === "/reset-password";
  if (authToken && !user.id && !isResetPasswordPath) {
    dispatch(restoreLogin({ user_agent: navigator.userAgent }));
  }
};

export default () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector(state => state.auth);

  tryAuthenticateWithQueryToken();
  tryRefreshExistingSession(user, dispatch);

  if (isAuthenticated) {
    return <PrivateLayout />;
  }
  return <PublicLayout />;
};
