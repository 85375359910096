import {
  buildAsyncGet,
  buildAsyncPost,
  buildAsyncPatch,
  buildAsyncDelete,
  requested
} from "../actionsFactory";

//TODO: instead to have many toggle modal, just general one

export const UPDATE_CLINIC = "update-clinic";
export const updateClinic = ({ id }) =>
  buildAsyncPatch(UPDATE_CLINIC, `clinic/v1/clinic/${id}`);

export const ADD_CLINIC_CUSTOM_FIELD = "add-clinic-custom-field";
export const addClinicCustomField = () =>
  buildAsyncPost(ADD_CLINIC_CUSTOM_FIELD, `clinic/v1/clinic-custom-fields`);

export const FETCH_CLINIC_CUSTOMIZATIONS = "fetch-clinic-custom-fields";
export const requestClinicCustomFields = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_CLINIC_CUSTOMIZATIONS,
    `clinic/v1/clinic/${clinic_id}/clinic-custom-fields`
  );

export const TOGGLE_CLINIC_TEAM_MEMBER_MODAL =
  "toggle-clinic-team-member-modal";
export const toggleClinicTeamMemberModal = {
  type: requested(TOGGLE_CLINIC_TEAM_MEMBER_MODAL)
};

export const TOGGLE_CLINIC_SPECIALTY_MODAL = "toggle-clinic-specialty-modal";
export const toggleClinicSpecialtyModal = {
  type: requested(TOGGLE_CLINIC_SPECIALTY_MODAL)
};

export const TOGGLE_CLINIC_CUSTOMIZATION_MODAL =
  "toggle-clinic-customization-modal";
export const toggleClinicCustomizationModal = {
  type: requested(TOGGLE_CLINIC_CUSTOMIZATION_MODAL)
};

export const TOGGLE_CLINIC_PATIENT_MODAL = "toggle-clinic-patient-modal";
export const toggleClinicPatientModal = {
  type: requested(TOGGLE_CLINIC_PATIENT_MODAL)
};

export const FETCH_CLINIC_ROLES = "fetch-clinic-roles";
export const requestClinicRoles = ({ clinic_id }) =>
  buildAsyncGet(FETCH_CLINIC_ROLES, `clinic/v1/clinic/${clinic_id}/roles`);

export const FETCH_TEAM = "fetch-team";
export const requestTeam = ({ clinic_id }) =>
  buildAsyncGet(FETCH_TEAM, `clinic/v1/clinic-assignment/${clinic_id}/team`);

export const FETCH_CLINIC_PATIENTS = "fetch-clinic-patients";
export const requestClinicPatients = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_CLINIC_PATIENTS,
    `clinic/v1/clinic/${clinic_id}/patients`
  );

export const FETCH_CLINIC_DOCTORS = "fetch-clinic-doctors";
export const requestClinicDoctors = ({ clinic_id }) =>
  buildAsyncGet(FETCH_CLINIC_DOCTORS, `clinic/v1/clinic/${clinic_id}/doctors`);

export const FETCH_CLINIC_TECHNICIANS = "fetch-clinic-technicians";
export const requestClinicTechnicians = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_CLINIC_TECHNICIANS,
    `clinic/v1/clinic/${clinic_id}/technicians`
  );

export const ADD_CLINIC_PATIENT = "add-clinic-patient";
export const addClinicPatient = ({ clinic_id }) =>
  buildAsyncPost(ADD_CLINIC_PATIENT, `clinic/v1/clinic/${clinic_id}/patient`);

export const ADD_CLINIC_PATIENT_AND_APPOINTMENT =
  "add-clinic-patient-appointment";
export const addClinicPatientAndAppointment = ({ clinic_id }) =>
  buildAsyncPost(
    ADD_CLINIC_PATIENT_AND_APPOINTMENT,
    `clinic/v1/clinic/${clinic_id}/patient-and-appointment`
  );

export const ADD_CLINIC_MEMBER = "add-clinic-member";
export const addClinicMember = ({ clinic_id }) =>
  buildAsyncPost(ADD_CLINIC_MEMBER, `clinic/v1/clinic/${clinic_id}/member`);

export const UPDATE_PATIENT = "update-patient";
export const updatePatient = ({ id }) =>
  buildAsyncPatch(UPDATE_PATIENT, `clinic/v1/patient/${id}`);

export const UPDATE_PATIENT_ASSIGNMENT = "update-patient-assignment";
export const updatePatientAssignment = () =>
  buildAsyncPatch(
    UPDATE_PATIENT_ASSIGNMENT,
    `clinic/v1/patient-assignment/clinic`
  );

export const UPDATE_TEAM_MEMBER = "update-team-member";
export const updateTeamMember = ({ id }) =>
  buildAsyncPatch(UPDATE_TEAM_MEMBER, `clinic/v1/user/${id}`);

export const REMOVE_TEAM_MEMBER = "remove-team-member";
export const removeTeamMember = ({ clinic_id, user_id }) =>
  buildAsyncDelete(
    REMOVE_TEAM_MEMBER,
    `clinic/v1/clinic/${clinic_id}/member/${user_id}`
  );

export const FETCH_DOCTOR = "fecth-doctor";
export const requestDoctor = ({ id }) =>
  buildAsyncGet(FETCH_DOCTOR, `clinic/v1/doctor/${id}`);

export const UPDATE_DOCTOR = "update-doctor";
export const updateDoctor = ({ id }) =>
  buildAsyncPatch(UPDATE_DOCTOR, `clinic/v1/doctor/${id}`);

export const FETCH_TECHNICIAN = "fecth-technician";
export const requestTechnician = ({ id }) =>
  buildAsyncGet(FETCH_TECHNICIAN, `clinic/v1/technician/${id}`);

export const UPDATE_TECHNICIAN = "update-technician";
export const updateTechnician = ({ id }) =>
  buildAsyncPatch(UPDATE_TECHNICIAN, `clinic/v1/technician/${id}`);

export const UPDATE_DOCTOR_ASSIGNMENT = "update-doctor-assignment";
export const updateDoctorAssignment = () =>
  buildAsyncPatch(
    UPDATE_DOCTOR_ASSIGNMENT,
    `clinic/v1/patient-assignment/clinic`
  );

export const UPDATE_TECHNICIAN_ASSIGNMENT = "update-technician-assignment";
export const updateTechnicianAssignment = () =>
  buildAsyncPatch(
    UPDATE_TECHNICIAN_ASSIGNMENT,
    `clinic/v1/patient-assignment/clinic`
  );

export const DELETE_CLINIC_CUSTOM_FIELD = "delete-clinic-custom-field";
export const deleteClinicCustomField = ({ id }) =>
  buildAsyncDelete(
    DELETE_CLINIC_CUSTOM_FIELD,
    `clinic/v1/clinic-custom-fields/${id}`
  );

export const FETCH_APPOINTMENTS = "fetch-appointments";
export const requestAppointments = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_APPOINTMENTS,
    `clinic/v1/clinic/${clinic_id}/appointments`
  );

export const FETCH_CALENDAR_APPOINTMENTS = "fetch-calendar-appointments";
export const requestCalendarAppointments = ({ clinic_id, member_id }) =>
  buildAsyncGet(
    FETCH_CALENDAR_APPOINTMENTS,
    `clinic/v1/clinic/${clinic_id}/calendar-appointments/${member_id}`
  );

export const FETCH_TODAY_APPOINTMENTS = "fetch-today-appointments";
export const requestTodayAppointments = ({ clinic_id, start_date }) =>
  buildAsyncGet(
    FETCH_TODAY_APPOINTMENTS,
    `clinic/v1/clinic/${clinic_id}/today-appointments/?start=${start_date}`
  );

export const ADD_APPOINTMENT = "add-clinic-appointment";
export const addAppointment = () =>
  buildAsyncPost(ADD_APPOINTMENT, `clinic/v1/appointment/`);

export const ADD_APPOINTMENT_ON_AGENDA_VIEW = "add-appointment-on-agenda-view";
export const addAppointmentOnAgendaView = () =>
  buildAsyncPost(
    ADD_APPOINTMENT_ON_AGENDA_VIEW,
    `clinic/v1/appointment/agenda`
  );

export const UPDATE_APPOINTMENT = "update-appointment";
export const updateAppointment = ({ id }) =>
  buildAsyncPatch(UPDATE_APPOINTMENT, `clinic/v1/appointment/${id}`);

export const UPDATE_APPOINTMENT_ON_AGENDA_VIEW =
  "update-appointment-on-agenda-view";
export const updateAppointmentOnAgendaView = ({ id }) =>
  buildAsyncPatch(
    UPDATE_APPOINTMENT_ON_AGENDA_VIEW,
    `clinic/v1/appointment/${id}/agenda`
  );

export const DELETE_APPOINTMENT = "delete-appointment";
export const deleteAppointment = ({ id }) =>
  buildAsyncDelete(DELETE_APPOINTMENT, `clinic/v1/appointment/${id}`);

export const TOGGLE_APPOINTMENT_MODAL = "toggle-appointment-modal";
export const toggleAppointmentModal = {
  type: requested(TOGGLE_APPOINTMENT_MODAL)
};

export const TOGGLE_APPOINTMENT_MODAL_ON_AGENDA =
  "toggle-appointment-modal-on-agenda";
export const toggleAppointmentModalOnAgenda = {
  type: requested(TOGGLE_APPOINTMENT_MODAL_ON_AGENDA)
};

export const TOGGLE_APPOINTMENT_MODAL_ON_CALENDAR =
  "toggle-appointment-modal-on-calendar";
export const toggleAppointmentModalOnCalendar = {
  type: requested(TOGGLE_APPOINTMENT_MODAL_ON_CALENDAR)
};

export const PREVIEW_APPOINTMENT_ON_AGENDA_VIEW =
  "toggle-preview-appointment-modal-on-agenda";
export const togglePreviewAppointmentModalOnAgenda = {
  type: requested(PREVIEW_APPOINTMENT_ON_AGENDA_VIEW)
};

export const FETCH_CLINIC_SPECIALTIES = "fetch-clinic-specialties";
export const requestClinicSpecialties = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_CLINIC_SPECIALTIES,
    `clinic/v1/clinic/${clinic_id}/specialties`
  );

export const FETCH_CLINIC_SERVICES = "fetch-clinic-services";
export const requestClinicServices = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_CLINIC_SERVICES,
    `clinic/v1/clinic/${clinic_id}/services`
  );

export const ADD_CLINIC_SPECIALTY = "add-clinic-specialty";
export const addClinicSpecialty = () =>
  buildAsyncPost(ADD_CLINIC_SPECIALTY, `clinic/v1/specialties`);

export const ADD_CLINIC_SERVICE = "add-clinic-service";
export const addClinicService = () =>
  buildAsyncPost(ADD_CLINIC_SERVICE, `clinic/v1/clinic-services`);

export const UPDATE_CLINIC_SERVICE = "update-clinic-service";
export const updateClinicService = ({ id }) =>
  buildAsyncPatch(UPDATE_CLINIC_SERVICE, `clinic/v1/clinic-services/${id}`);

export const GENERATE_PRESCRIPTION = "generate-prescription";
export const requestGeneratePrescription = ({ appointment_id }) =>
  buildAsyncPost(
    GENERATE_PRESCRIPTION,
    `clinic/v1/appointment/${appointment_id}/prescription`
  );

export const TOGGLE_END_APPOINTMENT_MODAL = "toggle-end-appointment-modal";
export const toggleEndAppointmentModal = {
  type: requested(TOGGLE_END_APPOINTMENT_MODAL)
};

export const FETCH_PRESCRIPTION_S3_DOWNLOAD_URL =
  "fecth-prescription-s3-download-url";
export const requestPrescriptionDownloadUrl = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_PRESCRIPTION_S3_DOWNLOAD_URL,
    `clinic/v1/appointment/${appointment_id}/download`
  );

export const APPOINTMEN_HAS_PRESCRIPTION = "appointment-has-prescription";
export const switchAppointmentHasPrescription = {
  type: requested(APPOINTMEN_HAS_PRESCRIPTION)
};

export const SET_LOADING = "set-loading";
export const setIsLoading = {
  type: requested(SET_LOADING)
};

export const DELETE_PATIENTE = "delete-patient";
export const deletePatient = ({ id }) =>
  buildAsyncDelete(DELETE_PATIENTE, `clinic/v1/patient/${id}`);

export const TOGGLE_CLINIC_SERVICE_MODAL = "toggle-clinic-service-modal";
export const toggleClinicServiceModal = {
  type: requested(TOGGLE_CLINIC_SERVICE_MODAL)
};

export const ADD_APPOINTMENT_SERVICES = "add-appointment-services";
export const addAppointmentServices = ({ appointment_id }) =>
  buildAsyncPost(
    ADD_APPOINTMENT_SERVICES,
    `clinic/v1/appointment-services/${appointment_id}`
  );

export const FETCH_APPOINTMENT_SERVICES = "fetch-appointment-services";
export const requestAppointmentServices = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_APPOINTMENT_SERVICES,
    `clinic/v1/appointment/${appointment_id}/services`
  );

export const TOGGLE_APPOINTMENT_SERVICES_MODAL = "toggle-appointment-services";
export const toggleApptServicesModal = {
  type: requested(TOGGLE_APPOINTMENT_SERVICES_MODAL)
};

export const DELETE_APPOINTMENT_SERVICE = "delete-appointment-service";
export const deleteAppointmentService = ({ id }) =>
  buildAsyncDelete(
    DELETE_APPOINTMENT_SERVICE,
    `clinic/v1/appointment-services/${id}`
  );

export const FETCH_UNCONFIRMED_APPOINTMENTS = "fetch-unconfirmed-appointments";
export const requestUnconformedAppointments = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_UNCONFIRMED_APPOINTMENTS,
    `clinic/v1/clinic/${clinic_id}/appointments/unconfirmed`
  );

export const UPDATE_APPOINTMENT_STATUS = "update-appointment-status";
export const updateAppointmentStatus = ({ id }) =>
  buildAsyncPatch(
    UPDATE_APPOINTMENT_STATUS,
    `clinic/v1/appointment/${id}/status`
  );

export const FETCH_DASHBOARD_DATA = "fetch-dashboard-data";
export const requestDashboardData = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_DASHBOARD_DATA,
    `clinic/v1/clinic/${clinic_id}/dashboard`
  );

export const FETCH_FINANCIAL_DATA = "fetch-financial-data";
export const requestFinancialData = ({ clinic_id }) =>
  buildAsyncGet(
    FETCH_FINANCIAL_DATA,
    `clinic/v1/clinic/${clinic_id}/financial`
  );

export const TOGGLE_VIDEO_APPOINTMENT_MODAL = "toggle-video-appointment-modal";
export const toggleVideoAppointmentModal = {
  type: requested(TOGGLE_VIDEO_APPOINTMENT_MODAL)
};

export const FETCH_PAYMENTS_METHODS = "fetch-payments-methods";
export const requestPaymentsMethods = () =>
  buildAsyncGet(
    FETCH_PAYMENTS_METHODS,
    "clinic/v1/user/payment-method/get-all"
  );

export const FETCH_STRIPE = "fetch-stripe";
export const requestStripe = () =>
  buildAsyncGet(FETCH_STRIPE, `clinic/v1/stripe`);

export const VERIFY_AND_SAVE_CREDIT_CARD = "verify-save-credit-card";
export const verifyAndSaveCreditCard = () =>
  buildAsyncPost(
    VERIFY_AND_SAVE_CREDIT_CARD,
    "clinic/v1/user-payment-methods/verify-and-save-credit-card"
  );

/* export const REMOVE_PAYMENT_METHOD = 'remove-payment-method';
export const removePaymentMethod = ({ payment_method_id, user_id }) =>
  buildAsyncDelete(REMOVE_PAYMENT_METHOD, `user/${user_id}/payment_method/${payment_method_id}`); */
