import React, { useEffect } from "react";
import { Row, Col, Button, Glyphicon, Modal } from "react-bootstrap";
import styles from "../index.module.css";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  requestClinicServices,
  toggleClinicServiceModal,
  requestClinicSpecialties
} from "../../../../actions/clinic/clinic";
import AddClinicService from "./AddClinicService";
import ClinicService from "./ClinicService";
import { requestSpecialties } from "../../../../actions/specialties/specialties";
import { findIndex } from "lodash";
import history from "../../../../history";
import Inter from "../../../../common/constants/internationalization";

const ClinicServices = ({
  clinic_id,
  clinic_type,
  match: {
    params: { id }
  }
}) => {
  const dispatch = useDispatch();

  const {
    clinicServices,
    specialties,
    clinicSpecialties,
    user_country
  } = useSelector(state => ({
    clinicServices: state.clinic.clinic.clinicServices.content,
    specialties: state.specialties.specialties,
    clinicSpecialties: state.clinic.clinic.clinicSpecialties,
    user_country: state.auth.user.country
  }));

  useEffect(() => {
    if (clinic_id) {
      dispatch(requestClinicServices({ clinic_id }));
      dispatch(requestSpecialties());
      dispatch(requestClinicSpecialties({ clinic_id }));
    }
  }, [clinic_id]);

  const toggleAddClinicServiceModal = () => dispatch(toggleClinicServiceModal);

  const toggle_modal = useSelector(
    store => store.clinic.clinic.toggleModalService
  );

  const specialtiesList = [
    ...specialties.content.filter(e =>
      Object.keys(clinicSpecialties.content).includes(e.id.toString())
    )
  ];

  //TODO check tableSolsticeHeader
  const servicesByClinic = [
    {
      accessor: "id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/clinic/services/${props.original.id}`}>
          {" "}
          {props.original.id}{" "}
        </Link>
      ),
      filterable: false
    },
    {
      Header: "Especialización",
      accessor: "specialty_name",
      minWidth: 200,
      maxWidth: 250,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/clinic/services/${props.original.id}`}>
          {" "}
          {props.original.specialty_name}{" "}
        </Link>
      ),
      filterable: true
    },
    {
      Header: "Servicio",
      accessor: "service_name",
      minWidth: 200,
      maxWidth: 250,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/clinic/services/${props.original.id}`}>
          {" "}
          {props.original.service_name}{" "}
        </Link>
      ),
      filterable: true
    },
    {
      Header: "Precio",
      accessor: "price",
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <span>{`${Inter[user_country].currency}${props.original.price}`}</span>
      ),
      filterable: false
    }
  ];

  if (
    !clinicSpecialties.loaded ||
    !specialties.loaded ||
    !clinicServices.length
  ) {
    return <div>Loading...</div>;
  } else {
    if (id && !isNaN(id)) {
      const [service] = clinicServices.filter(
        service => service.id === parseInt(id)
      );
      if (!service) {
        history.push(`/clinic/services`);
        return <div>Loading...</div>;
      } else {
        return (
          <div>
            <ClinicService service={service} clinic_id={clinic_id} />
          </div>
        );
      }
    } else {
      return (
        <div className="h2-section -no-hide">
          {/* Starts section for buttons */}
          <Row className={styles.buttonsSection}>
            <Col xs={12}>
              <div className={styles.actionBut}>
                <Button bsSize="small" onClick={toggleAddClinicServiceModal}>
                  <Glyphicon glyph="plus" /> Agregar servicio
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <Modal
                show={toggle_modal}
                onHide={toggleAddClinicServiceModal}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Agregar servicio a la clínica</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddClinicService
                    clinic_id={clinic_id}
                    specialtiesList={specialtiesList}
                    specialties={specialties.content}
                  />
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
          {/* Ends section for buttons */}

          {/* Starts content */}
          <div className="h3-section">
            <h3>Servicios</h3>
          </div>
          <div>
            <ReactTable
              data={clinicServices}
              columns={servicesByClinic}
              className={[styles.tableSolstice, "body -highlight"].join(" ")}
              defaultPageSize={10}
            />
          </div>
          {/* Ends content */}
        </div>
      );
    }
  }
};

export default withRouter(ClinicServices);
