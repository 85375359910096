import validUrl from "valid-url";

export const min = (minVal, customMessage) => value => {
  if (!value || !value.length) return null;
  return value.length < minVal
    ? customMessage || `Must be at least ${minVal} characters.`
    : null;
};

export const max = (maxVal, customMessage) => value => {
  if (!value || !value.length) return null;
  return value.length > maxVal
    ? customMessage || `Must be no more than ${maxVal} characters.`
    : null;
};

export const isEmail = customMessage => value => {
  return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? customMessage || "Must be a valid email."
    : null;
};

export const isValidZipCode = customMessage => value => {
  return /^\d{5}$/.test(value)
    ? null
    : customMessage || "Must be a 5 digit zip code";
};

export const isUrl = customMessage => value => {
  return !validUrl.isWebUri(value)
    ? customMessage || "Must be a valid URL."
    : null;
};

export const required = customMessage => value => {
  return !value ? customMessage || "This field is required." : null;
};

export const notZero = customMessage => value => {
  return value === "0" ? customMessage || "Must select a value." : null;
};
export const alphanum = customMessage => value => {
  return !/^[a-z0-9]+$/i.test(value)
    ? customMessage || "Must only be letters and numbers with no spaces."
    : null;
};

export const address = customMessage => value => {
  return !/^[a-zA-Z0-9,.#!? ]*$/.test(value)
    ? customMessage || "Must be a valid street address."
    : null;
};

export const lettersAndSpaces = customMessage => value => {
  return !/^[a-zA-Z\s]*$/.test(value)
    ? customMessage || "Must only be letters and spaces."
    : null;
};

export const noSpaces = customMessage => value => {
  return !/^\S*$/.test(value) ? customMessage || "No spaces allowed" : null;
};

export const lettersOnly = customMessage => value => {
  return !/^[a-zA-Z]*$/.test(value)
    ? customMessage || "Must only be letters."
    : null;
};

export const numbersAndDash = customMessage => value => {
  return !/^(\d+-?)+\d+$/.test(value)
    ? customMessage || "Must only be numbers and may include a dash."
    : null;
};

export const numbersOnly = customMessage => value => {
  return !/^\d+$/.test(value) ? customMessage || "Must be only numbers." : null;
};

export const numberWithDecimal = customMessage => value => {
  return !/^\d*\.?\d*$/.test(value)
    ? customMessage || "Must only be a number with a decimal point"
    : null;
};
export const eitherThisDefinedOr = (otherKey, customMessage) => (
  value,
  form
) => {
  const ret = !(value || form[otherKey])
    ? customMessage || "One or the other must be defined."
    : null;
  return ret;
};

export const shouldEqual = (otherKey, customMessage) => (value, form) => {
  return !(value === form[otherKey])
    ? customMessage || "Must match other value."
    : null;
};
