import {
  requested,
  succeeded,
  failed,
  error
} from "../../actions/actionsFactory";

export const isRelatedAction = (action, actionFamily) =>
  action.type.substr(4) === actionFamily;

export const processServerErrors = payload => {
  return payload.error ? [payload.error] : payload;
};

export const pristine = (defaultValue = {}) => ({
  pristine: true,
  loading: false,
  loaded: false,
  failed: false,
  default: defaultValue,
  content: defaultValue,
  updated: null,
  errors: null
});

export const fetchingStarted = state => ({
  ...state,
  loading: true,
  loaded: false,
  failed: false,
  errors: null
});

export const fetchingSucceded = (state, action) => ({
  ...state,
  pristine: false,
  loading: false,
  loaded: true,
  failed: false,
  content: action.payload,
  updated: new Date(),
  errors: null
});

export const fetchingFailed = (state, action) => ({
  ...state,
  pristine: false,
  loading: false,
  loaded: false,
  failed: true,
  updated: new Date(),
  errors: processServerErrors(action.payload)
});

export const reduce = (state, name, actionInstance, actionBaseName) => {
  switch (actionInstance.type) {
    case requested(actionBaseName):
      return {
        ...state,
        [name]: fetchingStarted(state[name])
      };

    case succeeded(actionBaseName):
      return {
        ...state,
        [name]: fetchingSucceded(state[name], actionInstance)
      };

    case failed(actionBaseName):
    case error(actionBaseName):
      return {
        ...state,
        [name]: fetchingFailed(state[name], actionInstance)
      };

    default:
      return state;
  }
};
