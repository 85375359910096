import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import {
  FETCH_TECHNICIAN,
  UPDATE_TECHNICIAN,
  UPDATE_TECHNICIAN_ASSIGNMENT
} from "../../actions/clinic/clinic";

var defaultState = {
  technician: pristine([])
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_TECHNICIAN)) {
    return reduce(state, "technician", action, FETCH_TECHNICIAN);
  }

  if (action.type === succeeded(UPDATE_TECHNICIAN)) {
    return {
      ...state,
      technician: {
        ...state.technician,
        content: [
          ...state.technician.content.filter(
            e => e.id !== action.payload.user_id
          ),
          action.payload
        ]
      }
    };
  }

  if (action.type === failed(UPDATE_TECHNICIAN)) {
    return {
      ...state
    };
  }

  return state;
}
