import React from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import dashboardRoutes from "../dashboard/_dashboardRoutes";
import styles from "./Body.module.css";
import TopMenu from "../components/TopMenu";
import { toggleMenu } from "../../../actions/common/layout";
// import Properties from '../../customer/properties/Properties';
// import AcceptTermsAndPolicy from '../../../components/AcceptTermsAndPolicy/AcceptTermsAndPolicy';

const RenderRoutes = ({ routes, role }) => (
  <div>
    <Switch>
      {routes[role].map(route => {
        const routes = [];
        routes.push(
          <Route exact path={route.path} component={route.component} />
        );
        for (const subRoute of route.sub_routes || []) {
          routes.push(
            <Route
              exact
              path={subRoute}
              component={route.component}
              key={subRoute}
            />
          );
        }
        return routes;
      })}
      <Redirect to="/dashboard" />
    </Switch>
  </div>
);

export default withRouter(
  ({
    role,
    setup_completed,
    menu_expanded,
    policy_and_terms_accepted,
    policy_and_terms_version,
    user_first_name,
    current_policy_and_terms_version,
    location
  }) => {
    const dispatch = useDispatch();
    const toggleMenuAction = () => dispatch(toggleMenu);

    let contentClassNames;
    let headerClassNames;

    if (isMobile) {
      contentClassNames = [styles.ContentBody, styles.mobilePadding].join(" ");
    } else {
      if (menu_expanded) {
        contentClassNames = [styles.ContentBody, styles.ExpandedMenu].join(" ");
        headerClassNames = [
          styles.ContentHeader,
          styles.ExpandedMenuHeader
        ].join(" ");
      } else {
        contentClassNames = [
          styles.ContentBody,
          role === "customer" && !setup_completed
            ? styles.initialAccount
            : styles.NonExpandedMenu
        ].join(" ");
        headerClassNames = [
          styles.ContentHeader,
          styles.NonExpandedMenuHeader
        ].join(" ");
      }
    }

    if (role === "customer") {
      if (!setup_completed && !(location.pathname === "/docusign")) {
        return (
          <div id="content" className={contentClassNames}>
            {/* <Properties /> */}
          </div>
        );
      }

      if (!policy_and_terms_accepted) {
        return (
          <div id="content" className={contentClassNames}>
            {/* <AcceptTermsAndPolicy
              type={
                policy_and_terms_version !== current_policy_and_terms_version ? 'NoUpdated' : 'NoAccepted'
              }
              userName={user_first_name}
              currentPolicyAndTerms={current_policy_and_terms_version}
            /> */}
          </div>
        );
      }
    }

    return (
      <div>
        <div className={headerClassNames}>
          <TopMenu toggleMenu={toggleMenuAction} />
        </div>
        <div id="content" className={contentClassNames}>
          <RenderRoutes
            className={styles.scrollableDiv}
            role={role}
            routes={dashboardRoutes}
          />
        </div>
      </div>
    );
  }
);
