import { pristine, reduce, isRelatedAction } from "../utils";
import { FETCH_SPECIALTIES } from "../../actions/specialties/specialties";

var defaultState = {
  specialties: pristine([])
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_SPECIALTIES)) {
    return reduce(state, "specialties", action, FETCH_SPECIALTIES);
  }

  return state;
}
