import React, { useEffect } from "react";
import VirtualAppointmentBox from "./components/VideoAppointment/VideoAppointmentBox";
import VirtualAppointmentUnavialble from "./components/VideoAppointment/VideoAppointmentUnavilable";
import queryString from "query-string";
import styles from "./Login.module.css";
import saludLogo from "../../static/img/SaludLogo.svg";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestVirtualAppointmentToken } from "../../actions/virtual-appointment";

export default ({ location: { search } }) => {
  const dispatch = useDispatch();
  const q = queryString.parse(search);

  const { appointmentToken } = useSelector(state => ({
    appointmentToken: state.virtualAppointment.appointmentToken
  }));

  useEffect(() => {
    if (q.id) {
      dispatch(requestVirtualAppointmentToken(q.id));
    } else {
    }
  }, [q.id]);

  if (!appointmentToken.failed) {
    if (!appointmentToken.loaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className={[styles.wrapper, styles.gradientPrimary].join(" ")}>
          <div className={styles.topBar}>
            <Row>
              <Col xs={12} sm={6} align={"left"}>
                <Image width="291px" src={saludLogo} />
              </Col>
            </Row>
          </div>
          {}
          <VirtualAppointmentBox apptToken={appointmentToken.content} />
        </div>
      );
    }
  } else {
    let appointment_date;
    if (appointmentToken.errors[0]) {
      if (isNaN(new Date(appointmentToken.errors[0].message).getTime())) {
        appointment_date = null;
      } else {
        appointment_date = new Date(appointmentToken.errors[0].message);
      }
    } else {
      appointment_date = null;
    }
    return (
      <div className={[styles.wrapper, styles.gradientPrimary].join(" ")}>
        <div className={styles.topBar}>
          <Row>
            <Col xs={12} sm={6} align={"left"}>
              <Image width="291px" src={saludLogo} />
            </Col>
          </Row>
        </div>
        {}
        <VirtualAppointmentUnavialble appointment_date={appointment_date} />
      </div>
    );
  }
};
