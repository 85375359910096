import React, { useState, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./StripeCardTokenizer.module.css";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";

import { ErrorToast } from "../../common/functions/toasts";
import { useDispatch } from "react-redux";
import { verifyAndSaveCreditCard } from "../../actions/clinic/clinic";
import NotifBox from "../Layout/NotifBox/NotifBox";

const StripeCardTokenizer = ({ account, onCancel, stripe }) => {
  const dispatch = useDispatch();

  const [stripeErrorMessage, setStripeErrorMessage] = useState("");

  const handleChange = e => {
    if (e.error) {
      setStripeErrorMessage(e.error.message);
    }
  };

  const onVerifyAndSave = async (ev, accountId) => {
    ev.preventDefault();
    let { token } = await stripe.createToken();
    if (token && token.id) {
      dispatch(
        verifyAndSaveCreditCard()(
          {
            token,
            accountId
          },
          {
            onFailedMessage: {
              type: "error",
              view: "billing",
              title: "Error Saving Credit Card",
              message: "Could not save Credit Card."
            }
          }
        )
      );
      onCancel();
    } else {
      ErrorToast({
        title: "Error Saving Card",
        message: "Please Enter a Valid Card."
      });
    }
  };

  return (
    <form>
      <Fragment>
        <NotifBox view="stripe-card" />
        <Row>
          <Col xs={12}>
            <label className={styles.formLabel}>Numero de tarjeta</label>
            <CardNumberElement onChange={handleChange} />
          </Col>
        </Row>
        <Row className={styles.cardNumberWrapper}>
          <Col xs={6}>
            <label className={styles.formLabel}>CVC</label>
            <CardCVCElement onChange={handleChange} />
          </Col>
          <Col xs={6}>
            <label className={styles.formLabel}>Fecha de vencimiento</label>
            <CardExpiryElement onChange={handleChange} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="error" role="alert">
              {stripeErrorMessage}
            </div>
            <div className={styles.saveButtonContainer}>
              <Button
                className={styles.saveButton}
                onClick={ev => onVerifyAndSave(ev, account.id)}
              >
                Guardar tarjeta
              </Button>{" "}
              <Button className={styles.cancelButton} onClick={onCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
        </Row>
      </Fragment>
    </form>
  );
};

export default injectStripe(StripeCardTokenizer);
