import { Col, Row } from "react-bootstrap";
import StripeCardWraper from "../../../../../components/Stripe/StripeCardWraper";
import React from "react";

export default ({ account, toggleSelf }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <StripeCardWraper account={account} onCancel={toggleSelf} />
        </Col>
      </Row>
    </>
  );
};
