import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import { EDIT_MODE } from "../../actions/clinic/common";
import {
  TOGGLE_APPOINTMENT_MODAL,
  FETCH_PATIENT_APPOINTMENT_HISTORY,
  FETCH_VIRTUAL_APPOINTMENT_TOKEN,
  CHECK_APPOINTMENT_EXAMS,
  TOGGLE_APPOINTMENT_EXAMS_MODAL,
  UPDATE_APPOINTMENT_EXAMS,
  ADD_APPOINTMENT_EXAMS,
  FETCH_APPOINTMENT_EXAMS,
  FETCH_PREV_APPOINTMENT_EXAMS,
  FETCH_APPOINTMENT_EXAMS_LIST,
  DELETE_APPOINTMENT_EXAM,
  SET_LOADING
} from "../../actions/clinic/appointment";

var defaultState = {
  editMode: false,
  toggleModal: false,
  patientAppointmentHistory: pristine([]),
  prescription_s3_download_url: "",
  appointmentToken: pristine({}),
  appointmentExamsStatus: pristine({}),
  appointmentExams: pristine([]),
  prevAppointmentExams: pristine([]),
  toggleAppointmentExamsModal: false,
  appointmentExamsList: pristine({}),
  loading: false
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_PATIENT_APPOINTMENT_HISTORY)) {
    return reduce(
      state,
      "patientAppointmentHistory",
      action,
      FETCH_PATIENT_APPOINTMENT_HISTORY
    );
  }

  if (action.type === requested(EDIT_MODE)) {
    return {
      ...state,
      editMode: !state.editMode,
      patientAppointmentHistory: {
        ...state.patientAppointmentHistory,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal,
      patientAppointmentHistory: {
        ...state.patientAppointmentHistory,
        errors: null
      }
    };
  }

  if (isRelatedAction(action, FETCH_VIRTUAL_APPOINTMENT_TOKEN)) {
    return reduce(
      state,
      "appointmentToken",
      action,
      FETCH_VIRTUAL_APPOINTMENT_TOKEN
    );
  }

  if (isRelatedAction(action, CHECK_APPOINTMENT_EXAMS)) {
    return reduce(
      state,
      "appointmentExamsStatus",
      action,
      CHECK_APPOINTMENT_EXAMS
    );
  }

  if (isRelatedAction(action, FETCH_APPOINTMENT_EXAMS)) {
    return reduce(state, "appointmentExams", action, FETCH_APPOINTMENT_EXAMS);
  }

  if (isRelatedAction(action, FETCH_PREV_APPOINTMENT_EXAMS)) {
    return reduce(
      state,
      "prevAppointmentExams",
      action,
      FETCH_PREV_APPOINTMENT_EXAMS
    );
  }

  if (isRelatedAction(action, FETCH_APPOINTMENT_EXAMS_LIST)) {
    return reduce(
      state,
      "appointmentExamsList",
      action,
      FETCH_APPOINTMENT_EXAMS_LIST
    );
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_EXAMS_MODAL)) {
    return {
      ...state,
      toggleAppointmentExamsModal: !state.toggleAppointmentExamsModal,
      appointmentExams: {
        ...state.appointmentExams,
        errors: null
      }
    };
  }

  if (action.type === succeeded(UPDATE_APPOINTMENT_EXAMS)) {
    let appointmentExamsUpdated = [];
    let prevAppointmentExamsUpdated = [];
    if (action.payload.length > 0) {
      action.payload.map(exam => {
        if (state.appointmentExams.content.filter(e => e.id === exam.id) > -1) {
          prevAppointmentExamsUpdated = [
            ...state.prevAppointmentExams.content.filter(e => e.id !== exam.id),
            exam
          ];
        } else {
          appointmentExamsUpdated = [
            ...state.appointmentExams.content.filter(e => e.id !== exam.id),
            exam
          ];
        }
      });
    }
    return {
      ...state,
      appointmentExams: {
        ...state.appointmentExams,
        content: appointmentExamsUpdated,
        errors: null
      },
      prevAppointmentExams: {
        ...state.prevAppointmentExams,
        content: prevAppointmentExamsUpdated,
        errors: null
      }
    };
  }

  if (action.type === succeeded(ADD_APPOINTMENT_EXAMS)) {
    let listUpdated = {};
    if (action.payload.length > 0) {
      for (let i = 0; i < action.payload.length; i++) {
        listUpdated = {
          ...listUpdated,
          [action.payload[i].id]: {
            result: action.payload[i].result
          }
        };
      }
    }
    return {
      ...state,
      appointmentExams: {
        ...state.appointmentExams,
        content: [...state.appointmentExams.content, ...action.payload],
        errors: null
      },
      appointmentExamsList: {
        ...state.appointmentExamsList,
        content: { ...state.appointmentExamsList.content, ...listUpdated },
        errors: null
      },
      loading: false
    };
  }

  if (action.type === succeeded(DELETE_APPOINTMENT_EXAM)) {
    return {
      ...state,
      appointmentExams: {
        ...state.appointmentExams,
        content: [
          ...state.appointmentExams.content.filter(
            e => e.id !== action.payload.id
          )
        ],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === requested(SET_LOADING)) {
    return {
      ...state,
      loading: !state.loading
    };
  }

  return state;
}
