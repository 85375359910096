/*     Super Admin Pages    */
import SaHome from "../../admin/home";
import SaBilling from "../../admin/billing";

import AppointmentWindow from "../../clinic/agenda/components/VideoAppointment/components/AppointmentWindow";

/*     Admin Pages    */
import Home from "../../clinic/home";
import Clinic from "../../clinic/clinic";
import Patients from "../../clinic/patients";
import Agenda from "../../clinic/agenda";
import Finance from "../../clinic/finance";
import Reports from "../../clinic/reports";
import Profile from "../../clinic/profile";

/*     Common Views    */
import Login from "../../../views/common/Login";
import Logout from "../../../views/common/Logout";
import VirtualAppointment from "../../common/VirtualAppointment";
import RequestDemo from "../../common/RequestDemo";
/* import Register from '../Register';
import RequestResetPassword from '../RequestResetPassword';
import ResetPassword from '../ResetPassword';
import ActivateAccount from '../ActivateAccount';
import EmailVerification from '../EmailVerification'; */

export default {
  nonAuthenticated: [
    {
      path: "/login",
      component: Login
    },
    {
      path: "/virtual-appointment",
      component: VirtualAppointment
    },
    {
      path: "/request-demo",
      component: RequestDemo
    }
    /*     {
      path: '/register',
      component: Register
    },
    {
      path: '/request-reset-password',
      component: RequestResetPassword
    },
    {
      path: '/reset-password',
      component: ResetPassword
    },
    {
      path: '/activate-account',
      component: ActivateAccount
    },
    {
      path: '/email-verification',
      component: EmailVerification
    } */
  ],
  superAdmin: [
    {
      path: "/dashboard",
      component: SaHome
    },
    {
      path: "/billing",
      component: SaBilling
    },
    {
      path: "/finance",
      component: SaBilling,
      sub_routes: ["/finance/invoicing", "/finance/billing"]
    },
    {
      path: "/logout",
      component: Logout
    }
  ],
  admin: [
    {
      path: "/dashboard",
      component: Home
    },
    {
      path: "/clinic",
      component: Clinic,
      sub_routes: [
        "/clinic/basic-info",
        "/clinic/team",
        "/clinic/team/:id",
        "/clinic/services",
        "/clinic/services/:id",
        "/clinic/customizations",
        "/clinic/payments",
        "/clinic/settings"
      ]
    },
    {
      path: "/patients",
      component: Patients,
      sub_routes: [
        "/patients/:id",
        "/patients/:id/personal",
        "/patients/:id/profile",
        "/patients/:id/history",
        "/patients/:id/history/:appt_id"
      ]
    },
    {
      path: "/agenda",
      component: Agenda,
      sub_routes: [
        "/agenda/:id",
        "/agenda/:id/info",
        "/agenda/:id/physical-examination",
        "/agenda/:id/exams",
        "/agenda/:id/results",
        "/agenda/:id/documents"
      ]
    },
    {
      path: "/finance",
      component: Finance
    },
    {
      path: "/reports",
      component: Reports
    },
    {
      path: "/profile",
      component: Profile
    },
    {
      path: "/logout",
      component: Logout
    },
    {
      path: "/video-appointment",
      component: AppointmentWindow
    }
  ],
  doctor: [
    {
      path: "/dashboard",
      component: Home
    },
    {
      path: "/patients",
      component: Patients,
      sub_routes: [
        "/patients/:id",
        "/patients/:id/personal",
        "/patients/:id/profile",
        "/patients/:id/history",
        "/patients/:id/history/:appt_id"
      ]
    },
    {
      path: "/agenda",
      component: Agenda,
      sub_routes: [
        "/agenda/:id",
        "/agenda/:id/info",
        "/agenda/:id/physical-examination",
        "/agenda/:id/results",
        "/agenda/:id/documents"
      ]
    },
    {
      path: "/finance",
      component: Finance
    },
    {
      path: "/reports",
      component: Reports
    },
    {
      path: "/profile",
      component: Profile
    },
    {
      path: "/logout",
      component: Logout
    }
  ],
  technician: [
    {
      path: "/dashboard",
      component: Home
    },
    {
      path: "/patients",
      component: Patients,
      sub_routes: [
        "/patients/:id",
        "/patients/:id/personal",
        "/patients/:id/profile",
        "/patients/:id/history",
        "/patients/:id/history/:appt_id"
      ]
    },
    {
      path: "/agenda",
      component: Agenda,
      sub_routes: [
        "/agenda/:id",
        "/agenda/:id/info",
        "/agenda/:id/physical-examination",
        "/agenda/:id/results",
        "/agenda/:id/documents"
      ]
    },
    {
      path: "/finance",
      component: Finance
    },
    {
      path: "/reports",
      component: Reports
    },
    {
      path: "/profile",
      component: Profile
    },
    {
      path: "/logout",
      component: Logout
    }
  ],
  assistant: [
    {
      path: "/dashboard",
      component: Home
    },
    {
      path: "/patients",
      component: Patients,
      sub_routes: [
        "/patients/:id",
        "/patients/:id/personal",
        "/patients/:id/profile",
        "/patients/:id/history",
        "/patients/:id/history/:appt_id"
      ]
    },
    {
      path: "/agenda",
      component: Agenda,
      sub_routes: [
        "/agenda/:id",
        "/agenda/:id/info",
        "/agenda/:id/physical-examination",
        "/agenda/:id/results",
        "/agenda/:id/documents"
      ]
    },
    {
      path: "/finance",
      component: Finance
    },
    {
      path: "/reports",
      component: Reports
    },
    {
      path: "/profile",
      component: Profile
    },
    {
      path: "/logout",
      component: Logout
    }
  ]
};
