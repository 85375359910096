import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./Agenda.module.css";
import moment from "moment";
import _ from "lodash";
import Calendar from "./Calendar";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { requestCalendarAppointments } from "../../../../../actions/clinic/clinic";

const Agenda = ({
  clinic_id,
  doctorsList,
  techniciansList,
  settings,
  clinicServices,
  defaultDoctor,
  defaultTechnician
}) => {
  const dispatch = useDispatch();

  const {
    clinicAppointments,
    clinicCalendarAppointments,
    errors
  } = useSelector(state => ({
    clinicAppointments: state.clinic.clinic.clinicAppointments.content,
    clinicCalendarAppointments: state.clinic.clinic.clinicCalendarAppointments,
    errors: state.clinic.clinic.clinicCalendarAppointments.errors
  }));

  useEffect(() => {
    if (clinic_id) {
      dispatch(
        requestCalendarAppointments({
          clinic_id,
          member_id: defaultDoctor ? defaultDoctor : defaultTechnician
        })
      );
    }
  }, [clinic_id]);

  if (!clinicCalendarAppointments.loaded || !clinic_id) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={styles.calendarWrapper}>
        <Row className={styles.navCalendar}>
          <Col md={12}></Col>
        </Row>
        <Calendar
          appointments={clinicCalendarAppointments.content}
          settings={settings}
          doctorsList={doctorsList}
          techniciansList={techniciansList}
          clinic_id={clinic_id}
          errors={errors}
          clinicServices={clinicServices}
          clinicAppointments={clinicAppointments}
        />
      </div>
    );
  }
};
export default Agenda;
