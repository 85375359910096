import { create } from "axios";
import { TOKEN_STORAGE_KEY } from "../constants";

const getHost = host => (host === "localhost:3000" ? "localhost:4000" : host);
const getHeaders = token =>
  token !== null ? { Authorization: "Bearer " + token } : null;

const createApiProxy = () => {
  const { host, protocol } = window.location;
  const authToken = window.localStorage.getItem(TOKEN_STORAGE_KEY);
  const options = {
    baseURL: `${protocol}//${getHost(host)}/api/`,
    timeout: 50000,
    headers: getHeaders(authToken),
    validateStatus: function(status) {
      return status < 500;
    }
  };

  const proxy = create(options);
  proxy.interceptors.response.use(
    response => {
      if (response.status === 401) {
        console.warn("Session expired");
        window.localStorage.removeItem(TOKEN_STORAGE_KEY);
        window.location = "/login";
      }

      if (response.status === 403) {
        console.warn("Forbidden");
        window.localStorage.removeItem(TOKEN_STORAGE_KEY);
        window.location = "/login";
      }

      if (response.status === 302) {
        window.location =
          response.data.location + "/login?authtoken=" + response.data.token;
      }

      return response;
    },
    error => {
      console.error(error);
      Promise.reject(error);
    }
  );
  return proxy;
};

export default createApiProxy;
