import React from "react";
import styles from "./PaymentMethod.module.css";
import { Row, Col, Glyphicon, Button } from "react-bootstrap";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import ChipLogo from "../../components/PaymentMethod/ChipLogo";
import { WarnToast } from "../../common/functions/toasts";
//import { removePaymentMethod } from '../../../../../actions/customer';
import { useDispatch } from "react-redux";

const CreditCard = props => {
  const dispatch = useDispatch();

  return (
    <ConfirmModal
      title={"Remover tarjeta"}
      message={"Esta seguro que desea remover esta tarjeta de credito?"}
    >
      {({ confirm }) => (
        <>
          <div className={styles.selectedPaymentWrapper}>
            <Row>
              <Col xs={12}>
                <div
                  className={styles.creditCard}
                  onClick={e => {
                    props.onUpdateAccount(props.methodId);
                  }}
                >
                  <Row>
                    <Col xs={12} md={3}>
                      <div className={styles.nameTagTitle}>Nombre</div>
                      <div className={styles.nameTag}>
                        {props.first_name + " " + props.last_name}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className={styles.nameTagTitle}>
                        Ultimos 4 digitos
                      </div>
                      <div className={styles.nameTag}>{props.last_four}</div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className={styles.nameTagTitle}>Vencimiento</div>
                      <div className={styles.nameTag}>
                        {props.exp_month + "/" + props.exp_year}
                      </div>
                    </Col>
                    <Col xs={12} md={3}>
                      <div className={styles.nameTagTitle}>Tipo de tarjeta</div>
                      <div className={styles.nameTag}>
                        <div className={styles.visaLogo}>
                          <ChipLogo cardNetwork={props.card_network} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

          <Row className={styles.buttonHolder}>
            <Col xs={12}>
              <div className={"pull-right"}>
                <Button
                  className="push-button"
                  onClick={() => props.setPrimaryPM(props.method)}
                >
                  Seleccionar como primario
                </Button>{" "}
                {/* <Button
                  className="push-button -second"
                  onClick={() => props.setSecondaryPM(props.method)}
                >
                  Seleccionar como secundario
                </Button> */}
              </div>
            </Col>
          </Row>
        </>
      )}
    </ConfirmModal>
  );
};

export default CreditCard;
