import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Glyphicon, Image } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import {
  updateAppointment,
  deleteAppointment,
  toggleApptServicesModal,
  deleteAppointmentService
} from "../../../../actions/clinic/clinic";
import _ from "lodash";
import ReactTable from "react-table";
import deleteImg from "../../../../static/img/icons/delete.svg";
import Inter from "../../../../common/constants/internationalization";
import AddServicesToAppointment from "./AddServicesToAppointment";

const duration = [
  {
    value: 15,
    text: "15"
  },
  {
    value: 30,
    text: "30"
  },
  {
    value: 45,
    text: "45"
  }
];

//TODO: Make global
const statusList = [
  {
    value: 1,
    text: "Pendiente"
  },
  {
    value: 2,
    text: "Confirmada"
  },
  {
    value: 3,
    text: "Finalizada"
  },
  {
    value: 4,
    text: "Cancelada"
  },
  {
    value: 5,
    text: "Pagada"
  }
];

const AppointmentInfo = ({
  settings,
  appointment,
  clinic_id,
  doctorsList,
  techniciansList,
  appointmentServices,
  clinicServices
}) => {
  const dispatch = useDispatch();
  const update = updateAppointment({ id: appointment.appointment_id });

  const { errors, edit_mode, user_country, toggle_modal } = useSelector(
    store => ({
      errors: store.clinic.clinic.clinicAppointments.errors,
      edit_mode: store.clinic.appointment.editMode,
      user_country: store.auth.user.country,
      toggle_modal: store.clinic.clinic.toggleAppointmentServicesModal
    })
  );

  const [formState, setFormState] = useState({
    ...appointment,
    appointment_date: new Date(appointment.appointment_date)
  });

  const [docOrTechValidationState, setDocOrTechValidationState] = useState(
    false
  );

  const setEditMode = () => {
    dispatch(editMode);
  };

  const toggleAddAppointmentServices = () => dispatch(toggleApptServicesModal);

  const onUpdateAppointment = () => {
    if (
      (formState.doctor_id === null || formState.doctor_id == 0) &&
      (formState.technician_id === null || formState.technician_id == 0)
    ) {
      setDocOrTechValidationState(true);
    } else {
      setDocOrTechValidationState(false);
      let date = new Date(formState.appointment_date);

      dispatch(
        update(
          {
            id: appointment.appointment_id,
            appointment_date:
              _.isDate(formState.appointment_date) &&
              !_.isNull(formState.appointment_date)
                ? date
                : null,
            patient_id: formState.patient_id,
            duration: formState.duration,
            doctor_id: formState.doctor_id > 0 ? formState.doctor_id : null,
            technician_id:
              formState.technician_id > 0 ? formState.technician_id : null,
            comments: formState.comments,
            clinic_id: clinic_id,
            video_appointment_id: formState.video_appointment_id,
            status: formState.status
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "appointment",
              title: "Cita se ha actualizado",
              message:
                "Se ha actualizado la información básica de la cita exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "appointment",
              title: "Falló la actualización de la cita",
              message:
                "No se pudo actualizar la información básica de la cita, por favor intente nuevamente."
            }
          },
          { onFinishedAction: editMode }
        )
      );
    }
  };

  const deleteAppt = () => {
    dispatch(
      deleteAppointment({ id: appointment.appointment_id })(
        {
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Cita eliminada",
            message: "La cita de la clínica se ha eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló eliminando la cita",
            message: "No se pudo eliminar la cita, consulte a soporte técnico."
          }
        }
        /* { onFinishedAction: togglePreviewAppointmentModalOnAgenda } */
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const removeService = id => {
    dispatch(
      deleteAppointmentService({ id })({
        onSuccessMessage: {
          type: "success",
          view: "appointment",
          title: "Servicio eliminada de la cita",
          message: "El servicio ha sido eliminado de la cita exitosamente."
        },
        onFailedMessage: {
          type: "error",
          view: "appointment",
          title: "Falló eliminando el servicio de la cita",
          message:
            "No se pudo eliminar el servicio de la cita, consulte a soporte técnico."
        }
      })
    );
  };

  const onIsVideoAppointment = () => {
    setFormState(oldState => ({
      ...oldState,
      video_appointment_id: !oldState.video_appointment_id ? "undefined" : null
    }));
  };

  const servicesByAppt = [
    {
      accessor: "service_id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false
    },
    {
      Header: "Servicio",
      accessor: "service_name",
      minWidth: 200,
      maxWidth: 250,
      headerClassName: styles.tableSolsticeHeader,
      filterable: true
    },
    {
      Header: "Precio",
      accessor: "price",
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <span>{`${Inter[user_country].currency}${props.original.price}`}</span>
      ),
      filterable: false
    },
    {
      Header: "Remover",
      accessor: "id",
      minWidth: 150,
      maxWidth: 150,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <div>
          <Button
            bsSize="small"
            onClick={() => removeService(props.original.id)}
            className={styles.deleteButton}
          >
            <Image width="12px" src={deleteImg} className={styles.deleteImg} />
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}></Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Información de la cita</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} className={styles.toggleb}>
              <Toggle
                checked={edit_mode}
                icons={false}
                onChange={() => setEditMode()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="select"
                selectOptions={statusList}
                selectFirstOption={<option value={0}>Sin definir</option>}
                selectMap={status => (
                  <option key={status.value.toString()} value={status.value}>
                    {" "}
                    {status.text}{" "}
                  </option>
                )}
                id="status"
                label="Estado"
                value={formState.status || 0}
                readOnly={!edit_mode}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="text"
                    id="first_name"
                    label="Nombre"
                    value={formState.first_name}
                    onChange={onChange}
                    helpClassName={"requiredField"}
                    placeholder="Juan"
                    disabled={true}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="text"
                    id="last_name"
                    label="Apellido(s)"
                    value={formState.last_name}
                    onChange={onChange}
                    helpClassName={"requiredField"}
                    placeholder="Perez"
                    disabled={true}
                  />
                </Col>
              </Row>
              {docOrTechValidationState && (
                <div className={"requiredField"}>
                  Se debe seleccionar un doctor o técnico
                </div>
              )}
              <Row>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="select"
                    selectOptions={doctorsList ? doctorsList : []}
                    selectFirstOption={<option value={0}>Sin asignar</option>}
                    selectMap={doctor => (
                      <option
                        key={doctor.value.toString()}
                        value={doctor.value}
                      >
                        {" "}
                        {doctor.text}{" "}
                      </option>
                    )}
                    id="doctor_id"
                    label="Doctor"
                    value={formState.doctor_id || 0}
                    readOnly={!edit_mode}
                    onChange={onChange}
                  />
                  <FieldGroup
                    type="date"
                    id="appointment_date"
                    label="Fecha"
                    value={formState.appointment_date}
                    onChange={onChange}
                    placeholder="Seleccione una fecha"
                    errors={errors}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                    showTimeSelect={true}
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="select"
                    selectOptions={techniciansList ? techniciansList : []}
                    selectFirstOption={<option value={0}>Sin asignar</option>}
                    selectMap={technician => (
                      <option
                        key={technician.value.toString()}
                        value={technician.value}
                      >
                        {" "}
                        {technician.text}{" "}
                      </option>
                    )}
                    id="technician_id"
                    label="Técnico"
                    value={formState.technician_id || 0}
                    readOnly={!edit_mode}
                    onChange={onChange}
                  />
                  <Row>
                    <Col sm={4}>
                      <FieldGroup
                        type="select"
                        className={styles.timeField}
                        selectOptions={duration}
                        selectMap={minute => (
                          <option
                            key={minute.value.toString()}
                            value={minute.value}
                          >
                            {" "}
                            {minute.text}{" "}
                          </option>
                        )}
                        id="duration"
                        label="Duración"
                        value={formState.duration}
                        readOnly={!edit_mode}
                        onChange={onChange}
                        errors={errors}
                        helpClassName={"requiredField"}
                      />
                    </Col>
                    <Col sm={8}></Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FieldGroup
                    type="textarea"
                    id="comments"
                    label="Comentarios"
                    value={formState.comments ? formState.comments : ""}
                    onChange={onChange}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FieldGroup
                    type="checkbox"
                    id="video_appointment_id"
                    className={styles.checkboxRequired}
                    value={formState.video_appointment_id}
                    onClick={onIsVideoAppointment}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                  >
                    <span>Video consulta</span>
                  </FieldGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </BodyWidget>
        <div className={styles.butArea}>
          <Button onClick={onUpdateAppointment} disabled={!edit_mode}>
            Actualizar
          </Button>
        </div>
      </div>
      {/* Ends content */}

      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}>
          <div className={styles.actionBut}>
            <Button bsSize="small" onClick={toggleAddAppointmentServices}>
              <Glyphicon glyph="plus" /> Agregar servicios
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <Modal
            show={toggle_modal}
            onHide={toggleAddAppointmentServices}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Agregar servicios a la cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddServicesToAppointment
                appointment={appointment}
                clinicServices={clinicServices}
                appointmentServices={appointmentServices}
              />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Servicios</h3>
        {appointmentServices.length > 0 ? (
          <div>
            <ReactTable
              data={appointmentServices}
              columns={servicesByAppt}
              className={[styles.tableSolstice, "body -highlight"].join(" ")}
              defaultPageSize={5}
            />
          </div>
        ) : (
          <BodyWidget>
            <Row>
              <Col xs={12} md={12}>
                <div className={styles.servicesWrapper}>
                  Esta consulta no tiene servicios registrados.
                </div>
              </Col>
            </Row>
          </BodyWidget>
        )}
      </div>
      {/* Ends content */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Eliminar cita</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} md={12}>
              <div className={styles.butArea}>
                <Button onClick={deleteAppt}>Eliminar</Button>
              </div>
              <p>
                Esta acción no se puede revertir y afectará el historial del
                paciente.
              </p>
            </Col>
          </Row>
        </BodyWidget>
      </div>
      {/* Ends content */}
    </div>
  );
};

export default AppointmentInfo;
