import React, { useEffect } from "react";
import { Row, Col, Button, Modal, Glyphicon, Image } from "react-bootstrap";
import styles from "../../clinic.module.css";
import AddClinicCustomization from "./AddClinicCustomization";
import { toggleClinicCustomizationModal } from "../../../../actions/clinic/clinic";
import { useDispatch, useSelector } from "react-redux";
import {
  requestClinicCustomFields,
  deleteClinicCustomField
} from "../../../../actions/clinic/clinic";
import ReactTable from "react-table";
import deleteImg from "../../../../static/img/icons/delete.svg";
import _ from "lodash";

//TODO: make global
const fieldTypes = [
  {
    value: "text",
    text: "Texto"
  },
  {
    value: "textarea",
    text: "Párrafo"
  },
  {
    value: "boolean",
    text: "Si/No"
  },
  {
    value: "date",
    text: "Fecha"
  }
];

const ClinicCustomizations = ({ clinic }) => {
  const dispatch = useDispatch();

  const { clinicCustomFields, toggle_modal } = useSelector(state => ({
    clinicCustomFields:
      state.clinic.clinicCustomFields.clinicCustomFields.content,
    toggle_modal: state.clinic.clinicCustomFields.toggleModal
  }));

  useEffect(() => {
    dispatch(requestClinicCustomFields({ clinic_id: clinic.id }));
  }, [dispatch, clinic.id]);

  const toggleAddClinicCustomizationModal = () =>
    dispatch(toggleClinicCustomizationModal);

  const removeCustomField = id => {
    if (id) {
      dispatch(
        deleteClinicCustomField({ id })({
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Campo personalizado de la clínica eliminado",
            message: "Campo personalizado de la clínica eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló removiendo el campo personalizado",
            message:
              "No se pudo remover el campo personalizado, recuerde que no se pueden remover campos que ya han sido llenados en perfiles de pacientes, si este el caso contacte a soporte técnico."
          }
        })
      );
    }
  };

  const customFields = [
    {
      accessor: "id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false
    },
    {
      Header: "Nombre",
      accessor: "name",
      minWidth: 200,
      maxWidth: 250,
      headerClassName: styles.tableSolsticeHeader,
      filterable: true
    },
    {
      Header: "Tipo",
      accessor: "type",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false,
      Cell: props => (
        <span>{_.find(fieldTypes, ["value", props.original.type]).text}</span>
      )
    },
    {
      Header: "Requerido",
      accessor: "required",
      minWidth: 100,
      maxWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false,
      Cell: props => <span>{props.original.required ? "Sí" : "No"}</span>
    },
    {
      Header: "Descripción",
      accessor: "description",
      headerClassName: styles.tableSolsticeHeader,
      filterable: false
    },
    {
      Header: "Remover",
      accessor: "id",
      minWidth: 100,
      maxWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <div>
          <div className={styles.deleteWrapper}>
            <Button
              bsSize="small"
              onClick={() => removeCustomField(props.original.id)}
              className={styles.deleteButton}
            >
              <Image
                width="12px"
                src={deleteImg}
                className={styles.deleteImg}
              />
            </Button>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}>
          <div className={styles.actionBut}>
            <Button bsSize="small" onClick={toggleAddClinicCustomizationModal}>
              <Glyphicon glyph="plus" /> Agregar campos personalizados
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <Modal
            show={toggle_modal}
            onHide={toggleAddClinicCustomizationModal}
            backdrop="static"
            bsSize="large"
          >
            <Modal.Header closeButton>
              <Modal.Title>Agregar campos personalizados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddClinicCustomization clinic={clinic} />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Campos personalizados</h3>
      </div>
      <div>
        {clinicCustomFields.length > 0 && (
          <ReactTable
            data={clinicCustomFields}
            columns={customFields}
            className={[styles.tableSolstice, "body -highlight"].join(" ")}
            defaultPageSize={10}
          />
        )}
        {clinicCustomFields.length === 0 && (
          <Row>
            <Col xs={12}>No hay campos personalizados para esta clínica.</Col>
          </Row>
        )}
      </div>
      {/* Ends content */}
    </div>
  );
};

export default ClinicCustomizations;
