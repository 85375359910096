import React from "react";
import { Image } from "react-bootstrap";
import toogleOnImg from "../../../src/static/img/icons/toggle_on.svg";
import styles from "./tooltipsTexts.module.css";

export default {
  "basic-info": {
    title: "Mantén tu información actualizada",
    content: (
      <div>
        Verifica que tu información es correcta y esta actualizada, si deseas
        editar información presiona el botón editar ubicado en la parte superior
        derecha de cada ficha.{" "}
        <Image src={toogleOnImg} responsive className={styles.toggleIcon} />
      </div>
    )
  },
  team: {
    title:
      "Agrega al personal medico o asistentes que forman parte de tu clínica",
    content: (
      <div>
        Al agregar un nuevo personal, ellos recibirán un correo y tedrán acceso
        a la información de la clínica que les hayas asignado, siempre podras
        editar esta información o restringir accesos si es necesario.{" "}
      </div>
    )
  },
  services: {
    title: "Agrega los servicios de tu clínica",
    content: (
      <div>
        Agregar los servicios de tu clínica te permitirá llevar un mejor control
        de tus finanzas así como generar reportes mas precisos que te servirá
        para la toma de decisiones de tu clínica.{" "}
      </div>
    )
  },
  payments: {
    title: "Configura tus métodos de pago",
    content: (
      <div>
        Agrega tus métodos de pago para pagar la subscripción mensual de tu
        membresía en la plataforma, recuerda que tu métodos de pago debe estar
        activo para no perder los beneficios de la plataforma.{" "}
      </div>
    )
  },
  settings: {
    title: "Modifica algunas configuraciones de tu clínica",
    content: (
      <div>
        En esta sección puedes modificar algunas configuraciones de tu clínica
        que son utilizadas por nuestra plataforma.{" "}
      </div>
    )
  },
  customizations: {
    title:
      "Campos personalizados son los campos específicos de tu clínica  que seran mostrados en el perfil clínico de tus pacientes",
    content: (
      <div>
        Sabemos que cada clínica es diferente por lo que tu puedes personalizar
        campos del perfil clínico de tu paciente, agrega tantos como necesites,
        estos aparecerán en la sección "Perfil clínico" de cada paciente.{" "}
      </div>
    )
  },
  personal: {
    title: "Información básica del paciente",
    content: (
      <div>
        Actualízala cada vez que sea necesario, a pesar que algunos campos no
        son requeridos te recomendamos agregarlos ya que servirán para mantener
        comunicación con tu paciente.{" "}
      </div>
    )
  },
  profile: {
    title:
      "El perfil clínico muestra datos importantes de la salud del paciente",
    content: (
      <div>
        En este formulario encontrarás campos específicos de tu clínica que
        necesitas saber de cada paciente, los campos deben ser agregados
        previamente por un administrador de tu clínica en la sección "Campos
        personalizados" para que sean mostrados en esta sección.{" "}
      </div>
    )
  },
  history: {
    title: "Historial del paciente en tu clínica",
    content: (
      <div>
        Aquí podrás encontrar el historial de todas las citas de tu paciente al
        seleccionar una de ellas podrás ver los detalles de cada cita.{" "}
      </div>
    )
  }
};
