import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./VideoAppointmentBox.module.css";
import Video from "twilio-video";
import Participant from "./Participant";

const Room = ({ roomName, token, handleLogout }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    Video.connect(token, {}).then(room => {
      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function(
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  return (
    <div className="room">
      {/* Show patient name */}
      {/* <h2>Room: {roomName}</h2> */}
      <div className={styles.logoutWrapper}>
        <Button onClick={handleLogout}>Salir de la cita</Button>
      </div>
      <div className={styles.activeParticipantWrapper}>
        {room && participants[0] ? (
          <Participant
            key={participants[0].sid}
            participant={participants[0]}
            style={styles.activeParticipant}
            videoStyle={styles.videoActiveParticipant}
          />
        ) : (
          room && (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              style={styles.activeParticipant}
              videoStyle={styles.videoActiveParticipant}
            />
          )
        )}
      </div>
      <div
        className={styles.localParticipantWrapper}
        style={room && participants[0] ? {} : { display: "none" }}
      >
        {room && participants[0] ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            style={styles.localParticipant}
            videoStyle={styles.videoLocalParticipant}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Room;
