import createProxy from "../api/apiProxy";
import { showNotification, hideNotification } from "./";

export const requested = name => `req-${name}`;
export const succeeded = name => `suc-${name}`;
export const failed = name => `fai-${name}`;
export const error = name => `err-${name}`;

export const buildAsyncGet = (name, path) => async dispatch => {
  dispatch(hideNotification);
  dispatch({ type: requested(name) });

  try {
    const {
      data: { success, payload }
    } = await createProxy().get(path);
    dispatch({ type: success ? succeeded(name) : failed(name), payload });
  } catch (err) {
    dispatch({ type: error(name), payload: err });
  }
};

export const buildAsyncPost = (name, path) => (
  data,
  { onStartedMessage, onSuccessMessage, onFailedMessage } = {
    onStartedMessage: null,
    onSuccessMessage: null,
    onFailedMessage: null
  },
  { onFinishedAction } = { onFinishedAction: null }
) => async dispatch => {
  dispatch({ type: "hide-notification" });
  dispatch({ type: requested(name), data });
  if (onStartedMessage) {
    dispatch({ type: "show-notification", payload: onStartedMessage });
  }

  try {
    const {
      data: { success, payload }
    } = await createProxy().post(path, data);

    if (success) {
      dispatch({ type: succeeded(name), payload });

      if (onFinishedAction) {
        dispatch(onFinishedAction);
      }

      if (onSuccessMessage) {
        dispatch({ type: "show-notification", payload: onSuccessMessage });
      }
    } else {
      dispatch({ type: failed(name), payload });

      if (onFailedMessage) {
        dispatch({ type: "show-notification", payload: onFailedMessage });
      }
    }
  } catch (err) {
    dispatch({ type: error(name), payload: err });

    if (onFailedMessage) {
      dispatch({ type: "show-notification", payload: onFailedMessage });
    }
  } finally {
    setTimeout(() => dispatch({ type: "hide-notification" }), 10000);
  }
};

export const buildAsyncPut = (name, path) => (
  data,
  { onStartedMessage, onSuccessMessage, onFailedMessage } = {
    onStartedMessage: null,
    onSuccessMessage: null,
    onFailedMessage: null
  }
) => async dispatch => {
  dispatch({ type: "hide-notification" });
  dispatch({ type: requested(name), data });
  if (onStartedMessage) {
    dispatch({ type: "show-notification", payload: onStartedMessage });
  }
  try {
    const {
      data: { success, payload }
    } = await createProxy().put(path, data);
    if (success) {
      dispatch({ type: succeeded(name), payload });
      if (onSuccessMessage) {
        dispatch({ type: "show-notification", payload: onSuccessMessage });
      }
    } else {
      dispatch({ type: failed(name), payload });
      if (onFailedMessage) {
        dispatch({ type: "show-notification", payload: onFailedMessage });
      }
    }
  } catch (err) {
    dispatch({ type: error(name), payload: err });
    if (onFailedMessage) {
      dispatch({ type: "show-notification", payload: onFailedMessage });
    }
  } finally {
    setTimeout(() => dispatch({ type: "hide-notification" }), 10000);
  }
};

export const buildAsyncPatch = (name, path) => (
  data,
  { onStartedMessage, onSuccessMessage, onFailedMessage } = {
    onStartedMessage: null,
    onSuccessMessage: null,
    onFailedMessage: null
  },
  { onFinishedAction } = { onFinishedAction: null }
) => async dispatch => {
  dispatch({ type: "hide-notification" });
  dispatch({ type: requested(name), data });
  if (onStartedMessage) {
    dispatch({ type: "show-notification", payload: onStartedMessage });
  }

  try {
    const {
      data: { success, payload }
    } = await createProxy().patch(path, data);

    if (success) {
      dispatch({ type: succeeded(name), payload });

      if (onFinishedAction) {
        dispatch(onFinishedAction);
      }

      if (onSuccessMessage) {
        dispatch({ type: "show-notification", payload: onSuccessMessage });
      }
    } else {
      dispatch({ type: failed(name), payload });

      if (onFailedMessage) {
        dispatch({ type: "show-notification", payload: onFailedMessage });
      }
    }
  } catch (err) {
    dispatch({ type: error(name), payload: err });

    if (onFailedMessage) {
      dispatch({ type: "show-notification", payload: onFailedMessage });
    }
  } finally {
    setTimeout(() => dispatch({ type: "hide-notification" }), 10000);
  }
};

export const buildAsyncDelete = (name, path) => (
  { onStartedMessage, onSuccessMessage, onFailedMessage } = {
    onStartedMessage: null,
    onSuccessMessage: null,
    onFailedMessage: null
  },
  { onFinishedAction } = { onFinishedAction: null }
) => async dispatch => {
  dispatch({ type: "hide-notification" });
  dispatch({ type: requested(name) });
  if (onStartedMessage) {
    dispatch({ type: "show-notification", payload: onStartedMessage });
  }
  try {
    const {
      data: { success, payload }
    } = await createProxy().delete(path);

    if (success) {
      dispatch({ type: succeeded(name), payload });

      if (onFinishedAction) {
        dispatch(onFinishedAction);
      }

      if (onSuccessMessage) {
        dispatch({ type: "show-notification", payload: onSuccessMessage });
      }
    } else {
      dispatch({ type: failed(name), payload });

      if (onFailedMessage) {
        dispatch({ type: "show-notification", payload: onFailedMessage });
      }
    }
  } catch (err) {
    dispatch({ type: error(name), payload: err });

    if (onFailedMessage) {
      dispatch({ type: "show-notification", payload: onFailedMessage });
    }
  } finally {
    setTimeout(() => dispatch({ type: "hide-notification" }), 10000);
  }
};
