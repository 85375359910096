import React, { Fragment, useState } from "react";
import { FormControl } from "react-bootstrap";
import styles from "./AutoComplete.module.css";

const AutoComplete = ({
  suggestions = [],
  onChange,
  type,
  readOnly,
  onBlur,
  id,
  ...props
}) => {
  const [autoCompleteState, setAutoCompleteState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: ""
  });

  const onChangeValue = e => {
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setAutoCompleteState({
      activeSuggestion: 0,
      filteredSuggestions: filteredSuggestions,
      showSuggestions: true,
      userInput
    });
    onChange({ target: { id: id, value: userInput } });
  };

  const onClick = e => {
    setAutoCompleteState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    onChange({ target: { id: id, value: e.currentTarget.innerText } });
  };

  const onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = autoCompleteState;

    if (e.keyCode === 9 || e.keyCode === 13) {
      if (
        autoCompleteState.showSuggestions &&
        autoCompleteState.userInput != ""
      ) {
        onChange({
          target: {
            id: id,
            value: filteredSuggestions[activeSuggestion]
              ? filteredSuggestions[activeSuggestion]
              : autoCompleteState.userInput
          }
        });
        setAutoCompleteState({
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion]
            ? filteredSuggestions[activeSuggestion]
            : autoCompleteState.userInput
        });
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setAutoCompleteState(oldState => ({
        ...oldState,
        activeSuggestion: autoCompleteState.activeSuggestion - 1
      }));
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setAutoCompleteState(oldState => ({
        ...oldState,
        activeSuggestion: autoCompleteState.activeSuggestion + 1
      }));
    }
  };

  let suggestionsListComponent;

  if (autoCompleteState.showSuggestions && autoCompleteState.userInput) {
    if (autoCompleteState.filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul>
          {autoCompleteState.filteredSuggestions.map((suggestion, index) => {
            let className;
            if (index === autoCompleteState.activeSuggestion) {
              className = styles.suggestionActive;
            }
            return (
              <li
                className={className}
                key={suggestion}
                onClick={onClick}
                onKeyDown={onClick}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <div className={styles.AutocompleteText}>
      <Fragment>
        <FormControl
          type="text"
          onChange={onChangeValue}
          onKeyDown={onKeyDown}
          value={autoCompleteState.userInput}
          readOnly={readOnly}
          onBlur={onBlur}
          autoComplete="off"
          {...props}
        />
        {suggestionsListComponent}
      </Fragment>
    </div>
  );
};

export default AutoComplete;
