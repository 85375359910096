import React from "react";
import { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import styles from "./NavItem.module.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const _NavItem = ({
  text,
  iconClass,
  iconSize = "1.75em",
  eventKey,
  location,
  currLocation,
  expanded,
  userId
}) => {
  const navTextStyle = {};
  if (expanded) {
    navTextStyle.display = "block";
  }
  const currLocationSplit = currLocation.split("/");
  const locationSplit = location.split("/");
  const locationKey = locationSplit[1];
  const currLocationKey = currLocationSplit[1];
  return (
    <NavItem
      eventKey={eventKey}
      key={eventKey}
      className={locationKey === currLocationKey ? styles.selectedBorder : null}
    >
      <NavIcon>
        <Link to={location}>
          <div
            className={
              currLocation === location
                ? [iconClass, styles.icoSelected].join(" ")
                : iconClass
            }
            style={{ fontSize: iconSize }}
          />
        </Link>
      </NavIcon>
      <NavText style={navTextStyle}>
        <Link to={location}>
          <div className={styles.navText}>
            {currLocation === location ? (
              <span className={styles.selected}> {text} </span>
            ) : (
              <span> {text} </span>
            )}
          </div>
        </Link>
      </NavText>
    </NavItem>
  );
};
NavItem.PropTypes = {
  text: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  currLocation: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired
};

export default _NavItem;
