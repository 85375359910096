import React, { useEffect, useState } from "react";
import AppointmentsList from "./components/AppointmentsList";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import styles from "./index.module.css";
import AddAppointment from "./components/Calendar/AddAppointment";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";
import Agenda from "../agenda/components/Calendar/Agenda";
import {
  toggleAppointmentModal,
  toggleAppointmentModalOnAgenda,
  requestClinicDoctors,
  requestClinicTechnicians,
  requestAppointments,
  requestClinicServices,
  requestCalendarAppointments
} from "../../../actions/clinic/clinic";
import colors from "../../../common/constants/agendaColors";
import FieldGroup from "../../../components/Forms/FieldGroup/FieldGroup";

export default ({ filter }) => {
  const dispatch = useDispatch();

  const {
    patients,
    toggleModalAppointment,
    toggleModalAppointmentOnAgenda,
    clinics,
    active_clinic,
    doctors,
    technicians,
    clinicAppointments,
    clinicServices,
    role
  } = useSelector(store => ({
    patients: store.clinic.clinic.clinicPatients,
    toggleModalAppointment: store.clinic.clinic.toggleModalAppointment,
    toggleModalAppointmentOnAgenda: store.clinic.clinic.toggleModalOnAgenda,
    clinics: store.clinic.account.clinics.content,
    active_clinic: store.clinic.account.activeClinic,
    doctors: store.clinic.clinic.clinicDoctors.content,
    technicians: store.clinic.clinic.clinicTechnicians.content,
    clinicAppointments: store.clinic.clinic.clinicAppointments,
    clinicServices: store.clinic.clinic.clinicServices,
    role: store.auth.user.role
  }));

  const toggleCLinicAppointmentModal = () => dispatch(toggleAppointmentModal);

  const toggleCLinicAppointmentModalOnAgenda = () =>
    dispatch(toggleAppointmentModalOnAgenda);

  const [calendarViewState, setCalendarViewState] = useState(false);

  const [defaultMemberState, setDefaultMemberState] = useState(null);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  const onChangeView = () => {
    setCalendarViewState(!calendarViewState);
  };

  const onChangeDefaultMember = ({ target: { value } }) => {
    setDefaultMemberState(value);
    let member_id = parseInt(value.substring(1));
    dispatch(
      requestCalendarAppointments({ clinic_id: active_clinic, member_id })
    );
  };

  //TODO: Double check if patients is needed?
  useEffect(() => {
    dispatch(requestClinicDoctors({ clinic_id: active_clinic }));
    dispatch(requestClinicTechnicians({ clinic_id: active_clinic }));
    dispatch(requestAppointments({ clinic_id: active_clinic }));
    dispatch(requestClinicServices({ clinic_id: active_clinic }));
  }, [clinic]);

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.doctor_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.technician_id,
      text: technician.first_name + " " + technician.last_name
    }));

  const defaultDoctor = doctors && doctors[0] && doctors[0].doctor_id;

  const defaultTechnician =
    technicians && technicians[0] && technicians[0].technician_id;

  const doctorsData =
    doctors &&
    doctors.map(doctor => ({
      value: "d" + doctor.doctor_id,
      text: "Dr. " + doctor.first_name + " " + doctor.last_name,
      color: colors[0]
    }));

  const techniciansData =
    technicians &&
    technicians.map(technician => ({
      value: "t" + technician.technician_id,
      text: "Lic. " + technician.first_name + " " + technician.last_name,
      color: colors[colors.length - 1]
    }));

  const memberList = doctorsData.concat(techniciansData);

  const showMembers = role === "admin" || role === "assistant";

  if (
    patients.loading ||
    clinicAppointments.loading ||
    !clinic ||
    !clinicServices.loaded ||
    (!defaultDoctor && !defaultTechnician) ||
    memberList.length < 1
  )
    return <div>Loading...</div>;
  else
    return (
      <div>
        <div className={"top-nav"}>
          <div className={"container"}>
            <div className={"brand"}>Agenda</div>
          </div>
        </div>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="appointments" view="appointments" />
          </Col>
        </Row>
        <div
          className={[
            "body-area",
            calendarViewState ? styles.calendarWrapper : "",
            !showMembers || memberList.length === 1 ? "-slim" : ""
          ].join(" ")}
        >
          <div className="site-header">
            <h1>Citas</h1>
            <p>
              Clic sobre el nombre del paciente para ver los detalles de la
              cita.
            </p>
          </div>
          <div className={styles.newProperty}>
            <Row>
              <Col xs={12} sm={6}>
                <Row className={styles.memberList}>
                  {calendarViewState ? (
                    memberList.length > 0 ? (
                      <Col xs={12} sm={8}>
                        <FieldGroup
                          type="select"
                          selectOptions={memberList ? memberList : []}
                          selectMap={member => (
                            <option
                              key={member.value.toString()}
                              value={member.value}
                            >
                              {" "}
                              {member.text}{" "}
                            </option>
                          )}
                          id="appointment.doctor_id"
                          value={
                            defaultMemberState
                              ? defaultMemberState
                              : defaultDoctor
                              ? "d" + defaultDoctor
                              : "t" + defaultTechnician
                          }
                          onChange={onChangeDefaultMember}
                          helpClassName={"requiredField"}
                          tabIndex={2}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col xs={12} sm={6}>
                <div className={styles.buttonsWrapper}>
                  <div className={styles.buttonWrap}>
                    <Button
                      className={"push-button -white"}
                      bsSize="small"
                      onClick={onChangeView}
                    >
                      {!calendarViewState ? "Calendario" : "Lista"}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={"push-button -white"}
                      bsSize="small"
                      onClick={
                        calendarViewState
                          ? toggleCLinicAppointmentModalOnAgenda
                          : toggleCLinicAppointmentModal
                      }
                    >
                      Crear cita
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {!calendarViewState && (
            <AppointmentsList
              appointments={clinicAppointments.content}
              filter={filter}
              doctorsList={doctorsList}
              techniciansList={techniciansList}
              memberList={memberList}
              showMembers={showMembers}
            />
          )}
          {calendarViewState && (
            <Agenda
              clinic_id={clinic.id}
              doctorsList={doctorsList}
              techniciansList={techniciansList}
              settings={clinic.settings}
              clinicServices={clinicServices.content}
              defaultDoctor={defaultDoctor}
              defaultTechnician={defaultTechnician}
            />
          )}
          <Modal
            show={
              calendarViewState
                ? toggleModalAppointmentOnAgenda
                : toggleModalAppointment
            }
            onHide={
              calendarViewState
                ? toggleCLinicAppointmentModalOnAgenda
                : toggleCLinicAppointmentModal
            }
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Crear cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddAppointment
                settings={clinic.settings}
                doctorsList={doctorsList}
                techniciansList={techniciansList}
                clinic_id={clinic.id}
                clinic_type={clinic.type}
                clinicServices={clinicServices.content}
                view={calendarViewState ? "agenda" : "list"}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
};
