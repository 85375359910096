import React, { useEffect } from "react";
import Appointment from "./components/Appointment";
import { useDispatch, useSelector } from "react-redux";
import {
  requestAppointments,
  requestClinicDoctors,
  requestClinicTechnicians,
  requestClinicServices,
  requestAppointmentServices
} from "../../../actions/clinic/clinic";
import {
  checkAppointmentExams,
  requestAppointmentExams,
  requestPrevAppointmentExams,
  requestAppointmentExamsList
} from "../../../actions/clinic/appointment";
import history from "../../../history";

export default ({ appointment_id }) => {
  const dispatch = useDispatch();

  const {
    appointments,
    active_clinic,
    clinics,
    doctors,
    technicians,
    clinicServices,
    clinicAppointmentServices,
    appointmentExamsStatus,
    appointmentExams,
    prevAppointmentExams,
    appointmentExamsList
  } = useSelector(state => ({
    appointments: state.clinic.clinic.clinicAppointments.content,
    active_clinic: state.clinic.account.activeClinic,
    clinics: state.clinic.account.clinics.content,
    doctors: state.clinic.clinic.clinicDoctors.content,
    technicians: state.clinic.clinic.clinicTechnicians.content,
    clinicServices: state.clinic.clinic.clinicServices.content,
    clinicAppointmentServices: state.clinic.clinic.clinicAppointmentServices,
    appointmentExamsStatus: state.clinic.appointment.appointmentExamsStatus,
    appointmentExams: state.clinic.appointment.appointmentExams,
    prevAppointmentExams: state.clinic.appointment.prevAppointmentExams,
    appointmentExamsList: state.clinic.appointment.appointmentExamsList
  }));

  useEffect(() => {
    if (!appointments.length) {
      dispatch(requestAppointments({ clinic_id: active_clinic }));
    }
    dispatch(requestClinicDoctors({ clinic_id: active_clinic }));
    dispatch(requestClinicTechnicians({ clinic_id: active_clinic }));
    dispatch(requestClinicServices({ clinic_id: active_clinic }));
    dispatch(requestAppointmentServices({ appointment_id }));
    dispatch(checkAppointmentExams({ appointment_id }));
    dispatch(requestAppointmentExams({ appointment_id }));
    dispatch(requestPrevAppointmentExams({ appointment_id }));
    dispatch(requestAppointmentExamsList({ appointment_id }));
  }, [active_clinic, appointment_id]);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  const [appointment] = appointments.filter(
    appointment => appointment.appointment_id === parseInt(appointment_id)
  );

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.doctor_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.technician_id,
      text: technician.first_name + " " + technician.last_name
    }));

  if (appointments && clinic && appointment_id && !appointment) {
    history.push("/agenda");
  }

  if (
    !appointment_id ||
    !appointment ||
    !clinic ||
    !clinicAppointmentServices.loaded ||
    !appointmentExamsStatus.loaded
  ) {
    return <div>Loading...</div>;
  } else {
    return (
      <Appointment
        appointment_id={appointment_id}
        appointment={appointment}
        settings={clinic.settings}
        clinic_id={clinic.id}
        doctorsList={doctorsList}
        techniciansList={techniciansList}
        clinicServices={clinicServices}
        appointmentServices={clinicAppointmentServices.content}
        appointmentExamsStatus={appointmentExamsStatus.content}
        appointmentExams={appointmentExams}
        prevAppointmentExams={prevAppointmentExams}
        appointmentExamsList={appointmentExamsList}
      />
    );
  }
};
