import React, { useState } from "react";
import { Row, Col, Button, Glyphicon, Modal } from "react-bootstrap";
import styles from "../index.module.css";
import { withRouter } from "react-router-dom";
import { editMode } from "../../../../actions/clinic/common";
import { updateClinic } from "../../../../actions/clinic/clinic";
import "react-toggle/style.css";
import { useDispatch, useSelector } from "react-redux";
import Payments from "./Payments/Payments";
import CardForm from "./Payments/CardForm";

const ClinicPayments = ({ clinic }) => {
  const dispatch = useDispatch();
  const update = updateClinic(clinic);

  const { edit_mode, errors } = useSelector(state => ({
    edit_mode: state.clinic.account.editMode,
    errors: state.clinic.clinic.clinicTeam.errors
  }));

  const [formState, setFormState] = useState({
    ...clinic
  });

  const onUpdatePaymentMethods = payment_id => {
    dispatch(
      update(
        {
          id: clinic.id,
          primary_payment_method_id: payment_id
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Clínica actilizada",
            message: "Se ha actualizado tu metodo de pago."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló la actualización de la clínica",
            message:
              "No se pudo actualizar tu metodo de pago, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const [cardFormVisible, setCardFormVisible] = useState(false);

  const toggleCardForm = () => {
    setCardFormVisible(!cardFormVisible);
  };

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}>
          <div className={styles.actionBut}>
            <Button bsSize="small" onClick={toggleCardForm}>
              <Glyphicon glyph="plus" /> Agregar tarjeta de credito
            </Button>
          </div>
        </Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Pagos</h3>
        <Payments
          formState={formState}
          setFormState={setFormState}
          onUpdatePaymentMethods={onUpdatePaymentMethods}
        />
      </div>
      {/* Ends content */}

      {cardFormVisible && (
        <Modal show={cardFormVisible} onHide={toggleCardForm}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar metodo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <CardForm account={clinic} toggleSelf={toggleCardForm} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default withRouter(ClinicPayments);
