import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "../index.module.css";

const ClinicSpecialties = ({ clinic_specialties, specialties }) => {
  if (!clinic_specialties) {
    return <div>Loading...</div>;
  }
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          {Object.keys(clinic_specialties).map(function(key, index) {
            if (clinic_specialties[key].length > 0) {
              let result = [
                <p className={styles.specialtiesList} key={index}>
                  {
                    specialties.filter(spec => spec.id === parseInt(key))[0]
                      .name
                  }
                </p>
              ];
              clinic_specialties[key].map((subSpecialty, ii) => {
                if (subSpecialty) {
                  result.push(
                    <p className={styles.subSpecialtiesList} key={"sub-" + ii}>
                      {
                        specialties
                          .filter(spec => spec.id === parseInt(key))[0]
                          .sub.filter(
                            spec => spec.id === parseInt(subSpecialty)
                          )[0].name
                      }
                    </p>
                  );
                }
              });
              return result;
            } else {
              if (specialties)
                return (
                  <p className={styles.specialtiesList}>
                    {specialties.filter(spec => spec.id === parseInt(key))[0] &&
                      specialties.filter(spec => spec.id === parseInt(key))[0]
                        .name}
                  </p>
                );
            }
          })}
        </div>
      </Col>
      <Col xs={12} sm={6}></Col>
    </Row>
  );
};
export default ClinicSpecialties;
