import React, { useState } from "react";
import { Row, Col, Button, ControlLabel } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

const PatientAppointmentDetail = ({
  appointment,
  doctorsList,
  techniciansList,
  settings
}) => {
  const hourAppointment = new Date(
    appointment.appointment_date
  ).toLocaleString("en-SV", { timeZone: "America/El_Salvador" });

  const [formState, setFormState] = useState({
    ...appointment,
    appointment_date: new Date(appointment.appointment_date),
    hour: parseFloat(
      new Date(hourAppointment).getHours() +
        "." +
        (new Date(hourAppointment).getMinutes() * 100) / 60
    )
  });

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}>
          <div className={styles.wrapper}>
            <Link
              className={["push-button"].join(" ")}
              to={`/agenda/${appointment.appointment_id}`}
            >
              Editar cita
            </Link>
          </div>
        </Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Historial de la cita</h3>
        <BodyWidget>
          <Row className={styles.historyWrapper}>
            <Col xs={12} md={12}>
              <h4>Detalles de la cita</h4>
              <Row>
                <Col xs={12} md={12}>
                  <ControlLabel>Paciente</ControlLabel>
                  <p>{`${formState.first_name} ${formState.last_name}`}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ControlLabel>Doctor</ControlLabel>
                  <p>
                    {doctorsList &&
                    doctorsList.filter(
                      doctor =>
                        parseInt(doctor.value) === parseInt(formState.doctor_id)
                    )[0]
                      ? doctorsList.filter(
                          doctor =>
                            parseInt(doctor.value) ===
                            parseInt(formState.doctor_id)
                        )[0].text
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <ControlLabel>Técnico</ControlLabel>
                  <p>
                    {techniciansList &&
                    techniciansList.filter(
                      technician =>
                        parseInt(technician.value) ===
                        parseInt(formState.technician_id)
                    )[0]
                      ? techniciansList.filter(
                          technician =>
                            parseInt(technician.value) ===
                            parseInt(formState.technician_id)
                        )[0].text
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ControlLabel>Fecha</ControlLabel>
                  <p>{`${moment(formState.appointment_date).format("lll")}`}</p>
                </Col>
                <Col xs={12} md={6}>
                  <ControlLabel>Duración</ControlLabel>
                  <p>{`${formState.duration}`}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <ControlLabel>Comentarios</ControlLabel>
                  <p>{`${formState.comments ? formState.comments : "-"}`}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          {formState.physical_examination && (
            <Row className={styles.historyWrapper}>
              <Col xs={12}>
                <h4>Consulta médica</h4>
                <Row>
                  <Col xs={12} md={12}>
                    <ControlLabel>Consulta por</ControlLabel>
                    <p>{`${
                      formState.physical_examination.reason_for_appointment
                        ? formState.physical_examination.reason_for_appointment
                        : "-"
                    }`}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <ControlLabel>Enfermedad que presenta</ControlLabel>
                    <p>{`${
                      formState.physical_examination.disease
                        ? formState.physical_examination.disease
                        : "-"
                    }`}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Presión Arterial</ControlLabel>
                        <p>{`${
                          formState.physical_examination.blood_pressure
                            ? formState.physical_examination.blood_pressure
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Frecuencia Cardíaca</ControlLabel>
                        <p>{`${
                          formState.physical_examination.heart_rate
                            ? formState.physical_examination.heart_rate
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Respiratoria</ControlLabel>
                        <p>{`${
                          formState.physical_examination.respiratory
                            ? formState.physical_examination.respiratory
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Temperatura</ControlLabel>
                        <p>{`${
                          formState.physical_examination.temperature
                            ? formState.physical_examination.temperature
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Cabeza</ControlLabel>
                        <p>{`${
                          formState.physical_examination.head
                            ? formState.physical_examination.head
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Ojos</ControlLabel>
                        <p>{`${
                          formState.physical_examination.eyes
                            ? formState.physical_examination.eyes
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Fosas Nasales</ControlLabel>
                        <p>{`${
                          formState.physical_examination.nostrils
                            ? formState.physical_examination.nostrils
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Cuello</ControlLabel>
                        <p>{`${
                          formState.physical_examination.neck
                            ? formState.physical_examination.neck
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Boca y Lengua</ControlLabel>
                        <p>{`${
                          formState.physical_examination.mouth_and_tongue
                            ? formState.physical_examination.mouth_and_tongue
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Torax</ControlLabel>
                        <p>{`${
                          formState.physical_examination.thorax
                            ? formState.physical_examination.thorax
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Abdomen</ControlLabel>
                        <p>{`${
                          formState.physical_examination.abdomen
                            ? formState.physical_examination.abdomen
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Cardiovascular</ControlLabel>
                        <p>{`${
                          formState.physical_examination.cardiovascular
                            ? formState.physical_examination.cardiovascular
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Genitales</ControlLabel>
                        <p>{`${
                          formState.physical_examination.genitals
                            ? formState.physical_examination.genitals
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Extremidades</ControlLabel>
                        <p>{`${
                          formState.physical_examination.extremities
                            ? formState.physical_examination.extremities
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Piel</ControlLabel>
                        <p>{`${
                          formState.physical_examination.skin
                            ? formState.physical_examination.skin
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <ControlLabel>Peso</ControlLabel>
                        <p>{`${
                          formState.physical_examination.weight
                            ? formState.physical_examination.weight
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>Altura</ControlLabel>
                        <p>{`${
                          formState.physical_examination.height
                            ? formState.physical_examination.height
                            : "-"
                        }`}</p>
                      </Col>
                      <Col xs={12} md={4}>
                        <ControlLabel>IMC</ControlLabel>
                        <p>{`${
                          formState.physical_examination.bmi
                            ? formState.physical_examination.bmi
                            : "-"
                        }`}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className={styles.historyWrapper}>
            <Col xs={12}>
              <h4>Resultados</h4>
              <Row>
                <Col xs={12} md={12}>
                  <ControlLabel>Diagnóstico</ControlLabel>
                  <p>{`${formState.diagnosis ? formState.diagnosis : "-"}`}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <ControlLabel>Observaciones</ControlLabel>
                  <p>{`${
                    formState.observations ? formState.observations : "-"
                  }`}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <ControlLabel>Medicamentos</ControlLabel>
                  <p>{`${formState.medicines ? formState.medicines : "-"}`}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </BodyWidget>
      </div>
      {/* Ends content */}
    </div>
  );
};

export default PatientAppointmentDetail;
