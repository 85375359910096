import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./AddClinicCustomization.module.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import { addClinicCustomField } from "../../../../actions/clinic/clinic";
import { toggleClinicCustomizationModal } from "../../../../actions/clinic/clinic";
import { useDispatch, useSelector } from "react-redux";

const fieldTypes = [
  {
    value: "text",
    text: "Texto"
  },
  {
    value: "textarea",
    text: "Párrafo"
  },
  {
    value: "boolean",
    text: "Si/No"
  },
  {
    value: "date",
    text: "Fecha"
  }
];

const AddClinicCustomization = ({ clinic }) => {
  const dispatch = useDispatch();
  const create_clinic_custom_field = addClinicCustomField();

  const { errors } = useSelector(store => ({
    errors: store.clinic.clinicCustomFields.clinicCustomFields.errors
  }));

  const [formState, setFormState] = useState({
    name: "",
    key: "",
    type: "text",
    required: false,
    description: ""
  });

  const addClinicCustomization = () => {
    let field_key = formState.name.toLowerCase();
    field_key = field_key.replace(" ", "_");
    dispatch(
      create_clinic_custom_field(
        {
          ...formState,
          key: field_key,
          required: formState.required,
          active: true,
          clinic_id: parseInt(clinic.id)
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Campo personalizado de la clínica agregado",
            message: "Campo personalizado de la clínica agregado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló agregando el campo personalizado",
            message:
              "No se pudo agregar el campo personalizado, por favor inténtelo nuevamente."
          }
        },
        { onFinishedAction: toggleClinicCustomizationModal }
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    if (value === "None") {
      value = null;
    }
    setFormState({
      ...formState,
      [id]: value === "on" ? !formState[id] : value
    });
  };

  return (
    <div>
      <Row>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="clinic" view="clinic_customization_add_modal" />
          </Col>
        </Row>
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="name"
                label="Nombre"
                errors={errors}
                value={formState.name}
                placeholder=""
                onChange={onChange}
                isRequired
                helpClassName={"requiredField"}
              />
            </Col>
            <Col xs={12} md={2}>
              <FieldGroup
                type="select"
                selectOptions={fieldTypes}
                selectMap={fieldType => (
                  <option
                    key={fieldType.value.toString()}
                    value={fieldType.value}
                  >
                    {" "}
                    {fieldType.text}{" "}
                  </option>
                )}
                id="type"
                label="Tipo"
                value={formState.field_type}
                onChange={onChange}
                isRequired
                errors={errors}
                helpClassName={"requiredField"}
              />
            </Col>
            <Col xs={12} md={2}>
              <FieldGroup
                type="checkbox"
                id="required"
                className={styles.checkboxRequired}
                value={formState.required}
                onClick={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                label="Obligatorio"
              >
                {" "}
              </FieldGroup>
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="description"
                label="Descripción"
                value={formState.description}
                placeholder=""
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  bsSize="small"
                  onClick={addClinicCustomization}
                >
                  AGREGAR CAMPO PERSONALIZADO
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddClinicCustomization;
