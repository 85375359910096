import React, { useEffect } from "react";
import styles from "../VideoAppointment.module.css";
import Room from "./Room";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

const AppointmentWindow = ({ location: { search } }) => {
  const q = queryString.parse(search);

  useEffect(() => {
    if (!window.opener) {
      window.close();
    }
  }, []);

  if (1 == 2) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={styles.popUpWrapper}>
        <Room token={q.token} appointment_id={q.id} />
      </div>
    );
  }
};

export default withRouter(AppointmentWindow);
