import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import { EDIT_MODE } from "../../actions/clinic/common";
import {
  FETCH_CLINIC_CUSTOMIZATIONS,
  ADD_CLINIC_CUSTOM_FIELD,
  TOGGLE_CLINIC_CUSTOMIZATION_MODAL,
  DELETE_CLINIC_CUSTOM_FIELD
} from "../../actions/clinic/clinic";
import _ from "lodash";

var defaultState = {
  clinicCustomFields: pristine([]),
  editMode: false,
  toggleModal: false
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_CLINIC_CUSTOMIZATIONS)) {
    return reduce(
      state,
      "clinicCustomFields",
      action,
      FETCH_CLINIC_CUSTOMIZATIONS
    );
  }

  if (action.type === requested(EDIT_MODE)) {
    return {
      ...state,
      editMode: !state.editMode,
      clinicCustomFields: {
        ...state.clinicCustomFields,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_CLINIC_CUSTOMIZATION_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal,
      clinicCustomFields: {
        ...state.clinicCustomFields,
        errors: null
      }
    };
  }

  if (action.type === succeeded(ADD_CLINIC_CUSTOM_FIELD)) {
    return {
      ...state,
      clinicCustomFields: {
        ...state.clinicCustomFields,
        content: [...state.clinicCustomFields.content, action.payload],
        errors: null
      }
    };
  }

  if (action.type === failed(ADD_CLINIC_CUSTOM_FIELD)) {
    return {
      ...state,
      clinicCustomFields: {
        ...state.clinicCustomFields,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(DELETE_CLINIC_CUSTOM_FIELD)) {
    const index = _.findIndex(state.clinicCustomFields.content, {
      id: action.payload.id
    });
    let newClinicCustomFields = state.clinicCustomFields.content;
    newClinicCustomFields.splice(index, 1);
    return {
      ...state,
      clinicCustomFields: {
        ...state.clinicCustomFields,
        content: newClinicCustomFields
      }
    };
  }

  if (action.type === failed(DELETE_CLINIC_CUSTOM_FIELD)) {
    return {
      ...state
    };
  }

  return state;
}
