import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  requestPatientCustomFields,
  addPatientCustomFields,
  updatePatientCustomFields
} from "../../../../actions/clinic/patient_custom_fields";
//import { showNotification } from '../../../../actions/index'
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { editMode } from "../../../../actions/clinic/common";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import _ from "lodash";
import { required } from "../../../../common/functions/validations";
import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";

const booleanValues = [
  {
    value: 0,
    text: "No"
  },
  {
    value: 1,
    text: "Si"
  }
];

const PatientClinicProfile = ({
  patient_id,
  patient_custom_fields,
  clinic_custom_fields,
  customFieldsMerged
}) => {
  const dispatch = useDispatch();
  const create_new_patient_custom_fields = addPatientCustomFields();
  const update = updatePatientCustomFields(patient_custom_fields);

  const { clinicCustomFields, active_clinic, edit_mode } = useSelector(
    state => ({
      clinicCustomFields:
        state.clinic.clinicCustomFields.clinicCustomFields.content,
      active_clinic: state.clinic.account.activeClinic,
      edit_mode: state.clinic.account.editMode
    })
  );

  useEffect(() => {
    if (patient_id && active_clinic) {
      dispatch(requestPatientCustomFields({ patient_id }));
    }
  }, [patient_id, active_clinic]);

  const [formState, setFormState] = useState({
    ...patient_custom_fields.custom_fields
  });

  const [errors, setErrors] = useState({});

  const setEditMode = () => {
    dispatch(editMode);
  };

  const isFormValid = (formState, formValidation) => {
    let errors = {};
    let noErrors = true;
    Object.keys(formState).forEach(field => {
      if (Array.isArray(formValidation[field])) {
        formValidation[field].forEach(validation => {
          const result = validation(formState[field], formState);
          if (result) {
            errors = {
              ...errors,
              [field]: [
                {
                  name: field,
                  message: "Este campo es requerido",
                  value: null,
                  location: "body"
                }
              ]
            };
            noErrors = false;
          }
        });
      }
    });
    setErrors({ ...errors });
    return noErrors;
  };

  const formValidation = clinicCustomFields.reduce(function(
    newFormValidation,
    clinicCustomField
  ) {
    if (clinicCustomField.required)
      newFormValidation[clinicCustomField.key] = [required()];
    return newFormValidation;
  },
  {});

  const onUpdatePatientCustomFields = () => {
    if (_.isEmpty(patient_custom_fields)) {
      if (isFormValid(customFieldsMerged, formValidation)) {
        dispatch(
          create_new_patient_custom_fields(
            {
              patient_id: patient_id,
              clinic_id: active_clinic,
              custom_fields: {
                ...formState
              }
            },
            {
              onSuccessMessage: {
                type: "success",
                view: "patient",
                title: "Paciente actualizado",
                message:
                  "Se ha actualizado el perfil clínico del paciente exitosamente."
              },
              onFailedMessage: {
                type: "error",
                view: "patient",
                title: "Falló la actualización del paciente",
                message:
                  "No se pudo actualizar el perfil clínico del paciente, por favor intente nuevamente."
              }
            },
            { onFinishedAction: editMode }
          )
        );
      } else {
        dispatch({
          type: "show-notification",
          payload: {
            type: "error",
            view: "patient",
            title: "Falló la actualización del perfil clínico paciente",
            message:
              "Asegurese que todos los campos requeridos han sido ingresados."
          }
        });
      }
    } else {
      if (
        isFormValid(
          Object.keys(customFieldsMerged).length ===
            Object.keys(formState).length
            ? formState
            : customFieldsMerged,
          formValidation
        )
      ) {
        dispatch(
          update(
            {
              id: patient_custom_fields.id,
              custom_fields: {
                ...formState
              }
            },
            {
              onSuccessMessage: {
                type: "success",
                view: "patient",
                title: "Paciente actualizado",
                message:
                  "Se ha actualizado el perfil clínico del paciente exitosamente."
              },
              onFailedMessage: {
                type: "error",
                view: "patient",
                title: "Falló la actualización del paciente",
                message:
                  "No se pudo actualizar el perfil clínico del paciente, por favor intente nuevamente."
              }
            },
            { onFinishedAction: editMode }
          )
        );
      } else {
        dispatch({
          type: "show-notification",
          payload: {
            type: "error",
            view: "patient",
            title: "Falló la actualización del perfil clínico paciente",
            message:
              "Asegurese que todos los campos requeridos han sido ingresados."
          }
        });
      }
    }
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  if (!clinicCustomFields) {
    return <div>Loading...</div>;
  } else
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <div className={styles.toggleb}></div>
          <h3>Perfil Clínico</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            {clinicCustomFields.length === 0
              ? "No hay campos registrados."
              : clinicCustomFields.map((customField, i) => {
                  if (customField.type === "text")
                    return (
                      <Row
                        key={customField.key}
                        className={styles.customFieldWrapper}
                      >
                        <Col>
                          <FieldGroup
                            type="text"
                            id={customField.key}
                            label={customField.name}
                            readOnly={!edit_mode}
                            errors={errors}
                            value={formState[customField.key]}
                            placeholder={customField.description}
                            onChange={onChange}
                            helpClassName={"requiredField"}
                            isRequired={customField.required}
                          />
                        </Col>
                      </Row>
                    );
                  if (customField.type === "textarea")
                    return (
                      <Row
                        key={customField.key}
                        className={styles.customFieldWrapper}
                      >
                        <Col>
                          <FieldGroup
                            type="textarea"
                            id={customField.key}
                            label={customField.name}
                            readOnly={!edit_mode}
                            errors={errors}
                            value={formState[customField.key]}
                            placeholder={customField.description}
                            onChange={onChange}
                            helpClassName={"requiredField"}
                            isRequired={customField.required}
                          />
                        </Col>
                      </Row>
                    );
                  if (customField.type === "date")
                    return (
                      <Row
                        key={customField.key}
                        className={styles.customFieldWrapper}
                      >
                        <Col>
                          <FieldGroup
                            type="date"
                            id={customField.key}
                            label={customField.name}
                            disabled={!edit_mode}
                            errors={errors}
                            value={
                              formState[customField.key]
                                ? new Date(formState[customField.key])
                                : null
                            }
                            placeholder={customField.description}
                            onChange={onChange}
                            helpClassName={"requiredField"}
                            isRequired={customField.required}
                          />
                        </Col>
                      </Row>
                    );
                  if (customField.type === "boolean")
                    return (
                      <Row
                        key={customField.key}
                        className={styles.customFieldWrapper}
                      >
                        <Col>
                          <FieldGroup
                            type="select"
                            disabled={!edit_mode}
                            selectFirstOption={<option value={null}></option>}
                            selectOptions={booleanValues}
                            selectMap={booleanValue => (
                              <option
                                key={booleanValue.value.toString()}
                                value={booleanValue.value}
                              >
                                {" "}
                                {booleanValue.text}{" "}
                              </option>
                            )}
                            id={customField.key}
                            label={customField.name}
                            value={formState[customField.key]}
                            onChange={onChange}
                            isRequired={customField.required}
                            errors={errors}
                            helpClassName={"requiredField"}
                          />
                        </Col>
                      </Row>
                    );
                })}
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={onUpdatePatientCustomFields} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>
        {/* Ends content */}
      </div>
    );
};

export default PatientClinicProfile;
