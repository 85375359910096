import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";

const Lobby = ({
  username,
  handleUsernameChange,
  roomName,
  handleRoomNameChange,
  handleSubmit
}) => {
  return (
    <Row>
      <Col xs={12}>
        <Button onClick={handleSubmit}>Iniciar consulta</Button>
      </Col>
    </Row>
  );
};

export default Lobby;
