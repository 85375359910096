import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Nav, Image, Button, Modal } from "react-bootstrap";
import AppointmentInfo from "./AppointmentInfo";
import AppointmentPhysicalExamination from "./AppointmentPhysicalExamination";
import AppointmentLabExams from "./AppointmentLabExams";
import AppointmentResults from "./AppointmentResults";
import AppointmentDocuments from "./AppointmentDocuments";
import styles from "../index.module.css";
// import CustomerHeader from '../../../../components/Layout/Headers/CustomerHeader';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../../../history";
import rightArrowImg from "../../../../static/img/right-arrow.svg";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import NavLinkCleaner from "../../../../components/NavLinkCleaner/NavLinkCleaner";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleEndAppointmentModal,
  switchAppointmentHasPrescription,
  toggleVideoAppointmentModal
} from "../../../../actions/clinic/clinic";
import { requestPrescriptionDownloadUrl } from "../../../../actions/clinic/appointment";
import EndAppointment from "./EndAppointment";
import VideoAppointment from "./VideoAppointment/VideoAppointment";
import pdfIcon from "../../../../static/img/pdf.svg";
import { createLocalTracks } from "twilio-video";
import { Link } from "react-router-dom";
import { ImProfile } from "react-icons/im";

const Appointment = ({
  appointment_id,
  appointment,
  settings,
  appointment_history,
  clinic_id,
  doctorsList,
  techniciansList,
  clinicServices,
  appointmentServices,
  appointmentExamsStatus,
  appointmentExams,
  prevAppointmentExams,
  appointmentExamsList
}) => {
  const dispatch = useDispatch();
  const pathname = history.location.pathname;
  const tab_key = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length
  );

  //Shows a message when the PopUp is opened or closed
  const [messageState, setMessageState] = useState("");

  const sonResponse = (err, res) => {
    if (err) {
      setMessageState(err);
    }
    setMessageState(res);
  };

  let tab;
  const valid_tabs = [
    "info",
    "physical-examination",
    "exams",
    "results",
    "documents"
  ];
  if (valid_tabs.includes(tab_key)) {
    tab = tab_key;
  } else {
    tab = "info";
  }

  const {
    toggle_modal,
    appointmentHasPrescription,
    toggleVideoAppointment,
    user_id
  } = useSelector(store => ({
    toggle_modal: store.clinic.clinic.toggleModal,
    appointmentHasPrescription: store.clinic.clinic.appointmentHasPrescription,
    toggleVideoAppointment: store.clinic.clinic.toggleVideoAppointment,
    user_id: store.auth.user.id
  }));

  useEffect(() => {
    let hasPrescription = appointment.prescription_pdf_s3_key ? true : false;
    if (hasPrescription != appointmentHasPrescription) {
      dispatch(switchAppointmentHasPrescription);
    }
  }, [appointment_id]);

  const toggleEndAppointment = () => dispatch(toggleEndAppointmentModal);

  const toggleVideoAppt = () => dispatch(toggleVideoAppointmentModal);

  const emailsList =
    appointment && appointment.emails.length > 0
      ? appointment.emails.map(email => ({
          value: email,
          text: email
        }))
      : [];

  const downloadPrescription = () => {
    dispatch(
      requestPrescriptionDownloadUrl({
        appointment_id: appointment.appointment_id
      })
    );
  };

  if (!appointment && !appointment_history && !emailsList) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        {/* <CustomerHeader customer={profile} /> */}

        <div className="body-area">
          <Row className={styles.notifWrapper}>
            <Col xs={12}>
              <NotifBox side="clinic" view="appointment" />
            </Col>
          </Row>
          <Row className={styles.buttonsRow}>
            <Col xs={12} md={12} className={styles.videoWrapper}>
              {appointment.video_appointment_id && (
                <div className={styles.buttonWrap}>
                  <Modal
                    show={toggleVideoAppointment}
                    onHide={toggleVideoAppt}
                    backdrop="static"
                    bsSize="large"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Video consulta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <VideoAppointment
                        appointment={appointment}
                        emailsList={emailsList}
                        sonResponse={sonResponse}
                        toggleVideoAppt={toggleVideoAppt}
                      />
                    </Modal.Body>
                  </Modal>
                  <Button onClick={toggleVideoAppt}>Video Consulta</Button>
                </div>
              )}
              {appointmentHasPrescription && (
                <div className={styles.buttonWrap}>
                  <Button
                    className={[
                      "push-button -white",
                      styles.generateButton
                    ].join(" ")}
                    bsSize="small"
                    onClick={downloadPrescription}
                  >
                    <Image
                      src={pdfIcon}
                      responsive
                      className={styles.pdfIcon}
                    />
                    Prescripción
                  </Button>
                </div>
              )}
              {!appointmentHasPrescription && (
                <div className={styles.buttonWrap}>
                  <Modal
                    show={toggle_modal}
                    onHide={toggleEndAppointment}
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Finalizar cita</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <EndAppointment
                        appointment={appointment}
                        emailsList={emailsList}
                        clinicServices={clinicServices}
                        appointmentServices={appointmentServices}
                      />
                    </Modal.Body>
                  </Modal>
                  <Button onClick={toggleEndAppointment}>Prescripción</Button>
                </div>
              )}
              <div className={[styles.buttonWrap].join(" ")}>
                <Link
                  className={["push-button", styles.profileButton].join(" ")}
                  to={`/patients/${appointment.patient_id}`}
                >
                  <ImProfile className={styles.profileIcon} />
                  Perfil de paciente
                </Link>
              </div>
            </Col>
          </Row>
          <Tab.Container
            id="left-tabs-example"
            activeKey={tab}
            defaultActiveKey="info"
            onSelect={() => {}}
          >
            <Row className="clearfix">
              <Col xs={12} md={3}>
                <Nav className={styles.menu} bsStyle="pills" stacked>
                  <NavLinkCleaner
                    to={`/agenda/${appointment_id}/`}
                    className={tab === "info" ? "adminNavActive" : "adminNav"}
                  >
                    Detalles de la cita
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner>
                  {settings.pe_form && (
                    <NavLinkCleaner
                      to={`/agenda/${appointment_id}/physical-examination`}
                      className={
                        tab === "physical-examination"
                          ? "adminNavActive"
                          : "adminNav"
                      }
                    >
                      Consulta
                      <Image
                        src={rightArrowImg}
                        responsive
                        className={styles.rightArrow}
                      />
                    </NavLinkCleaner>
                  )}
                  {settings.lab_exams && (
                    <NavLinkCleaner
                      to={`/agenda/${appointment_id}/exams`}
                      className={
                        tab === "exams" ? "adminNavActive" : "adminNav"
                      }
                    >
                      Exámenes de laboratorio
                      {appointmentExamsStatus.exams_pending > 0 && (
                        <span className={styles.examsAlert}>
                          <span className={"alertdot"} />
                        </span>
                      )}
                      <Image
                        src={rightArrowImg}
                        responsive
                        className={styles.rightArrow}
                      />
                    </NavLinkCleaner>
                  )}
                  <NavLinkCleaner
                    to={`/agenda/${appointment_id}/results`}
                    className={
                      tab === "results" ? "adminNavActive" : "adminNav"
                    }
                  >
                    Resultados
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner>
                  {/* <NavLinkCleaner
                    to={`/agenda/${appointment_id}/documents`}
                    className={
                      tab === "documents" ? "adminNavActive" : "adminNav"
                    }
                  >
                    Documentos
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner> */}
                </Nav>
              </Col>
              <Col xs={12} md={9} id="cTab">
                <Tab.Content animation className={styles.contentCustomers}>
                  <Tab.Pane eventKey="info">
                    <AppointmentInfo
                      appointment={appointment}
                      settings={settings}
                      clinic_id={clinic_id}
                      doctorsList={doctorsList}
                      techniciansList={techniciansList}
                      appointmentServices={appointmentServices}
                      clinicServices={clinicServices}
                    />
                  </Tab.Pane>
                  {settings.pe_form && (
                    <Tab.Pane eventKey="physical-examination">
                      <AppointmentPhysicalExamination
                        appointment={appointment}
                        pe_form={settings.pe_form}
                      />
                    </Tab.Pane>
                  )}
                  {tab === "exams" && (
                    <Tab.Pane eventKey="exams">
                      <AppointmentLabExams
                        appointment_id={appointment_id}
                        patient_id={appointment.patient_id}
                        appointmentExamsStatus={appointmentExamsStatus}
                        appointment_date={appointment.appointment_date}
                        appointmentExams={appointmentExams}
                        prevAppointmentExams={prevAppointmentExams}
                        appointmentExamsList={appointmentExamsList}
                      />
                    </Tab.Pane>
                  )}
                  <Tab.Pane eventKey="results">
                    <AppointmentResults appointment={appointment} />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="documents">
                    <AppointmentDocuments appointment={appointment} />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
  }
};

Appointment.propTypes = {
  match: PropTypes.object
};

export default withRouter(Appointment);
