import React from "react";
import moment from "moment";

const ProfileUserSince = ({ created_at, text_user_since }) => {
  return (
    <p>{`${text_user_since} ${moment(created_at)
      .locale("es")
      .format("LL")}.`}</p>
  );
};

export default ProfileUserSince;
