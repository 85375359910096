import {
  TOGGLE_MENU,
  CLICK_OUTSIDE,
  //BROWSER_LANGUAGE,
  //FETCH_DICTIONARY,
  SWITCH_TOOLTIPS_MODE
} from "../../actions/common/layout";
import { isMobile } from "react-device-detect";
import { isRelatedAction } from "../utils";
import { pristine, reduce } from "../utils";

var defaultState = {
  opened: isMobile ? true : false,
  language: "en",
  tooltipsMode: false,
  dictionary: pristine({}),
  hidden: false
};

export default function(state = defaultState, action) {
  /* if (isRelatedAction(action, FETCH_DICTIONARY)) {
    return reduce(state, "dictionary", action, FETCH_DICTIONARY);
  } */

  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, opened: !state.opened };
    case SWITCH_TOOLTIPS_MODE:
      return { ...state, tooltipsMode: !state.tooltipsMode };
    case CLICK_OUTSIDE:
      if (state.opened && isMobile) {
        return { ...state, opened: false };
      }
      return state;
    /* case BROWSER_LANGUAGE:
      return { ...state, language: action.payload }; */
    default:
      return state;
  }
}
