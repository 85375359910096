import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import { EDIT_MODE } from "../../actions/clinic/common";
import {
  TOGGLE_CLINIC_TEAM_MEMBER_MODAL,
  TOGGLE_CLINIC_PATIENT_MODAL,
  TOGGLE_APPOINTMENT_MODAL,
  TOGGLE_APPOINTMENT_MODAL_ON_AGENDA,
  TOGGLE_APPOINTMENT_MODAL_ON_CALENDAR,
  TOGGLE_CLINIC_SPECIALTY_MODAL,
  TOGGLE_CLINIC_SERVICE_MODAL,
  FETCH_CLINIC_ROLES,
  FETCH_CLINIC_PATIENTS,
  ADD_CLINIC_PATIENT,
  FETCH_TEAM,
  ADD_CLINIC_MEMBER,
  FETCH_CLINIC_DOCTORS,
  FETCH_CLINIC_TECHNICIANS,
  UPDATE_PATIENT,
  UPDATE_PATIENT_ASSIGNMENT,
  UPDATE_TEAM_MEMBER,
  FETCH_TODAY_APPOINTMENTS,
  FETCH_APPOINTMENTS,
  FETCH_CALENDAR_APPOINTMENTS,
  ADD_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  ADD_APPOINTMENT_ON_AGENDA_VIEW,
  PREVIEW_APPOINTMENT_ON_AGENDA_VIEW,
  UPDATE_APPOINTMENT_ON_AGENDA_VIEW,
  ADD_CLINIC_PATIENT_AND_APPOINTMENT,
  FETCH_CLINIC_SPECIALTIES,
  ADD_CLINIC_SPECIALTY,
  GENERATE_PRESCRIPTION,
  TOGGLE_END_APPOINTMENT_MODAL,
  APPOINTMEN_HAS_PRESCRIPTION,
  SET_LOADING,
  DELETE_PATIENTE,
  REMOVE_TEAM_MEMBER,
  FETCH_CLINIC_SERVICES,
  ADD_CLINIC_SERVICE,
  UPDATE_CLINIC_SERVICE,
  FETCH_APPOINTMENT_SERVICES,
  TOGGLE_APPOINTMENT_SERVICES_MODAL,
  ADD_APPOINTMENT_SERVICES,
  DELETE_APPOINTMENT_SERVICE,
  FETCH_UNCONFIRMED_APPOINTMENTS,
  UPDATE_APPOINTMENT_STATUS,
  FETCH_DASHBOARD_DATA,
  TOGGLE_VIDEO_APPOINTMENT_MODAL,
  FETCH_FINANCIAL_DATA,
  VERIFY_AND_SAVE_CREDIT_CARD,
  FETCH_PAYMENTS_METHODS,
  FETCH_STRIPE
} from "../../actions/clinic/clinic";
import { FETCH_PRESCRIPTION_S3_DOWNLOAD_URL } from "../../actions/clinic/appointment";
import _ from "lodash";

var defaultState = {
  clinicRoles: pristine([]),
  clinicPatients: pristine([]),
  clinicTeam: pristine([]),
  clinicDoctors: pristine([]),
  clinicTechnicians: pristine([]),
  clinicAppointments: pristine([]),
  clinicCalendarAppointments: pristine([]),
  clinicTodayAppointments: pristine([]),
  clinicSpecialties: pristine([]),
  clinicServices: pristine([]),
  clinicAppointmentServices: pristine([]),
  clinicUnconfirmedAppointments: pristine([]),
  clinicDashboardData: pristine({}),
  clinicFinancialData: pristine({}),
  prescription: "",
  editMode: false,
  toggleModal: false,
  toggleModalAppointment: false,
  toggleModalOnAgenda: false,
  toggleModalOnCalendar: false,
  togglePreviewModalOnAgenda: false,
  toggleSpecialtyModal: false,
  toggleModalService: false,
  toggleAppointmentServicesModal: false,
  toggleVideoAppointment: false,
  appointmentHasPrescription: false,
  //TODO: Special loading for generate prescription, fix with pristine if possible
  loading: false,
  payments_methods: pristine([]),
  stripe: pristine({})
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_CLINIC_ROLES)) {
    return reduce(state, "clinicRoles", action, FETCH_CLINIC_ROLES);
  }

  if (isRelatedAction(action, FETCH_TEAM)) {
    return reduce(state, "clinicTeam", action, FETCH_TEAM);
  }

  if (isRelatedAction(action, FETCH_CLINIC_PATIENTS)) {
    return reduce(state, "clinicPatients", action, FETCH_CLINIC_PATIENTS);
  }

  if (isRelatedAction(action, FETCH_CLINIC_DOCTORS)) {
    return reduce(state, "clinicDoctors", action, FETCH_CLINIC_DOCTORS);
  }

  if (isRelatedAction(action, FETCH_CLINIC_TECHNICIANS)) {
    return reduce(state, "clinicTechnicians", action, FETCH_CLINIC_TECHNICIANS);
  }

  if (isRelatedAction(action, FETCH_APPOINTMENTS)) {
    return reduce(state, "clinicAppointments", action, FETCH_APPOINTMENTS);
  }

  if (isRelatedAction(action, FETCH_CALENDAR_APPOINTMENTS)) {
    return reduce(
      state,
      "clinicCalendarAppointments",
      action,
      FETCH_CALENDAR_APPOINTMENTS
    );
  }

  if (isRelatedAction(action, FETCH_CLINIC_SERVICES)) {
    return reduce(state, "clinicServices", action, FETCH_CLINIC_SERVICES);
  }

  if (isRelatedAction(action, FETCH_TODAY_APPOINTMENTS)) {
    return reduce(
      state,
      "clinicTodayAppointments",
      action,
      FETCH_TODAY_APPOINTMENTS
    );
  }

  if (isRelatedAction(action, FETCH_CLINIC_SPECIALTIES)) {
    return reduce(state, "clinicSpecialties", action, FETCH_CLINIC_SPECIALTIES);
  }

  if (isRelatedAction(action, FETCH_APPOINTMENT_SERVICES)) {
    return reduce(
      state,
      "clinicAppointmentServices",
      action,
      FETCH_APPOINTMENT_SERVICES
    );
  }

  if (isRelatedAction(action, FETCH_UNCONFIRMED_APPOINTMENTS)) {
    return reduce(
      state,
      "clinicUnconfirmedAppointments",
      action,
      FETCH_UNCONFIRMED_APPOINTMENTS
    );
  }

  if (isRelatedAction(action, FETCH_DASHBOARD_DATA)) {
    return reduce(state, "clinicDashboardData", action, FETCH_DASHBOARD_DATA);
  }

  if (isRelatedAction(action, FETCH_FINANCIAL_DATA)) {
    return reduce(state, "clinicFinancialData", action, FETCH_FINANCIAL_DATA);
  }

  if (action.type === requested(EDIT_MODE)) {
    return {
      ...state,
      editMode: !state.editMode,
      clinicPatients: {
        ...state.clinicPatients,
        errors: null
      },
      clinicAppointments: {
        ...state.clinicAppointments,
        errors: null
      },
      clinicTeam: {
        ...state.clinicTeam,
        errors: null
      },
      clinicDoctors: {
        ...state.clinicDoctors,
        errors: null
      },
      clinicTechnicians: {
        ...state.clinicTechnicians,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_CLINIC_TEAM_MEMBER_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal,
      clinicTeam: {
        ...state.clinicTeam,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_CLINIC_SPECIALTY_MODAL)) {
    return {
      ...state,
      toggleSpecialtyModal: !state.toggleSpecialtyModal,
      clinicSpecialties: {
        ...state.clinicSpecialties,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_CLINIC_SERVICE_MODAL)) {
    return {
      ...state,
      toggleModalService: !state.toggleModalService,
      clinicServices: {
        ...state.clinicServices,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_CLINIC_PATIENT_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal,
      clinicPatients: {
        ...state.clinicPatients,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_MODAL)) {
    return {
      ...state,
      toggleModalAppointment: !state.toggleModalAppointment,
      toggleModalOnAgenda: false,
      toggleModalOnCalendar: false,
      clinicAppointments: {
        ...state.clinicAppointments,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_MODAL_ON_AGENDA)) {
    return {
      ...state,
      toggleModalOnAgenda: !state.toggleModalOnAgenda,
      toggleModalAppointment: false,
      toggleModalOnCalendar: false,
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_MODAL_ON_CALENDAR)) {
    return {
      ...state,
      toggleModalOnCalendar: !state.toggleModalOnCalendar,
      toggleModalOnAgenda: false,
      toggleModalAppointment: false,
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_END_APPOINTMENT_MODAL)) {
    return {
      ...state,
      toggleModal: !state.toggleModal
    };
  }

  if (action.type === requested(TOGGLE_APPOINTMENT_SERVICES_MODAL)) {
    return {
      ...state,
      toggleAppointmentServicesModal: !state.toggleAppointmentServicesModal,
      clinicAppointmentServices: {
        ...state.clinicAppointmentServices,
        errors: null
      }
    };
  }

  if (action.type === requested(TOGGLE_VIDEO_APPOINTMENT_MODAL)) {
    return {
      ...state,
      toggleVideoAppointment: !state.toggleVideoAppointment
    };
  }

  if (action.type === requested(PREVIEW_APPOINTMENT_ON_AGENDA_VIEW)) {
    return {
      ...state,
      togglePreviewModalOnAgenda: !state.togglePreviewModalOnAgenda,
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        errors: null
      }
    };
  }

  if (action.type === succeeded(ADD_CLINIC_PATIENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        content: [...state.clinicPatients.content, action.payload],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_CLINIC_PATIENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(ADD_CLINIC_PATIENT_AND_APPOINTMENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        content: [...state.clinicPatients.content, action.payload],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_CLINIC_PATIENT_AND_APPOINTMENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(ADD_CLINIC_MEMBER)) {
    return {
      ...state,
      clinicTeam: {
        ...state.clinicTeam,
        content: [...state.clinicTeam.content, action.payload],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_CLINIC_MEMBER)) {
    return {
      ...state,
      clinicTeam: {
        ...state.clinicTeam,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(UPDATE_PATIENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        content: [
          ...state.clinicPatients.content.filter(
            e => e.id !== action.payload.id
          ),
          action.payload
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_PATIENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(UPDATE_PATIENT_ASSIGNMENT)) {
    let patient = state.clinicPatients.content.filter(
      e => e.id === action.payload.patient_id
    );
    let patientUpdated = {
      ...patient[0],
      doctor_id: action.payload.doctor_id,
      technician_id: action.payload.technician_id
    };
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        content: [
          ...state.clinicPatients.content.filter(
            e => e.id !== action.payload.patient_id
          ),
          patientUpdated
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_PATIENT_ASSIGNMENT)) {
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(UPDATE_TEAM_MEMBER)) {
    return {
      ...state,
      clinicTeam: {
        ...state.clinicTeam,
        content: [
          ...state.clinicTeam.content.filter(e => e.id !== action.payload.id),
          action.payload
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_TEAM_MEMBER)) {
    return {
      ...state,
      clinicTeam: {
        ...state.clinicTeam,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(ADD_APPOINTMENT)) {
    let appointmentsSorted = [
      ...state.clinicAppointments.content,
      action.payload
    ];
    appointmentsSorted = _.sortBy(appointmentsSorted, ["appointment_date"]);
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: appointmentsSorted.reverse(),
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_APPOINTMENT)) {
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(ADD_APPOINTMENT_ON_AGENDA_VIEW)) {
    let endAppt = new Date(action.payload.appointment_date);
    let apptAdded = {
      id: action.payload.appointment_id,
      title: action.payload.first_name + " " + action.payload.last_name,
      start: action.payload.appointment_date,
      end: endAppt.setMinutes(
        endAppt.getMinutes() + parseInt(action.payload.duration)
      )
    };
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: [...state.clinicAppointments.content, action.payload],
        errors: null
      },
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        content: [...state.clinicCalendarAppointments.content, apptAdded],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_APPOINTMENT_ON_AGENDA_VIEW)) {
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        errors: action.payload
      },
      loading: false
    };
  }

  //TODO: should it be appointment id instead of payload.id
  if (action.type === succeeded(UPDATE_APPOINTMENT)) {
    let endAppt = new Date(action.payload.appointment_date);
    let apptUpdated = {
      id: action.payload.appointment_id,
      title: action.payload.first_name + " " + action.payload.last_name,
      start: action.payload.appointment_date,
      end: endAppt.setMinutes(
        endAppt.getMinutes() + parseInt(action.payload.duration)
      )
    };

    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: [
          ...state.clinicAppointments.content.filter(
            e => e.appointment_id !== action.payload.appointment_id
          ),
          action.payload
        ]
      },
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        content: [
          ...state.clinicCalendarAppointments.content.filter(
            e => e.id !== action.payload.appointment_id
          ),
          apptUpdated
        ]
      }
    };
  }

  if (action.type === failed(UPDATE_APPOINTMENT)) {
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        errors: {
          ...action.payload
        }
      },
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        errors: {
          ...action.payload
        }
      }
    };
  }

  if (action.type === succeeded(UPDATE_APPOINTMENT_ON_AGENDA_VIEW)) {
    let endAppt = new Date(action.payload.appointment_date);
    let apptUpdated = {
      id: action.payload.appointment_id,
      title: action.payload.first_name + " " + action.payload.last_name,
      start: action.payload.appointment_date,
      end: endAppt.setMinutes(
        endAppt.getMinutes() + parseInt(action.payload.duration)
      )
    };

    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: [
          ...state.clinicAppointments.content.filter(
            e => e.appointment_id !== action.payload.appointment_id
          ),
          action.payload
        ]
      },
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        content: [
          ...state.clinicCalendarAppointments.content.filter(
            e => e.id !== action.payload.appointment_id
          ),
          apptUpdated
        ]
      }
    };
  }

  if (action.type === failed(UPDATE_APPOINTMENT_ON_AGENDA_VIEW)) {
    return {
      ...state,
      clinicCalendarAppointments: {
        ...state.clinicAppointments,
        errors: {
          ...action.payload
        }
      }
    };
  }

  if (action.type === succeeded(DELETE_APPOINTMENT)) {
    let newAppointments = [
      ...state.clinicAppointments.content.filter(
        e => e.appointment_id !== action.payload.id
      )
    ];
    let newCalendarAppointments = state.clinicCalendarAppointments.loaded
      ? [
          ...state.clinicCalendarAppointments.content.filter(
            e => e.id !== action.payload.id
          )
        ]
      : [];
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: newAppointments
      },
      clinicCalendarAppointments: {
        ...state.clinicCalendarAppointments,
        content: newCalendarAppointments
      }
    };
  }

  if (action.type === failed(DELETE_APPOINTMENT)) {
    return {
      ...state
    };
  }

  if (action.type === succeeded(ADD_CLINIC_SPECIALTY)) {
    const specialty = action.payload.specialty_id;
    const sub_specialty = action.payload.sub_specialty_id;
    let newSpecialty;
    if (sub_specialty) {
      if (state.clinicSpecialties.content[specialty]) {
        let content = state.clinicSpecialties.content[specialty];
        content.push(sub_specialty);
        delete state.clinicSpecialties.content[specialty];
        newSpecialty = {
          ...state.clinicSpecialties.content,
          [specialty]: content
        };
      } else {
        newSpecialty = {
          ...state.clinicSpecialties.content,
          [specialty]: [sub_specialty]
        };
      }
    } else {
      newSpecialty = { ...state.clinicSpecialties.content, [specialty]: [] };
    }
    return {
      ...state,
      clinicSpecialties: {
        ...state.clinicSpecialties,
        content: newSpecialty,
        errors: null
      }
    };
  }

  if (action.type === failed(ADD_CLINIC_SPECIALTY)) {
    return {
      ...state,
      clinicSpecialties: {
        ...state.clinicSpecialties,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(GENERATE_PRESCRIPTION)) {
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointments,
        content: [
          ...state.clinicAppointments.content.filter(
            e => e.id !== action.payload.appointment_id
          ),
          action.payload
        ]
      },
      appointmentHasPrescription: action.payload.prescription_pdf_s3_key
        ? true
        : false,
      clinicAppointmentServices: {
        ...state.clinicAppointmentServices,
        content: [
          ...state.clinicAppointmentServices.content,
          ...action.payload.services
        ]
      },
      loading: false
    };
  }

  if (action.type === failed(GENERATE_PRESCRIPTION)) {
    return {
      ...state,
      loading: false
    };
  }

  if (isRelatedAction(action, FETCH_PRESCRIPTION_S3_DOWNLOAD_URL)) {
    switch (action.type) {
      case succeeded(FETCH_PRESCRIPTION_S3_DOWNLOAD_URL):
        let tab = window.open(action.payload, "_blank");
        tab.focus();
        return {
          ...state,
          prescription_s3_download_url: action.payload
        };
      default:
        break;
    }
  }

  if (action.type === requested(APPOINTMEN_HAS_PRESCRIPTION)) {
    return {
      ...state,
      appointmentHasPrescription: !state.appointmentHasPrescription
    };
  }

  if (action.type === requested(SET_LOADING)) {
    return {
      ...state,
      loading: !state.loading
    };
  }

  if (action.type === succeeded(DELETE_PATIENTE)) {
    let newPatients = [
      ...state.clinicPatients.content.filter(e => e.id !== action.payload.id)
    ];
    return {
      ...state,
      clinicPatients: {
        ...state.clinicPatients,
        content: newPatients
      }
    };
  }

  if (action.type === failed(DELETE_APPOINTMENT)) {
    return {
      ...state
    };
  }

  if (action.type === succeeded(REMOVE_TEAM_MEMBER)) {
    return {
      ...state,
      clinicTeam: {
        ...state.clinicTeam,
        content: [...action.payload],
        errors: null
      }
    };
  }

  if (action.type === failed(REMOVE_TEAM_MEMBER)) {
    return {
      ...state
    };
  }

  if (action.type === succeeded(ADD_CLINIC_SERVICE)) {
    return {
      ...state,
      clinicServices: {
        ...state.clinicServices,
        content: [...state.clinicServices.content, action.payload],
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_CLINIC_SERVICE)) {
    return {
      ...state,
      clinicServices: {
        ...state.clinicServices,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(UPDATE_CLINIC_SERVICE)) {
    return {
      ...state,
      clinicServices: {
        ...state.clinicServices,
        content: [
          ...state.clinicServices.content.filter(
            e => e.id !== action.payload.id
          ),
          action.payload
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_CLINIC_SERVICE)) {
    return {
      ...state,
      clinicServices: {
        ...state.clinicServices,
        errors: action.payload
      }
    };
  }

  if (action.type === succeeded(ADD_APPOINTMENT_SERVICES)) {
    return {
      ...state,
      clinicAppointmentServices: {
        ...state.clinicAppointmentServices,
        content: state.clinicAppointmentServices.content.concat(action.payload),
        errors: null
      },
      loading: false
    };
  }

  if (action.type === failed(ADD_APPOINTMENT_SERVICES)) {
    return {
      ...state,
      clinicAppointments: {
        ...state.clinicAppointmentServices,
        errors: action.payload
      },
      loading: false
    };
  }

  if (action.type === succeeded(DELETE_APPOINTMENT_SERVICE)) {
    let newServices = [
      ...state.clinicAppointmentServices.content.filter(
        e => e.id !== action.payload.id
      )
    ];
    return {
      ...state,
      clinicAppointmentServices: {
        ...state.clinicAppointmentServices,
        content: newServices
      }
    };
  }

  if (action.type === failed(DELETE_APPOINTMENT_SERVICE)) {
    return {
      ...state
    };
  }

  if (action.type === succeeded(UPDATE_APPOINTMENT_STATUS)) {
    return {
      ...state,
      clinicUnconfirmedAppointments: {
        ...state.clinicUnconfirmedAppointments,
        content: [
          ...state.clinicUnconfirmedAppointments.content.filter(
            e => e.appointment_id !== action.payload.id
          ),
          action.payload
        ],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_APPOINTMENT_STATUS)) {
    return {
      ...state
    };
  }

  if (isRelatedAction(action, FETCH_PAYMENTS_METHODS)) {
    return reduce(state, "payments_methods", action, FETCH_PAYMENTS_METHODS);
  }

  if (
    isRelatedAction(action, VERIFY_AND_SAVE_CREDIT_CARD)
    //isRelatedAction(action, ADD_UNVERIFIED_BANK_ACCOUNT) ||
    //isRelatedAction(action, ADD_VERIFIED_PAYMENT_METHOD)
  ) {
    if (action.type.substr(0, 3) === "suc") {
      return {
        ...state,
        payments_methods: {
          ...state.payments_methods,
          content: [...state.payments_methods.content, action.payload]
        }
      };
    }
  }

  if (isRelatedAction(action, FETCH_STRIPE)) {
    return reduce(state, "stripe", action, FETCH_STRIPE);
  }

  /* 	if (action.type === succeeded(UPDATE_DOCTOR)) {
		return {
			...state,
			clinicTeam: {
				...state.clinicTeam,
				content: [
					...state.clinicTeam.content.filter(
						(e) => e.id !== action.payload.user_id
					),
					action.payload,
				],
			},
		}
	}

	if (action.type === failed(UPDATE_DOCTOR)) {
		return {
			...state,
		}
	}

	if (action.type === succeeded(UPDATE_TECHNICIAN)) {
		return {
			...state,
			clinicTeam: {
				...state.clinicTeam,
				content: [
					...state.clinicTeam.content.filter(
						(e) => e.id !== action.payload.user_id
					),
					action.payload,
				],
			},
		}
	}

	if (action.type === failed(UPDATE_TECHNICIAN)) {
		return {
			...state,
		}
	} */

  return state;
}
