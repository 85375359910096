import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import styles from "../../../clinic/patients/index.module.css"; //TODO: MAKE A GLOBAL CSS
import moment from "moment";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import Inter from "../../../../common/constants/internationalization";
import { useSelector } from "react-redux";

const statusConfig = {
  1: (
    <span>
      <span className={"inactivedot"} />
    </span>
  ),
  2: (
    <span>
      <span className={"activedot"} />
    </span>
  ),
  3: (
    <span>
      <span className={"alertdot"} />
    </span>
  ),
  4: (
    <span>
      <span className={"reddot"} />
    </span>
  ),
  5: (
    <span>
      <span className={"messagedot"} />
    </span>
  )
};

const MemberDisplay = ({ members, value }) => {
  let name = members
    .filter(obj => obj.value && obj.value === value)
    .map(obj => obj.text);
  return <div>{name}</div>;
};

export default ({ appointments, filter, memberList, showMembers }) => {
  const { user_country } = useSelector(store => ({
    user_country: store.auth.user.country
  }));

  const appointmentsListColumns = [
    {
      accessor: "appointment_id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/agenda/${props.original.appointment_id}`}>
          {" "}
          {props.original.appointment_id}{" "}
        </Link>
      ),
      filterable: false
    },
    {
      Header: "Nombre(s)",
      accessor: "name",
      id: "name",
      minWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/agenda/${props.original.appointment_id}`}>
          {" "}
          {props.original.name}{" "}
        </Link>
      ),
      filterable: true,
      filterMethod: (filter, row) =>
        row.name.toLowerCase().includes(filter.value.toLowerCase())
    },
    {
      Header: "Apellido(s)",
      accessor: "last_name",
      id: "email",
      minWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/agenda/${props.original.appointment_id}`}>
          {" "}
          {props.original.last_name}{" "}
        </Link>
      ),
      filterable: true,
      filterMethod: (filter, row) =>
        row.email.toLowerCase().includes(filter.value.toLowerCase())
    },
    {
      Header: "Fecha",
      accessor: "date",
      id: "date",
      minWidth: 190,
      maxWidth: 190,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/agenda/${props.original.appointment_id}`}>
          {" "}
          {moment(
            new Date(props.original.date).toLocaleString(
              Inter[user_country].language,
              { timeZone: Inter[user_country].timeZone }
            )
          ).format("lll")}
        </Link>
      ),
      filterable: true,
      filterMethod: (f, row) => {
        if (f.value || !f.value === "") {
          return moment(row.date).format("L") === moment(f.value).format("L");
        } else {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <FieldGroup
          type="date"
          id="date"
          value={
            filter
              ? filter.value && filter.value != ""
                ? new Date(filter.value)
                : undefined
              : undefined
          }
          onChange={event => onChange(event.target.value)}
          placeholder="Seleccione una fecha"
          helpClassName={"requiredField"}
          hideLabel={true}
        />
      )
    },
    showMembers &&
      memberList.length > 1 && {
        Header: "Especialista",
        accessor: "memberAsigned",
        id: "memberAsigned",
        minWidth: 200,
        maxWidth: 200,
        Cell: props =>
          props.original.memberAsigned ? (
            <MemberDisplay
              members={memberList}
              value={props.original.memberAsigned}
            />
          ) : (
            ""
          ),
        filterable: true,
        filterMethod: (f, row) => {
          if (f.value || !f.value === "") {
            return row.memberAsigned === f.value;
          } else {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <FieldGroup
            type="select"
            selectOptions={memberList ? memberList : []}
            selectFirstOption={<option value={""}>Todos</option>}
            selectMap={member => (
              <option key={member.value.toString()} value={member.value}>
                {" "}
                {member.text}{" "}
              </option>
            )}
            style={{ color: "#678" }}
            id="appointment.doctor_id"
            className={styles.membersListInTable}
            value={
              filter
                ? filter.value && filter.value != ""
                  ? new Date(filter.value)
                  : undefined
                : undefined
            }
            onChange={event => onChange(event.target.value)}
            hideLabel={true}
          />
        )
      },
    {
      Header: "Estado",
      accessor: "status",
      minWidth: 90,
      maxWidth: 90,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => <span>{statusConfig[props.original.status]}</span>,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "2") {
          return row[filter.id] === 2;
        }
        if (filter.value === "1") {
          return row[filter.id] === 1;
        }
        if (filter.value === "3") {
          return row[filter.id] === 3;
        }
        if (filter.value === "4") {
          return row[filter.id] === 4;
        }
        if (filter.value === "5") {
          return row[filter.id] === 5;
        }
        return row[filter.id] === 2;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Todo</option>
          <option value="2">Confirmadas</option>
          <option value="1">Sin confirmar</option>
          <option value="3">Finalizadas</option>
          <option value="4">Canceladas</option>
          <option value="5">Pagadas</option>
        </select>
      )
    }
  ];

  appointments =
    appointments &&
    appointments.map(appointment => ({
      ...appointment,
      name: appointment.first_name + " " + appointment.middle_name,
      date: appointment.appointment_date,
      memberAsigned: appointment.doctor_id
        ? "d" + appointment.doctor_id
        : "t" + appointment.technician_id
    }));

  return (
    <div>
      <ReactTable
        data={appointments}
        columns={appointmentsListColumns.filter(Boolean)}
        className={[styles.tableSolstice, "body -highlight"].join(" ")}
        defaultPageSize={10}
        filterable
        defaultFiltered={
          filter
            ? [
                {
                  id: "date",
                  value: moment(new Date()).format("L")
                }
              ]
            : []
        }
      />
    </div>
  );
};
