import React, { useEffect } from "react";
import Patient from "./components/Patient";
import { useDispatch, useSelector } from "react-redux";
import { requestPatientCustomFields } from "../../../actions/clinic/patient_custom_fields";
import _ from "lodash";
import { requestClinicCustomFields } from "../../../actions/clinic/clinic";
import history from "../../../history";

export default ({ patient_id }) => {
  const dispatch = useDispatch();

  const {
    patients,
    clinicPatientCustomFields,
    clinicCustomFields,
    active_clinic
  } = useSelector(state => ({
    patients: state.clinic.clinic.clinicPatients.content,
    clinicPatientCustomFields:
      state.clinic.patientCustomFields.patientCustomFields.content,
    clinicCustomFields: state.clinic.clinicCustomFields.clinicCustomFields,
    active_clinic: state.clinic.account.activeClinic
  }));

  useEffect(() => {
    if (patient_id) {
      dispatch(requestPatientCustomFields({ patient_id }));
    }
    if (active_clinic) {
      dispatch(requestClinicCustomFields({ clinic_id: active_clinic }));
    }
  }, [patient_id, active_clinic]);

  const [patient] = patients.filter(
    patient => patient.id === parseInt(patient_id)
  );

  const PatientCustomFields = clinicPatientCustomFields
    ? clinicPatientCustomFields[0]
      ? clinicPatientCustomFields[0]
      : {}
    : {};

  /*   const dispatch = useDispatch();
  const fetch_all_team_members = requestAllTeamMembers(['outreachAdmin', 'outreachStaff']);

  const { customer_detail, account_managers_list, solar_farms_list } = useSelector(state => ({
    customer_detail: state.admin.customer.detail.content,
    account_managers_list: state.admin.teamManagement.all,
    solar_farms_list: state.admin.solarFarm.all
  }));

  useEffect(() => {
    if (shouldLoadCustomerData(customer_detail, customerId)) {
      dispatch(requestCustomerDetail({ id: customerId }));
    }
    return () => {
      dispatch(clearSolarFarmsByZipCode);
    };
  }, [dispatch, customer_detail, customerId]);

  useEffect(() => {
    if (shouldLoadCustomerData(customer_detail, customerId)) {
      dispatch(fetch_all_team_members);
    }
  }, [dispatch, customer_detail, customerId]);

  useEffect(() => {
    if (shouldLoadCustomerData(customer_detail, customerId)) {
      dispatch(requestSolarDevelopers());
    }
  }, [dispatch, customer_detail, customerId]);

  useEffect(() => {
    if (shouldLoadCustomerData(customer_detail, customerId)) {
      dispatch(requestSolarFarms());
    }
  }, [dispatch, customer_detail, customerId]); */

  const clinicCustomFieldsList = clinicCustomFields.content.reduce(function(
    newCustomFieldsList,
    clinicCustomField
  ) {
    if (clinicCustomField.required)
      newCustomFieldsList[clinicCustomField.key] = null;
    return newCustomFieldsList;
  },
  {});

  if (patients && patient_id && !patient) {
    history.push("/patients");
  }

  if (
    !patient_id ||
    _.isEmpty(patient) ||
    !PatientCustomFields ||
    !clinicCustomFields.loaded ||
    !clinicCustomFieldsList
  ) {
    return <div>Loading...</div>;
  }
  return (
    <Patient
      patient_id={patient_id}
      patient={patient}
      patient_custom_fields={PatientCustomFields}
      clinic_custom_fields={clinicCustomFields.content}
      clinicCustomFieldsList={clinicCustomFieldsList}
      clinic_id={active_clinic}
      /* customer_detail={customer_detail}
      account_managers={account_managers_list.content}
      solar_farms={solar_farms_list.content} */
    />
  );
};
