import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./VideoAppointmentBox.module.css";
import moment from "moment";

const calculateTimeLeft = date => {
  let year = new Date().getFullYear();
  let difference = +new Date(date) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};

export default ({ appointment_date }) => {
  const [timeLeft, setTimeLeft] = useState(
    appointment_date ? calculateTimeLeft(appointment_date) : null
  );

  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (timeLeft) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
        setYear(new Date().getFullYear());
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  });

  const timerComponents = [];

  if (timeLeft) {
    Object.keys(timeLeft).forEach(interval => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
  }

  return (
    <Row>
      {/* Login Box */}
      <div className={styles.globalContainer}>
        <Row>
          <Col xs={12}>
            <Row className={styles.sectionWrapper}>
              <Col xs={12}>
                <b>ACCESO DENEGADO</b>
              </Col>
            </Row>
          </Col>
        </Row>
        {timeLeft && (
          <div className={styles.sectionWrapper}>
            {timerComponents.length ? (
              timerComponents
            ) : (
              <span>
                La cita ha caducado.{" "}
                {moment(appointment_date)
                  .locale("es")
                  .format("LL")}
                .
              </span>
            )}
          </div>
        )}
      </div>

      {/* Footer */}
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Col xs={12}>Copyright ©2020 Monitoreo, Tech.</Col>
        </div>
      </div>
    </Row>
  );
};
