import React from "react";
import { Row, Col } from "react-bootstrap";

const Home = () => {
  return (
    <Row>
      <Col>Inicio</Col>
    </Row>
  );
};

export default Home;
