import React from "react";
import styles from "../index.module.css";
import Card from "./Card";
import { Row, Col, Button } from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import { Link } from "react-router-dom";
import Inter from "../../../../common/constants/internationalization";
import { useSelector } from "react-redux";

const activeAppt = false;

const TodayAppointments = ({ todayAppointments, todayAppointmentsMsg }) => {
  const { user_country } = useSelector(store => ({
    user_country: store.auth.user.country
  }));

  return (
    <Card
      title={"Próximas citas"}
      footer={"Ver todas las citas de hoy →"}
      footerLink={"/agenda?filter=today"}
    >
      <Row className={styles.apptsHeader}>
        <Col xs={1}>Estado</Col>
        <Col xs={5}>Paciente</Col>
        <Col xs={2}>Hora</Col>
        <Col xs={4}>Acciones</Col>
      </Row>
      {todayAppointments.length > 0 ? (
        todayAppointments.map(appt => (
          <Row className={styles.apptRow} key={appt.appointment_id}>
            <Col xs={1}>
              <span>
                {" "}
                {1 === 1 ? (
                  <span className={activeAppt ? "animateDot" : ""}>
                    <span className={"activedot"} />
                  </span>
                ) : (
                  <span className={activeAppt ? "animateDot" : ""}>
                    <span className={"inactivedot"} />
                  </span>
                )}{" "}
              </span>
            </Col>
            <Col xs={5}>
              <Row>
                <Col xs={2}>
                  <Avatar name={appt.patient} size="32" />
                </Col>
                <Col xs={10}>
                  <div className={styles.middleLabel}>{appt.patient}</div>
                  <div className={styles.patientData}>
                    {appt.dob ? moment().diff(appt.dob, "years") : ""} years
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={2}>
              {new Date(
                new Date(appt.appointment_date).toLocaleString(
                  Inter[user_country].language,
                  { timeZone: Inter[user_country].timeZone }
                )
              ).getHours() +
                ":" +
                (new Date(
                  new Date(appt.appointment_date).toLocaleString(
                    Inter[user_country].language,
                    {
                      timeZone: Inter[user_country].timeZone
                    }
                  )
                ).getMinutes() === 0
                  ? "00"
                  : new Date(
                      new Date(appt.appointment_date).toLocaleString(
                        Inter[user_country].language,
                        {
                          timeZone: Inter[user_country].timeZone
                        }
                      )
                    ).getMinutes())}
            </Col>
            <Col xs={4}>
              <div className={styles.addNew}>
                <Link to={`agenda/${appt.appointment_id}`}>
                  <Button className={"-dashboard"} bsSize="small">
                    Ver cita
                  </Button>
                </Link>
                <Link to={`patients/${appt.patient_id}`}>
                  <Button className={"-dashboard"} bsSize="small">
                    Ver perfil del paciente
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        ))
      ) : (
        <div className={styles.msgWrapper}>{todayAppointmentsMsg}</div>
      )}
    </Card>
  );
};

export default TodayAppointments;
