import React, { useEffect } from "react";
import PatientsList from "./components/PatientsList";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import styles from "./index.module.css";
import {
  toggleClinicPatientModal,
  requestClinicServices
} from "../../../actions/clinic/clinic";
import AddPatient from "./components/AddPatient";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";
import _ from "lodash";

export default () => {
  const dispatch = useDispatch();

  const {
    patients,
    toggle_modal,
    clinics,
    active_clinic,
    clinicServices
  } = useSelector(store => ({
    patients: store.clinic.clinic.clinicPatients,
    toggle_modal: store.clinic.clinic.toggleModal,
    clinics: store.clinic.account.clinics.content,
    active_clinic: store.clinic.account.activeClinic,
    clinicServices: store.clinic.clinic.clinicServices
  }));

  const toggleCLinicPatienModalModal = () => dispatch(toggleClinicPatientModal);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  useEffect(() => {
    dispatch(requestClinicServices({ clinic_id: active_clinic }));
  }, [clinic]);

  if (patients.loading || _.isEmpty(clinic) || !clinicServices.loaded)
    return <div> Loading... </div>;
  else
    return (
      <div>
        <div className={"top-nav"}>
          <div className={"container"}>
            <div className={"brand"}>Registro</div>
          </div>
        </div>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="patients" view="patients" />
          </Col>
        </Row>
        <div className="body-area -slim">
          <div className="site-header">
            <h1>Pacientes</h1>
            <p>Clic sobre el nombre del paciente para ver los detalles.</p>
          </div>
          <div className={styles.newProperty}>
            <Modal
              show={toggle_modal}
              onHide={toggleCLinicPatienModalModal}
              backdrop="static"
              bsSize="large"
            >
              <Modal.Header closeButton>
                <Modal.Title>Agregar paciente</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddPatient
                  clinic={clinic}
                  settings={clinic.settings}
                  source={"patients"}
                  clinicServices={clinicServices.content}
                />
              </Modal.Body>
            </Modal>
            <div className={styles.addNew}>
              <Button
                className={"push-button -white"}
                bsSize="small"
                onClick={toggleCLinicPatienModalModal}
              >
                Agregar paciente
              </Button>
            </div>
          </div>
          <PatientsList patients={patients.content} />
        </div>
      </div>
    );
};
