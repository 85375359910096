import React, { useState, Fragment, useEffect } from "react";
import styles from "./Payments.module.css";
import { Row, Col, Button, Modal, Glyphicon } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardForm from "./CardForm";
import PaymentDetail from "./PaymentDetail";
import { Accordion, AccordionItem } from "react-sanfona";
import CreditCard from "../../../../../components/PaymentMethod/CreditCard";
import { requestPaymentsMethods } from "../../../../../actions/clinic/clinic";

const Payments = ({ ...props }) => {
  const dispatch = useDispatch();

  const { paymentMethods, plaidEnv } = useSelector(state => ({
    paymentMethods: state.clinic.clinic.payments_methods.content
  }));

  const setPrimaryPM = payment => {
    props.setFormState({
      ...props.formState,
      primary_payment_method_id: payment.id
    });
    props.onUpdatePaymentMethods(payment.id);
  };

  useEffect(() => {
    dispatch(requestPaymentsMethods());
  }, []);

  return (
    <Fragment>
      {/* Header */}
      <Row>
        <Col xs={12}>
          {/* Primary Payment Info */}
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Método de pago activo</div>
            <PaymentDetail
              selectedPaymentMethodId={
                props.formState.primary_payment_method_id
              }
              paymentMethods={paymentMethods}
            />
          </div>
        </Col>
      </Row>

      {/* Sub-header */}
      <Row className={styles.stepTitle}>
        <Col xs={4} xsOffset={4} className={"center-text"}></Col>
        <Col xs={4}>
          <div className={"pull-right"}>
            Lista de metodo de pagos
            {/* <Button
							bsClass={styles.addPaymentButton}
							onClick={toggleCardForm}
						>
							Agregar tarjeta de credito
						</Button> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} xsHidden smHidden>
          {/* <Image className={styles.wizardCardIso} width='100%' src={wizardCardIso} /> */}
        </Col>
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12}>
              <div className={styles.accordionTitle}>
                Tarjetas de crédito
                {/* <I18NText componentKey='properties_step3' translationKey='title_cc' /> */}
              </div>
              <Accordion className={styles.reactSanfona}>
                {paymentMethods
                  .filter(c => c.payment_type === "card")
                  .map((card, index) => {
                    return (
                      <AccordionItem
                        key={`method-${index}`}
                        title={
                          <div>
                            Terminación ${card.last_four}{" "}
                            <div className={styles.expandIco}>
                              <Glyphicon glyph="menu-down" />
                            </div>
                          </div>
                        }
                        expanded={card === 1}
                        className={styles.reactSanfonaItem}
                        titleClassName={styles.reactSanfonaItemTitle}
                        expandedClassName={styles.reactSanfonaItemExpanded}
                        bodyClassName={styles.reactSanfonaItemBodyWrapper}
                      >
                        <Row className={styles.reactSanfonaItemBody}>
                          <CreditCard
                            setPrimaryPM={setPrimaryPM}
                            methodId={card.id}
                            onUpdateAccount={() => true}
                            last_four={card.last_four}
                            first_name={card.first_name}
                            last_name={card.last_name}
                            exp_month={card.exp_month}
                            exp_year={card.exp_year}
                            card_network={card.card_network}
                            method={card}
                            //setSecondaryPM={setSecondaryPM}
                          />
                        </Row>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.checkboxWrapper}>
        <Col xs={12}>
          <Row>
            <div className={styles.hTextRow}>
              {/* <FieldGroup
              type='checkbox'
              id='payment_auth_checkbox'
              labelClassName={styles.formLabel}
              className={styles.checkBoxConsent}
              helpClassName={styles.helpMessage}
              validations={props.formValidationState['payment_auth_checkbox']}
              onChange={e => {
                props.setFormState(e);
              }}
              value={props.formState['payment_auth_checkbox']}
            >
              Payment Authorization{' '}
            </FieldGroup> */}
              {/* <HelpText
              title={'Payment Authorization'}
              body={
                'We store your payment data so we can automatically bill you each month (just like your utility). We will always send an invoice to your email 5 days before you are charged.'
              }
            /> */}

              <div className={styles.divConsentDescription}>
                <span>
                  Autorizo a Monitoreo Salud a cargar mi tarjeta de
                  crédito/debito para cubrir los pagos mensuales por el uso de
                  la plataforma de Monitoreo Salud. Entiendo que los fondos
                  suficientes deben estar en mi cuenta para el pago de lo
                  contrario se perderá el acceso a la misma.
                </span>
                <br />
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      {/* <PaymentConsentModal show={paymentConsentModal} toggleShow={togglePaymentConsentModal} /> */}
      <Row>
        <Col xs={12} align="right" className={styles.buttonContainer}></Col>
      </Row>
    </Fragment>
  );
};

export default Payments;
