import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import {
  updatePatient,
  deletePatient,
  updatePatientAssignment
} from "../../../../actions/clinic/clinic";

const clinicTypes = [
  {
    value: 1,
    text: "Individual"
  },
  {
    value: 2,
    text: "Centro medico"
  }
];

const genders = [
  {
    value: "M",
    text: "Masculino"
  },
  {
    value: "F",
    text: "Femenino"
  }
];

const PatientPersonalInfo = ({ patient, clinic_id }) => {
  const dispatch = useDispatch();
  const update = updatePatient(patient);
  const update_assignment = updatePatientAssignment();

  const { edit_mode, errors, doctors, technicians } = useSelector(store => ({
    edit_mode: store.clinic.clinic.editMode,
    errors: store.clinic.clinic.clinicPatients.errors,
    doctors: store.clinic.clinic.clinicDoctors.content,
    technicians: store.clinic.clinic.clinicTechnicians.content
  }));

  const [formState, setFormState] = useState({
    ...patient,
    emails: patient.emails ? patient.emails.toString() : "",
    phones: patient.phones ? patient.phones.toString() : "",
    allergies: patient.allergies ? patient.allergies.toString() : ""
  });

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdatePatient = () => {
    let emailsArray = formState.emails.replace(/\s/g, "");
    let phonesArray = formState.phones.replace(/\s/g, "");
    let allergiesArray = formState.allergies.replace(/\s/g, "");
    dispatch(
      update(
        {
          id: patient.id,
          first_name: formState.first_name,
          middle_name: formState.middle_name,
          last_name: formState.last_name,
          type: parseInt(formState.type),
          emails: emailsArray === "" ? [] : emailsArray.split(","),
          phones: phonesArray === "" ? [] : phonesArray.split(","),
          gender: formState.gender,
          allergies: allergiesArray === "" ? [] : allergiesArray.split(","),
          dob: new Date(formState.dob)
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "patient",
            title: "Paciente actualizado",
            message:
              "Se ha actualizado la información básica del paciente exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "patient",
            title: "Falló la actualización del paciente",
            message:
              "No se pudo actualizar la información básica del paciente, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onUpdateAssignments = () => {
    dispatch(
      update_assignment(
        {
          clinic_id: parseInt(clinic_id),
          patient_id: parseInt(patient.id),
          doctor_id:
            formState.doctor_id === "" ? null : parseInt(formState.doctor_id),
          technician_id:
            formState.technician_id === ""
              ? null
              : parseInt(formState.technician_id)
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "patient",
            title: "Paciente actualizado",
            message:
              "Se ha actualizado la asignación del paciente exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "patient",
            title: "Falló la actualización del paciente",
            message:
              "No se pudo actualizar la asignación del paciente, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const onDeletePatient = () => {
    dispatch(
      deletePatient({ id: patient.id })(
        {
          onSuccessMessage: {
            type: "success",
            view: "patient",
            title: "Paciente eliminado",
            message: "El paciente se ha eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "patient",
            title: "Falló eliminando al paciente",
            message:
              "No se pudo eliminar al paciente, consulte a soporte técnico."
          }
        }
        /* { onFinishedAction: togglePreviewAppointmentModalOnAgenda } */
      )
    );
  };

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.doctor_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.technician_id,
      text: technician.first_name + " " + technician.last_name
    }));

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}></Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Información Personal</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} className={styles.toggleb}>
              <Toggle
                checked={edit_mode}
                icons={false}
                onChange={() => setEditMode()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="text"
                id="first_name"
                label="Primer nombre"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.first_name}
                placeholder="Primer nombre"
                onChange={onChange}
                helpClassName={"requiredField"}
                isRequired
              />
              <FieldGroup
                type="text"
                id="last_name"
                label="Apellido(s)"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.last_name}
                placeholder="Apellido(s)"
                onChange={onChange}
                helpClassName={"requiredField"}
                isRequired
              />
              <FieldGroup
                type="select"
                disabled={!edit_mode}
                selectOptions={clinicTypes}
                selectMap={clinicType => (
                  <option
                    key={clinicType.value.toString()}
                    value={clinicType.value}
                  >
                    {" "}
                    {clinicType.text}{" "}
                  </option>
                )}
                id="type"
                label="Tipo de paciente"
                value={formState.type}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
              <FieldGroup
                type="date"
                disabled={!edit_mode}
                id="dob"
                label="Fecha de Naciemiento"
                value={new Date(formState.dob)}
                onChange={onChange}
                placeholder="Seleccione una fecha"
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
              <FieldGroup
                type="text"
                id="phones"
                label="Teléfonos"
                placeholder="2222-2222, 7777-7777"
                value={
                  formState.phones.length ? formState.phones.toString() : ""
                }
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={!edit_mode}
              />
            </Col>
            <Col xs={12} md={6}>
              <FieldGroup
                type="text"
                id="middle_name"
                label="Segundo nombre"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.middle_name || ""}
                placeholder="Segundo nombre"
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="select"
                disabled={!edit_mode}
                selectOptions={genders}
                selectMap={gender => (
                  <option key={gender.value.toString()} value={gender.value}>
                    {" "}
                    {gender.text}{" "}
                  </option>
                )}
                id="gender"
                label="Genero"
                value={formState.gender}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
              <FieldGroup
                type="text"
                id="insurance_id"
                label="Número de aseguranza"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.insurance_id || ""}
                placeholder="Numero de aseguranza"
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="emails"
                label="Correos Electrónicos"
                placeholder="juan@email.com, jose@email.com"
                value={
                  formState.emails.length ? formState.emails.toString() : ""
                }
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={!edit_mode}
              />
              <FieldGroup
                type="text"
                id="allergies"
                label="Alergias"
                placeholder="Polen, ácaros"
                value={
                  formState.allergies.length
                    ? formState.allergies.toString()
                    : ""
                }
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={!edit_mode}
              />
            </Col>
          </Row>
        </BodyWidget>
        <div className={styles.butArea}>
          <Button onClick={onUpdatePatient} disabled={!edit_mode}>
            Actualizar
          </Button>
        </div>
      </div>
      {/* Ends content */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Asignaciones del paciente</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} className={styles.toggleb}>
              <Toggle
                checked={edit_mode}
                icons={false}
                onChange={() => setEditMode()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="select"
                disabled={!edit_mode}
                selectOptions={doctorsList ? doctorsList : []}
                selectFirstOption={<option value={""}>Seleccione uno</option>}
                selectMap={doctor => (
                  <option key={doctor.value.toString()} value={doctor.value}>
                    {" "}
                    {doctor.text}{" "}
                  </option>
                )}
                id="doctor_id"
                label="Doctor"
                value={formState.doctor_id ? formState.doctor_id : ""}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={10}
              />
            </Col>
            <Col xs={12} md={6}>
              <FieldGroup
                type="select"
                disabled={!edit_mode}
                selectOptions={techniciansList ? techniciansList : []}
                selectFirstOption={<option value={""}>Seleccione uno</option>}
                selectMap={technician => (
                  <option
                    key={technician.value.toString()}
                    value={technician.value}
                  >
                    {" "}
                    {technician.text}{" "}
                  </option>
                )}
                id="technician_id"
                label="Licenciado o Técnico"
                value={formState.technician_id ? formState.technician_id : ""}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={11}
              />
            </Col>
          </Row>
        </BodyWidget>
        <div className={styles.butArea}>
          <Button onClick={onUpdateAssignments} disabled={!edit_mode}>
            Actualizar
          </Button>
        </div>
      </div>
      {/* Ends content */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Eliminar paciente</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} md={12}>
              <div className={styles.butArea}>
                <Button onClick={onDeletePatient}>Eliminar</Button>
              </div>
              <p>
                Esta acción no se puede revertir y toda la información del
                paciente será eliminada de la plataforma.
              </p>
            </Col>
          </Row>
        </BodyWidget>
      </div>
      {/* Ends content */}
    </div>
  );
};

export default PatientPersonalInfo;
