import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import styles from "./Agenda.module.css";
import moment from "moment";
import _ from "lodash";
import AppointmentPreview from "./AppointmentPreview";
import AddAppointment from "./AddAppointment";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAppointmentModalOnCalendar,
  togglePreviewAppointmentModalOnAgenda
} from "../../../../../actions/clinic/clinic";
import Inter from "../../../../../common/constants/internationalization";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { isMobile } from "react-device-detect";

const Calendar = ({
  appointments,
  weekState,
  settings,
  doctorsList,
  techniciansList,
  clinic_id,
  errors,
  clinicServices,
  clinicAppointments
}) => {
  const dispatch = useDispatch();

  const [ApptDetails, setToggleApptDetails] = useState({});

  const [NewApptDate, setNewApptDate] = useState(null);

  const { toggle_modal, toggle_preview_modal, user_country } = useSelector(
    store => ({
      toggle_modal: store.clinic.clinic.toggleModalOnCalendar,
      toggle_preview_modal: store.clinic.clinic.togglePreviewModalOnAgenda,
      user_country: store.auth.user.country
    })
  );

  const toggleApptDetailsModal = e => {
    if (e && e.event && e.event.id) {
      let appt = _.find(clinicAppointments, [
        "appointment_id",
        parseInt(e.event.id)
      ]);
      setToggleApptDetails(appt);
    }
    dispatch(togglePreviewAppointmentModalOnAgenda);
  };

  const toggleAddApptModal = e => {
    if (e && e.start) {
      let date = new Date(e.start);
      setNewApptDate(date);
    }
    dispatch(toggleAppointmentModalOnCalendar);
  };

  return (
    <div className={styles.calendarWrapper}>
      <Modal show={toggle_preview_modal} onHide={toggleApptDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la cita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AppointmentPreview
            details={ApptDetails}
            settings={settings}
            doctorsList={doctorsList}
            techniciansList={techniciansList}
            clinic_id={clinic_id}
            errors={errors}
            weekState={weekState}
          />
        </Modal.Body>
      </Modal>
      <Modal show={toggle_modal} onHide={toggleAddApptModal}>
        <Modal.Header closeButton>
          <Modal.Title>Crear cita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAppointment
            date={NewApptDate}
            settings={settings}
            doctorsList={doctorsList}
            techniciansList={techniciansList}
            clinic_id={clinic_id}
            clinicServices={clinicServices}
            view="calendar"
          />
        </Modal.Body>
      </Modal>
      <Row className={styles.calendar}>
        <Col md={12}>
          <FullCalendar
            plugins={[dayGridPlugin]}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={
              isMobile
                ? { left: "prev,next", right: "title" }
                : {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                  }
            }
            initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
            events={appointments}
            locale={"es"}
            editable={true}
            selectable={true}
            select={toggleAddApptModal}
            eventClick={toggleApptDetailsModal}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Calendar;
