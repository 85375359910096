import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import styles from "../index.module.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAppointmentExamsModal,
  addAppointmentExams,
  deleteAppointmentExam
} from "../../../../actions/clinic/appointment";
import _ from "lodash";
import blue_spinner from "../../../../static/img/gif/blue_spinner.gif";
import ReactTable from "react-table";
import Inter from "../../../../common/constants/internationalization";
import deleteImg from "../../../../static/img/icons/delete.svg";

const AddExamsToAppointment = ({
  appointment_id,
  labExams,
  appointmentExams
}) => {
  const dispatch = useDispatch();
  const add_appt_exams = addAppointmentExams({
    appointment_id: appointment_id
  });

  /* const { loading, user_country } = useSelector(store => ({
    loading: store.clinic.clinic.loading,
    user_country: store.auth.user.country
  })); */

  const [examsState, setExamsState] = useState({ class_id: 0, exams: [] });

  const [formExamState, setFormExamState] = useState({
    class_id: 0,
    exam_id: 0
  });

  const [appointmentExamsState, setAppointmentExamsState] = useState([]);

  const [duplicateExamState, setDuplicateExamState] = useState(false);

  const onChangeExam = ({ target: { value, id } }) => {
    setFormExamState(oldState => ({
      ...oldState,
      class_id: parseInt(examsState.class_id),
      exam_id: parseInt(value)
    }));
  };

  const onChangeClass = ({ target: { value, id } }) => {
    let [exams] = labExams.filter(exam => exam.class_id === parseInt(value));
    setExamsState({ class_id: parseInt(value), exams: exams.exams });
  };

  const addExam = () => {
    if (
      _.findIndex(appointmentExamsState, {
        exam_id: formExamState.exam_id
      }) > -1 ||
      _.findIndex(appointmentExams, {
        exam_id: appointmentExams.exam_id
      }) > -1
    ) {
      setDuplicateExamState(true);
    } else {
      setDuplicateExamState(false);
      setAppointmentExamsState(oldState => [...oldState, formExamState]);
    }
  };

  const addServices = () => {
    dispatch(
      add_appt_exams(
        appointmentExamsState,
        {
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Exámenes de la cita agregados",
            message: "Se han agregado exámenes a la cita."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló actualizando los exámenes",
            message: "No se ha podido agregar exámenes, intente nuevamente."
          }
        },
        { onFinishedAction: toggleAppointmentExamsModal }
      )
    );
  };

  const removeService = (id, exam_id) => {
    if (id) {
      dispatch(
        deleteAppointmentExam({ id })({
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Exámen de la cita ha sido eliminado",
            message: "Exámen de la cita eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló removiendo el exámen de la cita",
            message:
              "No se pudo remover el exámen de la cita, contacte a soporte técnico."
          }
        })
      );
    } else {
      setAppointmentExamsState(oldstate => [
        ...oldstate.filter(e => e.exam_id !== exam_id)
      ]);
    }
  };

  const examsByAppointment = [
    {
      accessor: "exam_id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false
    },
    {
      Header: "Nombre",
      accessor: "exam_id",
      headerClassName: styles.tableSolsticeHeader,
      filterable: false,
      Cell: props => (
        <div>
          {props.original.exam
            ? props.original.exam
            : _.find(
                _.find(labExams, ["class_id", props.original.class_id]).exams,
                ["id", props.original.exam_id]
              ).name}
        </div>
      )
    },
    {
      Header: "Remover",
      accessor: "exam_id",
      minWidth: 150,
      maxWidth: 150,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <div>
          {props.original.exam_id && (
            <Button
              bsSize="small"
              onClick={() =>
                removeService(props.original.id, props.original.exam_id)
              }
              className={styles.deleteButton}
            >
              <Image
                width="12px"
                src={deleteImg}
                className={styles.deleteImg}
              />
            </Button>
          )}
        </div>
      )
    }
  ];

  if (1 == 2) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col sm={8}>
                <FieldGroup
                  type="select"
                  selectOptions={labExams ? labExams : []}
                  selectFirstOption={<option value={0}>Seleccionar</option>}
                  selectMap={exam => (
                    <option
                      key={exam.class_id.toString()}
                      value={exam.class_id}
                    >
                      {" "}
                      {exam.class_name}{" "}
                    </option>
                  )}
                  id="class_id"
                  label="Tipo de exámen"
                  value={examsState.class_id || 0}
                  onChange={onChangeClass}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <FieldGroup
                  type="select"
                  selectOptions={examsState ? examsState.exams : []}
                  selectFirstOption={<option value={0}>Seleccionar</option>}
                  selectMap={exam => (
                    <option key={exam.id.toString()} value={exam.id}>
                      {" "}
                      {exam.name}{" "}
                    </option>
                  )}
                  id="exam_id"
                  label="Exámen"
                  value={formExamState.exam_id || 0}
                  onChange={onChangeExam}
                />
              </Col>
              <Col xs={4}>
                <div className={styles.addServiceWrapper}>
                  <Button
                    className={"push-button -white"}
                    bsSize="small"
                    onClick={addExam}
                  >
                    Agregar
                  </Button>
                </div>
              </Col>
            </Row>
            {duplicateExamState && (
              <p className={"requiredField"}>Error: exámen duplicado.</p>
            )}
            <Row>
              <Col xs={12}>
                <ReactTable
                  data={[...appointmentExamsState, ...appointmentExams]}
                  columns={examsByAppointment}
                  className={[styles.tableSolstice, "body -highlight"].join(
                    " "
                  )}
                  defaultPageSize={5}
                  showPagination={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.submitGenerateButtonWrapper}>
              <Button
                className={styles.submitButton}
                bsSize="small"
                onClick={addServices}
                tabIndex={8}
              >
                {"Agregar exámenes"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default AddExamsToAppointment;
