import React from "react";
import styles from "./ProfileBox.module.css";
import { Row, Col } from "react-bootstrap";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import Inter from "../../../../common/constants/internationalization";

const ProfileBox = ({ formState, setFormState, errors, user_country }) => {
  return (
    <div className={styles.profileSection}>
      <Row>
        <Col xs={12} md={5} className={styles.title}>
          Información del perfil
        </Col>
        <Col xs={12} md={7}>
          <Row>
            <Col xs={12}>
              <FieldGroup
                type="text"
                label="Correo electrónico"
                id="email"
                value={formState.email}
                placeholder="john.doe@email.com"
                onChange={setFormState}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
            </Col>
            <Col xs={12}>
              <FieldGroup
                type="masked"
                mask={Inter[user_country].phone_format}
                id="phone_number"
                label="Teléfono"
                value={formState.phone_number}
                form={formState}
                onChange={setFormState}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FieldGroup
                type="text"
                id="address_1"
                label="Descripción"
                value={formState.address_1}
                placeholder="10 Ware St"
                onChange={setFormState}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
            </Col>
            <Col xs={6}>
              <FieldGroup
                type="text"
                label="Ciudad"
                id="city"
                value={formState.city}
                placeholder="Cambridge"
                onChange={setFormState}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
            </Col>
            <Col xs={7}>
              {/*  <FieldGroup
                type='select'
                selectOptions={Object.keys(states).map(state => ({
                  code: state,
                  name: states[state].name
                }))}
                placeholder='Select'
                selectMap={state => (
                  <option key={state.code} value={state.code}>
                    {' '}
                    {state.code} - {state.name}{' '}
                  </option>
                )}
                id='state'
                label={I18NText({ componentKey: 'profile', translationKey: 'label_state' })}
                labelClassName={styles.formLabel}
                onChange={setFormState}
                helpClassName={styles.helpMessage}
                value={formState.state}
                errors={errors}
                helpClassName={'requiredField'}
                isRequired
              /> */}
            </Col>
            <Col xs={5}>
              {/*  <FieldGroup
                type='text'
                value={formState.zip_code}
                maxLength={5}
                label='Codigo de area'
                id='zip_code'
                placeholder='02138'
                onChange={setFormState}
                errors={errors}
                helpClassName={'requiredField'}
                isRequired
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileBox;
