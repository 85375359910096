import React from "react";
import { Col, Alert } from "react-bootstrap";
import styles from "./NotifBox.module.css";

export default ({ title, message, type }) => {
  const bgColor = {
    success: "#d9ecdb",
    error: "#f3d8da",
    warning: "#fdf3d1"
  };

  const textColor = {
    success: "#2a5529",
    error: "#8e1e24",
    warning: "#806520"
  };

  return (
    <Col xs={12} className={styles.messageContainer}>
      <Alert
        style={{
          backgroundColor: bgColor[type],
          color: textColor[type],
          border: 0
        }}
      >
        {title ? <span className={styles.messageTitle}> {title} </span> : null}
        <span className={styles.messageBody}>{message} </span>
      </Alert>
    </Col>
  );
};
