import { requested, buildAsyncGet } from "../actionsFactory";

/* export const RESET_PASSWORD_TEAM_MEMBER = 'reset-password-team-member';
export const resetPasswordTeamMember = () =>
  buildAsyncPost(RESET_PASSWORD_TEAM_MEMBER, `/auth/request-password-reset`); */

export const EDIT_MODE = "edit-mode";
export const editMode = { type: requested(EDIT_MODE) };

export const TOGGLE_MODAL = "toggle-modal";
export const toggleModal = { type: requested(TOGGLE_MODAL) };

export const FETCH_ROLES = "fetch-roles";
export const requestRoles = () => buildAsyncGet(FETCH_ROLES, `clinic/v1/roles`);
