import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
  Glyphicon,
  ControlLabel
} from "react-bootstrap";
import { Link } from "react-router-dom";
import useFormInput from "../../../../common/uses/useFormInput";
import styles from "./LoginBox.module.css";
import {
  requestLogin,
  getRecaptchaSiteKey
} from "../../../../actions/common/auth";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import RecaptchaComponent from "../../../../components/Recaptcha/RecaptchaComponent";

export default ({ queryMessage = "" }) => {
  const dispatch = useDispatch();
  const showPassword = useFormInput(false);

  const { user, errors } = useSelector(store => store.auth);

  const { isRecaptcha, recaptchaSiteKey } = useSelector(state => ({
    isRecaptcha: state.auth.isRecaptchaToken,
    recaptchaSiteKey: state.auth.recaptchaSiteKey
  }));

  const [form, setForm] = useState({
    username: "",
    password: ""
  });

  useEffect(() => {
    if (!recaptchaSiteKey) {
      dispatch(getRecaptchaSiteKey());
    }
  }, [recaptchaSiteKey, dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      requestLogin({
        username: form.username,
        password: form.password,
        user_agent: navigator.userAgent
      })
    );
  };

  const onChange = e => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    });
  };

  return (
    <Row>
      {/* Login Box */}
      <div className={styles.globalContainer}>
        <Row>
          <Col className={styles.logoContainer}>
            <div className={styles.logintext}> Inicio de sesión </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs={12} className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <FieldGroup
                  type="text"
                  id="username"
                  value={form.username}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={styles.requiredField}
                  className={styles.formControl}
                  label="Correo electrónico"
                  labelClassName={styles.labelStyle}
                />
                <FormControl.Feedback />
              </FormGroup>
              <FormGroup className={styles.passwordFormGroup}>
                <ControlLabel className={styles.labelStyle}>
                  Contraseña
                </ControlLabel>
                <FormControl
                  id="password"
                  type={showPassword.value ? "text" : "password"}
                  value={form.password}
                  onChange={onChange}
                  className={styles.formControl}
                />
                <Glyphicon
                  className={styles.showPasswordButton}
                  onClick={() =>
                    showPassword.onChange({
                      target: { value: !showPassword.value }
                    })
                  }
                  glyph={showPassword.value ? "eye-close" : "eye-open"}
                />
                <FormControl.Feedback />
              </FormGroup>

              {errors && errors.length
                ? errors.map((error, index) =>
                    error.name !== "TokenAuth" ? (
                      <p
                        className={[styles.lead, styles.successMessage].join(
                          " "
                        )}
                        key={`err-${index}`}
                      >
                        {error.message}
                      </p>
                    ) : (
                      ""
                    )
                  )
                : ""}

              {queryMessage === "passwordResetSuccess" && (
                <p className={[styles.lead, styles.successMessage].join(" ")}>
                  Your password has been updated. Please login using your new
                  password.
                </p>
              )}
              {queryMessage === "UserRegisterSuccess" && (
                <p className={[styles.lead, styles.successMessage].join(" ")}>
                  Your new account has been created. Please verfiy your account
                  using the link sent to your email and then login.
                </p>
              )}
              {queryMessage === "resetPasswordRequested" && (
                <p className={[styles.lead, styles.successMessage].join(" ")}>
                  We have sent you an email to reset your password. Please
                  follow the instructions in the email.
                </p>
              )}
              {queryMessage === "UserNotFound" && (
                <p className={[styles.lead, styles.errorMessage].join(" ")}>
                  A user with that email was not found. Please check your
                  credentials and try again.
                </p>
              )}
              {queryMessage === "NotAuthorized" && (
                <p className={[styles.lead, styles.errorMessage].join(" ")}>
                  The password you entered was incorrect. Please try again or
                  request a password reset below.
                </p>
              )}
              <div className={styles.forgotPasswordLink}>
                {/* 
								<Link to='/request-reset-password'>
                                    ¿Olvidaste tu contraseña?
								</Link>{' '}
                 */}
              </div>
              <Button
                className={styles.LoginButton}
                type="submit"
                disable={`"${!isRecaptcha}"`}
              >
                Iniciar sesión
              </Button>
            </form>
          </Col>
        </Row>
      </div>

      {/* Footer */}
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Col xs={12}>Copyright ©2020 Monitoreo, Tech.</Col>
        </div>
      </div>
      {recaptchaSiteKey && (
        <RecaptchaComponent recaptchaSiteKey={recaptchaSiteKey} />
      )}
    </Row>
  );
};
