import React from "react";
import Patients from "./Patients";
import PatientDetail from "./PatientDetail";

export default ({
  match: {
    params: { id }
  }
}) => {
  if (id) {
    return <PatientDetail patient_id={id} />;
  }
  return <Patients />;
};
