import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import {
  FETCH_DOCTOR,
  UPDATE_DOCTOR,
  UPDATE_DOCTOR_ASSIGNMENT
} from "../../actions/clinic/clinic";

var defaultState = {
  doctor: pristine({})
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_DOCTOR)) {
    return reduce(state, "doctor", action, FETCH_DOCTOR);
  }

  if (action.type === succeeded(UPDATE_DOCTOR)) {
    return {
      ...state,
      doctor: {
        ...state.doctor,
        content: {
          ...action.payload
        }
      }
    };
  }

  if (action.type === failed(UPDATE_DOCTOR)) {
    return {
      ...state
    };
  }

  //TODO: PROBABLY NO NEEDED
  /* if (action.type === succeeded(UPDATE_DOCTOR_ASSIGNMENT)) {
        const patientsIds = state.doctor.patientsIds.concat(action.payload.patient_id)
		return {
			...state,
			doctor: {
				...state.all,
				content: [...state.doctor.content, patientsIds ],
			},
		}
	}

	if (action.type === failed(UPDATE_DOCTOR_ASSIGNMENT)) {
		return {
			...state,
		}
	} */

  return state;
}
