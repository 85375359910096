import {
  buildAsyncGet,
  buildAsyncPatch,
  buildAsyncPost
} from "../actionsFactory";

export const FETCH_MY_CLINICS = "fetch-my-clinics";
export const requestMyClinics = ({ user_id }) =>
  buildAsyncGet(
    FETCH_MY_CLINICS,
    `clinic/v1/clinic-assignment/${user_id}/clinics`
  );

export const CHANGE_ACTIVE_CLINIC = "change-active-clinic";
export const changeActiveClinic = clinicId => ({
  type: CHANGE_ACTIVE_CLINIC,
  payload: clinicId
});

export const UPDATE_USER_ACCOUNT = "update-user-account";
export const updateUserAccount = ({ id }) =>
  buildAsyncPatch(UPDATE_USER_ACCOUNT, `clinic/v1/user/${id}`);

export const UPDATE_PASSWORD = "update-password";
export const updatePassword = ({ id }) =>
  buildAsyncPost(UPDATE_PASSWORD, `clinic/v1/user/${id}/change-password`);
