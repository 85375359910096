import React from "react";
import queryString from "query-string";
import styles from "./Login.module.css";
import saludLogo from "../../static/img/logo_alt.png";
import aboutImg from "../../static/img/about.svg";
import pcIcon from "../../static/img/pcIcon.png";
import mobileIcon from "../../static/img/mobileIcon.png";
import videoIcon from "../../static/img/videoIcon.png";
import appImgClinica from "../../static/img/imgClinica.png";
import appImgPacientes from "../../static/img/imgPacientes.png";
import appImgCitas from "../../static/img/imgCitas.png";
import appImgMarketing from "../../static/img/imgMarketing.png";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import ReactPlayer from "react-player/youtube";
import { BsCheck } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { ImYoutube2 } from "react-icons/im";
import { isMobile } from "react-device-detect";

export default ({ location: { search } }) => {
  return (
    <Parallax ref={ref => (React.parallax = ref)} pages={6}>
      {/* Page 1, layer 0 */}
      <ParallaxLayer
        offset={0}
        speed={0}
        factor={2}
        style={{ backgroundColor: "#11cdef" }}
      />

      {/* Page 1, layer 1 */}
      <ParallaxLayer
        offset={0}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(1)}
        style={{
          display: "flex"
        }}
      >
        <div className={styles.topBar}>
          <Row>
            <Col xs={12} sm={6} align={"left"}>
              <Image width="180px" className={styles.logoAlt} src={saludLogo} />
            </Col>
            <Col xs={12} sm={6} align={"right"}>
              <div className={styles.loginBtn}>
                <Link
                  className={[
                    "push-button -white",
                    styles.webBtn,
                    styles.LoginButton
                  ].join(" ")}
                  to={`/login`}
                >
                  Iniciar sesión
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.mainContent}>
          <Row>
            <Col xs={12} sm={6}>
              <div className={styles.mainText}>
                <div className={styles.mainTextBig}>Tu clínica en la nube</div>
                <div className={styles.mainTextSmall}>
                  Una plataforma amigable y práctica para el manejo y
                  administración de clínicas medicas.
                </div>
                <div className={styles.mainButton}>
                  <Link
                    className={["push-button", styles.webBtn].join(" ")}
                    to={`/request-demo`}
                  >
                    Solicitar demo
                  </Link>
                </div>
              </div>
            </Col>
            {!isMobile && (
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={12} sm={6}>
                    <div className={[styles.lcard, styles.leftCard].join(" ")}>
                      <div
                        className={[styles.icoWrapper, styles.icoRed].join(" ")}
                      >
                        <Image src={pcIcon} className={styles.ico} />
                      </div>
                      <h3 className={styles.cardTitle}>App Web</h3>
                      <p className={styles.cardText}>
                        Moderna aplicación web para el manejo de clínicas
                        medicas.
                      </p>
                    </div>
                    <div className={[styles.lcard, styles.leftCard].join(" ")}>
                      <div
                        className={[styles.icoWrapper, styles.icoBlue].join(
                          " "
                        )}
                      >
                        <Image src={videoIcon} className={styles.ico} />
                      </div>
                      <h3 className={styles.cardTitle}>Video consultas</h3>
                      <p className={styles.cardText}>
                        Aprovecha la era de las video consultas y video
                        asistencia medica.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className={[styles.lcard, styles.rightCard].join(" ")}>
                      <div
                        className={[styles.icoWrapper, styles.icoGreen].join(
                          " "
                        )}
                      >
                        <Image src={mobileIcon} className={styles.icoAlt} />
                      </div>
                      <h3 className={styles.cardTitle}>App Móvil</h3>
                      <p className={styles.cardText}>
                        Primera app móvil para la interacción con pacientes.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </ParallaxLayer>

      {/* Page 2, layer 0 */}
      <ParallaxLayer
        offset={1}
        speed={1}
        style={{ backgroundColor: "#D5E6F0" }}
      />

      {/* Page 2, layer 1 */}
      <ParallaxLayer
        offset={1}
        speed={0.1}
        style={{
          top: 0
        }}
      >
        <div className={styles.textPage2}>
          <div className={styles.textPage2Big}>Una solución completa</div>
          <div className={styles.textPage2BigNextLine}>para tu clínica</div>
          <div className={styles.textPage2Small}>
            Monitoreo Salud es una aplicación web que facilita la administración
            de clínicas medicas ofreciendo servicios en la nube que permiten
            acceder a la información desde y cuando se desee, de forma rápida y
            segura, lo cual facilita el manejo de personal medico, expediente de
            pacientes y programación de citas.
          </div>
          <ul className={styles.listUnstyled}>
            <li className="py-2">
              <div className={styles.dFlex}>
                <div>
                  <span className={styles.badge}>
                    <BsCheck />
                  </span>
                </div>
                <div>
                  <div className={styles.listText}>
                    Seguridad de la data ingresada
                  </div>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className={styles.dFlex}>
                <div>
                  <span className={styles.badge}>
                    <BsCheck />
                  </span>
                </div>
                <div>
                  <div className={styles.listText}>
                    Respaldo a base de datos
                  </div>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className={styles.dFlex}>
                <div>
                  <span className={styles.badge}>
                    <BsCheck />
                  </span>
                </div>
                <div>
                  <div className={styles.listText}>
                    Exportación de información
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ParallaxLayer>

      {/* Page 2, layer 2 */}
      <ParallaxLayer
        offset={1}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(2)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      ></ParallaxLayer>

      {/* Page 2, layer 3 */}
      <ParallaxLayer
        offset={1.3}
        speed={-0.3}
        style={{ pointerEvents: "none" }}
      >
        <Image src={aboutImg} style={{ width: "15%", marginLeft: "70%" }} />
      </ParallaxLayer>

      {/* Page 3, layer 0 */}
      <ParallaxLayer
        offset={2}
        speed={0}
        style={{ backgroundColor: "#f8f9fe" }}
      />

      {/* Page 3, layer 1 */}
      <ParallaxLayer
        offset={2}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(3)}
      >
        <Row className={styles.contentRow}>
          <Col sx={12}>
            <Row>
              <Col sx={12} sm={6}>
                <div className={styles.textPageLeft}>
                  <div className={styles.textPageMedium}>
                    Personalización de tu clínica
                  </div>
                  <div className={styles.textPageLeftSmall}>
                    Nuestra plataforma te permite administrar el personal de tu
                    clínica, tus servicios y formularios medicos, al igual que
                    personalizar tus correo electrónico.
                  </div>
                </div>
              </Col>
              {!isMobile && (
                <Col sx={12} sm={6}>
                  <div className={styles.vectorWrapper}>
                    <Image src={appImgClinica} className={styles.vectors} />
                  </div>
                </Col>
              )}
            </Row>
            <Row className={styles.mt4}>
              <Col sx={12} sm={6}>
                <div className={styles.vectorWrapper}>
                  <Image src={appImgPacientes} className={styles.vectors} />
                </div>
              </Col>
              <Col sx={12} sm={6}>
                <div className={styles.textPageRight}>
                  <div className={styles.textPageMedium}>
                    Manejo de pacientes
                  </div>
                  <div className={styles.textPageRightSmall}>
                    Nuestra plataforma te permite administrar el personal de tu
                    clínica, tus servicios y formularios medicos, al igual que
                    personalizar tus correo electrónico.
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ParallaxLayer>

      {/* Page 4, layer 0 */}
      <ParallaxLayer
        offset={3}
        speed={0}
        style={{ backgroundColor: "#f8f9fe" }}
      />

      {/* Page 4, layer 1 */}
      <ParallaxLayer
        offset={3}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(4)}
      >
        <Row className={styles.contentRow}>
          <Col sx={12}>
            <Row>
              <Col sx={12} sm={6}>
                <div className={styles.textPageLeft}>
                  <div className={styles.textPageMedium}>Manejo de citas</div>
                  <div className={styles.textPageLeftSmall}>
                    Nuestra plataforma te permite administrar el personal de tu
                    clínica, tus servicios y formularios medicos, al igual que
                    personalizar tus correo electrónico.
                  </div>
                </div>
              </Col>
              <Col sx={12} sm={6}>
                <div className={styles.vectorWrapper}>
                  <Image src={appImgCitas} className={styles.vectors} />
                </div>
              </Col>
            </Row>
            <Row className={styles.mt4}>
              {!isMobile && (
                <Col sx={12} sm={6}>
                  <div className={styles.vectorWrapper}>
                    <Image src={appImgMarketing} className={styles.vectors} />
                  </div>
                </Col>
              )}
              <Col sx={12} sm={6}>
                <div className={styles.textPageRight}>
                  <div className={styles.textPageMedium}>Marketing</div>
                  <div className={styles.textPageRightSmall}>
                    Nuestra plataforma te permite administrar el personal de tu
                    clínica, tus servicios y formularios medicos, al igual que
                    personalizar tus correo electrónico.
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ParallaxLayer>

      {/* Page 5, layer 0 */}
      <ParallaxLayer
        offset={4}
        speed={0}
        style={{ backgroundColor: "#FFFFFF" }}
      />

      {/* Page 5, layer 1 */}
      <ParallaxLayer
        offset={4}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(5)}
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div className={styles.videoText}>
          <div className={styles.textPageMedium}>Sobre a Monitoreo Salud</div>
        </div>
        <div className={styles.mainContent}>
          <Row
            className={[
              !isMobile && styles.dFlex,
              styles.justifyContentBetween
            ].join(" ")}
          >
            <Col
              xs={12}
              sm={5}
              className={[styles.bgLightblue, styles.cardSpecial].join(" ")}
            >
              <p className={[styles.textBold, styles.fontEncabezado].join(" ")}>
                Misión
              </p>
              <p className={styles.textInfoClinica}>
                Brindar a nuestros clientes una alternativa que permita
                administrar toda la información relacionada a clínica medicas de
                manera segura y confiable, ofreciendo soluciones innovadoras que
                resuelvan las necesidades y las expectativas de la red de
                profesionales en el sector salud de El Salvador.
              </p>
            </Col>
            <Col
              xs={12}
              sm={5}
              className={[styles.bgLightblue, styles.cardSpecial].join(" ")}
            >
              <p className={[styles.textBold, styles.fontEncabezado].join(" ")}>
                Visión
              </p>
              <p className={styles.textInfoClinica}>
                Convertirnos en una empresa líder a nivel nacional y regional en
                servicios de gestión y administración de la información en la
                nube, que ayude en la generación de valor en la oferta médica,
                favoreciendo al crecimiento de nuestros clientes.
              </p>
            </Col>
          </Row>
          {!isMobile && (
            <Row className={styles.watchVideo}>
              <Col xs={12} sm={6}></Col>
              <Col xs={12} sm={6}>
                <a
                  href="https://www.youtube.com/watch?v=hGNqk0JHuzo"
                  target="_blank"
                >
                  Introducción a Monitoreo Salud
                  <ImYoutube2 className={styles.youtubeIcon} />
                </a>
              </Col>
            </Row>
          )}
        </div>
      </ParallaxLayer>

      {/* Page 6, layer 0 */}
      {/* <ParallaxLayer
        offset={5}
        speed={0}
        style={{ backgroundColor: "#f8f9fe" }}
      /> */}

      {/* Page 6, layer 1 */}
      <ParallaxLayer
        offset={5.2}
        speed={0.1}
        onClick={() => React.parallax.scrollTo(0)}
      >
        <div
          className={[styles.positionRelative, styles.w100, styles.h100].join(
            " "
          )}
        >
          <div className={styles.footerContent}>
            <div
              className={[styles.textPageMedium, styles.textGreen].join(" ")}
            >
              ¿Interesado en nuestra App Web?
            </div>
            {!isMobile && (
              <div
                className={[styles.textPageMedium, styles.textLightBlue].join(
                  " "
                )}
              >
                Solicita una demostración en vivo o en línea
              </div>
            )}
            <div className={styles.footerWrapper}>
              <div className={[styles.trialText].join(" ")}>
                Al solicitar un demo, nuestro equipo de atención al cliente se
                pondrá en contacto contigo para agendar una reunión presencial o
                virtual donde podrás conocer todas las características de la
                App.
              </div>
            </div>
            <div className={styles.mt2}>
              <Link
                className={["push-button", styles.trialButton].join(" ")}
                to={`/request-demo`}
              >
                Solicitar demo
              </Link>
            </div>
            <div
              className={[
                styles.dFlex,
                styles.justifyContentCenter,
                styles.mt2,
                styles.mb2
              ].join(" ")}
            >
              <span className={styles.mailIcon}>
                <AiOutlineMail />
              </span>
            </div>
            <div>
              <p>contacto@monitoreosalud.com</p>
            </div>
          </div>
          <div className={styles.footer}>
            <Row>
              <Col>
                <div className="copyright text-center text-xl-left black-text">
                  <span className={styles.blackText}>© 2021 </span>
                  <a className="font-weight-bold ml-1" href="">
                    {"  "}{" "}
                    <span>| Tecnologías de monitoreo de El Salvador</span>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </ParallaxLayer>
    </Parallax>
  );
};
