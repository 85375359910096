import React, { useEffect } from "react";
import { Row, Col, Button, Glyphicon, Modal } from "react-bootstrap";
import styles from "../index.module.css";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  requestTeam,
  requestClinicRoles,
  toggleClinicTeamMemberModal,
  requestDoctor,
  requestTechnician
} from "../../../../actions/clinic/clinic";
import AddClinicTeamMember from "./AddClinicTeamMember";
import ClinicTeamMember from "./ClinicTeamMember";
import { findIndex } from "lodash";
import history from "../../../../history";

const teamByClinic = [
  {
    accessor: "id",
    Header: "Id",
    minWidth: 80,
    maxWidth: 80,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <Link to={`/clinic/team/${props.original.id}`}>
        {" "}
        {props.original.id}{" "}
      </Link>
    ),
    filterable: false
  },
  {
    Header: "Nombre",
    accessor: "name",
    minWidth: 100,
    maxWidth: 200,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <Link to={`/clinic/team/${props.original.id}`}>
        {" "}
        {props.original.name}{" "}
      </Link>
    ),
    filterable: true
  },
  {
    Header: "Correo electrónico",
    accessor: "email",
    headerClassName: styles.tableSolsticeHeader,
    filterable: false
  },
  {
    Header: "Rol",
    accessor: "role",
    headerClassName: styles.tableSolsticeHeader,
    filterable: false
  }
];

const ClinicTeam = ({
  clinic_id,
  clinic_type,
  match: {
    params: { id }
  }
}) => {
  const dispatch = useDispatch();

  const { clinicTeam, clinicRoles, doctor, technician } = useSelector(
    state => ({
      clinicTeam: state.clinic.clinic.clinicTeam.content,
      clinicRoles: state.clinic.clinic.clinicRoles.content,
      doctor: state.clinic.doctor.doctor,
      technician: state.clinic.technician.technician
    })
  );

  useEffect(() => {
    if (clinic_id) {
      dispatch(requestTeam({ clinic_id }));
      dispatch(requestClinicRoles({ clinic_id }));
    }
  }, [clinic_id]);

  useEffect(() => {
    if (id) {
      dispatch(requestDoctor({ id }));
      dispatch(requestTechnician({ id }));
    }
  }, [id]);

  const toggleAddClinicTeamMemberModal = () =>
    dispatch(toggleClinicTeamMemberModal);

  const toggle_modal = useSelector(store => store.clinic.clinic.toggleModal);

  let team_members = [];
  if (clinicTeam.length > 0 && clinicRoles.length > 0) {
    team_members = clinicTeam.map(team_member => ({
      ...team_member,
      name: team_member.first_name + " " + team_member.last_name,
      role:
        clinicRoles[
          findIndex(clinicRoles, { id: parseInt(team_member.role) })
        ] &&
        clinicRoles[findIndex(clinicRoles, { id: parseInt(team_member.role) })]
          .name
    }));
  }

  if (!clinicTeam.length && !clinicRoles.length) {
    return <div>Loading...</div>;
  } else {
    if (id && !isNaN(id)) {
      const [team_member] = clinicTeam.filter(
        member => member.id === parseInt(id)
      );
      if (!team_member) {
        history.push(`/clinic/team`);
        return <div>Loading...</div>;
      } else {
        switch (team_member.role) {
          case 2001:
            return (
              <div>
                <ClinicTeamMember
                  team_member={team_member}
                  roles={clinicRoles}
                  additionalInformation={false}
                  clinic_id={clinic_id}
                />
              </div>
            );
          case 2002:
            if (!doctor.loaded) {
              return <div>Loading...</div>;
            } else {
              return (
                <div>
                  <ClinicTeamMember
                    team_member={team_member}
                    roles={clinicRoles}
                    doctor={doctor.content}
                    additionalInformation={true}
                    clinic_id={clinic_id}
                  />
                </div>
              );
            }
          case 2003:
            if (!technician.loaded) {
              return <div>Loading...</div>;
            } else {
              return (
                <div>
                  <ClinicTeamMember
                    team_member={team_member}
                    roles={clinicRoles}
                    technician={technician.content}
                    additionalInformation={true}
                    clinic_id={clinic_id}
                  />
                </div>
              );
            }
        }
      }
    } else {
      return (
        <div className="h2-section -no-hide">
          {/* Starts section for buttons */}
          <Row className={styles.buttonsSection}>
            <Col xs={12}>
              <div className={styles.actionBut}>
                <Button bsSize="small" onClick={toggleAddClinicTeamMemberModal}>
                  <Glyphicon glyph="plus" /> Agregar personal
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <Modal
                show={toggle_modal}
                onHide={toggleAddClinicTeamMemberModal}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Agregar personal a clinica</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddClinicTeamMember
                    clinic_id={clinic_id}
                    roles={clinicRoles}
                    clinic_type={clinic_type}
                  />
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
          {/* Ends section for buttons */}

          {/* Starts content */}
          <div className="h3-section">
            <h3>Personal</h3>
          </div>
          <div>
            <ReactTable
              data={team_members}
              columns={teamByClinic}
              className={[styles.tableSolstice, "body -highlight"].join(" ")}
              defaultPageSize={10}
            />
          </div>
          {/* Ends content */}
        </div>
      );
    }
  }
};

export default withRouter(ClinicTeam);
