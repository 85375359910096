import React from "react";
import styles from "./MenuHeader.module.css";
import { Link } from "react-router-dom";
import saludLogo from "../../../static/img/saludAppLogo.png";
import saludLogoAlt from "../../../static/img/saludAppLogo_alt.png";

export default ({ toggleMenu, opened }) => {
  return (
    <div id="topMenu">
      <nav>
        <div className={opened ? styles.container : styles.noExpandedContainer}>
          <Link to="/dashboard" className={styles.saludLogo}>
            {opened ? (
              <img src={saludLogo} width="80px" alt="logo" />
            ) : (
              <img src={saludLogoAlt} width="30px" alt="logo" />
            )}
          </Link>
        </div>
      </nav>
    </div>
  );
};
