import { pristine, reduce, isRelatedAction } from "../utils";
import { FETCH_LAB_EXAMS } from "../../actions/lab_exams/lab_exams";

var defaultState = {
  exams: pristine([])
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_LAB_EXAMS)) {
    return reduce(state, "exams", action, FETCH_LAB_EXAMS);
  }

  return state;
}
