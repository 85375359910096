import React from "react";
import styles from "./NavHeader.module.css";
import PropTypes from "prop-types";

const NavHeader = ({ text, expanded }) => {
  return <div className={styles.header}>{expanded ? text : ""}</div>;
};

NavHeader.propTypes = {
  text: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired
};

export default NavHeader;
