import React from "react";
import styles from "./Billing.module.css";
import blue_spinner from "../../../../static/img/gif/blue_spinner.gif";
import { Button } from "react-bootstrap";

export default ({ text, total, processed, status, onClickHandle }) => {
  const getStatus = () => {
    return status === "Processing"
      ? styles.submitProcessing
      : status === "Finished"
      ? styles.submitFinished
      : "";
  };

  return (
    <div className={styles.submitButtonWrapper}>
      {!status ? (
        <Button
          className={[styles.submitButton, getStatus()].join(" ")}
          bsSize="small"
          onClick={onClickHandle}
        >
          {text}
        </Button>
      ) : (
        <div className={getStatus()}>
          {status === "Processing" && (
            <img
              style={{ float: "left" }}
              src={blue_spinner}
              alt="spinner"
              width="35px"
            />
          )}
          <span>{`${status} ${processed}/${total}`}</span>
        </div>
      )}
    </div>
  );
};
