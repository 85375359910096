import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./AddClinicTeamMember.module.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import { addClinicMember } from "../../../../actions/clinic/clinic";
import {
  toggleClinicTeamMemberModal,
  setIsLoading
} from "../../../../actions/clinic/clinic";
import { generate } from "generate-password";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import blue_spinner from "../../../../static/img/gif/blue_spinner.gif";
import Inter from "../../../../common/constants/internationalization";

const loadingSpinner = <img src={blue_spinner} alt="spinner" width="30px" />;

const genders = [
  {
    value: "M",
    text: "Masculino"
  },
  {
    value: "F",
    text: "Femenino"
  }
];

const extraRoles = [
  {
    value: 0,
    text: "Ninguno"
  },
  {
    value: 1,
    text: "Doctor"
  },
  {
    value: 2,
    text: "Licenciado / Técnico"
  }
];

const AddClinicTeamMember = ({ clinic_id, clinic_type, roles }) => {
  const dispatch = useDispatch();
  const create_clinic_member = addClinicMember({ clinic_id });

  const { errors, loading, user_country } = useSelector(store => ({
    errors: store.clinic.clinic.clinicTeam.errors,
    loading: store.clinic.clinic.loading,
    user_country: store.auth.user.country
  }));

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    role: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    gender: "M",
    dob: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "SS",
    zip_code: "00000",
    country: "El Salvador",
    is_doctor: null,
    is_technician: null
  });

  const addClinicTeamMember = () => {
    if (!loading) {
      dispatch(setIsLoading);
      dispatch(
        create_clinic_member(
          {
            ...formState,
            username: formState.email,
            role: parseInt(formState.role),
            dob: new Date(formState.dob)
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "clinic",
              title: "Personal de la clínica agregado",
              message: "Personal de la clínica agregado exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "clinic",
              title: "Falló la creación de nuevo personal",
              message:
                "No se pudo crear nuevo personal, por favor intente nuevamente."
            }
          },
          { onFinishedAction: toggleClinicTeamMemberModal }
        )
      );
    }
  };

  const onChange = ({ target: { value, id } }) => {
    if (value === "None") {
      value = null;
    }
    setFormState({
      ...formState,
      [id]: value === "on" ? !formState[id] : value
    });
  };

  const onChangeExtraRole = ({ target: { value, id } }) => {
    switch (value) {
      case "0":
        setFormState({
          ...formState,
          is_doctor: null,
          is_technician: null
        });
        break;
      case "1":
        setFormState({
          ...formState,
          is_doctor: true,
          is_technician: null
        });
        break;
      case "2":
        setFormState({
          ...formState,
          is_technician: true,
          is_doctor: null
        });
        break;
    }
  };

  const genPass = () => {
    setFormState({
      ...formState,
      password: generate({
        length: 12,
        excludeSimilarCharacters: true,
        numbers: true
      })
    });
  };

  return (
    <div>
      <Row>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="clinic" view="clinic_customization_add_modal" />
          </Col>
        </Row>
        <Col xs={12} md={12}>
          <Accordion>
            <AccordionItem expanded={true}>
              <AccordionItemTitle>
                <div className={styles.numberDiv}>
                  {/* <Image src={numberOneImg} responsive /> */}
                </div>
                <div className={styles.title}>Información de usuario</div>
              </AccordionItemTitle>
              <AccordionItemBody>
                <Row>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="first_name"
                      label="Primer nombre"
                      value={formState.first_name}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      placeholder="Juan"
                      isRequired
                      tabIndex={1}
                    />
                    <FieldGroup
                      type="select"
                      selectOptions={genders}
                      selectMap={gender => (
                        <option
                          key={gender.value.toString()}
                          value={gender.value}
                        >
                          {" "}
                          {gender.text}{" "}
                        </option>
                      )}
                      id="gender"
                      label="Genero"
                      value={formState.gender}
                      onChange={onChange}
                      isRequired
                      errors={errors}
                      helpClassName={"requiredField"}
                      tabIndex={3}
                    />
                    <FieldGroup
                      type="text"
                      id="email"
                      label="Correo electrónico"
                      value={formState.email}
                      placeholder="juan@mail.com"
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={5}
                    />
                    <FieldGroup
                      type="select"
                      selectOptions={roles}
                      selectFirstOption={
                        <option key="null" value={""} id="">
                          Seleccione un rol
                        </option>
                      }
                      selectMap={role => (
                        <option key={role.id} value={role.id}>
                          {" "}
                          {role.name}{" "}
                        </option>
                      )}
                      id="role"
                      value={formState.role}
                      label="Rol"
                      onChange={onChange}
                      labelClassName={styles.formLabel}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={7}
                    />
                    {formState.role === "2001" && (
                      <FieldGroup
                        type="select"
                        selectOptions={extraRoles}
                        selectMap={extraRole => (
                          <option
                            key={extraRole.value.toString()}
                            value={extraRole.value}
                          >
                            {" "}
                            {extraRole.text}{" "}
                          </option>
                        )}
                        id="extra_role"
                        label="Rol adicional"
                        value={
                          formState.is_doctor
                            ? 1
                            : formState.is_technician
                            ? 2
                            : 0
                        }
                        onChange={onChangeExtraRole}
                        errors={errors}
                        helpClassName={"requiredField"}
                        tabIndex={3}
                      />
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="last_name"
                      label="Apellidos"
                      value={formState.last_name}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      placeholder="Perez"
                      isRequired
                      tabIndex={2}
                    />
                    <FieldGroup
                      type="date"
                      id="dob"
                      label="Fecha de naciemiento"
                      value={formState.dob}
                      onChange={onChange}
                      placeholder="Seleccione una fecha"
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tIndex={4}
                    />
                    <FieldGroup
                      type="text"
                      id="username"
                      label="Nombre de usuario"
                      value={formState.email}
                      placeholder="Same as email (disabled)"
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={6}
                    />
                    <FieldGroup
                      type="password"
                      id="password"
                      label="Contraseña"
                      value={formState.password}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={8}
                    />
                    <Button onClick={genPass}>Generar contraseña</Button>
                  </Col>
                </Row>
              </AccordionItemBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemTitle>
                <div className={styles.numberDiv}>
                  {/* <Image src={numberTwoImg} responsive /> */}
                </div>
                <div className={styles.tittle}>Información personal</div>
              </AccordionItemTitle>
              <AccordionItemBody>
                <Row>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="address_1"
                      label="Dirección 1"
                      value={formState.address_1}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={9}
                    />
                    <FieldGroup
                      type="text"
                      id="city"
                      label="Ciudad"
                      value={formState.city}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={11}
                    />
                    {/* <FieldGroup
											type='text'
											id='country'
											label='Country'
											value={formState.country}
											onChange={onChange}
											//errors={errors}
											helpClassName={'requiredField'}
											disabled={true}
										/> */}
                    {/* <FieldGroup
											type='text'
											id='zip_code'
											label='Zip Code'
											value={formState.zip_code}
											maxLength={5}
											onChange={onChange}
											//errors={errors}
											helpClassName={'requiredField'}
										/> */}
                  </Col>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="address_2"
                      label="Dirección 2"
                      value={formState.address_2}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      tabIndex={10}
                    />
                    {/* 										<FieldGroup
											type='select'
											selectOptions={Object.keys(states).map(
												(state) => ({
													code: state,
													name: states[state].name,
												})
											)}
											placeholder='Select'
											selectMap={(state) => (
												<option
													key={state.code}
													value={state.code}
												>
													{' '}
													{state.code} - {state.name}{' '}
												</option>
											)}
											id='state'
											label='State'
											onChange={onChange}
											labelClassName={styles.formLabel}
											value={formState.state}
											//errors={errors}
											helpClassName={'requiredField'}
										/> */}
                    <FieldGroup
                      type="masked"
                      mask={Inter[user_country].phone_format}
                      id="phone_number"
                      label="Número de teléfono"
                      placeholder="Phone Number"
                      value={formState.phone_number}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={12}
                    />
                  </Col>
                </Row>
              </AccordionItemBody>
            </AccordionItem>
          </Accordion>
          <Row>
            <Col xs={12}>
              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  bsSize="small"
                  onClick={addClinicTeamMember}
                >
                  Agregar personal {loading ? loadingSpinner : ""}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddClinicTeamMember;
