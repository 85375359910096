import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReCaptcha } from "react-recaptcha-google";
import { setRecaptchaToken } from "../../actions/common/auth";

const loadRecaptcha = callback => {
  const existingScript = document.getElementById("recaptcha");

  if (!existingScript) {
    var script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://www.google.com/recaptcha/api.js";
    script.id = "recaptcha";
    document.body.appendChild(script);

    script.onload = () => {
      window.grecaptcha.ready(() => {
        if (callback) callback();
      });
    };
  }

  if (existingScript && callback) callback();
};

const ReCaptchaComponent = ({ recaptchaSiteKey }) => {
  const [recaptchaTokenState, setRecaptchaTokenState] = useState(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    loadRecaptcha(() => {
      setRecaptchaLoaded(true);
    });
    if (recaptchaTokenState) {
      recaptchaTokenState.reset();
      recaptchaTokenState.execute();
    }
  }, [recaptchaTokenState]);

  const onLoadRecaptcha = () => {
    if (recaptchaTokenState) {
      recaptchaTokenState.reset();
      recaptchaTokenState.execute();
    }
  };

  const verifyCallback = recaptchaTokenState => {
    dispatch(
      setRecaptchaToken({
        sitekey: recaptchaTokenState
      })
    );
  };

  return !recaptchaLoaded ? (
    <></>
  ) : (
    <div>
      <ReCaptcha
        ref={el => setRecaptchaTokenState(el)}
        size="invisible"
        render="explicit"
        sitekey={recaptchaSiteKey}
        grecaptcha={window.grecaptcha}
        onloadCallback={onLoadRecaptcha}
        verifyCallback={verifyCallback}
      />
    </div>
  );
};

export default ReCaptchaComponent;
