import React from "react";
import Warn from "../../components/Layout/Toasts/Warn";
import Success from "../../components/Layout/Toasts/Success";
import { toast } from "react-toastify";

export const WarnToast = ({ title, message }) =>
  toast.info(<Warn message={message} title={title} />);

export const ErrorToast = ({ title, message }) =>
  toast.error(<Warn message={message} title={title} />);

export const SuccessToast = ({ title, message }) =>
  toast.success(<Success message={message} title={title} />);
