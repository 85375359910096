import React, { useEffect, Fragment } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeCardTokenizer from "./StripeCardTokenizer";
import { useDispatch, useSelector } from "react-redux";
import { requestStripe } from "../../actions/clinic/clinic";

const StripeCardWraper = ({
  account,
  onCancel,
  addPaymentMethod,
  ...props
}) => {
  const dispatch = useDispatch();

  const pk_stripe = useSelector(store => store.clinic.clinic.stripe);

  useEffect(() => {
    dispatch(requestStripe());
  }, [dispatch]);

  const display = pk_stripe.loaded ? (
    <StripeProvider apiKey={pk_stripe.content}>
      <div>
        <p>Información de tarjeta de credito</p>
        <Elements>
          <StripeCardTokenizer account={{ id: 1 }} onCancel={onCancel} />
        </Elements>
      </div>
    </StripeProvider>
  ) : (
    <div>Loading...</div>
  );

  return <Fragment>{display}</Fragment>;
};

export default StripeCardWraper;
