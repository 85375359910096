import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import styles from "./VideoAppointment.module.css";
import FieldGroup from "../../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  sendVirtualAppointmentEmail,
  requestVirtualAppointmentToken
} from "../../../../../actions/clinic/appointment";
import { VideoAppointmentOpener } from "./VideoAppointmentOpener";
import NotifBox from "../../../../../components/Layout/NotifBox/NotifBox";

const VideoAppointment = ({
  appointment,
  emailsList,
  sonResponse,
  toggleVideoAppt
}) => {
  const dispatch = useDispatch();
  const send_appointment_invitation = sendVirtualAppointmentEmail({
    id: appointment.appointment_id
  });

  const { appointmentToken } = useSelector(state => ({
    appointmentToken: state.clinic.appointment.appointmentToken
  }));

  useEffect(() => {
    if (appointment.appointment_id) {
      dispatch(requestVirtualAppointmentToken(appointment.appointment_id));
    } else {
    }
  }, [appointment.appointment_id]);

  const onChangeEmail = ({ target: { value, id } }) => {
    setPatientEmail(value);
  };

  const [token, setToken] = useState(null);

  const [patientEmail, setPatientEmail] = useState(
    emailsList.length > 0 ? emailsList[0].value : ""
  );

  const sendVirtualAppointmentInvitation = () => {
    dispatch(
      send_appointment_invitation(
        {
          email: patientEmail
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "video_appointment",
            title: "Correo enviado",
            message: "Se ha enviado la información por correo electrónico."
          },
          onFailedMessage: {
            type: "error",
            view: "video_appointment",
            title: "Falló el envio de correo",
            message: "No se pudo enviar la información por correo electrónico."
          }
        }
      )
    );
  };

  const setTokenAppt = () => {
    if (appointmentToken && appointmentToken.content) {
      setToken(appointmentToken.content);
    }
  };

  //TODO: Fix this
  if (!appointment.appointment_id) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="appointments" view="video_appointment" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.wrapper}>
              {!token && (
                <Row className={styles.sectionWrapper}>
                  <Col xs={12}>
                    <p className={styles.title}>Enviar mensaje al paciente</p>
                    <p>
                      ¿Tu paciente aun no tiene acceso? enviale un correo con la
                      información para conectarse a la video consulta.
                    </p>
                    <div className={styles.emailListWrapper}>
                      {emailsList.length > 0 && (
                        <Row>
                          <Col sm={12}>
                            <FieldGroup
                              type="select"
                              selectOptions={emailsList}
                              selectMap={email => (
                                <option
                                  key={email.value.toString()}
                                  value={email.value}
                                >
                                  {" "}
                                  {email.text}{" "}
                                </option>
                              )}
                              id="email"
                              label="Email"
                              value={0}
                              onChange={onChangeEmail}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                    <Button onClick={sendVirtualAppointmentInvitation}>
                      Enviar información de acceso
                    </Button>
                  </Col>
                </Row>
              )}
              <Row className={styles.sectionWrapper}>
                <Col xs={12}>
                  {appointmentToken.loaded && (
                    <div className={styles.generateWrapper}>
                      <p className={styles.title}>Inicia la video consulta</p>
                      <VideoAppointmentOpener
                        url={`/video-appointment?id=${appointment.appointment_id}&token=${appointmentToken.content}`}
                        bridge={sonResponse}
                        toggleVideoAppt={toggleVideoAppt}
                        handleSubmit={setTokenAppt}
                      >
                        Iniciar la video consulta
                      </VideoAppointmentOpener>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default VideoAppointment;
