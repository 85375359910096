import React from "react";
import { Row, Col } from "react-bootstrap";
import FieldGroup from "../../../../../components/Forms/FieldGroup/FieldGroup";

const FormA = ({ formState, onChange, edit_mode, errors }) => {
  if (!formState) {
    return <div>Loading...</div>;
  } else {
    return (
      <Row>
        <Col>
          <Row>
            <Col xs={6} sm={12}>
              <FieldGroup
                type="textarea"
                id="physical_examination.reason_for_appointment"
                label="Consulta por"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.reason_for_appointment}
                placeholder="Motivo o razón de la consulta..."
                onChange={onChange}
                helpClassName={"requiredField"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <FieldGroup
                type="textarea"
                id="physical_examination.disease"
                label="Enfermedad que presenta"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.disease}
                placeholder="Describir enfermedades que el paciente presenta..."
                onChange={onChange}
                helpClassName={"requiredField"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="physical_examination.blood_pressure"
                label="Presión Arterial"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.blood_pressure}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.temperature"
                label="Temperatura"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.temperature}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.nostrils"
                label="Fosas Nasales"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.nostrils}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.thorax"
                label="Torax"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.thorax}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.genitals"
                label="Genitales"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.genitals}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.weight"
                label="Peso"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.weight}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="physical_examination.heart_rate"
                label="Frecuencia Cardíaca"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.heart_rate}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.head"
                label="Cabeza"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.head}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.neck"
                label="Cuello"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.neck}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.abdomen"
                label="Abdomen"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.abdomen}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.extremities"
                label="Extremidades"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.extremities}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.height"
                label="Altura"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.height}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="physical_examination.respiratory"
                label="Respiratoria"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.respiratory}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.eyes"
                label="Ojos"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.eyes}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.mouth_and_tongue"
                label="Boca y Lengua"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.mouth_and_tongue}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.cardiovascular"
                label="Cardiovascular"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.cardiovascular}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.skin"
                label="Piel"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.skin}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
              <FieldGroup
                type="text"
                id="physical_examination.bmi"
                label="IMC"
                readOnly={!edit_mode}
                errors={errors}
                value={formState.physical_examination.bmi}
                placeholder=""
                onChange={onChange}
                helpClassName={"requiredField"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
};

export default FormA;
