export default {
  "El Salvador": {
    currency: "$",
    calling_code: "503",
    phone_format: "9999-9999",
    language: "en-SV",
    timeZone: "America/El_Salvador"
  },
  Mexico_MST: {
    currency: "MX$",
    calling_code: "52",
    phone_format: "99-9999-9999",
    language: "en-MX",
    timeZone: "America/Hermosillo"
  },
  Mexico_CDT: {
    currency: "MX$",
    calling_code: "52",
    phone_format: "99-9999-9999",
    language: "en-MX",
    timeZone: "America/Mexico_City"
  }
};
