import {
  buildAsyncGet,
  buildAsyncPatch,
  buildAsyncPost
} from "../actionsFactory";

export const FETCH_PATIENT_CUSTOM_FIELDS = "fetch-patient-custom-fields";
export const requestPatientCustomFields = ({ patient_id }) =>
  buildAsyncGet(
    FETCH_PATIENT_CUSTOM_FIELDS,
    `clinic/v1/patient-custom-fields/${patient_id}/`
  );

export const ADD_PATIENT_CUSTOM_FIELDS = "add-patient-custom-fields";
export const addPatientCustomFields = () =>
  buildAsyncPost(ADD_PATIENT_CUSTOM_FIELDS, `clinic/v1/patient-custom-fields`);

export const UPDATE_PATIENT_CUSTOM_FIELDS = "update-patient-custom-fields";
export const updatePatientCustomFields = ({ id }) =>
  buildAsyncPatch(
    UPDATE_PATIENT_CUSTOM_FIELDS,
    `clinic/v1/patient-custom-fields/${id}`
  );
