import { pristine, reduce, isRelatedAction } from "../utils";
import { FETCH_ROLES } from "../../actions/clinic/common";

var defaultState = {
  roles: pristine([])
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_ROLES)) {
    return reduce(state, "roles", action, FETCH_ROLES);
  }

  return state;
}
