import React from "react";
import CardDetail from "../../../../../components/PaymentMethod/CardDetail";

export default ({ selectedPaymentMethodId, paymentMethods }) => {
  const [selectedPayment] = paymentMethods.filter(
    pm => pm.id === parseInt(selectedPaymentMethodId)
  );
  if (selectedPaymentMethodId && selectedPayment) {
    return (
      selectedPayment.payment_type === "card" && (
        <CardDetail
          setPrimaryPM={() => true}
          methodId={selectedPayment.id}
          onUpdateAccount={() => true}
          last_four={selectedPayment.last_four}
          first_name={selectedPayment.first_name}
          last_name={selectedPayment.last_name}
          exp_month={selectedPayment.exp_month}
          exp_year={selectedPayment.exp_year}
          card_network={selectedPayment.card_network}
        />
      )
    );
  }
  return <>Seleccione un metodo de pago de su lista.</>;
};
