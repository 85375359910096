import React, { useState } from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import styles from "./ChangePasswordBox.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  min,
  required,
  alphanum,
  shouldEqual
} from "../../../../common/functions/validations";
import { updatePassword } from "../../../../actions/clinic/account";

const ChangePasswordBox = ({ user_id, ...props }) => {
  const dispatch = useDispatch();
  const update_password = updatePassword({ id: user_id });

  const errors = useSelector(state => state.auth.passwordStatus.errors);

  const isFormValid = (formState, formValidation) => {
    let errors = [];
    Object.keys(formState).forEach(field => {
      if (Array.isArray(formValidation[field])) {
        formValidation[field].forEach(validation => {
          const result = validation(formState[field], formState);
          if (result) errors.push({ key: field, message: result });
        });
      }
    });
    return errors.length ? false : true;
  };

  /* const current_password = useFormInput('')
	const new_password = useFormInput('')
	const new_password_confirm = useFormInput('')
	const [opened, setOpened] = useState(false) */
  const [opened, setOpened] = useState(false);

  /* const formState = {
		current_password: current_password.value,
		new_password: new_password.value,
		new_password_confirm: new_password_confirm.value,
    } */

  const [formState, setFormState] = useState({
    current_password: "",
    new_password: "",
    new_password_confirm: ""
  });

  const formValidation = {
    new_password: [required(), min(8), alphanum()],
    new_password_confirm: [
      required(),
      min(8),
      alphanum(),
      shouldEqual("new_password", "Passwords must match.")
    ]
  };

  const resetFormItem = formItem =>
    formItem.onChange({ target: { value: "" } });

  const changePasswordSubmit = e => {
    e.preventDefault();
    if (isFormValid(formState, formValidation)) {
      dispatch(
        update_password(
          {
            current_password: formState.current_password,
            new_password: formState.new_password,
            new_password_confirm: formState.new_password_confirm
          },
          {
            onStartedMessage: {
              message: "Iniciando la actualización de contraseña.",
              title: "Inicio la actualización de contraseña",
              view: "profile",
              type: "info"
            },
            onSuccessMessage: {
              message:
                "Su contraseña ha sido actualizada exitosamente, puede ser utilizada inmediatamente.",
              title: "Contraseña actualizada",
              view: "profile",
              type: "success"
            },
            onFailedMessage: {
              message:
                "The password you supplied was incorrect or your new password is not secure. Please try again.",
              title: "Falló la actualización de contraseña",
              view: "profile",
              type: "error"
            }
          }
        )
      );
    } else {
      console.log("Error");
    }
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState({
      ...formState,
      [id]: value
    });
  };

  return (
    <div className={styles.profileSection}>
      <Row>
        <Col xs={12} md={5} className={styles.title}>
          Contraseña
        </Col>

        <Col xs={12} md={7}>
          <div className={styles.description}>
            <p>Cambiar contraseña</p>
          </div>
          <Button
            onClick={() => setOpened(!opened)}
            aria-controls="example-collapse-text"
            aria-expanded={opened}
            className={"push-button -light"}
          >
            Cambiar
          </Button>
          {/* errors || */}
          <Collapse in={opened} className={styles.body}>
            <div id="example-collapse-text">
              <p>Ingrese su contraseña actual y su nueva contraseña.</p>
              <FieldGroup
                id="current_password"
                type="password"
                label="Contraseña actual"
                errors={errors}
                onChange={onChange}
                value={formState.current_password}
                helpClassName={"requiredField"}
                isRequired
                //{...current_password}
              />
              <FieldGroup
                type="new-password"
                id="new_password"
                label="Nueva contraseña"
                className={styles.passwordEsp}
                errors={errors}
                onChange={onChange}
                value={formState.new_password}
                helpClassName={"requiredField"}
                isRequired
                //{...new_password}
              />
              <FieldGroup
                type="password"
                id="new_password_confirm"
                label="Confirmar nueva contraseña"
                className={styles.passwordEsp}
                //form={formState}
                onChange={onChange}
                value={formState.new_password_confirm}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
                //{...new_password_confirm}
              />
              {/* {errors && errors.error && errors.error.name === 'NewPasswordNotSecure' ? (
                <p className={[styles.lead, styles.errorMessage].join(' ')}>
                  This new password was exposed in previous data breaches, which makes it unsuitable for
                  ongoing use. We strongly recommend you select a different, stronger one.
                </p>
              ) : (
                ''
              )} */}
              <div align="right">
                <Button
                  onClick={() => setOpened(!opened)}
                  className={styles.cancelButton}
                >
                  Cancelar
                </Button>{" "}
                <Button onClick={changePasswordSubmit}>Actualizar</Button>
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePasswordBox;
