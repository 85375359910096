import React from "react";
import { Link } from "react-router-dom";

const NavLinkCleaner = ({
  activeKey,
  activeHref,
  to,
  className,
  children,
  ...otherProps
}) => {
  return (
    <Link to={to} className={className} {...otherProps}>
      {children}
    </Link>
  );
};
export default NavLinkCleaner;
