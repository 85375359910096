import styles from "./NavItem.module.css";

export default {
  doctor: {
    default: "my-salud",
    items: [
      {
        type: "NavItem",
        iconClass: [styles.icoHome, styles.icoDefault].join(" "),
        text: "Inicio",
        location: "/dashboard",
        eventKey: "my-salud"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoPatients, styles.icoDefault].join(" "),
        text: "Pacientes",
        location: "/patients",
        eventKey: "patients"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoAgenda, styles.icoDefault].join(" "),
        text: "Agenda",
        location: "/agenda",
        eventKey: "agenda"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoProfile, styles.icoDefault].join(" "),
        text: "Perfil",
        location: "/profile",
        eventKey: "profile"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoLogout, styles.icoDefault].join(" "),
        text: "Cerrar sesión",
        location: "/logout",
        eventKey: "logout"
      }
    ]
  },
  technician: {
    default: "my-salud",
    items: [
      {
        type: "NavItem",
        iconClass: [styles.icoHome, styles.icoDefault].join(" "),
        text: "Inicio",
        location: "/dashboard",
        eventKey: "my-salud"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoPatients, styles.icoDefault].join(" "),
        text: "Pacientes",
        location: "/patients",
        eventKey: "patients"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoAgenda, styles.icoDefault].join(" "),
        text: "Agenda",
        location: "/agenda",
        eventKey: "agenda"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoProfile, styles.icoDefault].join(" "),
        text: "Perfil",
        location: "/profile",
        eventKey: "profile"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoLogout, styles.icoDefault].join(" "),
        text: "Cerrar sesión",
        location: "/logout",
        eventKey: "logout"
      }
    ]
  },
  assistant: {
    default: "my-salud",
    items: [
      {
        type: "NavItem",
        iconClass: [styles.icoHome, styles.icoDefault].join(" "),
        text: "Inicio",
        location: "/dashboard",
        eventKey: "my-salud"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoPatients, styles.icoDefault].join(" "),
        text: "Pacientes",
        location: "/patients",
        eventKey: "patients"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoAgenda, styles.icoDefault].join(" "),
        text: "Agenda",
        location: "/agenda",
        eventKey: "agenda"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoProfile, styles.icoDefault].join(" "),
        text: "Perfil",
        location: "/profile",
        eventKey: "profile"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoLogout, styles.icoDefault].join(" "),
        text: "Cerrar sesión",
        location: "/logout",
        eventKey: "logout"
      }
    ]
  },
  admin: {
    default: "my-salud",
    items: [
      {
        type: "NavItem",
        iconClass: [styles.icoHome, styles.icoDefault].join(" "),
        text: "Inicio",
        location: "/dashboard",
        eventKey: "my-salud"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoClinic, styles.icoDefault].join(" "),
        text: "Clínica",
        location: "/clinic",
        eventKey: "clinica"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoPatients, styles.icoDefault].join(" "),
        text: "Pacientes",
        location: "/patients",
        eventKey: "patients"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoAgenda, styles.icoDefault].join(" "),
        text: "Agenda",
        location: "/agenda",
        eventKey: "agenda"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoFinance, styles.icoDefault].join(" "),
        text: "Finanzas",
        location: "/finance",
        eventKey: "team-management"
      },
      /* 			{
				type: 'NavItem',
				iconClass: [styles.icoReports, styles.icoDefault].join(' '),
				text: 'Reportes',
				location: '/reports',
				eventKey: 'solar-gardens',
			}, */
      {
        type: "NavItem",
        iconClass: [styles.icoProfile, styles.icoDefault].join(" "),
        text: "Perfil",
        location: "/profile",
        eventKey: "profile"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoLogout, styles.icoDefault].join(" "),
        text: "Cerrar sesión",
        location: "/logout",
        eventKey: "logout"
      }
    ]
  },
  superAdmin: {
    default: "my-salud",
    items: [
      {
        type: "NavItem",
        iconClass: [styles.icoHome, styles.icoDefault].join(" "),
        text: "Inicio",
        location: "/dashboard",
        eventKey: "my-salud"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoFinance, styles.icoDefault].join(" "),
        text: "Finanzas",
        location: "/finance",
        eventKey: "finance"
      },
      {
        type: "NavItem",
        iconClass: [styles.icoLogout, styles.icoDefault].join(" "),
        text: "Cerrar sesión",
        location: "/logout",
        eventKey: "logout"
      }
    ]
  }
};
