import { buildAsyncPost, buildAsyncGet } from "../../actionsFactory";

export const LOGIN = "log-in";
export const requestLogin = buildAsyncPost(LOGIN, "auth/login");

export const RESTORE_LOGIN = "restore-login";
export const restoreLogin = buildAsyncPost(RESTORE_LOGIN, "auth/restore");

export const LOGOUT = "log-out";
export const requestLogout = buildAsyncPost(LOGOUT, "auth/logout");

export const REQUEST_PASSWORD_RESET = "request-password-reset";
export const requestPasswordReset = buildAsyncPost(
  REQUEST_PASSWORD_RESET,
  "auth/request-password-reset"
);

export const RESET_PASSWORD = "reset-password";
export const resetPassword = buildAsyncPost(
  RESET_PASSWORD,
  "auth/reset-password"
);

export const SET_RECAPTCHA_TOKEN = "set-recaptcha-token";
export const setRecaptchaToken = buildAsyncPost(
  SET_RECAPTCHA_TOKEN,
  "recaptcha"
);

export const GET_RECAPTCHA_SITEKEY = "get-recaptcha-sitekey";
export const getRecaptchaSiteKey = () =>
  buildAsyncGet(GET_RECAPTCHA_SITEKEY, "/recaptcha/sitekey");

export const RESEND_TOKEN = "resend-token";
export const requestResendToken = buildAsyncPost(
  RESEND_TOKEN,
  "auth/resend-token"
);

export const VERIFY_EMAIL = "verify-email";
export const verifyEmail = () =>
  buildAsyncPost(VERIFY_EMAIL, `/user/verify-email`);
