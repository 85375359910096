import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./TopMenu.module.css";
import { Link } from "react-router-dom";
import ReactResponsiveSelect from "react-responsive-select";
import {
  requestMyClinics,
  changeActiveClinic
} from "../../../actions/clinic/account";
import { requestClinicPatients } from "../../../actions/clinic/clinic";
import history from "../../../history";

const caretIcon = (
  <svg
    className="caret-icon"
    x="0px"
    y="0px"
    width="11.848px"
    height="6.338px"
    viewBox="351.584 2118.292 11.848 6.338"
  >
    <g>
      <path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z" />
    </g>
  </svg>
);

const clinicsList = clinics => {
  if (clinics.length) {
    const list = clinics.map(clinic => {
      return {
        value: clinic.id,
        text: clinic.name
      };
    });
    return [...list];
  }
};

export default ({ toggleMenu }) => {
  const dispatch = useDispatch();

  const { clinics, user_id, active_clinic, settings } = useSelector(state => ({
    clinics: state.clinic.account.clinics.content,
    user_id: state.auth.user.id,
    active_clinic: state.clinic.account.activeClinic,
    settings: state.auth.user.settings
  }));

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(requestMyClinics({ user_id }));
  }, [dispatch]);

  const onProjectChange = clinic_id => {
    if (!firstLoad) {
      dispatch(changeActiveClinic(clinic_id));
      dispatch(requestClinicPatients({ clinic_id }));

      //Action when in internal views
      const pathname = history.location.pathname;
      const tab_key = pathname.substring(
        pathname.lastIndexOf("/") + 1,
        pathname.length
      );
      const index_end = pathname.indexOf("/", pathname.indexOf("/") + 1);
      const prev_tab_key = pathname.substring(1, index_end);

      const valid_tabs = [
        "/",
        "dashboard",
        "clinic",
        "team",
        "patients",
        "agenda",
        "profile"
      ];
      if (!valid_tabs.includes(tab_key)) {
        if (prev_tab_key === "patients") history.push(`/patients`);
        if (prev_tab_key === "agenda") history.push(`/agenda`);
      }
    } else {
      //TODO Do something when first load default clinic
      const defaultClinic = settings.defaultClinic
        ? settings.defaultClinic
        : null;
      if (defaultClinic) {
        dispatch(changeActiveClinic(defaultClinic));
        dispatch(requestClinicPatients({ clinic_id: defaultClinic }));
        setFirstLoad(false);
      } else {
        dispatch(changeActiveClinic(clinic_id));
        dispatch(requestClinicPatients({ clinic_id }));
        setFirstLoad(false);
      }
    }
  };

  if (!clinics.length > 0) {
    return <div>Loading...</div>;
  } else {
    return (
      <div id="top">
        <nav className={[styles.navbar, styles.navbarl1].join(" ")}>
          <div>
            {/* Buscando... */}
            <div className={styles.headerprselector}>
              <div
                className={[
                  styles.projectSelect,
                  clinics.length === 1 ? styles.projectSelectHidden : ""
                ].join(" ")}
              >
                <ReactResponsiveSelect
                  name="make1"
                  options={clinicsList(clinics)}
                  onSubmit={() => {}}
                  selectedValue={active_clinic}
                  caretIcon={caretIcon}
                  prefix="Clínica"
                  onChange={project => onProjectChange(project.value)}
                />
              </div>
            </div>
            <div
              className={styles.menu}
              id="menu-toggle"
              onClick={toggleMenu}
              role="button"
              tabIndex={0}
            >
              <div className={styles.ico_toggle_menu} />
              <span className="toggle-left" />
            </div>
          </div>
        </nav>
      </div>
    );
  }
};
