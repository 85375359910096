import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import styles from "../index.module.css";
import { Chart } from "react-google-charts";

const StatsSmall = ({ data, title, label, total, type, color, isCapacity }) => {
  const options = {
    // focusTarget: 'Conversions' '#EE7C70',

    crosshair: {
      orientation: "vertical",
      trigger: "both"
    },
    animation: {
      startup: true,
      duration: 100
    },
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlines: {
        color: "transparent"
      }
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlines: {
        color: "transparent"
      }
    },
    legend: "none",
    series: {
      0: { color: color, areaOpacity: 0.1, opacity: 0.1 },
      1: { color: "#f1ca3a", areaOpacity: 0.1, opacity: 0.1 },
      2: { color: "#f1ca3a" },
      3: { color: "#6f9654" },
      4: { color: "#1c91c0" },
      5: { color: "#43459d" }
    },
    tooltip: {
      textStyle: {
        bold: false,
        color: "#000000",
        fontSize: 14
      },
      showColorCode: true,
      isHtml: false,
      text: "both",
      trigger: "both"
    },
    focusTarget: "category",
    backgroundColor: "none",
    chartArea: {
      backgroundColor: "transparent",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%"
    }
  };

  return (
    <div
      className={[styles.statsSmall, styles.card, styles.cardSmall].join(" ")}
    >
      <div className={styles.cardSmallBody}>
        <Row>
          <Col xs={8}>
            <span className={styles.smallLabel}>{label}</span>
            <div className={styles.bigLabel}>
              {total}
              {isCapacity ? "%" : ""}
            </div>
          </Col>
          <Col xs={4}></Col>
        </Row>
        <Row>
          <Col xs={12}>
            {data && data.length > 0 && (
              <Chart
                chartType={type}
                data={data}
                options={options}
                graph_id={title}
                loader={<div>Loading Chart</div>}
                width="100%"
                height="70px"
                left="0px"
                top="0px"
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StatsSmall;
