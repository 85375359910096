import {
  buildAsyncGet,
  buildAsyncPatch,
  buildAsyncPost,
  requested,
  error,
  failed,
  succeeded
} from "../actionsFactory";
import createProxy from "../../api/apiProxy";

export const FETCH_TODAY_INVOICES_TO_CHARGE = "fetch-today-invoices-to-charge";
export const requestTodayInvoicesToCharge = () =>
  buildAsyncGet(FETCH_TODAY_INVOICES_TO_CHARGE, `admin/v1/billing/today`);

export const GENERATE_INVOICE = "generate-invoice";
export const generateInvoice = () =>
  buildAsyncPost(GENERATE_INVOICE, `admin/v1/invoicing/generate-invoices`);

export const GENERATE_SELECTED_INVOICES = "generate-selected-invoices";
export const generateSelectedInvoices = items => async dispatch => {
  dispatch({
    type: requested(GENERATE_SELECTED_INVOICES),
    payload: { totalInvoices: items.length, status: "Processing", processed: 0 }
  });

  for (let i = 0; i < items.length; i++) {
    try {
      const item = items[i];
      const {
        data: { success, payload }
      } = await createProxy().post(
        `admin/v1/invoicing/generate-invoices`,
        item
      );
      if (success) {
        console.log("payload", payload);
        dispatch({ type: succeeded(GENERATE_INVOICE), payload });
      } else {
        dispatch({
          type: failed(GENERATE_INVOICE),
          payload: { ...item, error: payload }
        });
      }
      if (items.length - 1 === i) {
        dispatch({
          type: succeeded(GENERATE_SELECTED_INVOICES),
          payload: {
            totalInvoices: items.length,
            status: "Finished",
            processed: items.length
          }
        });
      }
    } catch (err) {
      console.log("err", err);
      dispatch({ type: error(GENERATE_INVOICE), payload: { error: err } });
    }
  }
};

export const resetInvoiceProcess = () => ({
  type: requested(GENERATE_SELECTED_INVOICES),
  payload: { totalInvoices: null, status: null, processed: null }
});

export const CHARGE_INVOICE = "charge-invoice";
export const chargeInvoice = ({ id }) =>
  buildAsyncPost(CHARGE_INVOICE, `admin/v1/billing/invoice/${id}/charge`);

export const CHARGE_SELECTED_INVOICES = "charge-selected-invoices";
export const chargeSelectedInvoices = chargeItemIds => async dispatch => {
  dispatch({
    type: requested(CHARGE_SELECTED_INVOICES),
    payload: {
      totalBills: chargeItemIds.length,
      status: "Processing",
      processed: 0
    }
  });

  for (let i = 0; i < chargeItemIds.length; i++) {
    const itemId = chargeItemIds[i];
    try {
      const {
        data: { success, payload }
      } = await createProxy().post(
        `admin/v1/billing/invoice/${parseInt(itemId)}/charge`,
        {}
      );
      if (success) {
        dispatch({ type: succeeded(CHARGE_INVOICE), payload });
      } else {
        dispatch({
          type: failed(CHARGE_INVOICE),
          payload: { id: itemId, ...payload }
        });
      }
      if (chargeItemIds.length - 1 === i) {
        dispatch({
          type: succeeded(CHARGE_SELECTED_INVOICES),
          payload: {
            totalBills: chargeItemIds.length,
            status: "Finished",
            processed: chargeItemIds.length
          }
        });
      }
    } catch (err) {
      dispatch({
        type: error(CHARGE_INVOICE),
        payload: { id: itemId, error: err }
      });
    }
  }
};

export const resetBillingProcess = () => ({
  type: requested(CHARGE_SELECTED_INVOICES),
  payload: { totalBills: null, status: null, processed: null }
});
