import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Tab, Nav, Image } from "react-bootstrap";
import PatientPersonalInfo from "./PatientPersonalInfo";
import PatientClinicProfile from "./PatientClinicProfile";
import PatientClinicHistory from "./PatientClinicHistory";
import styles from "../index.module.css";
// import CustomerHeader from '../../../../components/Layout/Headers/CustomerHeader';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../../../history";
import rightArrowImg from "../../../../static/img/right-arrow.svg";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import NavLinkCleaner from "../../../../components/NavLinkCleaner/NavLinkCleaner";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import TooltipTexts from "../../../../common/constants/tooltipsTexts";

const Patient = ({
  patient_id,
  patient,
  patient_custom_fields,
  clinic_custom_fields,
  clinic_id
}) => {
  const pathname = history.location.pathname;
  const tab_key = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.length
  );

  const { tooltipsMode } = useSelector(state => state.layout);

  let tab;
  const valid_tabs = ["personal", "profile", "history"];
  if (valid_tabs.includes(tab_key)) {
    tab = tab_key;
  } else {
    if (pathname.lastIndexOf("history") != -1) {
      tab = "history";
    } else {
      tab = "personal";
    }
  }

  const customFieldsMerged = {
    ...clinic_custom_fields,
    ...patient_custom_fields.custom_fields
  };

  // const { profile, accounts, payment_methods, account_invoices, referrals, customer } = customer_detail;
  if (!patient) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        {/* <CustomerHeader customer={profile} /> */}

        <div className="body-area">
          <Row className={styles.notifWrapper}>
            <Col xs={12}>
              <NotifBox side="clinic" view="patient" />
            </Col>
          </Row>
          <Tab.Container
            id="left-tabs-example"
            activeKey={tab}
            defaultActiveKey="personal"
            onSelect={() => {}}
          >
            <Row className="clearfix">
              <Col xs={12} md={3}>
                <Nav className={styles.menu} bsStyle="pills" stacked>
                  <NavLinkCleaner
                    to={`/patients/${patient_id}/personal`}
                    className={
                      tab === "personal" ? "adminNavActive" : "adminNav"
                    }
                  >
                    Información personal
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner>
                  <NavLinkCleaner
                    to={`/patients/${patient_id}/profile`}
                    className={
                      tab === "profile" ? "adminNavActive" : "adminNav"
                    }
                  >
                    Perfil clínico
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner>
                  <NavLinkCleaner
                    to={`/patients/${patient_id}/history`}
                    className={
                      tab === "history" ? "adminNavActive" : "adminNav"
                    }
                  >
                    Historial clínico
                    <Image
                      src={rightArrowImg}
                      responsive
                      className={styles.rightArrow}
                    />
                  </NavLinkCleaner>
                </Nav>

                {/* Tooltip */}
                {tooltipsMode && (
                  <Tooltip title={TooltipTexts[tab].title}>
                    {TooltipTexts[tab].content}
                  </Tooltip>
                )}
              </Col>
              <Col xs={12} md={9} id="cTab">
                <Tab.Content animation className={styles.contentCustomers}>
                  <Tab.Pane eventKey="personal">
                    <PatientPersonalInfo
                      patient={patient}
                      clinic_id={clinic_id}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="profile">
                    <PatientClinicProfile
                      patient_id={patient_id}
                      patient_custom_fields={patient_custom_fields}
                      clinic_custom_fields={clinic_custom_fields}
                      customFieldsMerged={customFieldsMerged}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="history">
                    <PatientClinicHistory patient_id={patient_id} />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey='assignment'>
                  <AdminSolarGardenAssign
                    customer={customer_detail}
                    accounts={accounts}
                    solar_farms={solar_farms}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='payment-methods'>
                  <AdminPaymentMethods accounts={accounts} paymentMethods={payment_methods} />
                </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
  }
};

Patient.propTypes = {
  match: PropTypes.object
};

export default withRouter(Patient);
