import React, { useState } from "react";
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Checkbox,
  Radio,
  Glyphicon
} from "react-bootstrap";
import PropTypes from "prop-types";
import MaskedInputField from "react-input-mask";
import styles from "./FieldGroup.module.css";
import DatePicker from "react-datepicker";
import AutoComplete from "../AutoComplete/AutoComplete";
// import { validatePasswordStrength } from '../../../common/validations/password';
import FieldError from "./FieldError";

const FieldGroup = ({
  id,
  type = "text",
  mask,
  label,
  validations = {},
  labelClassName,
  helpClassName,
  selectOptions = [],
  selectMap,
  selectFirstOption,
  failedValidation,
  tip,
  tipClassName,
  form,
  checkboxText,
  checkboxSpacer = "  ",
  readOnly,
  checked,
  suggestions = [],
  placeholder,
  errors,
  isRequired,
  hideLabel,
  tIndex,
  setInvalid = () => null,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [isPasswordVisible, setPaswordVisibility] = useState(false);

  const error = null;

  if (type === "text")
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <FormControl
          type={type}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...props}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  if (type === "text-deposit")
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <Form className="form-inline">
          <label htmlFor="$0">$0.</label>
          <span> </span>
          <FormControl
            type={type}
            readOnly={readOnly}
            onBlur={() => setTouched(true)}
            placeholder={placeholder}
            {...props}
          />
          {tip && <span className={tipClassName}>{tip}</span>}
          <FieldError id={id} errorClassName={helpClassName} errors={errors} />
        </Form>
      </FormGroup>
    );
  if (type === "autocomplete")
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <AutoComplete
          suggestions={suggestions}
          type={type}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          id={id}
          placeholder={placeholder}
          {...props}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  if (type === "number") {
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <FormControl
          type={type}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...props}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  }
  if (type === "password")
    return (
      <FormGroup controlId={id} className={styles.passwordFormGroup}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <FormControl
          type={isPasswordVisible ? "text" : type}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...props}
        />
        <Glyphicon
          className={styles.showPasswordButton}
          onClick={() => setPaswordVisibility(!isPasswordVisible)}
          glyph={isPasswordVisible ? "eye-close" : "eye-open"}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  if (type === "new-password") {
    const passwordLength = props.value ? props.value.length : 0;
    const strengthClass = [
      styles.strengthMeter,
      "mt-2",
      passwordLength > 0 ? "visible" : "invisible"
    ]
      .join(" ")
      .trim();
    // const strength = props.value ? validatePasswordStrength(props.value) : -1;
    // temporal
    const strength = "Fair";

    let strengthText = "";
    const PasswordStrengthText = () => {
      switch (strength) {
        case 0:
          strengthText = "Weak";
          break;
        case 1:
          strengthText = "Fair";
          break;
        case 2:
          strengthText = "Good";
          break;
        case 3:
          strengthText = "Very Good";
          break;
        case 4:
          strengthText = "Excellent";
          break;
        default:
          strengthText = "";
      }
    };
    return (
      <FormGroup controlId={id} className={styles.passwordFormGroup}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <FormControl
          type={isPasswordVisible ? "text" : "password"}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          onFocus={PasswordStrengthText()}
          placeholder={placeholder}
          {...props}
        />
        <Glyphicon
          className={styles.showPasswordButton}
          onClick={() => setPaswordVisibility(!isPasswordVisible)}
          glyph={isPasswordVisible ? "eye-close" : "eye-open"}
        />
        <div className={strengthClass}>
          <div
            className={styles.strengthMeterFill}
            data-strength={strength}
          ></div>
        </div>
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
        {strengthText}
      </FormGroup>
    );
  } else if (type === "select") {
    return (
      <FormGroup controlId={id}>
        {!hideLabel && (
          <ControlLabel className={labelClassName}>
            {label}
            {isRequired && <span className={styles.isRequired}>*</span>}
          </ControlLabel>
        )}
        <FormControl
          readOnly={readOnly}
          type={type}
          componentClass="select"
          className={styles.selectStyle}
          onBlur={() => setTouched(true)}
          {...props}
        >
          {selectFirstOption ? selectFirstOption : null}
          {placeholder ? (
            <option value="" key="placeholder" disabled hidden>
              {placeholder}
            </option>
          ) : null}
          {selectOptions.map(
            selectMap
              ? selectMap
              : (option, i) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
          )}
        </FormControl>
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  } else if (type === "checkbox") {
    const { value, ...restProps } = props;
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        {checkboxSpacer}
        <Checkbox
          type={type}
          inline
          defaultChecked={value}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          {...restProps}
          id={id}
        />
        {checkboxText && (
          <span style={{ marginTop: "12px" }}>{checkboxText}</span>
        )}
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  } else if (type === "radio") {
    const { value, ...restProps } = props;
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        {checkboxSpacer}
        <Radio
          type={type}
          inline
          checked={checked}
          value={value}
          readOnly={readOnly}
          onBlur={() => setTouched(true)}
          name={name}
          {...restProps}
          id={id}
        />
        {checkboxText && (
          <span style={{ marginTop: "12px" }}>{checkboxText}</span>
        )}
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  } else if (type === "masked") {
    // TODO: why is label always Phone Number?
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <MaskedInputField
          readOnly={readOnly}
          className="form-control"
          mask={mask}
          label={label}
          id={id}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...props}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  } else if (type === "textarea") {
    return (
      <FormGroup controlId={id}>
        <ControlLabel className={labelClassName}>
          {label}
          {isRequired && <span className={styles.isRequired}>*</span>}
        </ControlLabel>
        <FormControl
          type={type}
          componentClass="textarea"
          readOnly={readOnly}
          rows={6}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...props}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        {error && <HelpBlock className={helpClassName}>{error}</HelpBlock>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  } else if (type === "date") {
    const { onChange, value, showTimeSelect, dateFormat, ...restProps } = props;
    return (
      <FormGroup controlId={id}>
        {!hideLabel && (
          <ControlLabel className={labelClassName}>
            {label}
            {isRequired && <span className={styles.isRequired}>*</span>}
          </ControlLabel>
        )}
        <DatePicker
          className="form-control"
          dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
          placeholderText="dd/mm/aaaa"
          showMonthDropdown
          showYearDropdown
          showTimeSelect={showTimeSelect}
          onChange={date => onChange({ target: { value: date, id } })}
          selected={value}
          tabIndex={tIndex}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          {...restProps}
        />
        {tip && <span className={tipClassName}>{tip}</span>}
        {error && <HelpBlock className={helpClassName}>{error}</HelpBlock>}
        <FieldError id={id} errorClassName={helpClassName} errors={errors} />
      </FormGroup>
    );
  }
};
FieldGroup.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  labelClassName: PropTypes.string,
  helpClassName: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.object),
  tip: PropTypes.string,
  tipClassName: PropTypes.string,
  setInvalid: PropTypes.func
};
export default FieldGroup;
