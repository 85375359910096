import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./index.module.css";
import ProfileUserSince from "./components/ProfileUserSince";
import ProfileBox from "./components/ProfileBox";
import { updateUserAccount } from "../../../actions/clinic/account";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";
import ChangePasswordBox from "./components/ChangePasswordBox";

const Profile = () => {
  const dispatch = useDispatch();

  const { user, errors, user_country, passwordStatus } = useSelector(store => ({
    user: store.auth.user,
    errors: store.auth.errors,
    user_country: store.auth.user.country,
    passwordStatus: store.auth.passwordStatus
  }));

  const update_profile = updateUserAccount({ id: user.id });

  const [form, setForm] = useState({
    ...user,
    country: "El Salvador"
  });

  const onHandleChange = e => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    });
  };

  const save = form => {
    const update = {
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      phone_number: form.phone_number.replace(/_/g, ""),
      address_1: form.address_1,
      address_2: "",
      city: form.city
      //state: form.state,
      //zip_code: form.zip_code,
      //auth_method: form.auth_method,
      //settings: form.settings
    };
    dispatch(
      update_profile(update, {
        onSuccessMessage: {
          message: "Se ha actualizado el perfil de usuario exitosamente.",
          title: "Perfil de usuario actualizado",
          view: "profile",
          type: "success"
        },
        onFailedMessage: {
          message:
            "No se pudo actualizar el perfil de usuario, por favor intente nuevamente.",
          title: "Falló la actualización del perfil de usuario",
          view: "profile",
          type: "error"
        }
      })
    );
  };

  if (!passwordStatus) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        {/* Top Header  */}
        <div className={"top-nav"}>
          <div className={"container"}>
            <div className={"brand"}>Perfil</div>
          </div>
        </div>
        <div className={"site-header"}>
          <h1>{form.first_name + " " + form.last_name}</h1>
          <ProfileUserSince
            created_at={form.created_at}
            text_user_since={"Usuario desde "}
          />
        </div>
        <div className={"body-area"}>
          {/* Profile */}
          <Row>
            <Col>
              <NotifBox view="profile" />
              <ProfileBox
                formState={form}
                setFormState={onHandleChange}
                errors={errors}
                user_country={user_country}
              />
            </Col>
            <Row>
              <Col
                xs={12}
                md={6}
                mdPush={6}
                className={styles.profileSectionEnd}
              >
                <Button
                  className={"push-button rright -wide"}
                  onClick={() => {
                    save(form);
                  }}
                >
                  Guardar cambios
                </Button>
              </Col>
            </Row>
          </Row>

          {/* Language */}
          {/* <Row>
          <LanguagesBox formState={form} onChangeLanguage={onChangeLanguage} />
          <Row>
            <Col xs={12} md={6} mdPush={6} className={styles.profileSectionEnd}>
              <Button
                className={'push-button rright -wide'}
                onClick={() => {
                  save(form);
                }}
              >
                <I18NText componentKey='profile' translationKey='button_language' />
              </Button>
            </Col>
          </Row>
        </Row> */}

          {/* Password */}
          <Row>
            <ChangePasswordBox user_id={user.id} />
          </Row>

          {/* Two Factor Authentication */}
          {/* <Row>
          <Col>
            <TwoFactorAuthentication
              formState={form}
              setFormState={onHandleChange}
              TwoFactorAuthenticationState={TwoFactorAuthenticationState}
              setTwoFactorAuthenticationState={setTwoFactorAuthenticationState}
            />
            <Row>
              <Col xs={12} md={6} mdPush={6} className={styles.profileSectionEnd}>
                <Button
                  className={'push-button rright -wide'}
                  onClick={() => {
                    save2fa(form);
                  }}
                >
                  <I18NText componentKey='profile' translationKey='button_profile' />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row> */}

          {/* Privacy Notice */}

          {/*  <Row>
          <div className={styles.profileSection}>
            <Row>
              <Col xs={12} md={5} className={styles.title}>
                <I18NText componentKey='profile' translationKey='privacy_title' />
              </Col>
              <Col xs={12} md={7}>
                <p>
                  <I18NText componentKey='profile' translationKey='privacy_msg_1' />
                </p>
                <p>
                  <I18NText componentKey='profile' translationKey='privacy_msg_2' />{' '}
                  <a
                    href='https://monitoreo-salud.com/privacy-policy-2/'
                    rel='noopener noreferrer'
                    className={'underlinedLink'}
                    target='_blank'
                  >
                    <I18NText componentKey='profile' translationKey='privacy_msg_3' />
                  </a>{' '}
                  <I18NText componentKey='profile' translationKey='privacy_msg_4' />
                </p>
              </Col>
            </Row>
          </div>
        </Row> */}
        </div>
      </div>
    );
  }
};

export default Profile;
