import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

const Reports = () => {
  return (
    <Row>
      <Col>REPORTS</Col>
    </Row>
  );
};

export default Reports;
