import * as React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";

export default class ConfirmModal extends React.Component {
  state = {
    open: false,
    callback: null
  };

  show = callback => event => {
    event.preventDefault();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value }
    };

    this.setState({
      open: true,
      callback: () => callback(event)
    });
  };

  hide = () => this.setState({ open: false, callback: null });

  confirm = () => {
    this.state.callback();
    this.hide();
  };

  render() {
    const { title, message, component: Component, componentProps } = this.props;
    return (
      <React.Fragment>
        {this.props.children({ confirm: this.show })}
        <Modal show={this.state.open} onHide={this.hide}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                {message ? message : <Component {...componentProps} />}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hide}>Cancel</Button>
            <Button onClick={this.confirm}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
