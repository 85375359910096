import React from "react";
import Appointments from "./Appointments";
import AppointmentDetail from "./AppointmentDetail";
import queryString from "query-string";

export default ({
  match: {
    params: { id }
  },
  location: { search }
}) => {
  if (id) {
    return <AppointmentDetail appointment_id={id} />;
  }
  const q = queryString.parse(search);
  return <Appointments filter={q.filter ? "today" : ""} />;
};
