import { succeeded, requested, failed } from "../../actions/actionsFactory";
import { pristine, reduce, isRelatedAction } from "../utils";
import { EDIT_MODE } from "../../actions/clinic/common";
import {
  FETCH_PATIENT_CUSTOM_FIELDS,
  ADD_PATIENT_CUSTOM_FIELDS,
  UPDATE_PATIENT_CUSTOM_FIELDS
} from "../../actions/clinic/patient_custom_fields";

var defaultState = {
  patientCustomFields: pristine([]),
  editMode: false,
  toggleModal: false
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_PATIENT_CUSTOM_FIELDS)) {
    return reduce(
      state,
      "patientCustomFields",
      action,
      FETCH_PATIENT_CUSTOM_FIELDS
    );
  }

  if (action.type === requested(EDIT_MODE)) {
    return {
      ...state,
      editMode: !state.editMode,
      patientCustomFields: {
        ...state.patientCustomFields,
        errors: null
      }
    };
  }

  /* 	if (action.type === requested(TOGGLE_CLINIC_CUSTOMIZATION_MODAL)) {
		return {
			...state,
			toggleModal: !state.toggleModal,
			detail: {
				...state.detail,
				errors: null,
			},
		}
    } */

  if (action.type === succeeded(ADD_PATIENT_CUSTOM_FIELDS)) {
    return {
      ...state,
      patientCustomFields: {
        ...state.patientCustomFields,
        content: [...state.patientCustomFields.content, action.payload],
        errors: null
      }
    };
  }

  if (action.type === failed(ADD_PATIENT_CUSTOM_FIELDS)) {
    return {
      ...state,
      patientCustomFields: {
        ...state.patientCustomFields,
        errors: {
          ...action.payload
        }
      }
    };
  }

  if (action.type === succeeded(UPDATE_PATIENT_CUSTOM_FIELDS)) {
    return {
      ...state,
      patientCustomFields: {
        ...state.patientCustomFields,
        content: [...state.patientCustomFields.content, action.payload],
        errors: null
      }
    };
  }

  if (action.type === failed(UPDATE_PATIENT_CUSTOM_FIELDS)) {
    return {
      ...state,
      patientCustomFields: {
        ...state.patientCustomFields,
        errors: {
          ...action.payload
        }
      }
    };
  }

  return state;
}
