import {
  buildAsyncGet,
  buildAsyncPatch,
  buildAsyncPost,
  buildAsyncDelete,
  requested
} from "../actionsFactory";

export const TOGGLE_APPOINTMENT_MODAL = "toggle-appointment-modal";
export const toggleAppointmentModal = {
  type: requested(TOGGLE_APPOINTMENT_MODAL)
};

export const FETCH_PATIENT_APPOINTMENT_HISTORY =
  "fetch-patient-appointment-history";
export const requestPatientAppointmentHistory = ({ id }) =>
  buildAsyncGet(
    FETCH_PATIENT_APPOINTMENT_HISTORY,
    `clinic/v1/patient/${id}/appointments`
  );

export const FETCH_PRESCRIPTION_S3_DOWNLOAD_URL =
  "fecth-prescription-s3-download-url";
export const requestPrescriptionDownloadUrl = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_PRESCRIPTION_S3_DOWNLOAD_URL,
    `clinic/v1/appointment/${appointment_id}/download`
  );

export const SEND_VIRTUAL_APPOINTMENT_EMAIL = "send-virtual-appointment_email";
export const sendVirtualAppointmentEmail = ({ id }) =>
  buildAsyncPatch(
    SEND_VIRTUAL_APPOINTMENT_EMAIL,
    `clinic/v1/appointment/${id}/video`
  );

export const FETCH_VIRTUAL_APPOINTMENT_TOKEN =
  "fetch-virtual-appointment-token";
export const requestVirtualAppointmentToken = id =>
  buildAsyncGet(
    FETCH_VIRTUAL_APPOINTMENT_TOKEN,
    `clinic/v1/appointment/${id}/video`
  );

export const SET_PATIENT_VIRTUAL_APPOINTMENT_COMPLETED =
  "set-patient-virtual-appointment-completed";
export const setVirtualAppointmentCompleted = ({ id }) =>
  buildAsyncPatch(
    SET_PATIENT_VIRTUAL_APPOINTMENT_COMPLETED,
    `clinic/v1/appointment/${id}/video-completed`
  );

export const CHECK_APPOINTMENT_EXAMS = "check-appointment-exams";
export const checkAppointmentExams = ({ appointment_id }) =>
  buildAsyncGet(
    CHECK_APPOINTMENT_EXAMS,
    `clinic/v1/appointment/${appointment_id}/exams-pending`
  );

export const FETCH_APPOINTMENT_EXAMS = "fetch-appointment-exams";
export const requestAppointmentExams = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_APPOINTMENT_EXAMS,
    `clinic/v1/appointment/${appointment_id}/exams`
  );

export const FETCH_PREV_APPOINTMENT_EXAMS = "fetch-prev-appointment-exams";
export const requestPrevAppointmentExams = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_PREV_APPOINTMENT_EXAMS,
    `clinic/v1/appointment/${appointment_id}/prev-exams`
  );

export const FETCH_PATIENT_EXAMS = "fetch-patient-exams";
export const requestPatientExams = ({ patient_id }) =>
  buildAsyncGet(FETCH_PATIENT_EXAMS, `clinic/v1/patient/${patient_id}/exams`);

export const TOGGLE_APPOINTMENT_EXAMS_MODAL = "toggle-appointment-exams-modal";
export const toggleAppointmentExamsModal = {
  type: requested(TOGGLE_APPOINTMENT_EXAMS_MODAL)
};

export const UPDATE_APPOINTMENT_EXAMS = "update-appointment-exams";
export const updateAppointmentExams = ({ appointment_id }) =>
  buildAsyncPatch(
    UPDATE_APPOINTMENT_EXAMS,
    `clinic/v1/appointment/${appointment_id}/exams`
  );

export const ADD_APPOINTMENT_EXAMS = "add-appointment-exams";
export const addAppointmentExams = ({ appointment_id }) =>
  buildAsyncPost(
    ADD_APPOINTMENT_EXAMS,
    `clinic/v1/appointment/${appointment_id}/exams`
  );

export const SET_LOADING = "set-loading";
export const setIsLoading = {
  type: requested(SET_LOADING)
};

export const FETCH_APPOINTMENT_EXAMS_LIST = "fetch-appointment-exams-list";
export const requestAppointmentExamsList = ({ appointment_id }) =>
  buildAsyncGet(
    FETCH_APPOINTMENT_EXAMS_LIST,
    `clinic/v1/appointment/${appointment_id}/exams-list`
  );

export const DELETE_APPOINTMENT_EXAM = "delete-appointment-exam";
export const deleteAppointmentExam = ({ id }) =>
  buildAsyncDelete(
    DELETE_APPOINTMENT_EXAM,
    `clinic/v1/appointment-exams/${id}`
  );
