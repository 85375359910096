import React from "react";
import LoginBox from "./components/LoginBox/LoginBox";
import Slider from "./components/DemoBox/Silder";
import queryString from "query-string";
import styles from "./Login.module.css";
import saludLogo from "../../static/img/logo_app.png";
import { Row, Col, Image } from "react-bootstrap";
// import BrowserCompatibility from '../../components/BrowserCompatibility/BrowserCompatibility'
import { Link } from "react-router-dom";
const { detect } = require("detect-browser");
const browser = detect();
/* 

  return (
    <div className={[styles.wrapper, bgImage].join(' ')}>
      {browserSupported() && !versionSupported() && (
        <div className={styles.browserWarning}>
          Your browser is out of date, we recommend updating your browser to keep your account safe.{' '}
          <Link to={!q.bw ? '/login?bw=true' : '/login'}>{!q.bw ? 'Read More' : 'Return to Login'}</Link>
        </div>
      )}
      <div className={styles.topBar}>
        <Row>
          <Col xs={12} sm={6} align={'left'}>
            <Image width='291px' src={solsticeLogo} />
          </Col>
        </Row>
      </div>
      {browserSupported() && !q.bw ? (
        <LoginBox queryMessage={q.m} />
      ) : (
        <BrowserCompatibility data={browser} type={q.bw ? 'warning' : 'prevent'} />
      )}
    </div>
  );
}; */

export default ({ location: { search } }) => {
  const q = queryString.parse(search);

  const browserSupportedVersions = {
    chrome: [80],
    crios: [80],
    firefox: [68],
    fxios: [68],
    edge: [80],
    "edge-ios": [80],
    ios: [13],
    safari: [13]
  };

  const browserSupported = () => {
    switch (browser && browser.name) {
      case "chrome":
      case "crios":
      case "firefox":
      case "fxios":
      case "edge":
      case "edge-ios":
      case "ios":
      case "safari":
        return true;

      default:
        return false;
    }
  };

  const versionSupported = () => {
    if (browser && browser.name && browser.version) {
      if (parseInt(browser.version) >= browserSupportedVersions[browser.name]) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className={[styles.wrapper, styles.gradientPrimary].join(" ")}>
      <div className={styles.topBar}>
        <Row className={styles.loginHeaderContainer}>
          <Col xs={12} sm={6} align={"left"}>
            <Image width="180px" src={saludLogo} />
          </Col>
        </Row>
      </div>
      <LoginBox queryMessage={q.m} />
      {/* <Slider /> */}
    </div>
  );
};
