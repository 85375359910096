import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import { Link, withRouter } from "react-router-dom";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { editMode } from "../../../../actions/clinic/common";
import {
  updateTeamMember,
  updateDoctor,
  updateTechnician,
  updateDoctorAssignment,
  updateTechnicianAssignment,
  removeTeamMember
} from "../../../../actions/clinic/clinic";
import { requestSpecialties } from "../../../../actions/specialties/specialties";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";

const genders = [
  {
    value: "M",
    text: "Masculino"
  },
  {
    value: "F",
    text: "Femenino"
  }
];

const ClinicTeamMember = ({
  team_member,
  roles,
  doctor,
  technician,
  additionalInformation,
  clinic_id
}) => {
  const dispatch = useDispatch();
  const update = updateTeamMember(team_member);
  const updateAdditionalInfo = doctor
    ? updateDoctor(doctor)
    : technician
    ? updateTechnician(technician)
    : "";
  const updateAssignment = doctor
    ? updateDoctorAssignment()
    : technician
    ? updateTechnicianAssignment()
    : "";

  useEffect(() => {
    dispatch(requestSpecialties());
  }, [clinic_id]);

  const { edit_mode, patients, errors, specialties } = useSelector(state => ({
    edit_mode: state.clinic.account.editMode,
    patients: state.clinic.clinic.clinicPatients.content,
    errors: state.clinic.clinic.clinicTeam.errors,
    specialties: state.specialties.specialties
  }));

  const [formState, setFormState] = useState({
    ...team_member
  });

  const [specialtyState, setSpecialtyState] = useState({});

  const [hasSubSpecialtiesState, setHasSubSpecialtiesState] = useState(false);

  const [additionalFormState, setAdditionalFormState] = useState(() => {
    if (doctor || technician) {
      return {
        ...doctor,
        ...technician,
        emails: doctor
          ? doctor.emails
            ? doctor.emails.toString()
            : ""
          : technician.emails
          ? technician.emails.toString()
          : "",
        phones: doctor
          ? doctor.phones
            ? doctor.phones.toString()
            : ""
          : technician.phones
          ? technician.phones.toString()
          : ""
      };
    } else {
      return {};
    }
  });

  const [patientsState, setPatientsState] = useState(() => {
    if (doctor || technician) {
      return doctor ? doctor.patientsIds : technician.patientsIds;
    } else {
      return [];
    }
  });

  let patientsList =
    patients &&
    patients.map(patient => ({
      ...patient,
      name: patient.first_name + " " + patient.middle_name,
      last_name: patient.last_name,
      added: patientsState.includes(patient.id) ? true : false
    }));

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdateBasicInfo = () => {
    dispatch(
      update(
        {
          id: team_member.id,
          first_name: formState.first_name,
          last_name: formState.last_name,
          gender: formState.gender,
          dob: new Date(formState.dob)
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Personal de la clínica actualizado",
            message:
              "Se ha actualizado la información básica del personal de la clínica exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló la actualización del personal de la clínica",
            message:
              "No se pudo actualizar información básica del personal de la clínica, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onUpdateAditionalInfo = () => {
    if (doctor || technician) {
      let emailsArray = additionalFormState.emails.replace(/\s/g, "");
      let phonesArray = additionalFormState.phones.replace(/\s/g, "");
      dispatch(
        updateAdditionalInfo(
          {
            id: doctor ? doctor.id : technician.id,
            emails: emailsArray === "" ? [] : emailsArray.split(","),
            phones: phonesArray === "" ? [] : phonesArray.split(","),
            speciality: additionalFormState.speciality,
            code: additionalFormState.code
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "clinic",
              title: "Personal de la clínica actualizado",
              message:
                "Se ha actualizado la información adicional del personal de la clínica exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "clinic",
              title: "Falló la actualización del personal de la clínica",
              message:
                "No se pudo actualizar información adicional del personal de la clínica, por favor intente nuevamente."
            }
          },
          { onFinishedAction: editMode }
        )
      );
    }
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const onChangeAdditionalInfo = ({ target: { value, id } }) => {
    setAdditionalFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const assignPatient = id => {
    if (doctor || technician) {
      const newList = patientsState.concat(id);
      setPatientsState(newList);
      dispatch(
        updateAssignment(
          {
            clinic_id: parseInt(clinic_id),
            patient_id: parseInt(id),
            doctor_id: doctor ? doctor.id : null,
            technician_id: technician ? technician.id : null
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "clinic",
              title: "Paciente asignado a personal de la clínica",
              message:
                "Paciente asignado a personal de la clínica exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "clinic",
              title:
                "Falló la asignación del paciente al personal de la clínica",
              message:
                "Falló la asignación del paciente al personal de la clínica, por favor intente nuevamente."
            }
          }
        )
      );
    }
  };

  const onChangeSpecialty = ({ target: { value, id } }) => {
    const [specialtySelected] = specialties.content.filter(
      specialty => specialty.id === parseInt(value)
    );

    if (specialtySelected && specialtySelected.sub.length > 0) {
      setHasSubSpecialtiesState(true);
    }

    setAdditionalFormState({
      ...additionalFormState,
      speciality: parseInt(value)
    });

    setSpecialtyState(specialtySelected);
  };

  const onChangeSubSpecialty = ({ target: { value, id } }) => {
    setAdditionalFormState({
      ...additionalFormState,
      speciality: parseInt(value)
    });
  };

  const onDeleteMember = () => {
    dispatch(
      removeTeamMember({ clinic_id, user_id: team_member.id })(
        {
          onSuccessMessage: {
            type: "success",
            view: "patient",
            title: "Paciente eliminado",
            message: "El paciente se ha eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "patient",
            title: "Falló eliminando al paciente",
            message:
              "No se pudo eliminar al paciente, consulte a soporte técnico."
          }
        }
        /* { onFinishedAction: togglePreviewAppointmentModalOnAgenda } */
      )
    );
  };

  const clinicPatients = [
    {
      accessor: "id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/patients/${props.original.id}`}> {props.original.id} </Link>
      ),
      filterable: false
    },
    {
      Header: "Nombre(s)",
      accessor: "name",
      id: "name",
      minWidth: 100,
      maxWidth: 200,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/patients/${props.original.id}`}>
          {" "}
          {props.original.name}{" "}
        </Link>
      ),
      filterable: true,
      filterMethod: (filter, row) =>
        row.name.toLowerCase().includes(filter.value.toLowerCase())
    },
    {
      Header: "Apellido(s)",
      accessor: "last_name",
      id: "email",
      minWidth: 100,
      headerClassName: styles.tableSolsticeHeader,
      filterable: true,
      filterMethod: (filter, row) =>
        row.email.toLowerCase().includes(filter.value.toLowerCase())
    },
    {
      Header: "",
      accessor: "added",
      minWidth: 150,
      maxWidth: 150,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <div>
          {!props.original.added ? (
            <Button
              disabled={props.original.added}
              bsSize="small"
              onClick={() => assignPatient(props.original.id)}
            >
              Asignar
            </Button>
          ) : (
            <div className={styles.patientAssigned}>Asignado</div>
          )}
        </div>
      ),
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        return row[filter.id] === false;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%", height: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Todos</option>
          <option value="true">Asignados</option>
          <option value="false">No asignados</option>
        </select>
      )
    }
  ];

  if (!specialties.loaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Información básica</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="text"
                  id="first_name"
                  label="Primer nombre"
                  value={formState.first_name}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={"requiredField"}
                  readOnly={!edit_mode}
                />
                <FieldGroup
                  type="select"
                  selectOptions={genders}
                  selectMap={gender => (
                    <option key={gender.value.toString()} value={gender.value}>
                      {" "}
                      {gender.text}{" "}
                    </option>
                  )}
                  id="gender"
                  label="Genero"
                  value={formState.gender}
                  onChange={onChange}
                  readOnly={!edit_mode}
                  errors={errors}
                  helpClassName={"requiredField"}
                />
                <FieldGroup
                  type="text"
                  id="email"
                  label="Correo electrónico"
                  value={formState.email}
                  placeholder=""
                  //onChange={onChange}
                  //errors={errors}
                  helpClassName={"requiredField"}
                  readOnly={true}
                />
              </Col>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="text"
                  id="last_name"
                  label="Apellido(s)"
                  value={formState.last_name}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={"requiredField"}
                  readOnly={!edit_mode}
                />
                <FieldGroup
                  type="date"
                  id="dob"
                  label="Fecha de Naciemiento"
                  value={formState.dob ? new Date(formState.dob) : ""}
                  onChange={onChange}
                  placeholder="Seleccione una fecha"
                  errors={errors}
                  helpClassName={"requiredField"}
                  disabled={!edit_mode}
                />
                <FieldGroup
                  type="text"
                  id="username"
                  label="Nombre de usuario"
                  value={formState.username}
                  placeholder=""
                  //onChange={onChange}
                  //errors={errors}
                  helpClassName={"requiredField"}
                  readOnly={true}
                />
                {/* 							<FieldGroup
								type='select'
								selectOptions={roles}
								selectFirstOption={
									<option key='null' id=''>
										Select a role
									</option>
								}
								selectMap={(role) => (
									<option key={role.id} value={role.id}>
										{' '}
										{role.name}{' '}
									</option>
								)}
								id='role'
								value={formState.role}
                                label='Role'
                                readOnly={true}
								//onChange={onChange}
								//errors={errors}
								helpClassName={'requiredField'}
							/> */}
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={onUpdateBasicInfo} disabled={!edit_mode}>
              Actualizar información basica
            </Button>
          </div>
        </div>
        {additionalInformation && (
          <div>
            <div className="h3-section">
              <h3>Información adicional</h3>
              <BodyWidget>
                <Row>
                  <Col xs={12} className={styles.toggleb}>
                    <Toggle
                      checked={edit_mode}
                      icons={false}
                      onChange={() => setEditMode()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="emails"
                      label="Correos electrónicos"
                      placeholder="juan@email.com, jose@email.com"
                      value={additionalFormState.emails}
                      onChange={onChangeAdditionalInfo}
                      //errors={errors}
                      helpClassName={"requiredField"}
                      readOnly={!edit_mode}
                    />
                    <FieldGroup
                      type="select"
                      selectFirstOption={
                        <option key="null" value={""} id="">
                          Seleccione una especialidad
                        </option>
                      }
                      selectOptions={specialties.content}
                      selectMap={clinicSpecialty => (
                        <option
                          key={clinicSpecialty.id.toString()}
                          value={clinicSpecialty.id}
                        >
                          {" "}
                          {clinicSpecialty.name}{" "}
                        </option>
                      )}
                      id="speciality"
                      label="Especialización"
                      value={
                        additionalFormState.speciality
                          ? parseInt(
                              additionalFormState.speciality
                                .toString()
                                .slice(-2)
                            ) > 0
                            ? parseInt(
                                additionalFormState.speciality
                                  .toString()
                                  .slice(0, 2) + "00"
                              )
                            : additionalFormState.speciality
                          : ""
                      }
                      onChange={onChangeSpecialty}
                      errors={errors}
                      helpClassName={"requiredField"}
                      readOnly={!edit_mode}
                    />
                    {hasSubSpecialtiesState &&
                      specialtyState &&
                      specialties.content.find(
                        ({ id }) => id === specialtyState.id
                      ).sub.length > 0 && (
                        <FieldGroup
                          type="select"
                          selectFirstOption={
                            <option key="null" value={""} id="">
                              Seleccione una sub especialidad
                            </option>
                          }
                          selectOptions={
                            specialties.content.filter(
                              spec => spec.id === specialtyState.id
                            )[0]
                              ? specialties.content.filter(
                                  spec => spec.id === specialtyState.id
                                )[0].sub
                              : []
                          }
                          selectMap={clinicSpecialty => (
                            <option
                              key={clinicSpecialty.id.toString()}
                              value={clinicSpecialty.id}
                            >
                              {" "}
                              {clinicSpecialty.name}{" "}
                            </option>
                          )}
                          id="sub_specialty_id"
                          label="Sub especialización"
                          value={
                            additionalFormState.speciality
                              ? additionalFormState.speciality
                              : ""
                          }
                          onChange={onChangeSubSpecialty}
                          errors={errors}
                          helpClassName={"requiredField"}
                          isRequired
                          readOnly={!edit_mode}
                        />
                      )}
                  </Col>
                  <Col xs={12} md={6}>
                    <FieldGroup
                      type="text"
                      id="phones"
                      label="Teléfonos"
                      placeholder="2222-2222, 7777-7777"
                      value={additionalFormState.phones}
                      onChange={onChangeAdditionalInfo}
                      //errors={errors}
                      helpClassName={"requiredField"}
                      readOnly={!edit_mode}
                    />
                    <FieldGroup
                      type="text"
                      id="code"
                      label={
                        formState.country === "El Salvador"
                          ? "J.V.P.M"
                          : "código médico"
                      }
                      placeholder=""
                      value={additionalFormState.code}
                      onChange={onChangeAdditionalInfo}
                      //errors={errors}
                      helpClassName={"requiredField"}
                      readOnly={!edit_mode}
                    />
                  </Col>
                </Row>
              </BodyWidget>
              <div className={styles.butArea}>
                <Button onClick={onUpdateAditionalInfo} disabled={!edit_mode}>
                  Actualizar información adicional
                </Button>
              </div>
            </div>
            <div className="h3-section">
              <h3>Pacientes asignados</h3>
              <Row>
                <Col xs={12} md={12}>
                  <ReactTable
                    data={patientsList}
                    columns={clinicPatients}
                    className={[styles.tableSolstice, "body -highlight"].join(
                      " "
                    )}
                    defaultPageSize={10}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
        {!additionalInformation && (
          <div className="h3-section">
            <h3>Pacientes asignados</h3>
            <BodyWidget>
              <Row>
                <Col xs={12} md={12}>
                  Este usuario tiene acceso a todos los pacientes de la clínica.
                </Col>
              </Row>
            </BodyWidget>
          </div>
        )}
        {/* Ends content */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Remover de clínica</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} md={12}>
                <div className={styles.butArea}>
                  <Button onClick={onDeleteMember}>Remover</Button>
                </div>
                <p>
                  Este usuario perderá acceso a la información de tu clínica.
                </p>
              </Col>
            </Row>
          </BodyWidget>
        </div>
        {/* Ends content */}
      </div>
    );
  }
};

export default withRouter(ClinicTeamMember);
