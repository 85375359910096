import React, { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import styles from "./index.module.css";
import StatsSmall from "./components/StatsSmall";
import TodayAppointments from "./components/TodayAppointments";
import AppointmentsToConfirm from "./components/AppointmentsToConfirm";
import FieldGroup from "../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  requestTodayAppointments,
  toggleClinicPatientModal,
  toggleAppointmentModal,
  requestUnconformedAppointments,
  requestDashboardData,
  requestClinicDoctors,
  requestClinicTechnicians,
  requestAppointments,
  requestClinicServices
} from "../../../actions/clinic/clinic";
import moment from "moment";
import AddPatient from "../patients/components/AddPatient";
import AddAppointment from "../agenda/components/Calendar/AddAppointment";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";

const shortcuts = [
  {
    value: 1,
    text: "Agregar paciente"
  },
  {
    value: 2,
    text: "Agendar cita"
  }
];

const Home = () => {
  const dispatch = useDispatch();
  const today = new Date();

  const {
    clinicTodayAppointments,
    errors,
    active_clinic,
    toggle_modal,
    clinics,
    clinicUnconfirmedAppointments,
    clinicDashboardData,
    doctors,
    technicians,
    toggleModalAppointment,
    appointments,
    clinicServices
  } = useSelector(state => ({
    clinicTodayAppointments: state.clinic.clinic.clinicTodayAppointments,
    errors: state.clinic.clinic.clinicTodayAppointments.errors,
    active_clinic: state.clinic.account.activeClinic,
    toggle_modal: state.clinic.clinic.toggleModal,
    toggleModalAppointment: state.clinic.clinic.toggleModalAppointment,
    clinics: state.clinic.account.clinics.content,
    clinicUnconfirmedAppointments:
      state.clinic.clinic.clinicUnconfirmedAppointments,
    clinicDashboardData: state.clinic.clinic.clinicDashboardData,
    doctors: state.clinic.clinic.clinicDoctors.content,
    technicians: state.clinic.clinic.clinicTechnicians.content,
    appointments: state.clinic.clinic.clinicAppointments.content,
    clinicServices: state.clinic.clinic.clinicServices
  }));

  useEffect(() => {
    if (active_clinic) {
      let start_date = today.getTime();
      dispatch(
        requestTodayAppointments({ clinic_id: active_clinic, start_date })
      );
      if (!appointments.length) {
        dispatch(requestAppointments({ clinic_id: active_clinic }));
      }
      dispatch(requestUnconformedAppointments({ clinic_id: active_clinic }));
      dispatch(requestDashboardData({ clinic_id: active_clinic }));
      dispatch(requestClinicDoctors({ clinic_id: active_clinic }));
      dispatch(requestClinicTechnicians({ clinic_id: active_clinic }));
      dispatch(requestClinicServices({ clinic_id: active_clinic }));
    }
  }, [active_clinic]);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  const toggleCLinicPatienModal = () => dispatch(toggleClinicPatientModal);

  const toggleCLinicAppointmentModal = () => dispatch(toggleAppointmentModal);

  const onChange = ({ target: { value, id } }) => {
    if (value === "1") {
      dispatch(toggleClinicPatientModal);
    }
    if (value === "2") {
      dispatch(toggleAppointmentModal);
    }
  };

  const todayAppointments =
    clinicTodayAppointments &&
    clinicTodayAppointments.content &&
    clinicTodayAppointments.content.filter(
      appt => Math.floor(appt.hour) >= today.getHours()
    );

  const todayAppointmentsMsg =
    clinicTodayAppointments.content.length > 0
      ? todayAppointments.length === 0
        ? "No hay más citas agendadas para hoy"
        : ""
      : "No hay citas agendadas para hoy";

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.doctor_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.technician_id,
      text: technician.first_name + " " + technician.last_name
    }));

  if (
    !clinicTodayAppointments.loaded ||
    !clinicUnconfirmedAppointments.loaded ||
    !active_clinic ||
    !clinicDashboardData.loaded
  ) {
    return <div>Loading...</div>;
  } else {
    return (
      <Row>
        <Col>
          <Row className={styles.notifWrapper}>
            <Col xs={12}>
              <NotifBox side="clinic" view="home" />
            </Col>
          </Row>
          <div>
            <div className={["body-area", styles.homeMargin].join(" ")}>
              <Row className={styles.welcomeWrapper}>
                <Col xs={6}>
                  <span className={styles.welcomeSubtitle}>Inicio</span>
                  <div className={styles.welcome}>Bienvenido</div>
                </Col>
                <Col xs={6}>
                  <div className={styles.shortcutsWrapper}>
                    <FieldGroup
                      type="select"
                      selectOptions={shortcuts}
                      selectMap={shortcut => (
                        <option
                          key={shortcut.value.toString()}
                          value={shortcut.value}
                        >
                          {" "}
                          {shortcut.text}{" "}
                        </option>
                      )}
                      selectFirstOption={
                        <option value={0}>Accesos directos</option>
                      }
                      id="shortcuts"
                      onChange={onChange}
                      //errors={errors}
                      helpClassName={"requiredField"}
                      tabIndex={5}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={3}>
                  <StatsSmall
                    data={clinicDashboardData.content.appointmentsSummary}
                    title={"appointments"}
                    label={"Total de Citas"}
                    total={clinicDashboardData.content.totalAppointments}
                    type={"AreaChart"}
                    color={"#007bff"}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <StatsSmall
                    data={clinicDashboardData.content.patientsSummary}
                    title={"patients"}
                    label={"Total de Pacientes"}
                    total={clinicDashboardData.content.totalPatients}
                    type={"AreaChart"}
                    color={"#17c671"}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <StatsSmall
                    data={clinicDashboardData.content.appointmentsMonthSummary}
                    title={"consultations"}
                    label={"Citas del mes"}
                    total={clinicDashboardData.content.totalMonhAppointments}
                    type={"AreaChart"}
                    color={"#ffb400"}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <StatsSmall
                    data={clinicDashboardData.content.weekCapacity}
                    title={"agenda"}
                    label={"Reservación semanal"}
                    total={clinicDashboardData.content.totalWeekCapacity}
                    type={"AreaChart"}
                    color={"#EE7C70"}
                    isCapacity
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={8}>
                  <TodayAppointments
                    todayAppointments={todayAppointments}
                    todayAppointmentsMsg={todayAppointmentsMsg}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <AppointmentsToConfirm
                    unconfirmedAppointments={
                      clinicUnconfirmedAppointments.content
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Modal
          show={toggle_modal}
          onHide={toggleCLinicPatienModal}
          backdrop="static"
          bsSize="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Agregar paciente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPatient
              clinic={clinic}
              settings={clinic.settings}
              source={"home"}
              clinicServices={clinicServices.content}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={toggleModalAppointment}
          onHide={toggleCLinicAppointmentModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Crear cita</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddAppointment
              settings={clinic.settings}
              doctorsList={doctorsList}
              techniciansList={techniciansList}
              clinic_id={clinic.id}
              clinic_type={clinic.type}
              view="list"
              source={"home"}
              clinicServices={clinicServices.content}
            />
          </Modal.Body>
        </Modal>
      </Row>
    );
  }
};

export default Home;
