import React from "react";
import styles from "../index.module.css";
import Card from "./Card";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { updateAppointmentStatus } from "../../../../actions/clinic/clinic";
import { useDispatch, useSelector } from "react-redux";
import Inter from "../../../../common/constants/internationalization";

const AppointmentsToConfirm = ({ unconfirmedAppointments }) => {
  const dispatch = useDispatch();

  const { user_country } = useSelector(store => ({
    user_country: store.auth.user.country
  }));

  const confirmAppointment = ({ target: { value, id } }) => {
    dispatch(
      updateAppointmentStatus({ id: parseInt(id) })({
        id: parseInt(id),
        status: 2
      })
    );
  };

  const cancelAppointment = ({ target: { value, id } }) => {
    dispatch(
      updateAppointmentStatus({ id: parseInt(id) })({
        id: parseInt(id),
        status: 4
      })
    );
  };

  return (
    <Card title={"Citas por confirmar"}>
      {unconfirmedAppointments.map((appt, i) => (
        <Row className={styles.apptRow2} key={i}>
          {appt.appointment_id && (
            <div>
              <Col xs={5}>
                <div>
                  <Link to={`/agenda/${appt.appointment_id}`}>
                    {appt.first_name} {appt.last_name}
                  </Link>
                </div>
                <div className={styles.patientData}>
                  {moment(
                    new Date(appt.appointment_date).toLocaleString(
                      Inter[user_country].language,
                      {
                        timeZone: Inter[user_country].timeZone
                      }
                    )
                  ).format("lll")}
                </div>
              </Col>
              <Col xs={7}>
                <div>
                  <Button
                    className={"-dashboard"}
                    bsSize="small"
                    onClick={confirmAppointment}
                    id={appt.appointment_id}
                  >
                    Confirmar
                  </Button>
                  <Button
                    className={"-dashboard"}
                    bsSize="small"
                    onClick={cancelAppointment}
                    id={appt.appointment_id}
                  >
                    Cancelar
                  </Button>
                </div>
              </Col>
            </div>
          )}
        </Row>
      ))}
    </Card>
  );
};

export default AppointmentsToConfirm;
