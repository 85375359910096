import React from "react";
import styles from "./BodyWidget.module.css";

const BodyWidget = ({ children }) => {
  return (
    <div className="body ppx">
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default BodyWidget;
