import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import { withRouter } from "react-router-dom";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { editMode } from "../../../../actions/clinic/common";
import { updateClinicService } from "../../../../actions/clinic/clinic";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useDispatch, useSelector } from "react-redux";

const ClinicService = ({ service, clinic_id }) => {
  const dispatch = useDispatch();
  const update = updateClinicService(service);

  const { edit_mode, errors } = useSelector(state => ({
    edit_mode: state.clinic.account.editMode,
    errors: state.clinic.clinic.clinicTeam.errors
  }));

  const [formState, setFormState] = useState({
    ...service
  });

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdateService = () => {
    dispatch(
      update(
        {
          clinic_id: formState.clinic_id,
          service_id: formState.service_id,
          specialty_id: formState.specialty_id,
          sub_specialty_id: formState.sub_specialty_id,
          price: formState.price,
          description: formState.description
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Servicio de la clínica actualizado",
            message: "Se ha actualizado un servicio de la clínica exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló la actualización del servicio de la clínica",
            message:
              "No se pudo actualizar el servicio de la clínica, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}></Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Servicio</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} className={styles.toggleb}>
              <Toggle
                checked={edit_mode}
                icons={false}
                onChange={() => setEditMode()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="text"
                id="specialty_name"
                label="Especialización"
                value={formState.specialty_name}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={true}
              />
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="text"
                id="service_name"
                label="Nombre del servicio"
                value={formState.service_name}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={true}
              />
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FieldGroup
                type="text"
                id="price"
                label="Precio"
                value={formState.price}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={!edit_mode}
              />
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <FieldGroup
                type="textarea"
                id="description"
                label="Descripción"
                value={formState.description ? formState.description : ""}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                readOnly={!edit_mode}
              />
            </Col>
          </Row>
        </BodyWidget>
        <div className={styles.butArea}>
          <Button onClick={onUpdateService} disabled={!edit_mode}>
            Actualizar Servicio
          </Button>
        </div>
      </div>
      {/* Ends content */}
    </div>
  );
};

export default withRouter(ClinicService);
