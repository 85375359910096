import React, { Fragment } from "react";
import { Provider } from "react-redux";
// import { ToastContainer, Flip } from 'react-toastify';
// import geocode from 'react-geocode';
import store from "./store";
import MainLayout from "./views/layout/mainLayout";
// import 'react-toastify/dist/ReactToastify.css';
// import DictionaryProvider from './common/translations/DictionaryProvider';
// import { disableReactDevTools } from './common/functions/disableDevTools';

// window.GEOCODE = geocode;

class App extends React.Component {
  async componentDidMount() {
    // window.GEOCODE.setApiKey('AIzaSyAoldbWBmW88JZSqiNDW4cKDgIbHI_umco');
    /* if (process.env.NODE_ENV === 'production') {
      disableReactDevTools();
    } */
  }

  render() {
    return (
      <Fragment>
        <Provider store={store}>
          {/* <DictionaryProvider> */}
          {/*  <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
              transition={Flip}
            /> */}
          <MainLayout />
          {/* 
          </DictionaryProvider> */}
        </Provider>
      </Fragment>
    );
  }
}

export default App;
