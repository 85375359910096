import React, { useEffect, useState } from "react";
import { Row, Col, Button, Glyphicon, Modal } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import {
  updateClinic,
  requestClinicSpecialties,
  toggleClinicSpecialtyModal
} from "../../../../actions/clinic/clinic";
import { requestSpecialties } from "../../../../actions/specialties/specialties";
import history from "../../../../history";
import ClinicSpecialties from "./ClinicSpecialties";
import AddClinicSpecialty from "./AddClinicSpecialty";
import Inter from "../../../../common/constants/internationalization";

const clinicTypes = [
  {
    value: 1,
    text: "Individual"
  },
  {
    value: 2,
    text: "Centro medico"
  }
];

const ClinicInfo = ({ clinic }) => {
  const dispatch = useDispatch();
  const update = updateClinic(clinic);

  const {
    edit_mode,
    errors,
    specialties,
    clinicSpecialties,
    toggle_modal,
    user_country
  } = useSelector(store => ({
    edit_mode: store.clinic.clinic.editMode,
    errors: store.clinic.account.clinics.errors,
    specialties: store.specialties.specialties,
    clinicSpecialties: store.clinic.clinic.clinicSpecialties,
    toggle_modal: store.clinic.clinic.toggleSpecialtyModal,
    user_country: store.auth.user.country
  }));

  const [formState, setFormState] = useState({
    ...clinic
  });

  useEffect(() => {
    if (clinic.id) {
      const pathname = history.location.pathname;
      if (clinic.id != formState.id) {
        if (pathname === "/clinic") {
          history.push(`/clinic/basic-info`);
        } else {
          history.push(`/clinic`);
        }
      }
      dispatch(requestSpecialties());
      dispatch(requestClinicSpecialties({ clinic_id: clinic.id }));
    }
  }, [clinic.id]);

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdateClinic = () => {
    dispatch(
      update(
        {
          id: clinic.id,
          name: formState.name,
          phone_number: formState.phone_number.replace(/_/g, ""),
          address_1: formState.address_1,
          city: formState.city,
          type: formState.type
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Clínica actilizada",
            message:
              "Se ha actualizado la información básica de la clínica exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló la actualización de la clínica",
            message:
              "No se pudo actualizar la información básica de la clínica, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const toggleAddClinicSpecialtyModal = () =>
    dispatch(toggleClinicSpecialtyModal);

  // const specialtiesKeys = Object.keys(clinicSpecialties.content)

  //TODO Removing clinic specialities in the list (No needed, will check it on validations)
  /* const specialtiesList = specialties.content && specialties.content.filter(function(element) {
		return !specialtiesKeys.includes(element.id.toString())
  }) */
  const specialtiesList = specialties.content;

  if (!clinicSpecialties.loaded || !specialties.loaded || !specialtiesList) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Información básica</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <FieldGroup
                  type="text"
                  id="name"
                  label="Nombre de clínica "
                  readOnly={!edit_mode}
                  errors={errors}
                  value={formState.name}
                  placeholder="Nombre de clínica "
                  onChange={onChange}
                  helpClassName={"requiredField"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="select"
                  disabled={!edit_mode}
                  selectOptions={clinicTypes}
                  selectMap={clinicType => (
                    <option
                      key={clinicType.value.toString()}
                      value={clinicType.value}
                    >
                      {" "}
                      {clinicType.text}{" "}
                    </option>
                  )}
                  id="type"
                  label="Tipo de clínica"
                  value={formState.type}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={"requiredField"}
                />
                <FieldGroup
                  type="text"
                  id="address_1"
                  label="Dirección"
                  readOnly={!edit_mode}
                  errors={errors}
                  value={formState.address_1}
                  placeholder=""
                  onChange={onChange}
                  helpClassName={"requiredField"}
                />
              </Col>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="masked"
                  mask={Inter[user_country].phone_format}
                  id="phone_number"
                  label="Teléfono"
                  readOnly={!edit_mode}
                  errors={errors}
                  value={formState.phone_number}
                  onChange={onChange}
                  helpClassName={"requiredField"}
                />
                <FieldGroup
                  type="text"
                  id="city"
                  label="Ciudad"
                  readOnly={!edit_mode}
                  errors={errors}
                  value={formState.city}
                  placeholder=""
                  onChange={onChange}
                  helpClassName={"requiredField"}
                />
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={onUpdateClinic} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>

        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}>
            <div className={styles.actionBut}>
              <Button bsSize="small" onClick={toggleAddClinicSpecialtyModal}>
                <Glyphicon glyph="plus" /> Agregar especialización
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <Modal
              show={toggle_modal}
              onHide={toggleAddClinicSpecialtyModal}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Agregar especialización de la clínica</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddClinicSpecialty
                  specialtiesList={specialtiesList}
                  specialties={specialties.content}
                  clinic_id={clinic.id}
                />
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        {/* Ends section for buttons */}

        <div className="h3-section">
          <h3>Especialidades</h3>
          <BodyWidget>
            {/* 						<Row>
							<Col xs={12} className={styles.toggleb}>
								<Toggle
									checked={edit_mode}
									icons={false}
									onChange={() => setEditMode()}
								/>
							</Col>
						</Row> */}
            <ClinicSpecialties
              clinic_specialties={clinicSpecialties.content}
              specialties={specialties.content}
              edit_mode={edit_mode}
            />
          </BodyWidget>
        </div>
        {/* Ends content */}
      </div>
    );
  }
};

export default ClinicInfo;
