import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import MonthlySummary from "./components/MonthlySummary";
import IncomeByServices from "./components/IncomeByServices";
import Last6MonthsSummary from "./components/Last6MonthsSummary";
import { requestFinancialData } from "../../../actions/clinic/clinic";

const Finance = () => {
  const dispatch = useDispatch();

  const { clinics, active_clinic, clinicFinancialData } = useSelector(
    store => ({
      clinics: store.clinic.account.clinics.content,
      active_clinic: store.clinic.account.activeClinic,
      clinicFinancialData: store.clinic.clinic.clinicFinancialData
    })
  );

  useEffect(() => {
    if (active_clinic) {
      dispatch(requestFinancialData({ clinic_id: active_clinic }));
    }
  }, [active_clinic]);

  // const toggleCLinicPatienModalModal = () => dispatch(toggleClinicPatientModal);

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  if (_.isEmpty(clinic) || !clinicFinancialData.loaded)
    return <div> Loading... </div>;
  else
    return (
      <div>
        <div className={"top-nav"}>
          <div className={"container"}>
            <div className={"brand"}>{clinic.name}</div>
          </div>
        </div>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            {/* <NotifBox side="patients" view="patients" /> */}
          </Col>
        </Row>
        <div className="body-area -slim">
          <div className="site-header">
            <h1>Finanzas</h1>
            {/* <p>Clic sobre los enlaces para ver los detalles.</p> */}
          </div>
          <div className={styles.newProperty}>
            {/* <div className={styles.addNew}>
              <Button
                className={"push-button -white"}
                bsSize="small"
                //onClick={toggleCLinicPatienModalModal}
              >
                Agregar paciente
              </Button>
            </div> */}
          </div>
          <MonthlySummary
            data={clinicFinancialData.content.appointmentsMonthSummary}
          />
          {clinicFinancialData.content.appointmentsSummary.length > 1 && (
            <IncomeByServices
              data={clinicFinancialData.content.icomeByServicesSummary}
            />
          )}
          {clinicFinancialData.content.appointmentsSummary.length > 1 && (
            <Last6MonthsSummary
              data={clinicFinancialData.content.appointmentsSummary}
            />
          )}
        </div>
      </div>
    );
};

export default Finance;
