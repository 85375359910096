import React from "react";
import styles from "./LeftMenu.module.css";
import SideNav from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { withRouter } from "react-router-dom";
import NavHeader from "./NavHeader";
import NavItem from "./NavItem";
import { isMobile } from "react-device-detect";
import uuid from "uuid/v4";
import PropTypes from "prop-types";
import MenuConfiguration from "./MenuConfiguration";

function LeftMenu({ role, expanded, history, userId }) {
  const navStyle = {
    paddingTop: "50px"
  };

  if (isMobile) {
    const classNames = expanded
      ? styles.sideNavContainer
      : [styles.sideNavContainer, styles.hidden].join(" ");

    return (
      <div>
        <SideNav expanded={expanded} className={classNames} style={navStyle}>
          <SideNav.Nav defaultSelected={MenuConfiguration[role].default}>
            {MenuConfiguration[role].items.map(({ type, ...item }) => {
              if (type === "NavHeader")
                return <NavHeader expanded={expanded} key={uuid()} {...item} />;
              else if (type === "NavItem")
                return (
                  <NavItem
                    expanded={expanded}
                    currLocation={history.location.pathname}
                    key={uuid()}
                    {...item}
                    userId={userId}
                  />
                );
              else return null;
            })}
          </SideNav.Nav>
        </SideNav>
      </div>
    );
  } else {
    return (
      <div>
        <SideNav
          expanded={expanded}
          className={styles.sideNavContainer}
          style={navStyle}
          onToggle={() => null}
        >
          <SideNav.Nav defaultSelected={MenuConfiguration[role].default}>
            {MenuConfiguration[role].items.map(({ type, ...item }) => {
              if (type === "NavHeader")
                return <NavHeader expanded={expanded} key={uuid()} {...item} />;
              else if (type === "NavItem")
                return (
                  <NavItem
                    expanded={expanded}
                    currLocation={history.location.pathname}
                    key={uuid()}
                    {...item}
                    userId={userId}
                  />
                );
              else return null;
            })}
          </SideNav.Nav>
        </SideNav>
      </div>
    );
  }
}
LeftMenu.propTypes = {
  role: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  history: PropTypes.object
};

export default withRouter(LeftMenu);
