import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./AddClinicSpecialty.module.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import { addClinicService } from "../../../../actions/clinic/clinic";
import { toggleClinicServiceModal } from "../../../../actions/clinic/clinic";
import { useDispatch, useSelector } from "react-redux";

const AddClinicService = ({ clinic_id, specialtiesList, specialties }) => {
  const dispatch = useDispatch();
  const create_clinic_service = addClinicService();

  const { errors } = useSelector(store => ({
    errors: store.clinic.clinic.clinicServices.errors
  }));

  const [formState, setFormState] = useState({
    clinic_id: clinic_id,
    specialty_id: null,
    sub_specialty_id: null,
    service_id: null,
    price: null,
    description: ""
  });

  const [specialtyState, setSpecialtyState] = useState({});

  const [servicesListState, setServicesListState] = useState([]);

  const onChangeSpecialty = ({ target: { value, id } }) => {
    setFormState({ ...formState, specialty_id: parseInt(value) });

    const [specialtySelected] = specialties.filter(
      specialty => specialty.id === parseInt(value)
    );

    setSpecialtyState(specialtySelected);

    setServicesListState(specialtySelected.services);
  };

  const onChangeSubSpecialty = ({ target: { value, id } }) => {
    setFormState({ ...formState, sub_specialty_id: parseInt(value) });

    const [subSpecialtySelected] = specialtyState.sub.filter(
      specialty => specialty.id === parseInt(value)
    );

    setServicesListState(subSpecialtySelected.services);
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState({
      ...formState,
      [id]: value
    });
  };

  const onAddClinicSpecialty = () => {
    let clinicService;
    if (
      formState.specialty_id &&
      specialties.find(({ id }) => id === formState.specialty_id).sub.length > 0
    ) {
      clinicService = {
        clinic_id: formState.clinic_id,
        specialty_id: formState.specialty_id,
        sub_specialty_id: formState.sub_specialty_id
          ? formState.sub_specialty_id
          : "",
        service_id: formState.service_id
          ? parseInt(formState.service_id)
          : null,
        price: formState.price ? parseFloat(formState.price) : null,
        description: formState.description
      };
    } else {
      clinicService = {
        clinic_id: formState.clinic_id,
        specialty_id: formState.specialty_id,
        service_id: formState.service_id
          ? parseInt(formState.service_id)
          : null,
        price: formState.price ? parseFloat(formState.price) : null,
        description: formState.description
      };
    }

    dispatch(
      create_clinic_service(
        {
          ...clinicService
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Especialización de la clínica agregada",
            message: "Especialización de la clínica agregada exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló agregando especialización",
            message:
              "No se pudo agregar la especialización, por favor inténtelo nuevamente."
          }
        },
        { onFinishedAction: toggleClinicServiceModal }
      )
    );
  };

  return (
    <div>
      <Row>
        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="clinic" view="clinic_customization_add_modal" />
          </Col>
        </Row>
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12}>
              <FieldGroup
                type="select"
                selectFirstOption={
                  <option key="null" value={""} id="">
                    Seleccione una especialidad
                  </option>
                }
                selectOptions={specialtiesList}
                selectMap={clinicSpecialty => (
                  <option
                    key={clinicSpecialty.id.toString()}
                    value={clinicSpecialty.id}
                  >
                    {" "}
                    {clinicSpecialty.name}{" "}
                  </option>
                )}
                id="specialty_id"
                label="Especialización"
                value={formState.specialty_id ? formState.specialty_id : "null"}
                onChange={onChangeSpecialty}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
              />
              {formState.specialty_id > 0 &&
                specialties.find(({ id }) => id === formState.specialty_id).sub
                  .length > 0 && (
                  <FieldGroup
                    type="select"
                    selectFirstOption={
                      <option key="null" value={""} id="">
                        Seleccione una sub especialidad
                      </option>
                    }
                    selectOptions={
                      specialties.filter(
                        spec => spec.id === formState.specialty_id
                      )[0]
                        ? specialties.filter(
                            spec => spec.id === formState.specialty_id
                          )[0].sub
                        : []
                    }
                    selectMap={clinicSpecialty => (
                      <option
                        key={clinicSpecialty.id.toString()}
                        value={clinicSpecialty.id}
                      >
                        {" "}
                        {clinicSpecialty.name}{" "}
                      </option>
                    )}
                    id="sub_specialty_id"
                    label="Sub especialización"
                    value={
                      formState.sub_specialty_id
                        ? formState.sub_specialty_id
                        : "null"
                    }
                    onChange={onChangeSubSpecialty}
                    errors={errors}
                    helpClassName={"requiredField"}
                    isRequired
                  />
                )}
              {servicesListState.length > 0 && (
                <FieldGroup
                  type="select"
                  selectFirstOption={
                    <option key="null" value={""} id="">
                      Seleccione un servicio
                    </option>
                  }
                  selectOptions={servicesListState}
                  selectMap={clinicSpecialty => (
                    <option
                      key={clinicSpecialty.id.toString()}
                      value={clinicSpecialty.id}
                    >
                      {" "}
                      {clinicSpecialty.name}{" "}
                    </option>
                  )}
                  id="service_id"
                  label="Servicios"
                  value={formState.service_id ? formState.service_id : "null"}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={"requiredField"}
                  isRequired
                />
              )}
            </Col>
          </Row>
          {formState.service_id > 0 && (
            <div>
              <Row>
                <Col xs={12} sm={6}>
                  <FieldGroup
                    type="text"
                    id="price"
                    label="Precio"
                    errors={errors}
                    value={formState.price ? formState.price : ""}
                    placeholder="0"
                    onChange={onChange}
                    isRequired
                    helpClassName={"requiredField"}
                  />
                </Col>
                <Col xs={12} sm={6}></Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <FieldGroup
                    type="textarea"
                    id="description"
                    label="Descripción (Opcional)"
                    errors={errors}
                    value={formState.description ? formState.description : ""}
                    placeholder=""
                    onChange={onChange}
                    helpClassName={"requiredField"}
                  />
                </Col>
                <Col xs={12} sm={6}></Col>
              </Row>
            </div>
          )}
          <Row>
            <Col xs={12}>
              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  bsSize="small"
                  onClick={onAddClinicSpecialty}
                >
                  AGREGAR SERVICIO
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddClinicService;
