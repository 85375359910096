import React from "react";
import { HelpBlock } from "react-bootstrap";

export default ({ id, errorClassName, errors }) => {
  if (!errors || !errors[id]) return null;

  return errors[id].slice(0, 1).map((error, index) => (
    <HelpBlock key={`err-${index}`} className={errorClassName}>
      {error.message}
    </HelpBlock>
  ));
};
