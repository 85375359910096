export default [
  "#039BE5",
  "#4285F4",
  "#7986CB",
  "#B39DDB",
  "#9E69AF",
  "#AD1457",
  "#F4511E",
  "#E4C441",
  "#0B8043",
  "#3F51B5",
  "#8E24AA",
  "#D81B60",
  "#EF6C00",
  "#C0CA33",
  "#009688",
  "#795548",
  "#D50000",
  "#F09300",
  "#7CB342",
  "#616161",
  "#E67C73",
  "#F6BF26",
  "#33B679",
  "#A79B8E"
];
