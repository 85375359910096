import React from "react";
import styles from "../index.module.css";
import { Link } from "react-router-dom";

const Card = ({ children, title, footer, footerLink }) => {
  return (
    <div
      className={[styles.cardWrapper, styles.card, styles.cardSmall].join(" ")}
    >
      <div>
        <div className={styles.cardHeader}>{title}</div>
        <div className={styles.cardBody}>{children}</div>
        <div className={styles.cardFooter}>
          {footerLink ? <Link to={footerLink}> {footer}</Link> : ""}
        </div>
      </div>
    </div>
  );
};

export default Card;
