import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./AddPatient.module.css";
import "react-accessible-accordion/dist/fancy-example.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import NotifBox from "../../../../components/Layout/NotifBox/NotifBox";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleClinicPatientModal,
  requestClinicDoctors,
  requestClinicTechnicians,
  setIsLoading
} from "../../../../actions/clinic/clinic";
import {
  addClinicPatient,
  addClinicPatientAndAppointment
} from "../../../../actions/clinic/clinic";
import blue_spinner from "../../../../static/img/gif/blue_spinner.gif";
import Inter from "../../../../common/constants/internationalization";

const loadingSpinner = <img src={blue_spinner} alt="spinner" width="30px" />;

const customerTypes = [
  {
    value: 1,
    text: "Independiente"
  },
  {
    value: 2,
    text: "Asegurado"
  },
  {
    value: 3,
    text: "Empresarial"
  }
];

const genders = [
  {
    value: "M",
    text: "Masculino"
  },
  {
    value: "F",
    text: "Femenino"
  }
];

//TODO: make const
const duration = [
  {
    value: 15,
    text: "15"
  },
  {
    value: 30,
    text: "30"
  },
  {
    value: 45,
    text: "45"
  }
];

//TODO: Make global
const statusList = [
  {
    value: 1,
    text: "Pendiente"
  },
  {
    value: 2,
    text: "Confirmada"
  },
  {
    value: 3,
    text: "Finalizada"
  },
  {
    value: 4,
    text: "Cancelada"
  },
  {
    value: 5,
    text: "Pagada"
  }
];

const AddPatient = ({ clinic, settings, source, clinicServices }) => {
  const dispatch = useDispatch();
  const create_new_patient = addClinicPatient(clinic);
  const create_new_patient_and_appointment = addClinicPatientAndAppointment(
    clinic
  );

  const {
    active_clinic,
    doctors,
    technicians,
    errors,
    user_country,
    loading
  } = useSelector(store => ({
    active_clinic: store.clinic.account.activeClinic,
    doctors: store.clinic.clinic.clinicDoctors.content,
    technicians: store.clinic.clinic.clinicTechnicians.content,
    errors: store.clinic.clinic.clinicPatients.errors,
    loading: store.clinic.clinic.loading,
    user_country: store.auth.user.country
  }));

  useEffect(() => {
    dispatch(requestClinicDoctors({ clinic_id: active_clinic }));
    dispatch(requestClinicTechnicians({ clinic_id: active_clinic }));
  }, [active_clinic]);

  const [formState, setFormState] = useState({
    patient: {
      first_name: "",
      middle_name: "",
      last_name: "",
      type: 1,
      emails: "",
      phones: "",
      gender: "M",
      dob: "",
      allergies: ""
    },
    doctor_id: null,
    technician_id: null,
    appointment: {
      appointment_date: null,
      patient_id: 0,
      patient: "",
      duration: settings.appointment_avg_duration,
      doctor_id: null,
      technician_id: null,
      comments: "",
      video_appointment_id: null,
      status: null
    },
    service: {
      service_id: null,
      clinic_service_id: null,
      price: null
    }
  });

  const [createAppointmentState, setCreateAppointmentState] = useState(false);

  const [docOrTechValidationState, setDocOrTechValidationState] = useState(
    false
  );

  const onChange = ({ target: { value, id } }) => {
    if (id.indexOf(".") > -1) {
      const [parent_key, key] = id.split(".");
      setFormState(oldState => ({
        ...oldState,
        [parent_key]: {
          ...oldState[parent_key],
          [key]: value
        }
      }));
    } else
      setFormState(oldState => ({
        ...oldState,
        [id]: value
      }));
  };

  const onCreateAppointment = () => {
    setCreateAppointmentState(!createAppointmentState);
  };

  const addPatient = () => {
    let emailsArray = formState.patient.emails.replace(/\s/g, "");
    let phonesArray = formState.patient.phones.replace(/\s/g, "");
    let allergiesArray = formState.patient.allergies.replace(/\s/g, "");

    const newPatient = {
      first_name: formState.patient.first_name,
      middle_name: formState.patient.middle_name,
      last_name: formState.patient.last_name,
      type: parseInt(formState.patient.type),
      emails: emailsArray === "" ? [] : emailsArray.split(","),
      phones: phonesArray === "" ? [] : phonesArray.split(","),
      gender: formState.patient.gender,
      allergies: allergiesArray === "" ? [] : allergiesArray.split(","),
      dob: new Date(formState.patient.dob)
    };

    if (createAppointmentState) {
      if (_.isNull(formState.doctor_id) && _.isNull(formState.technician_id)) {
        setDocOrTechValidationState(true);
      } else {
        setDocOrTechValidationState(false);
        let date = new Date(formState.appointment.appointment_date);

        const newAppointment = {
          appointment_date:
            _.isDate(formState.appointment.appointment_date) &&
            !_.isNull(formState.appointment.appointment_date)
              ? date
              : null,
          duration: formState.appointment.duration,
          comments: formState.appointment.comments,
          clinic_id: active_clinic,
          video_appointment_id: formState.video_appointment_id,
          status: formState.status
        };
        dispatch(
          create_new_patient_and_appointment(
            {
              patient: newPatient,
              doctor_id:
                formState.doctor_id === "" ? null : formState.doctor_id,
              technician_id:
                formState.technician_id === "" ? null : formState.technician_id,
              appointment: newAppointment,
              service: { ...formState.service }
            },
            {
              onSuccessMessage: {
                type: "success",
                view: source ? source : "patients",
                title: "Nuevo paciente creado y cita agendada",
                message:
                  "Se ha creado un nuevo paciente de la clínica y se agendo la cita exitosamente."
              },
              onFailedMessage: {
                type: "error",
                view: source ? source : "patients",
                title:
                  "Falló la creación del nuevo paciente o tratando de agendar la cita",
                message:
                  "No se pudo crear el nuevo paciente ni agendar la cita, por favor intente nuevamente."
              }
            },
            { onFinishedAction: toggleClinicPatientModal }
          )
        );
      }
    } else {
      dispatch(
        create_new_patient(
          {
            patient: newPatient,
            doctor_id: formState.doctor_id === "" ? null : formState.doctor_id,
            technician_id:
              formState.technician_id === "" ? null : formState.technician_id
          },
          {
            onSuccessMessage: {
              type: "success",
              view: source ? source : "patients",
              title: "Nuevo paciente creado",
              message:
                "Se ha creado un nuevo paciente de la clínica exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: source ? source : "patients",
              title: "Falló la creación del nuevo paciente",
              message:
                "No se pudo crear nuevo paciente, por favor intente nuevamente."
            }
          },
          { onFinishedAction: toggleClinicPatientModal }
        )
      );
    }
  };

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.doctor_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.technician_id,
      text: technician.first_name + " " + technician.last_name
    }));

  const onIsVideoAppointment = () => {
    setFormState(oldState => ({
      ...oldState,
      video_appointment_id: !oldState.video_appointment_id ? "undefined" : null
    }));
  };

  const onChangeService = ({ target: { value, id } }) => {
    if (id === "service.service_id") {
      if (value === "Seleccione uno" || !value) {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            clinic_service_id: null,
            price: null,
            service_id: null
          }
        }));
      } else {
        const [service] = clinicServices.filter(
          service => service.service_id === parseInt(value)
        );
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            clinic_service_id: service.id,
            price: service.price,
            service_id: parseInt(value)
          }
        }));
      }
    } else {
      if (value === "" || !value) {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            price: 0
          }
        }));
      } else {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            price: parseFloat(value)
          }
        }));
      }
    }
  };

  const servicesList =
    clinicServices &&
    clinicServices.map(service => ({
      value: service.service_id,
      text: service.service_name + " - " + service.specialty_name
    }));

  return (
    <div>
      <Row>
        <Col xs={12}>
          <NotifBox side="clinic" view="patients-add-modal" />
        </Col>
      </Row>
      {errors && errors.error && (
        <Row>
          <Col xs={12} className={"alert alert-danger"}>
            Hay una cita agendada a la misma hora para este doctor o técnico.
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="patient.first_name"
                label="Primer nombre"
                value={formState.patient.first_name}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                placeholder="Juan"
                isRequired
                tabIndex={1}
              />
              <FieldGroup
                type="select"
                selectOptions={genders}
                selectMap={gender => (
                  <option key={gender.value.toString()} value={gender.value}>
                    {" "}
                    {gender.text}{" "}
                  </option>
                )}
                id="patient.gender"
                label="Genero"
                value={formState.patient.gender}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
                tabIndex={4}
              />
              <FieldGroup
                type="text"
                id="patient.emails"
                label="Correos electrónicos"
                placeholder="juan@email.com, jose@email.com"
                value={formState.patient.emails}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                placeholder="juan@mail.com"
                tabIndex={7}
              />
              <FieldGroup
                type="select"
                selectOptions={doctorsList ? doctorsList : []}
                selectFirstOption={<option value={""}>Seleccione uno</option>}
                selectMap={doctor => (
                  <option key={doctor.value.toString()} value={doctor.value}>
                    {" "}
                    {doctor.text}{" "}
                  </option>
                )}
                id="doctor_id"
                label="Doctor"
                value={formState.doctor_id ? formState.doctor_id : ""}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={10}
              />
              {docOrTechValidationState && (
                <div className={"requiredField"}>
                  Se debe seleccionar un doctor o técnico
                </div>
              )}
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="patient.middle_name"
                label="Segundo nombre"
                value={formState.patient.middle_name}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                placeholder="Juan"
                tabIndex={2}
              />
              <FieldGroup
                type="select"
                selectOptions={customerTypes}
                selectMap={customerType => (
                  <option
                    key={customerType.value.toString()}
                    value={customerType.value}
                  >
                    {" "}
                    {customerType.text}{" "}
                  </option>
                )}
                id="patient.type"
                label="Tipo"
                value={formState.patient.type}
                onChange={onChange}
                isRequired
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={5}
              />
              <FieldGroup
                type="text"
                id="patient.phones"
                label="Teléfonos"
                placeholder="2222-2222, 7777-7777"
                value={formState.patient.phones}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={8}
              />
              <FieldGroup
                type="select"
                selectOptions={techniciansList ? techniciansList : []}
                selectFirstOption={<option value={""}>Seleccione uno</option>}
                selectMap={technician => (
                  <option
                    key={technician.value.toString()}
                    value={technician.value}
                  >
                    {" "}
                    {technician.text}{" "}
                  </option>
                )}
                id="technician_id"
                label="Licenciado o Técnico"
                value={formState.technician_id ? formState.technician_id : ""}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={11}
              />
            </Col>
            <Col xs={12} md={4}>
              <FieldGroup
                type="text"
                id="patient.last_name"
                label="Apellido(s)"
                value={formState.patient.last_name}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                placeholder="Perez"
                isRequired
                tabIndex={3}
              />
              <FieldGroup
                type="date"
                id="patient.dob"
                label="Fecha de naciemiento"
                value={formState.patient.dob}
                onChange={onChange}
                placeholder="Seleccione una fecha"
                errors={errors}
                helpClassName={"requiredField"}
                isRequired
                tIndex={6}
              />
              <FieldGroup
                type="text"
                id="patient.allergies"
                label="Alergias"
                placeholder="Polen, ácaros"
                value={formState.patient.allergies}
                onChange={onChange}
                errors={errors}
                helpClassName={"requiredField"}
                tabIndex={9}
              />
              <div className={styles.optionsContainer}>
                <FieldGroup
                  type="checkbox"
                  id="settings.accepted_payment_methods_ach"
                  value={createAppointmentState}
                  onChange={onCreateAppointment}
                  errors={errors}
                  helpClassName={"requiredField"}
                  tabIndex={12}
                >
                  {" "}
                  Agendar cita (Opcional)
                </FieldGroup>
              </div>
            </Col>
          </Row>
          <div style={{ display: createAppointmentState ? "inline" : "none" }}>
            <Row>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="date"
                  id="appointment.appointment_date"
                  label="Fecha de la cita"
                  value={formState.appointment.appointment_date}
                  onChange={onChange}
                  placeholder="Seleccione una fecha"
                  errors={errors}
                  helpClassName={"requiredField"}
                  isRequired
                  tIndex={4}
                  showTimeSelect={true}
                  dateFormat="dd/MM/yyyy h:mm aa"
                />
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col sm={4}>
                    <FieldGroup
                      type="select"
                      className={styles.timeField}
                      selectOptions={duration}
                      selectMap={minute => (
                        <option
                          key={minute.value.toString()}
                          value={minute.value}
                        >
                          {" "}
                          {minute.text}{" "}
                        </option>
                      )}
                      id="appointment.duration"
                      label="Duración"
                      value={formState.appointment.duration}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                      isRequired
                      tabIndex={6}
                    />
                  </Col>
                  <Col sm={8}>
                    <FieldGroup
                      type="select"
                      selectOptions={statusList}
                      selectFirstOption={
                        <option value={null}>Sin definir</option>
                      }
                      selectMap={status => (
                        <option
                          key={status.value.toString()}
                          value={status.value}
                        >
                          {" "}
                          {status.text}{" "}
                        </option>
                      )}
                      id="appointment.status"
                      label="Estado inicial"
                      value={formState.status || null}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FieldGroup
                  type="select"
                  selectOptions={servicesList ? servicesList : []}
                  selectFirstOption={<option>Seleccione uno</option>}
                  selectMap={service => (
                    <option
                      key={service.value.toString()}
                      value={service.value}
                    >
                      {" "}
                      {service.text}{" "}
                    </option>
                  )}
                  id="service.service_id"
                  label="Servicio a brindar"
                  value={
                    formState.service.service_id
                      ? formState.service.service_id
                      : ""
                  }
                  onChange={onChangeService}
                  errors={errors}
                  helpClassName={"requiredField"}
                  tabIndex={7}
                  isRequired
                />
              </Col>
              {formState.service.service_id && (
                <Col sm={3}>
                  <FieldGroup
                    type="text"
                    id="service.price"
                    label={`Precio (${Inter[user_country].currency})`}
                    value={
                      formState.service.price ? formState.service.price : ""
                    }
                    onChange={onChangeService}
                    errors={errors}
                    helpClassName={"requiredField"}
                    tabIndex={8}
                    isRequired
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12}>
                <FieldGroup
                  type="textarea"
                  id="appointment.comments"
                  label="Comentarios"
                  value={formState.appointment.comments}
                  onChange={onChange}
                  errors={errors}
                  helpClassName={"requiredField"}
                  tabIndex={7}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FieldGroup
                  type="checkbox"
                  id="video_appointment_id"
                  className={styles.checkboxRequired}
                  value={formState.video_appointment_id}
                  onClick={onIsVideoAppointment}
                  helpClassName={"requiredField"}
                >
                  <span>Video consulta</span>
                </FieldGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} md={12}>
              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  bsSize="small"
                  onClick={addPatient}
                  tabIndex={13}
                >
                  {createAppointmentState
                    ? "AGREGAR PACIENTE Y AGENDAR CITA"
                    : "AGREGAR PACIENTE"}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddPatient;
