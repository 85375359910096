import React from "react";
import styles from "./Warn.module.css";
import PropTypes from "prop-types";

const Warn = ({ closeToast, title, message }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

Warn.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default Warn;
