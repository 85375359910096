import { FETCH_PATIENT_VIRTUAL_APPOINTMENT_TOKEN } from "../../actions/virtual-appointment/";
import { isRelatedAction } from "../utils";
import { pristine, reduce } from "../utils";

var defaultState = {
  appointmentToken: pristine({})
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_PATIENT_VIRTUAL_APPOINTMENT_TOKEN)) {
    return reduce(
      state,
      "appointmentToken",
      action,
      FETCH_PATIENT_VIRTUAL_APPOINTMENT_TOKEN
    );
  }

  return state;
}
