import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "./Agenda.module.css";
import FieldGroup from "../../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointment,
  addAppointmentOnAgendaView,
  toggleAppointmentModal,
  toggleAppointmentModalOnAgenda,
  toggleAppointmentModalOnCalendar,
  setIsLoading
} from "../../../../../actions/clinic/clinic";
import _ from "lodash";
import blue_spinner from "../../../../../static/img/gif/blue_spinner.gif";
import Inter from "../../../../../common/constants/internationalization";

const loadingSpinner = <img src={blue_spinner} alt="spinner" width="30px" />;

//TODO: make const
const duration = [
  {
    value: 15,
    text: "15"
  },
  {
    value: 30,
    text: "30"
  },
  {
    value: 45,
    text: "45"
  }
];

//TODO: Make global
const statusList = [
  {
    value: 1,
    text: "Pendiente"
  },
  {
    value: 2,
    text: "Confirmada"
  },
  {
    value: 3,
    text: "Finalizada"
  },
  {
    value: 4,
    text: "Cancelada"
  },
  {
    value: 5,
    text: "Pagada"
  }
];

const AddAppointment = ({
  date,
  settings,
  doctorsList,
  techniciansList,
  clinic_id,
  view,
  clinic_type,
  source,
  clinicServices
}) => {
  const dispatch = useDispatch();
  const create_new_appointment = addAppointment();
  const create_new_appointment_on_agenda = addAppointmentOnAgendaView();

  const [formState, setFormState] = useState({
    appointment: {
      appointment_date: date ? new Date(date) : new Date().setHours(0, 0, 0, 0),
      patient_id: 0,
      patient: "",
      duration: settings.appointment_avg_duration,
      doctor_id:
        clinic_type === 1 && doctorsList.length > 0 ? doctorsList[0].value : 0,
      technician_id: 0,
      comments: "",
      video_appointment_id: null,
      status: null
    },
    service: {
      service_id: null,
      clinic_service_id: null,
      price: null
    }
  });

  const [docOrTechValidationState, setDocOrTechValidationState] = useState(
    false
  );

  const { patients, errors, user_country, loading } = useSelector(store => ({
    patients: store.clinic.clinic.clinicPatients.content,
    errors: store.clinic.clinic.clinicAppointments.errors,
    loading: store.clinic.clinic.loading,
    user_country: store.auth.user.country
  }));

  //TODO: it may be slow think about server side
  const suggestions =
    patients &&
    patients.map(patient =>
      !patient.middle_name || patient.middle_name === ""
        ? patient.first_name + " " + patient.last_name + " - " + patient.id
        : patient.first_name +
          " " +
          patient.middle_name +
          " " +
          patient.last_name +
          " - " +
          patient.id
    );

  const addNewAppointment = () => {
    if (
      formState.appointment.doctor_id === 0 &&
      formState.appointment.technician_id === 0
    ) {
      setDocOrTechValidationState(true);
    } else {
      setDocOrTechValidationState(false);
      let date = new Date(formState.appointment.appointment_date);

      const appointment = {
        appointment_date:
          _.isDate(formState.appointment.appointment_date) &&
          !_.isNull(formState.appointment.appointment_date)
            ? date
            : null,
        patient_id: _.isNaN(formState.appointment.patient_id)
          ? null
          : formState.appointment.patient_id,
        duration: formState.appointment.duration,
        doctor_id:
          formState.appointment.doctor_id === 0
            ? null
            : formState.appointment.doctor_id,
        technician_id:
          formState.appointment.technician_id === 0
            ? null
            : formState.appointment.technician_id,
        comments: formState.appointment.comments,
        clinic_id: clinic_id,
        video_appointment_id: formState.appointment.video_appointment_id,
        status: formState.status
      };

      if (view === "agenda" || view === "calendar") {
        //dispatch(setIsLoading);
        dispatch(
          create_new_appointment_on_agenda(
            {
              appointment: { ...appointment },
              service: { ...formState.service }
            },
            {
              onSuccessMessage: {
                type: "success",
                view: source ? source : "appointments",
                title: "Nuevo cita creada",
                message: "Se ha creado una nueva cita exitosamente."
              },
              onFailedMessage: {
                type: "error",
                view: source ? source : "appointments",
                title: "Falló la creación de la cita",
                message:
                  "No se pudo crear la nueva cita, asegúrese de que no exista una cita ya establecida para la hora deseada."
              }
            },
            {
              onFinishedAction:
                view === "agenda"
                  ? toggleAppointmentModalOnAgenda
                  : toggleAppointmentModalOnCalendar
            }
          )
        );
      } else {
        //dispatch(setIsLoading);
        dispatch(
          create_new_appointment(
            {
              appointment: { ...appointment },
              service: { ...formState.service }
            },
            {
              onSuccessMessage: {
                type: "success",
                view: source ? source : "appointments",
                title: "Nuevo cita creada",
                message: "Se ha creado una nueva cita exitosamente."
              },
              onFailedMessage: {
                type: "error",
                view: source ? source : "appointments",
                title: "Falló la creación de la cita",
                message:
                  "No se pudo crear la nueva cita, asegúrese de que no exista una cita ya establecida para la hora deseada."
              }
            },
            { onFinishedAction: toggleAppointmentModal }
          )
        );
      }
    }
  };

  const onChange = ({ target: { value, id } }) => {
    if (id.indexOf(".") > -1) {
      const [parent_key, key] = id.split(".");
      setFormState(oldState => ({
        ...oldState,
        [parent_key]: {
          ...oldState.appointment,
          [key]:
            id === "appointment.duration" || id === "appointment.doctor_id"
              ? parseFloat(value)
              : value
        }
      }));
    }
  };

  const onChangePatient = ({ target: { value, id } }) => {
    let value_parts = value.split(" ");
    setFormState(oldState => ({
      ...oldState,
      appointment: {
        ...oldState.appointment,
        patient_id: parseInt(value_parts[value_parts.length - 1]),
        patient: value
      }
    }));
  };

  const onChangeService = ({ target: { value, id } }) => {
    if (id === "service.service_id") {
      if (value === "Seleccione uno" || !value) {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            clinic_service_id: null,
            price: null,
            service_id: null
          }
        }));
      } else {
        const [service] = clinicServices.filter(
          service => service.service_id === parseInt(value)
        );
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            clinic_service_id: service.id,
            price: service.price,
            service_id: parseInt(value)
          }
        }));
      }
    } else {
      if (value === "" || !value) {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            price: 0
          }
        }));
      } else {
        setFormState(oldState => ({
          ...oldState,
          service: {
            ...oldState.service,
            price: parseFloat(value)
          }
        }));
      }
    }
  };

  const onIsVideoAppointment = () => {
    setFormState(oldState => ({
      ...oldState,
      appointment: {
        ...oldState.appointment,
        video_appointment_id: !oldState.video_appointment_id
          ? "undefined"
          : null
      }
    }));
  };

  const servicesList =
    clinicServices &&
    clinicServices.map(service => ({
      value: service.service_id,
      text: service.service_name + " - " + service.specialty_name
    }));

  if (doctorsList.length === 0 && techniciansList.length === 0) {
    return (
      <div className={styles.errorMessage}>
        Lo sentimos, no pudimos encontrar doctores o técnicos en tu cuenta,
        asegurate de haber registrado al menos un doctor o técnico en tu
        clínica, si ya los has hecho contacta a nuestro equipo de soporte para
        ayudarte con este problema.
      </div>
    );
  } else {
    //TODO: Fix this
    if (1 > 1) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          {errors && errors.error && (
            <Row>
              <Col xs={12} className={"alert alert-danger"}>
                Hay una cita agendada a la misma hora para este doctor o
                técnico.
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <Col xs={12} md={12}>
                  <FieldGroup
                    type="autocomplete"
                    id="appointment.patient_id"
                    label="Paciente"
                    value={formState.appointment.patient}
                    onChange={onChangePatient}
                    errors={errors}
                    helpClassName={"requiredField"}
                    placeholder="Digite el nombre o id de un paciente registrado..."
                    suggestions={suggestions}
                    isRequired
                    tabIndex={1}
                  />
                </Col>
              </Row>
              {docOrTechValidationState && (
                <div className={"requiredField"}>
                  Se debe seleccionar un doctor o técnico
                </div>
              )}
              <Row>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="select"
                    selectOptions={doctorsList ? doctorsList : []}
                    selectFirstOption={
                      <option value={""}>Seleccione uno</option>
                    }
                    selectMap={doctor => (
                      <option
                        key={doctor.value.toString()}
                        value={doctor.value}
                      >
                        {" "}
                        {doctor.text}{" "}
                      </option>
                    )}
                    id="appointment.doctor_id"
                    label="Doctor"
                    value={formState.appointment.doctor_id}
                    onChange={onChange}
                    errors={errors}
                    helpClassName={"requiredField"}
                    tabIndex={2}
                  />
                  <FieldGroup
                    type="date"
                    id="appointment.appointment_date"
                    label="Fecha"
                    value={formState.appointment.appointment_date}
                    onChange={onChange}
                    placeholder="Seleccione una fecha"
                    errors={errors}
                    helpClassName={"requiredField"}
                    isRequired
                    tIndex={4}
                    showTimeSelect={true}
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FieldGroup
                    type="select"
                    selectOptions={techniciansList ? techniciansList : []}
                    selectFirstOption={<option>Seleccione uno</option>}
                    selectMap={technician => (
                      <option
                        key={technician.value.toString()}
                        value={technician.value}
                      >
                        {" "}
                        {technician.text}{" "}
                      </option>
                    )}
                    id="appointment.technician_id"
                    label="Licenciado o Técnico"
                    value={formState.appointment.technician_id}
                    onChange={onChange}
                    errors={errors}
                    helpClassName={"requiredField"}
                    tabIndex={3}
                  />
                  <Row>
                    <Col sm={4}>
                      <FieldGroup
                        type="select"
                        className={styles.timeField}
                        selectOptions={duration}
                        selectMap={minute => (
                          <option
                            key={minute.value.toString()}
                            value={minute.value}
                          >
                            {" "}
                            {minute.text}{" "}
                          </option>
                        )}
                        id="appointment.duration"
                        label="Duración"
                        value={formState.appointment.duration}
                        onChange={onChange}
                        errors={errors}
                        helpClassName={"requiredField"}
                        isRequired
                        tabIndex={6}
                      />
                    </Col>
                    <Col sm={8}>
                      <FieldGroup
                        type="select"
                        selectOptions={statusList}
                        selectFirstOption={
                          <option value={null}>Sin definir</option>
                        }
                        selectMap={status => (
                          <option
                            key={status.value.toString()}
                            value={status.value}
                          >
                            {" "}
                            {status.text}{" "}
                          </option>
                        )}
                        id="appointment.status"
                        label="Estado inicial"
                        value={formState.status || null}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <FieldGroup
                    type="select"
                    selectOptions={servicesList ? servicesList : []}
                    selectFirstOption={<option>Seleccione uno</option>}
                    selectMap={service => (
                      <option
                        key={service.value.toString()}
                        value={service.value}
                      >
                        {" "}
                        {service.text}{" "}
                      </option>
                    )}
                    id="service.service_id"
                    label="Servicio a brindar"
                    value={
                      formState.service.service_id
                        ? formState.service.service_id
                        : ""
                    }
                    onChange={onChangeService}
                    errors={errors}
                    helpClassName={"requiredField"}
                    tabIndex={7}
                    isRequired
                  />
                </Col>
                {formState.service.service_id && (
                  <Col sm={3}>
                    <FieldGroup
                      type="text"
                      id="service.price"
                      label={`Precio (${Inter[user_country].currency})`}
                      value={
                        formState.service.price ? formState.service.price : ""
                      }
                      onChange={onChangeService}
                      errors={errors}
                      helpClassName={"requiredField"}
                      tabIndex={8}
                      isRequired
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={12}>
                  <FieldGroup
                    type="textarea"
                    id="appointment.comments"
                    label="Comentarios"
                    value={formState.appointment.comments}
                    onChange={onChange}
                    errors={errors}
                    helpClassName={"requiredField"}
                    tabIndex={8}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FieldGroup
                    type="checkbox"
                    id="appointment.video_appointment_id"
                    className={styles.checkboxRequired}
                    value={formState.appointment.video_appointment_id}
                    onClick={onIsVideoAppointment}
                    helpClassName={"requiredField"}
                  >
                    <span>Video consulta</span>
                  </FieldGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  bsSize="small"
                  onClick={addNewAppointment}
                  tabIndex={8}
                >
                  Crear cita
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
};

export default AddAppointment;
