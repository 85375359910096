import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import { updateAppointment } from "../../../../actions/clinic/clinic";
import _ from "lodash";

const AppointmentResults = ({ appointment }) => {
  const dispatch = useDispatch();
  const update = updateAppointment({ id: appointment.appointment_id });

  const { errors, edit_mode } = useSelector(store => ({
    errors: store.clinic.clinic.clinicAppointments.errors,
    edit_mode: store.clinic.appointment.editMode
  }));

  const [formStatePostAppt, setFormStatePostAppt] = useState({
    diagnosis: appointment.diagnosis,
    observations: appointment.observations,
    medicines: appointment.medicines
  });

  const [showShortPEState, setShowShortPESState] = useState(false);

  const [shortPEState, setShortPESState] = useState("");

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdateAppointment = () => {
    let toUpdate = {
      id: appointment.appointment_id,
      patient_id: appointment.patient_id,
      doctor_id: appointment.doctor_id,
      technician_id: appointment.technician_id,
      appointment_date: appointment.appointment_date,
      diagnosis: formStatePostAppt.diagnosis,
      observations: showShortPEState
        ? formStatePostAppt.observations + shortPEState
        : formStatePostAppt.observations,
      medicines: formStatePostAppt.medicines
    };
    dispatch(
      update(
        {
          ...toUpdate
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Cita se ha actualizado",
            message:
              "Se ha actualizado la información básica de la cita exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló la actualización de la cita",
            message:
              "No se pudo actualizar la información básica de la cita, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onChangeAppointmentHistoy = ({ target: { value, id } }) => {
    setFormStatePostAppt(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const onAddShortPE = () => {
    let shortPES = "";
    if (!showShortPEState) {
      let isFirst = true;
      if (appointment.physical_examination.blood_pressure) {
        shortPES = `${shortPES} Presión Arterial: ${appointment.physical_examination.blood_pressure}`;
        isFirst = false;
      }
      if (appointment.physical_examination.heart_rate) {
        shortPES = `${shortPES}${isFirst ? " " : ", "}Frecuencia Cardíaca: ${
          appointment.physical_examination.heart_rate
        }`;
        isFirst = false;
      }
      if (appointment.physical_examination.respiratory) {
        shortPES = `${shortPES}${isFirst ? " " : ", "}Respiratoria: ${
          appointment.physical_examination.respiratory
        }`;
        isFirst = false;
      }
      setShortPESState(shortPES);
    } else {
      setShortPESState("");
    }

    setShowShortPESState(!showShortPEState);
  };

  return (
    <div className="h2-section -no-hide">
      {/* Starts section for buttons */}
      <Row className={styles.buttonsSection}>
        <Col xs={12}></Col>
      </Row>
      {/* Ends section for buttons */}

      {/* Starts content */}
      <div className="h3-section">
        <h3>Resultados de la consulta</h3>
        <BodyWidget>
          <Row>
            <Col xs={12} className={styles.toggleb}>
              <Toggle
                checked={edit_mode}
                icons={false}
                onChange={() => setEditMode()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <Col xs={12} md={12}>
                  <FieldGroup
                    type="textarea"
                    id="diagnosis"
                    label="Diagnóstico"
                    value={
                      formStatePostAppt.diagnosis
                        ? formStatePostAppt.diagnosis
                        : ""
                    }
                    onChange={onChangeAppointmentHistoy}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                    errors={errors}
                  />
                  <FieldGroup
                    type="textarea"
                    id="observations"
                    label="Observaciones"
                    value={
                      formStatePostAppt.observations
                        ? formStatePostAppt.observations
                        : ""
                    }
                    onChange={onChangeAppointmentHistoy}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                    errors={errors}
                  />
                  {edit_mode && !appointment.observations && (
                    <FieldGroup
                      type="checkbox"
                      id="required"
                      className={styles.checkboxShortPE}
                      value={showShortPEState}
                      onClick={onAddShortPE}
                      disabled={!edit_mode}
                      helpClassName={"requiredField"}
                    >
                      <span>
                        Incluir Presión Arterial, Frecuencia Cardíaca y
                        Respiratoria
                      </span>
                    </FieldGroup>
                  )}
                  <FieldGroup
                    type="textarea"
                    id="medicines"
                    label="Medicamentos"
                    value={
                      formStatePostAppt.medicines
                        ? formStatePostAppt.medicines
                        : ""
                    }
                    onChange={onChangeAppointmentHistoy}
                    helpClassName={"requiredField"}
                    disabled={!edit_mode}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </BodyWidget>
        <div className={styles.butArea}>
          <Button onClick={onUpdateAppointment} disabled={!edit_mode}>
            Actualizar
          </Button>
        </div>
      </div>
      {/* Ends content */}
    </div>
  );
};

export default AppointmentResults;
