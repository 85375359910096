import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import { updateAppointment } from "../../../../actions/clinic/clinic";
import _ from "lodash";
import PhysicalExamForm from "./PhysicalExamForms/FormA";

const AppointmentPhysicalExamination = ({ appointment, pe_form }) => {
  const dispatch = useDispatch();
  const update = updateAppointment({ id: appointment.appointment_id });

  const { errors, edit_mode } = useSelector(store => ({
    errors: store.clinic.clinic.clinicAppointments.errors,
    edit_mode: store.clinic.appointment.editMode
  }));

  const [formState, setFormState] = useState({
    physical_examination: {
      ...appointment.physical_examination
    }
  });

  const setEditMode = () => {
    dispatch(editMode);
  };

  const onUpdateAppointment = () => {
    let toUpdate = {
      id: appointment.appointment_id,
      patient_id: appointment.patient_id,
      doctor_id: appointment.doctor_id,
      technician_id: appointment.technician_id,
      appointment_date: appointment.appointment_date,
      physical_examination: formState.physical_examination
    };

    dispatch(
      update(
        {
          ...toUpdate
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Cita se ha actualizado",
            message:
              "Se ha actualizado la información básica de la cita exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló la actualización de la cita",
            message:
              "No se pudo actualizar la información básica de la cita, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onChangeAppointment = ({ target: { value, id } }) => {
    if (id.indexOf(".") > -1) {
      const [parent_key, key] = id.split(".");
      setFormState(oldState => ({
        ...oldState,
        [parent_key]: {
          ...oldState[parent_key],
          [key]: value
        }
      }));
    } else
      setFormState(oldState => ({
        ...oldState,
        [id]: value
      }));
  };

  if (!appointment && !appointment.physical_examination) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Consulta y examen físico</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <PhysicalExamForm
                  formState={formState}
                  onChange={onChangeAppointment}
                  edit_mode={edit_mode}
                  errors={errors}
                />
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={onUpdateAppointment} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>
        {/* Ends content */}
      </div>
    );
  }
};

export default AppointmentPhysicalExamination;
