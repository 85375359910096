import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import styles from "../../../clinic/patients/index.module.css"; //TODO: MAKE A GLOBAL CSS

const patientsListColumns = [
  {
    accessor: "id",
    Header: "Id",
    minWidth: 80,
    maxWidth: 80,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <Link to={`/patients/${props.original.id}`}> {props.original.id} </Link>
    ),
    filterable: false
  },
  {
    Header: "Nombre(s)",
    accessor: "name",
    id: "name",
    minWidth: 150,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <Link to={`/patients/${props.original.id}`}> {props.original.name} </Link>
    ),
    filterable: true,
    filterMethod: (filter, row) =>
      row.name.toLowerCase().includes(filter.value.toLowerCase())
  },
  {
    Header: "Apellido(s)",
    accessor: "last_name",
    id: "email",
    minWidth: 150,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <Link to={`/patients/${props.original.id}`}>
        {" "}
        {props.original.last_name}{" "}
      </Link>
    ),
    filterable: true,
    filterMethod: (filter, row) =>
      row.email.toLowerCase().includes(filter.value.toLowerCase())
  },
  {
    Header: "Estado",
    accessor: "active_date",
    minWidth: 100,
    maxWidth: 100,
    headerClassName: styles.tableSolsticeHeader,
    Cell: props => (
      <span>
        {" "}
        {props.original.active_date ? (
          <span>
            <span className={"activedot"} />
          </span>
        ) : (
          <span>
            <span className={"inactivedot"} />
          </span>
        )}{" "}
      </span>
    ),
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Todo</option>
        <option value="true">Activo</option>
        <option value="false">Inactivo</option>
      </select>
    )
  }
];

export default ({ patients }) => {
  patients =
    patients &&
    patients.map(patient => ({
      ...patient,
      name: patient.first_name + " " + patient.middle_name,
      last_name: patient.last_name
    }));

  return (
    <div>
      <ReactTable
        data={patients}
        columns={patientsListColumns}
        className={[styles.tableSolstice, "body -highlight"].join(" ")}
        defaultPageSize={10}
        filterable
      />
    </div>
  );
};
