import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import styles from "../index.module.css";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addAppointmentServices,
  toggleApptServicesModal,
  setIsLoading
} from "../../../../actions/clinic/clinic";
import _ from "lodash";
import blue_spinner from "../../../../static/img/gif/blue_spinner.gif";
import ReactTable from "react-table";
import Inter from "../../../../common/constants/internationalization";
import deleteImg from "../../../../static/img/icons/delete.svg";

const loadingSpinner = <img src={blue_spinner} alt="spinner" width="30px" />;

const AddServicesToAppointment = ({
  appointment,
  clinicServices,
  appointmentServices
}) => {
  const dispatch = useDispatch();
  const add_appt_services = addAppointmentServices({
    appointment_id: appointment.appointment_id
  });

  const { loading, user_country } = useSelector(store => ({
    loading: store.clinic.clinic.loading,
    user_country: store.auth.user.country
  }));

  const [formState, setFormState] = useState({
    services: []
  });

  const [formServiceState, setFormServiceState] = useState({
    clinic_service_id: 0,
    service_id: 0,
    price: "",
    service_name: "",
    service: ""
  });

  const [appointmentServicesState, setAppointmentServicesState] = useState([]);

  const [
    clinicAppointmentServicesState,
    setClinicAppointmentServicesState
  ] = useState([...appointmentServices, ...appointmentServicesState]);

  const [duplicateServiceState, setDuplicateServiceState] = useState(false);

  //TODO: it may be slow think about server side
  const suggestions =
    clinicServices &&
    clinicServices.map(
      service =>
        service.service_name +
        " - " +
        service.specialty_name +
        " - " +
        service.service_id
    );

  const onChangeService = ({ target: { value, id } }) => {
    let value_parts = value.split(" ");
    setFormServiceState(oldState => ({
      ...oldState,
      service_id: parseInt(value_parts[value_parts.length - 1]),
      service_name: value_parts[0],
      service: value
    }));
  };

  const onChangePrice = ({ target: { value, id } }) => {
    setFormServiceState(oldState => ({
      ...oldState,
      price: parseFloat(value)
    }));
  };

  const addService = () => {
    let temp = clinicServices.filter(
      e => e.service_id === formServiceState.service_id
    );

    let newService = {
      ...formServiceState,
      clinic_service_id: temp ? temp[0].id : null
    };

    if (
      _.findIndex(appointmentServices, {
        appointment_id: appointment.appointment_id,
        service_id: formServiceState.service_id
      }) > -1
    ) {
      setDuplicateServiceState(true);
    } else {
      setDuplicateServiceState(false);
      setAppointmentServicesState(oldState => [...oldState, newService]);
      setClinicAppointmentServicesState(oldState => [...oldState, newService]);
      setFormState(oldState => ({
        ...oldState,
        services: [
          ...oldState.services,
          {
            clinic_service_id: newService.clinic_service_id,
            service_id: newService.service_id,
            price: newService.price
              ? parseFloat(newService.price) >= 0
                ? parseFloat(newService.price)
                : null
              : null
          }
        ]
      }));
      setFormServiceState(oldState => ({
        ...oldState,
        service_id: 0,
        price: 0,
        service: "",
        service_name: ""
      }));
    }
  };

  const addServices = () => {
    if (!loading) {
      dispatch(setIsLoading);
      dispatch(
        add_appt_services(
          formState.services,
          {
            onSuccessMessage: {
              type: "success",
              view: "appointment",
              title: "Servicios de la cita actualizados",
              message: "Se han agregado servicios a la cita."
            },
            onFailedMessage: {
              type: "error",
              view: "appointment",
              title: "Falló actualizando los servicios",
              message: "No se ha podido agregar servicios, intente nuevamente."
            }
          },
          { onFinishedAction: toggleApptServicesModal }
        )
      );
    }
  };

  const removeService = id => {
    setAppointmentServicesState(oldstate => [
      ...oldstate.filter(e => e.service_id !== id)
    ]);
  };

  const servicesByClinic = [
    {
      accessor: "service_id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      filterable: false
    },
    {
      Header: "Servicio",
      accessor: "service_name",
      minWidth: 200,
      maxWidth: 250,
      headerClassName: styles.tableSolsticeHeader,
      filterable: true
    },
    {
      Header: "Precio",
      accessor: "price",
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <span>{`${Inter[user_country].currency}${props.original.price}`}</span>
      ),
      filterable: false
    },
    {
      Header: "Remover",
      accessor: "service_id",
      minWidth: 150,
      maxWidth: 150,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <div>
          {!props.original.id && (
            <Button
              bsSize="small"
              onClick={() => removeService(props.original.service_id)}
              className={styles.deleteButton}
            >
              <Image
                width="12px"
                src={deleteImg}
                className={styles.deleteImg}
              />
            </Button>
          )}
        </div>
      )
    }
  ];

  if (1 == 2) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col sm={6}>
                <FieldGroup
                  type="autocomplete"
                  id="service_id"
                  label="Servicio"
                  value={formServiceState.service}
                  onChange={onChangeService}
                  helpClassName={"requiredField"}
                  placeholder="Consulta"
                  suggestions={suggestions}
                  isRequired
                  tabIndex={1}
                  autoComplete="off"
                />
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <FieldGroup
                      type="text"
                      id="price"
                      label="Precio"
                      value={formServiceState.price}
                      onChange={onChangePrice}
                      helpClassName={"requiredField"}
                      tabIndex={7}
                      placeholder="0"
                    />
                  </Col>
                  <Col sm={6}>
                    <div className={styles.addServiceWrapper}>
                      <Button
                        className={"push-button -white"}
                        bsSize="small"
                        onClick={addService}
                      >
                        Agregar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {duplicateServiceState && (
              <p className={"requiredField"}>Error: servicio duplicado.</p>
            )}
            {clinicAppointmentServicesState.length > 0 && (
              <Row>
                <Col xs={12}>
                  <ReactTable
                    data={clinicAppointmentServicesState}
                    columns={servicesByClinic}
                    className={[styles.tableSolstice, "body -highlight"].join(
                      " "
                    )}
                    defaultPageSize={5}
                    showPagination={false}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.submitGenerateButtonWrapper}>
              <Button
                className={styles.submitButton}
                bsSize="small"
                onClick={addServices}
                tabIndex={8}
              >
                {"Agregar servicios"} {loading ? loadingSpinner : ""}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default AddServicesToAppointment;
