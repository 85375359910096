import React from "react";
import serviceWorker from "./serviceWorker";
import { render } from "react-dom";
import App from "./App";
import { setConfig } from "react-hot-loader";
import { Router, Route } from "react-router-dom";
import history from "./history";
import "./style/style.scss";

setConfig({
  pureSFC: true
});

serviceWorker();

render(
  <Router history={history}>
    <Route component={App} />
  </Router>,
  document.getElementById("root")
);
