import { buildAsyncGet } from "../../actionsFactory";

export const TOGGLE_MENU = "toggle-menu";
export const toggleMenu = { type: TOGGLE_MENU };

export const SWITCH_TOOLTIPS_MODE = "switch-tooltips-mode";
export const switchTooltipsMode = { type: SWITCH_TOOLTIPS_MODE };

export const CLICK_OUTSIDE = "click-outside";
export const clickOutside = { type: CLICK_OUTSIDE };

/* export const BROWSER_LANGUAGE = "browser-language";
export const updateDefaultLanguage = val => ({
  type: BROWSER_LANGUAGE,
  payload: val
});

export const FETCH_DICTIONARY = "fetch-dictionary";
export const requestDictionary = ({ application, language }) =>
  buildAsyncGet(
    FETCH_DICTIONARY,
    `/i18n/translation/translations?app=${application}&lang=${language}`
  ); */
