import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
  Glyphicon
} from "react-bootstrap";
import { Link } from "react-router-dom";
import useFormInput from "../../../../common/uses/useFormInput";
import styles from "./VideoAppointmentBox.module.css";
import Lobby from "./Lobby";
import Room from "./Room";

export default ({ apptToken }) => {
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);

  const handleUsernameChange = useCallback(event => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback(event => {
    setRoomName(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setToken(apptToken);
    },
    [username, roomName]
  );

  const handleLogout = useCallback(event => {
    setToken(null);
  }, []);

  return (
    <Row>
      {/* Login Box */}
      <div className={styles.globalContainer}>
        <Row>
          <Col xs={12}>
            {/* {!token && (
							<Row className={styles.sectionWrapper}>
								<Col xs={12}>
									<p className={styles.title}>
										Enviar mensaje al paciente
									</p>
									<p>
										¿Tu paciente aun no tiene acceso? enviale un
										correo con la información para conectarse a la
										video consulta.
									</p>
									<Button
									//onClick={toggleVideoAppt}
									>
										Enviar información de acceso
									</Button>
								</Col>
							</Row>
						)} */}
            <Row className={styles.sectionWrapper}>
              <Col xs={12}>
                {token ? (
                  <Room
                    roomName={roomName}
                    token={token}
                    handleLogout={handleLogout}
                  />
                ) : (
                  <div>
                    <p className={styles.title}>Inicia la video consulta</p>
                    <Lobby
                      username={username}
                      roomName={roomName}
                      handleUsernameChange={handleUsernameChange}
                      handleRoomNameChange={handleRoomNameChange}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Footer */}
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Col xs={12}>Copyright ©2020 Monitoreo, Tech.</Col>
        </div>
      </div>
    </Row>
  );
};
