import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tab, Nav, Image } from "react-bootstrap";
import styles from "./index.module.css";
import Invoicing from "./components/Invoicing";
import Billing from "./components/Billing";
import history from "../../../history";
import rightArrowImg from "../../../static/img/right-arrow.svg";
import NavLinkCleaner from "../../../components/NavLinkCleaner/NavLinkCleaner";
import NotifBox from "../../../components/Layout/NotifBox/NotifBox";
import { withRouter } from "react-router-dom";
import { requestTodayInvoices } from "../../../actions/admin/invoicing";
import { requestTodayInvoicesToCharge } from "../../../actions/admin/billing";

const Home = () => {
  const dispatch = useDispatch();

  const { todayInvoices, todayInvoicesToCharge } = useSelector(store => ({
    todayInvoices: store.admin.billing.todayInvoices,
    todayInvoicesToCharge: store.admin.billing.todayInvoicesToCharge
  }));

  useEffect(() => {
    dispatch(requestTodayInvoices());
    dispatch(requestTodayInvoicesToCharge());
  }, []);

  const pathname = history.location.pathname;
  const tab_key =
    pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length) ===
    "finance"
      ? "invoicing"
      : pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);

  let tab;
  const valid_tabs = ["invoicing", "billing"];

  if (valid_tabs.includes(tab_key)) {
    tab = tab_key;
  }

  if (!todayInvoices.loaded) {
    return <p>Loading...</p>;
  } else
    return (
      <div className="body-area">
        {/* <ClinicHeader clinic={clinic} /> */}

        <Row className={styles.notifWrapper}>
          <Col xs={12}>
            <NotifBox side="admin" view="finance" />
          </Col>
        </Row>

        <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
          <Row className="clearfix">
            <Col sm={3}>
              <Nav className={styles.menu} bsStyle="pills" stacked>
                <NavLinkCleaner
                  to={`/finance/invoicing`}
                  className={
                    tab === "invoicing" ? "adminNavActive" : "adminNav"
                  }
                >
                  Facturación
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
                <NavLinkCleaner
                  to={`/finance/billing`}
                  className={tab === "billing" ? "adminNavActive" : "adminNav"}
                >
                  Cobros
                  <Image
                    src={rightArrowImg}
                    responsive
                    className={styles.rightArrow}
                  />
                </NavLinkCleaner>
              </Nav>
            </Col>

            <Col sm={9} id="cTab">
              <Tab.Content animation className={styles.contentSolarGardenAdmin}>
                <Tab.Pane eventKey="invoicing">
                  <Invoicing todayInvoices={todayInvoices.content} />
                </Tab.Pane>
                <Tab.Pane eventKey="billing">
                  <Billing
                    todayInvoicesToCharge={todayInvoicesToCharge.content}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
};

export default withRouter(Home);
