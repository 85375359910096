import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PatientAppointmentDetail from "./PatientAppointmentDetail";
import {
  requestClinicDoctors,
  requestClinicTechnicians
} from "../../../../actions/clinic/clinic";
import { requestPatientAppointmentHistory } from "../../../../actions/clinic/appointment";
import { useDispatch, useSelector } from "react-redux";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";

const PatientClinicHistory = ({
  patient_id,
  match: {
    params: { id, appt_id }
  }
}) => {
  const dispatch = useDispatch();

  const {
    clinics,
    active_clinic,
    doctors,
    technicians,
    patientAppointmentHistory
  } = useSelector(store => ({
    clinics: store.clinic.account.clinics.content,
    active_clinic: store.clinic.account.activeClinic,
    doctors: store.clinic.clinic.clinicDoctors.content,
    technicians: store.clinic.clinic.clinicTechnicians.content,
    patientAppointmentHistory:
      store.clinic.appointment.patientAppointmentHistory
  }));

  useEffect(() => {
    //TODO: Add un if here
    dispatch(requestClinicDoctors({ clinic_id: active_clinic }));
    dispatch(requestClinicTechnicians({ clinic_id: active_clinic }));
    dispatch(requestPatientAppointmentHistory({ id: patient_id }));
  }, [active_clinic, patient_id]);

  const appointmentsListColumns = [
    {
      accessor: "appointment_id",
      Header: "Id",
      minWidth: 80,
      maxWidth: 80,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/patients/${id}/history/${props.original.appointment_id}`}>
          {" "}
          {props.original.appointment_id}{" "}
        </Link>
      ),
      filterable: false
    },
    {
      Header: "Fecha",
      accessor: "date",
      id: "date",
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <Link to={`/patients/${id}/history/${props.original.appointment_id}`}>
          {" "}
          {moment(props.original.date).format("lll")}{" "}
        </Link>
      ),
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value || !filter.value === "") {
          return (
            moment(row.date).format("L") === moment(filter.value).format("L")
          );
        } else {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <FieldGroup
          type="date"
          id="date"
          value={
            filter
              ? filter.value && filter.value != ""
                ? new Date(filter.value)
                : undefined
              : undefined
          }
          onChange={event => onChange(event.target.value)}
          placeholder="Seleccione una fecha"
          helpClassName={"requiredField"}
          hideLabel={true}
        />
      )
    },
    {
      Header: "Estado",
      accessor: "date",
      minWidth: 150,
      maxWidth: 150,
      headerClassName: styles.tableSolsticeHeader,
      Cell: props => (
        <span>
          {" "}
          {props.original.date ? (
            <span>
              <span className={"activedot"} />
            </span>
          ) : (
            <span>
              <span className={"inactivedot"} />
            </span>
          )}{" "}
        </span>
      ),
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        return row[filter.id] === false;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? (filter.value ? filter.value : "all") : "all"}
        >
          <option value="all">Todo</option>
          <option value="true">Activo</option>
          <option value="false">Inactivo</option>
        </select>
      )
    }
  ];

  const [clinic] = clinics.filter(
    clinic => clinic.id === parseInt(active_clinic)
  );

  const doctorsList =
    doctors &&
    doctors.map(doctor => ({
      value: doctor.user_id,
      text: doctor.first_name + " " + doctor.last_name
    }));

  const techniciansList =
    technicians &&
    technicians.map(technician => ({
      value: technician.user_id,
      text: technician.first_name + " " + technician.last_name
    }));

  if (appt_id && !isNaN(appt_id)) {
    if (
      patientAppointmentHistory.loading ||
      !patientAppointmentHistory.loaded ||
      !clinic
    ) {
      return <div>Loading...</div>;
    } else {
      const [appointment] = patientAppointmentHistory.content.filter(
        appt => appt.appointment_id === parseInt(appt_id)
      );
      return (
        <PatientAppointmentDetail
          appointment={appointment}
          doctorsList={doctorsList}
          techniciansList={techniciansList}
          settings={clinic.settings}
        />
      );
    }
  } else {
    let appointmentsFormatted =
      patientAppointmentHistory.content &&
      patientAppointmentHistory.content.map(appointment => ({
        ...appointment,
        name: appointment.first_name + " " + appointment.middle_name,
        date: appointment.appointment_date
      }));
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Historial Clínico</h3>
        </div>
        <div>
          <ReactTable
            data={appointmentsFormatted}
            columns={appointmentsListColumns}
            className={[styles.tableSolstice, "body -highlight"].join(" ")}
            defaultPageSize={10}
            filterable
          />
        </div>
        {/* Ends content */}
      </div>
    );
  }
};

export default withRouter(PatientClinicHistory);
