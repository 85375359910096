import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { requestLogout } from "../../actions/common/auth";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLogout());
  }, [dispatch]);

  return <>Logging out...</>;
};

export default Logout;
