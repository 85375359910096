import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import MessageItem from "./MessageItem";

export default ({ side = "clinic", view, maxItems = 3 }) => {
  const currentMessage = useSelector(
    state => state.clinic.notification.current
  );

  useEffect(() => {
    if (currentMessage && view === currentMessage.view) {
      window.scrollTo(0, 0);
    }
  }, [currentMessage, view]);

  if (!currentMessage || view !== currentMessage.view) {
    return null;
  }

  if (currentMessage.message && currentMessage.message instanceof Array) {
    return (
      <>
        {currentMessage.message.map(msg => {
          return (
            <MessageItem
              title={currentMessage.title}
              message={msg.message}
              type={currentMessage.type}
            />
          );
        })}
      </>
    );
  }

  return (
    <div>
      <MessageItem {...currentMessage} />
    </div>
  );
};
