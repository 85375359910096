import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button, Modal } from "react-bootstrap";
import styles from "./Agenda.module.css";
import FieldGroup from "../../../../../components/Forms/FieldGroup/FieldGroup";
import { Link } from "react-router-dom";
import {
  updateAppointment,
  updateAppointmentOnAgendaView,
  deleteAppointment,
  togglePreviewAppointmentModalOnAgenda
} from "../../../../../actions/clinic/clinic";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Inter from "../../../../../common/constants/internationalization";

const duration = [
  {
    value: 0,
    text: "00"
  },
  {
    value: 15,
    text: "15"
  },
  {
    value: 30,
    text: "30"
  },
  {
    value: 45,
    text: "45"
  }
];

const AppointmentPreview = ({
  details,
  settings,
  doctorsList,
  techniciansList,
  clinic_id,
  errors,
  view = "agenda"
}) => {
  const dispatch = useDispatch();
  const update = updateAppointment({ id: details.appointment_id });
  const updateOnAgenda = updateAppointmentOnAgendaView({
    id: details.appointment_id
  });

  const { user_country } = useSelector(store => ({
    user_country: store.auth.user.country
  }));

  if (!_.isEmpty(details)) {
    details = {
      ...details,
      patient: details.first_name + " " + details.last_name,
      hour: parseFloat(details.hour),
      appointment_date: new Date(details.appointment_date)
    };
  }

  const hourAppointment =
    user_country &&
    new Date(details.appointment_date).toLocaleString(
      Inter[user_country].language,
      { timeZone: Inter[user_country].timeZone }
    );

  const [formState, setFormState] = useState({
    ...details,
    hour: parseFloat(
      new Date(hourAppointment).getHours() +
        "." +
        (new Date(hourAppointment).getMinutes() * 100) / 60
    )
  });

  const updateAppt = () => {
    let hour = Math.floor(formState.hour);
    let minutes = (formState.hour % 1) * 60;
    let date = new Date(formState.appointment_date);
    date = new Date(date.setHours(hour, minutes, 0));

    let data = {
      id: details.appointment_id,
      appointment_date:
        _.isDate(formState.appointment_date) &&
        !_.isNull(formState.appointment_date)
          ? date
          : null,
      patient_id: formState.patient_id,
      duration: formState.duration,
      doctor_id: formState.doctor_id === 0 ? null : formState.doctor_id,
      technician_id:
        formState.technician_id === 0 ? null : formState.technician_id,
      comments: formState.comments,
      clinic_id: clinic_id
    };

    if (view === "agenda" || view === "calendar") {
      dispatch(
        updateOnAgenda(
          {
            ...data
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "appointments",
              title: "Cita se ha actualizado",
              message:
                "Se ha actualizado la información básica de la cita exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "appointments",
              title: "Falló la actualización de la cita",
              message:
                "No se pudo actualizar la información básica de la cita, por favor intente nuevamente."
            }
          },
          { onFinishedAction: togglePreviewAppointmentModalOnAgenda }
        )
      );
    } else {
      dispatch(
        update(
          {
            ...data
          },
          {
            onSuccessMessage: {
              type: "success",
              view: "appointments",
              title: "Cita se ha actualizado",
              message:
                "Se ha actualizado la información básica de la cita exitosamente."
            },
            onFailedMessage: {
              type: "error",
              view: "appointments",
              title: "Falló la actualización de la cita",
              message:
                "No se pudo actualizar la información básica de la cita, por favor intente nuevamente."
            }
          },
          { onFinishedAction: togglePreviewAppointmentModalOnAgenda }
        )
      );
    }
  };

  const deleteAppt = () => {
    dispatch(
      deleteAppointment({ id: details.appointment_id })(
        {
          onSuccessMessage: {
            type: "success",
            view: "appointments",
            title: "Cita eliminada",
            message: "La cita de la clínica se ha eliminado exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "appointments",
            title: "Falló eliminando la cita",
            message: "No se pudo eliminar la cita, consulte a soporte técnico."
          }
        },
        { onFinishedAction: togglePreviewAppointmentModalOnAgenda }
      )
    );
  };

  const onChange = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: value
    }));
  };

  const goToApptDetails = () => {
    dispatch(togglePreviewAppointmentModalOnAgenda);
  };

  // Make it global
  let workHours = [];
  let hour;
  let minutes;
  let i;

  for (
    var x = parseInt(settings.weekdays_schedule.start);
    x < parseInt(settings.weekdays_schedule.end);
    x++
  ) {
    i = 0;
    hour = x;
    for (var y = 0; y < 4; y++) {
      minutes = i === 0 ? "00" : i;
      workHours.push({
        value: hour,
        text: x + ":" + minutes
      });
      hour = hour + 0.25;
      i = i + 15;
    }
  }

  if (_.isEmpty(details) || !workHours.length) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col xs={12} md={12}>
                <FieldGroup
                  type="text"
                  id="patient"
                  label="Paciente"
                  value={formState.patient}
                  readOnly
                  disabled
                  errors={errors}
                  helpClassName={"requiredField"}
                  placeholder="Juan"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="select"
                  selectOptions={doctorsList ? doctorsList : []}
                  selectFirstOption={<option>Seleccione un Doctor</option>}
                  selectMap={doctor => (
                    <option key={doctor.value.toString()} value={doctor.value}>
                      {" "}
                      {doctor.text}{" "}
                    </option>
                  )}
                  id="doctor_id"
                  label="Doctor"
                  value={formState.doctor_id || 0}
                  onChange={onChange}
                />
                <FieldGroup
                  type="date"
                  id="appointment_date"
                  label="Fecha"
                  value={formState.appointment_date}
                  onChange={onChange}
                  placeholder="Seleccione una fecha"
                  errors={errors}
                  helpClassName={"requiredField"}
                />
              </Col>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="select"
                  selectOptions={techniciansList ? techniciansList : []}
                  selectFirstOption={<option>Seleccione un Tecnico</option>}
                  selectMap={technician => (
                    <option
                      key={technician.value.toString()}
                      value={technician.value}
                    >
                      {" "}
                      {technician.text}{" "}
                    </option>
                  )}
                  id="technician_id"
                  label="Técnico"
                  value={formState.technician_id || 0}
                  onChange={onChange}
                />
                <Row>
                  <Col sm={6}>
                    <FieldGroup
                      type="select"
                      className={styles.timeField}
                      selectOptions={workHours}
                      selectMap={workHour => (
                        <option
                          key={workHour.value.toString()}
                          value={workHour.value}
                        >
                          {" "}
                          {workHour.text}{" "}
                        </option>
                      )}
                      id="hour"
                      label="Hora"
                      value={formState.hour}
                      onChange={onChange}
                    />
                  </Col>
                  <Col sm={6}>
                    <FieldGroup
                      type="select"
                      className={styles.timeField}
                      selectOptions={duration}
                      selectMap={minute => (
                        <option
                          key={minute.value.toString()}
                          value={minute.value}
                        >
                          {" "}
                          {minute.text}{" "}
                        </option>
                      )}
                      id="duration"
                      label="Duracion"
                      errors={errors}
                      value={formState.duration}
                      onChange={onChange}
                      helpClassName={"requiredField"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className={styles.goToDetails}>
            <Link
              to={`/agenda/${details.appointment_id}`}
              onClick={goToApptDetails}
            >
              {" "}
              Ir a mas detalles{" "}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.submitButtonWrapper}>
              <Button
                className={styles.submitButton}
                bsSize="small"
                onClick={deleteAppt}
              >
                Eliminar
              </Button>{" "}
              <Button
                className={styles.submitButton}
                bsSize="small"
                onClick={updateAppt}
              >
                Actualizar
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default AppointmentPreview;
