import {
  succeeded,
  requested,
  failed,
  error
} from "../../actions/actionsFactory";
import { isRelatedAction } from "../utils";
import { pristine, reduce } from "../utils";
import {
  FETCH_TODAY_INVOICES,
  GENERATE_INVOICE,
  GENERATE_SELECTED_INVOICES
} from "../../actions/admin/invoicing";
import {
  FETCH_TODAY_INVOICES_TO_CHARGE,
  CHARGE_INVOICE,
  CHARGE_SELECTED_INVOICES
} from "../../actions/admin/billing";

var defaultState = {
  invoices: pristine([]),
  todayInvoices: pristine([]),
  todayInvoicesToCharge: pristine([]),
  invoiceProcess: pristine({
    totalInvoices: null,
    status: null,
    processed: null
  }),
  billingProcess: pristine({ totalBills: null, status: null, processed: null })
};

export default function(state = defaultState, action) {
  if (isRelatedAction(action, FETCH_TODAY_INVOICES)) {
    return reduce(state, "todayInvoices", action, FETCH_TODAY_INVOICES);
  }

  if (isRelatedAction(action, FETCH_TODAY_INVOICES_TO_CHARGE)) {
    return reduce(
      state,
      "todayInvoicesToCharge",
      action,
      FETCH_TODAY_INVOICES_TO_CHARGE
    );
  }

  if (
    action.type === requested(GENERATE_SELECTED_INVOICES) ||
    action.type === succeeded(GENERATE_SELECTED_INVOICES)
  ) {
    return {
      ...state,
      invoiceProcess: {
        ...state.invoiceProcess,
        content: action.payload
      }
    };
  }

  if (
    action.type === failed(GENERATE_INVOICE) ||
    action.type === error(GENERATE_INVOICE)
  ) {
    return {
      ...state,
      invoiceProcess: {
        ...state.invoiceProcess,
        content: {
          ...state.invoiceProcess.content,
          processed: state.invoiceProcess.content.processed + 1
        }
      }
    };
  }

  if (
    action.type === requested(CHARGE_SELECTED_INVOICES) ||
    action.type === succeeded(CHARGE_SELECTED_INVOICES)
  ) {
    return {
      ...state,
      billingProcess: {
        ...state.billingProcess,
        content: action.payload
      }
    };
  }

  if (
    action.type === failed(CHARGE_INVOICE) ||
    action.type === error(CHARGE_INVOICE)
  ) {
    return {
      ...state,
      billingProcess: {
        ...state.billingProcess,
        content: {
          ...state.billingProcess.content,
          processed: state.billingProcess.content.processed + 1
        }
      }
    };
  }

  return state;
}
