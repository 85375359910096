import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Glyphicon } from "react-bootstrap";
import styles from "./AppointmentLabExams.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { editMode } from "../../../../actions/clinic/common";
import { requestLabExams } from "../../../../actions/lab_exams/lab_exams";
import {
  toggleAppointmentExamsModal,
  updateAppointmentExams
} from "../../../../actions/clinic/appointment";
import AddExamsToAppointment from "./AddExamsToAppointment";
import _ from "lodash";
import moment from "moment";

const AppointmentLabExams = ({
  appointment_id,
  appointmentExamsStatus,
  appointment_date,
  appointmentExams,
  prevAppointmentExams,
  appointmentExamsList
}) => {
  const dispatch = useDispatch();

  const { exams, edit_mode, toggle_modal } = useSelector(state => ({
    exams: state.exams.exams.content,
    edit_mode: state.clinic.appointment.editMode,
    toggle_modal: state.clinic.appointment.toggleAppointmentExamsModal
  }));

  useEffect(() => {
    dispatch(requestLabExams());
  }, [appointment_id]);

  const setEditMode = () => {
    dispatch(editMode);
  };

  const toggleAddAppointmentServices = () =>
    dispatch(toggleAppointmentExamsModal);

  const [formState, setFormState] = useState(appointmentExamsList.content);

  const onChangeExam = ({ target: { value, id } }) => {
    setFormState(oldState => ({
      ...oldState,
      [id]: { ...oldState[id], result: value }
    }));
  };

  const onUpdateAppointmentExams = () => {
    let instancesToUpdate = Object.entries(formState);
    dispatch(
      updateAppointmentExams({ appointment_id })(
        instancesToUpdate,
        {
          onSuccessMessage: {
            type: "success",
            view: "appointment",
            title: "Exámenes de la cita actualizados",
            message:
              "Se han actualizado los resultados de los exámenes de laboratorios."
          },
          onFailedMessage: {
            type: "error",
            view: "appointment",
            title: "Falló actualizando los exámenes",
            message:
              "No se han podido actualizar los exámenes de laboratorio, intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  if (
    !appointmentExams.loaded ||
    !prevAppointmentExams.loaded ||
    !appointmentExamsList.loaded
  ) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}>
            <div className={styles.actionBut}>
              <Button bsSize="small" onClick={toggleAddAppointmentServices}>
                <Glyphicon glyph="plus" /> Agregar exámenes
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <Modal
              show={toggle_modal}
              onHide={toggleAddAppointmentServices}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Agregar exámenes a la cita</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddExamsToAppointment
                  appointment_id={appointment_id}
                  labExams={exams}
                  appointmentExams={appointmentExams.content}
                />
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Exámenes de Laboratorio</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row className={styles.header}>
              <Col xs={12}>
                <div className={styles.headerLeftSide}>{`${
                  appointmentExamsStatus.previous_appointment_date
                    ? moment(appointmentExamsStatus.previous_appointment_date)
                        .locale("es")
                        .format("LL")
                    : ""
                } ${
                  appointmentExamsStatus.previous_appointment_date
                    ? "(Cita anterior)"
                    : "(No hay cita previa)"
                }`}</div>
                <div className={styles.separator}></div>
                <div className={styles.headerRightSide}>
                  {`${
                    appointment_date
                      ? moment(appointment_date)
                          .locale("es")
                          .format("LL")
                      : ""
                  } (Cita actual)`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                {prevAppointmentExams.content ? (
                  prevAppointmentExams.content.map(exam => (
                    <FieldGroup
                      type="text"
                      key={`${exam.id}`}
                      id={`${exam.id}`}
                      label={exam.exam}
                      value={
                        formState[exam.id] ? formState[exam.id].result : ""
                      }
                      onChange={onChangeExam}
                      helpClassName={"requiredField"}
                      disabled={!edit_mode}
                      className={styles.fieldWidth}
                    />
                  ))
                ) : (
                  <div className={styles.noExams}>
                    {appointmentExamsStatus.previous_appointment_date
                      ? "No hay exámenes en la cita anterior"
                      : ""}
                  </div>
                )}
              </Col>
              <Col xs={12} md={6}>
                {appointmentExams.content ? (
                  appointmentExams.content.map(exam => (
                    <FieldGroup
                      type="text"
                      key={`${exam.id}`}
                      id={`${exam.id}`}
                      label={exam.exam}
                      value={
                        formState[exam.id] ? formState[exam.id].result : ""
                      }
                      onChange={onChangeExam}
                      helpClassName={"requiredField"}
                      disabled={!edit_mode}
                      className={styles.fieldWidth}
                    />
                  ))
                ) : (
                  <div className={styles.noExams}>
                    No hay exámenes en cita actual
                  </div>
                )}
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={onUpdateAppointmentExams} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>
        {/* Ends content */}
      </div>
    );
  }
};

export default AppointmentLabExams;
