import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";

import authReducer from "./common/authReducer";
import layoutReducer from "./common/layoutReducer";
import accountReducer from "./clinic/accountReducer";
import notificationReducer from "./clinic/notificationReducer";
import clinicReducer from "./clinic/clinicReducer";
import clinicCustomFieldsReducer from "./clinic/clinicCustomFieldsReducer";
import rolesReducer from "./clinic/rolesReducer";
import patientCustomFieldsReducer from "./clinic/patientCustomFieldsReducer";
import doctorReducer from "./clinic/doctorReducer";
import technicianReducer from "./clinic/technicianReducer";
import appointmentReducer from "./clinic/appointmentReducer";
import specialtiesReducer from "./specialties/specialtiesReducer";
import virtualAppointmentReducer from "./common/virtualAppointmentReducer";
import labExamsReducer from "./lab_exams/labExamsReducer";

import billingReducer from "./admin/billingReducer";

import { succeeded, failed, error } from "../actions/actionsFactory";
import { LOGOUT } from "../actions/common/auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
  switch (action.type) {
    case succeeded(LOGOUT):
    case failed(LOGOUT):
    case error(LOGOUT):
      state = undefined;
      break;
    default:
      break;
  }

  return storeReducers(state, action);
};

const storeReducers = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  clinic: combineReducers({
    account: accountReducer,
    notification: notificationReducer,
    clinic: clinicReducer,
    clinicCustomFields: clinicCustomFieldsReducer,
    roles: rolesReducer,
    patientCustomFields: patientCustomFieldsReducer,
    doctor: doctorReducer,
    technician: technicianReducer,
    appointment: appointmentReducer
  }),
  admin: combineReducers({
    billing: billingReducer
  }),
  virtualAppointment: virtualAppointmentReducer,
  specialties: specialtiesReducer,
  exams: labExamsReducer
});

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
