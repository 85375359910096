import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import { editMode } from "../../../../actions/clinic/common";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import _ from "lodash";
import { updateClinic } from "../../../../actions/clinic/clinic";

const duration = [
  {
    value: 0,
    text: "00"
  },
  {
    value: 15,
    text: "15"
  },
  {
    value: 30,
    text: "30"
  },
  {
    value: 45,
    text: "45"
  }
];

const ClinicSettings = ({ clinic }) => {
  const dispatch = useDispatch();
  const update = updateClinic(clinic);

  const { edit_mode, errors } = useSelector(store => ({
    edit_mode: store.clinic.clinic.editMode,
    errors: store.clinic.clinic.clinicPatients.errors
  }));

  const [formState, setFormState] = useState({
    ...clinic.settings
  });

  const [workOnSatState, setworkOnSatState] = useState(
    !_.isEmpty(clinic.settings.saturday_schedule)
  );

  const [workOnSunState, setWorkOnSunState] = useState(
    !_.isEmpty(clinic.settings.sunday_schedule)
  );

  const [showPeFormState, setShowPeFormState] = useState(
    !_.isEmpty(clinic.settings.pe_form)
  );

  const [showLabExamsState, setShowLabExamsState] = useState(
    !_.isEmpty(clinic.settings.lab_exams)
  );

  const setEditMode = () => {
    dispatch(editMode);
  };

  const updateClinicSetting = () => {
    let satSchedule = workOnSatState ? formState.saturday_schedule : {};
    let sunSchedule = workOnSunState ? formState.sunday_schedule : {};
    dispatch(
      update(
        {
          id: clinic.id,
          settings: {
            ...formState,
            saturday_schedule: satSchedule,
            sunday_schedule: sunSchedule,
            pe_form: showPeFormState ? "formA" : null,
            lab_exams: showLabExamsState ? showLabExamsState : false
          }
        },
        {
          onSuccessMessage: {
            type: "success",
            view: "clinic",
            title: "Clínica actilizada",
            message:
              "Se ha actualizado la información básica de la clínica exitosamente."
          },
          onFailedMessage: {
            type: "error",
            view: "clinic",
            title: "Falló la actualización de la clínica",
            message:
              "No se pudo actualizar la información básica de la clínica, por favor intente nuevamente."
          }
        },
        { onFinishedAction: editMode }
      )
    );
  };

  const onWorkSat = () => {
    setworkOnSatState(!workOnSatState);
  };

  const onWorkSun = () => {
    setWorkOnSunState(!workOnSunState);
  };

  const onShowLabExams = () => {
    setShowLabExamsState(!showLabExamsState);
  };

  const onShowPeForm = () => {
    setShowPeFormState(!showPeFormState);
  };

  const onChange = ({ target: { value, id } }) => {
    if (id.indexOf(".") > -1) {
      const [parent_key, key] = id.split(".");
      setFormState(oldState => ({
        ...oldState,
        [parent_key]: {
          ...oldState[parent_key],
          [key]: parseFloat(value)
        }
      }));
    } else
      setFormState(oldState => ({
        ...oldState,
        [id]: value
      }));
  };

  let workHours = [];
  let hour;
  let minutes;
  let i;
  for (var x = 0; x < 24; x++) {
    i = 0;
    hour = x;
    for (var y = 0; y < 4; y++) {
      minutes = i === 0 ? "00" : i;
      workHours.push({
        value: hour,
        text: x + ":" + minutes
      });
      hour = hour + 0.25;
      i = i + 15;
    }
  }

  if (!clinic.settings) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="h2-section -no-hide">
        {/* Starts section for buttons */}
        <Row className={styles.buttonsSection}>
          <Col xs={12}></Col>
        </Row>
        {/* Ends section for buttons */}

        {/* Starts content */}
        <div className="h3-section">
          <h3>Horarios de atención</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row>
              <span>De lunes a viernes</span>
              <Col xs={12} md={12}>
                <Row>
                  <Col xs={6} md={6}>
                    <Row>
                      <Col xs={6} md={6}>
                        <FieldGroup
                          type="select"
                          className={styles.timeField}
                          selectOptions={workHours}
                          selectMap={workHour => (
                            <option
                              key={workHour.value.toString()}
                              value={workHour.value}
                            >
                              {" "}
                              {workHour.text}{" "}
                            </option>
                          )}
                          id="weekdays_schedule.start"
                          label="De"
                          readOnly={!edit_mode}
                          value={formState.weekdays_schedule.start}
                          onChange={onChange}
                          errors={errors}
                          helpClassName={"requiredField"}
                        />
                      </Col>
                      <Col xs={6} md={6}>
                        <FieldGroup
                          type="select"
                          className={styles.timeField}
                          selectOptions={workHours}
                          selectMap={workHour => (
                            <option
                              key={workHour.value.toString()}
                              value={workHour.value}
                            >
                              {" "}
                              {workHour.text}{" "}
                            </option>
                          )}
                          id="weekdays_schedule.end"
                          label="A"
                          readOnly={!edit_mode}
                          value={formState.weekdays_schedule.end}
                          onChange={onChange}
                          errors={errors}
                          helpClassName={"requiredField"}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} md={6}>
                    <FieldGroup
                      type="select"
                      className={styles.timeField}
                      selectOptions={duration}
                      selectMap={minute => (
                        <option
                          key={minute.value.toString()}
                          value={minute.value}
                        >
                          {" "}
                          {minute.text}{" "}
                        </option>
                      )}
                      id="appointment_avg_duration"
                      label="Duración promedio por cita"
                      value={formState.appointment_avg_duration}
                      readOnly={!edit_mode}
                      onChange={onChange}
                      errors={errors}
                      helpClassName={"requiredField"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <FieldGroup
                type="checkbox"
                id="required"
                className={styles.checkboxRequired}
                value={workOnSatState}
                onClick={onWorkSat}
                disabled={!edit_mode}
                //errors={errors}
                helpClassName={"requiredField"}
              >
                <span>Sábado</span>
              </FieldGroup>
              <Col xs={12} md={12}>
                <Row>
                  <Col xs={6} md={6}>
                    {workOnSatState && (
                      <Row>
                        <Col xs={6} md={6}>
                          <FieldGroup
                            type="select"
                            className={styles.timeField}
                            selectOptions={workHours}
                            selectMap={workHour => (
                              <option
                                key={workHour.value.toString()}
                                value={workHour.value}
                              >
                                {" "}
                                {workHour.text}{" "}
                              </option>
                            )}
                            id="saturday_schedule.start"
                            label="De"
                            readOnly={!edit_mode || !workOnSatState}
                            value={formState.saturday_schedule.start}
                            onChange={onChange}
                            errors={errors}
                            helpClassName={"requiredField"}
                          />
                        </Col>
                        <Col xs={6} md={6}>
                          <FieldGroup
                            type="select"
                            className={styles.timeField}
                            selectOptions={workHours}
                            selectMap={workHour => (
                              <option
                                key={workHour.value.toString()}
                                value={workHour.value}
                              >
                                {" "}
                                {workHour.text}{" "}
                              </option>
                            )}
                            id="saturday_schedule.end"
                            label="A"
                            readOnly={!edit_mode || !workOnSatState}
                            value={formState.saturday_schedule.end}
                            onChange={onChange}
                            errors={errors}
                            helpClassName={"requiredField"}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs={6} md={6}></Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <FieldGroup
                type="checkbox"
                id="required"
                className={styles.checkboxRequired}
                value={workOnSunState}
                onClick={onWorkSun}
                disabled={!edit_mode}
                //errors={errors}
                helpClassName={"requiredField"}
              >
                <span>Domingo</span>
              </FieldGroup>
              <Col xs={12} md={12}>
                <Row>
                  <Col xs={6} md={6}>
                    {workOnSunState && (
                      <Row>
                        <Col xs={6} md={6}>
                          <FieldGroup
                            type="select"
                            className={styles.timeField}
                            selectOptions={workHours}
                            selectMap={workHour => (
                              <option
                                key={workHour.value.toString()}
                                value={workHour.value}
                              >
                                {" "}
                                {workHour.text}{" "}
                              </option>
                            )}
                            id="sunday_schedule.start"
                            label="De"
                            readOnly={!edit_mode || !workOnSunState}
                            value={formState.sunday_schedule.start}
                            onChange={onChange}
                          />
                        </Col>
                        <Col xs={6} md={6}>
                          <FieldGroup
                            type="select"
                            className={styles.timeField}
                            selectOptions={workHours}
                            selectMap={workHour => (
                              <option
                                key={workHour.value.toString()}
                                value={workHour.value}
                              >
                                {" "}
                                {workHour.text}{" "}
                              </option>
                            )}
                            id="sunday_schedule.end"
                            label="A"
                            readOnly={!edit_mode || !workOnSunState}
                            value={formState.sunday_schedule.end}
                            onChange={onChange}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs={6} md={6}></Col>
                </Row>
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={updateClinicSetting} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>

        <div className="h3-section">
          <h3>Formularios y opciones para citas</h3>
          <BodyWidget>
            <Row>
              <Col xs={12} className={styles.toggleb}>
                <Toggle
                  checked={edit_mode}
                  icons={false}
                  onChange={() => setEditMode()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="checkbox"
                  id="required"
                  className={styles.checkboxRequired}
                  value={showPeFormState}
                  onClick={onShowPeForm}
                  disabled={!edit_mode}
                  helpClassName={"requiredField"}
                >
                  <span>Mostrar sección de exámen físico</span>
                </FieldGroup>
              </Col>
              <Col xs={12} md={6}>
                <FieldGroup
                  type="checkbox"
                  id="required"
                  className={styles.checkboxRequired}
                  value={showLabExamsState}
                  onClick={onShowLabExams}
                  disabled={!edit_mode}
                  helpClassName={"requiredField"}
                >
                  <span>Mostrar sección de exámenes de laboratorios</span>
                </FieldGroup>
              </Col>
            </Row>
          </BodyWidget>
          <div className={styles.butArea}>
            <Button onClick={updateClinicSetting} disabled={!edit_mode}>
              Actualizar
            </Button>
          </div>
        </div>
        {/* Ends content */}

        {/* Starts content */}
        {/* <div className='h3-section'>
				<div className={styles.toggleb}>
				</div>
				<h3>Imagen</h3>
				<BodyWidget>
					<Row>
						<Col xs={12} className={styles.toggleb}></Col>
					</Row>
				</BodyWidget>
				<div className={styles.butArea}>
					<Button onClick={onUpdateClinic} disabled={!edit_mode}>
						Actualizar
					</Button>
				</div>
			</div> */}
        {/* Ends content */}
      </div>
    );
  }
};

export default ClinicSettings;
