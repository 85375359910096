import React from "react";
import queryString from "query-string";
import styles from "./Login.module.css";
import saludLogo from "../../static/img/logo_app.png";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export default ({ location: { search } }) => {
  const q = queryString.parse(search);

  return (
    <div className={[styles.wrapper, styles.gradientPrimary].join(" ")}>
      <div className={styles.topBar}></div>
      <div>
        <p className={styles.comingSoon}>
          Estamos trabajando en esta sección. Visitanos pronto.
        </p>
      </div>
    </div>
  );
};
