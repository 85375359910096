import { TOKEN_STORAGE_KEY } from "../../constants";
import {
  requested,
  succeeded,
  failed,
  error
} from "../../actions/actionsFactory";
import {
  LOGIN,
  LOGOUT,
  RESTORE_LOGIN,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  SET_RECAPTCHA_TOKEN,
  GET_RECAPTCHA_SITEKEY,
  RESEND_TOKEN,
  VERIFY_EMAIL
} from "../../actions/common/auth";
import {
  UPDATE_PASSWORD,
  UPDATE_USER_ACCOUNT
} from "../../actions/clinic/account";
// import { UPDATE_ACCOUNT, ACCEPT_TERMS_AND_CONDITIONS } from '../actions/customer';
import history from "../../history";
import {
  pristine,
  reduce,
  isRelatedAction,
  processServerErrors
} from "../utils";

var defaultState = {
  isAuthenticated: false,
  user: {
    id: 0,
    role: null,
    first_name: null,
    current_policy_and_terms_version: null,
    settings: {
      tooltipsModeOn: null,
      leftMenuOpened: null,
      initialAccount: { setup_completed: true },
      preferred_language: null
    },
    email_verified_date: null
  },
  token: null,
  isRecaptchaToken: false,
  recaptchaSiteKey: "",
  errors: [],
  email_verified: false,
  passwordStatus: pristine("")
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case failed(RESET_PASSWORD):
    case failed(REQUEST_PASSWORD_RESET):
      return {
        ...state,
        errors: processServerErrors(action.payload)
      };
    case requested(LOGIN):
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      return {
        ...state,
        isAuthenticated: false,
        user: defaultState.user,
        token: null,
        errors: []
      };

    case succeeded(LOGIN):
    case succeeded(RESTORE_LOGIN):
      const { token, ...user } = action.payload;
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...user,
          settings: { ...state.user.settings, ...user.settings }
        },
        token,
        isRecaptchaToken: state.isRecaptchaToken,
        errors: []
      };

    case failed(LOGIN):
    case error(LOGIN):
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      return {
        isAuthenticated: false,
        user: defaultState.user,
        token: null,
        isRecaptchaToken: state.isRecaptchaToken,
        errors: processServerErrors(action.payload)
      };

    case succeeded(LOGOUT):
    case failed(LOGOUT):
    case error(LOGOUT):
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      setTimeout(() => {
        history.push("/login");
      }, 500);
      return defaultState;

    case succeeded(REQUEST_PASSWORD_RESET):
      if (!state.isAuthenticated) {
        setTimeout(() => {
          history.push("/login?m=resetPasswordRequested");
        }, 500);
      }
      break;

    case succeeded(RESET_PASSWORD):
      setTimeout(() => {
        history.push("/login?m=passwordResetSuccess");
      }, 500);
      break;
    /*TODO     case succeeded(ACCEPT_TERMS_AND_CONDITIONS):
    case succeeded(UPDATE_ACCOUNT):
      return {
        ...state,
        user: {
          ...state.user,
          settings: { ...state.user.settings, ...action.payload.settings }
        }
      }; */
    case succeeded(UPDATE_USER_ACCOUNT):
      return {
        ...state,
        user: {
          ...state.user,
          //Role comes as number
          //role: action.payload.role,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
          address_1: action.payload.address_1,
          city: action.payload.city,
          // current_policy_and_terms_version: null,
          settings: { ...state.user.settings, ...action.payload.settings },
          email_verified_date: action.payload.email_verified_date
        }
      };

    case failed(UPDATE_USER_ACCOUNT):
    case error(UPDATE_USER_ACCOUNT):
      return {
        ...state,
        errors: processServerErrors(action.payload)
      };

    case succeeded(SET_RECAPTCHA_TOKEN):
      return { ...state, isRecaptchaToken: action.payload.success };

    case succeeded(GET_RECAPTCHA_SITEKEY):
      return { ...state, recaptchaSiteKey: action.payload };

    case succeeded(RESEND_TOKEN):
      return { ...state, errors: [action.payload] };
    case failed(RESEND_TOKEN):
    case error(RESEND_TOKEN):
      return { ...state, errors: processServerErrors(action.payload) };

    case succeeded(VERIFY_EMAIL):
      return {
        ...state,
        user: {
          ...user,
          email_verified_date: action.payload
        },
        email_verified: true
      };

    case failed(VERIFY_EMAIL):
      return {
        ...state,
        email_verified: false
      };

    default:
      break;
  }

  if (isRelatedAction(action, UPDATE_PASSWORD)) {
    return reduce(state, "passwordStatus", action, UPDATE_PASSWORD);
  }

  return state;
}
