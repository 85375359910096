import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../index.module.css";
import BodyWidget from "../../../../components/BodyWidget/BodyWidget";
import FieldGroup from "../../../../components/Forms/FieldGroup/FieldGroup";
import { useDispatch, useSelector } from "react-redux";
import _, { now } from "lodash";
import { Chart } from "react-google-charts";
import meses from "../../../../common/constants/months";

const MonthlySummary = ({ data }) => {
  const dispatch = useDispatch();

  const options = {
    // focusTarget: 'Conversions' '#EE7C70',
    animation: {
      startup: true,
      duration: 100
    },
    series: {
      0: { color: "#17c671", areaOpacity: 0.1, opacity: 0.1 },
      1: { color: "#43459d", areaOpacity: 0.1, opacity: 0.1 },
      2: { color: "#f1ca3a" },
      3: { color: "#6f9654" },
      4: { color: "#1c91c0" },
      5: { color: "#43459d" }
    },
    legend: "none",
    tooltip: {
      textStyle: {
        bold: false,
        color: "#000000",
        fontSize: 14
      },
      showColorCode: true,
      isHtml: false,
      text: "both",
      trigger: "both"
    }
  };

  return (
    <div className="h3-section">
      <h3>Ingresos - {meses[new Date().getMonth()]}</h3>
      <BodyWidget>
        <Row>
          <Col xs={12} md={12}>
            {data && data.length > 0 && (
              <Chart
                chartType={"AreaChart"}
                loader={<div>Loading Chart</div>}
                data={data}
                options={options}
                graph_id={"Mensual"}
                width="100%"
                height="200px"
                left="0px"
                top="0px"
              />
            )}
          </Col>
        </Row>
      </BodyWidget>
    </div>
  );
};

export default MonthlySummary;
